import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Button, Form, Image, Spin, Typography, message, Collapse } from 'antd';
import { useForm, useFieldArray } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import { InputField, SelectField, InputCheckbox } from '../../../../../atoms/FormElement';
import plusIcon from '../../../../../../assets/svg/plus.svg';
import trashIcon from '../../../../../../assets/svg/trash-icon.svg';
import { useHistory } from 'react-router-dom';
import { addObject, checkIfAccountIsReported, downloadFile, saveFile } from '../../../../../../services/crudApis';
import { SAVE_ACCOUNTS } from '../../../../../../configs/endpoints';
import { useSelector, useDispatch } from 'react-redux';
import { getAccountInfoFormData, getFinancialInstitutes } from '../../ducks/actions';
import ContactBox from '../../../../../molecules/Dashboard/ContactBox';
import { Popup } from '../../../../../atoms/Popup';
import ConfirmDelete from '../../../../../molecules/ConfirmDelete';
import ReportAmendment from '../../../../../molecules/ReportAmendment';
import ChangeBankAccount from '../../ChangeBankAccount';
import { DownloadIcon } from '../../../../../atoms/CustomIcons';
import { baseUrl } from '../../../../../../configs/constants';
import ArrowIconNew from '../../../../../atoms/CustomIcons/ArrowIconNew';

const _ = require('lodash');
const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;
const { Panel } = Collapse;

const initQ = {
  accountId: '',
  structure_name: 'Accounts',
  code: '',
  type: '',
  account_number: '',
  financial_institute_name: '',
  address: {
    mailing_address: '',
    state: '',
    city: '',
    post_code: '',
  },
  purpose: '',
};

export default (props) => {
  const { control, errors, clearErrors, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      accounts_committee: [{ name: 'useFieldArray' }],
    },
  });
  const [loading, setLoading] = useState(false);
  const [deletedIndexes, setDeletedIndexes] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [contactBoxEditCase, setContactBoxEditCase] = useState('');
  const [bankAccountDropdown, setBankAccountDropdown] = useState([]);
  const [contactToSelect, setContactToSelect] = useState(null);
  const [reportVisible, setReportVisible] = useState(false);
  const [isReportId, setIsReportId] = useState(false);
  const [notDeleted, setNotDeleted] = useState(false);
  const [changeAccount, setChangeAccount] = useState(false);
  const [accountId, setAccountId] = useState('');

  const formData = useSelector((state) => state.setting.accountFormData);
  const getFinancialInstitutesData = useSelector((state) => state.setting.getFinancialInstitutesData);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'accounts_committee',
  });

  const onRemove = async (e, index) => {
    if (e.accountId != '') {
      setDeletedIndexes([...deletedIndexes, e.accountId]);
      setAccountId(e.accountId);
      const accountsArray = [];
      await formData?.accounts?.map((x) => {
        if (e?.accountId !== x?.accountId) {
          accountsArray?.push({
            label: x?.code,
            value: x?.accountId,
          });
        }
      });
      setBankAccountDropdown(accountsArray);
      checkIfAccountIsReported(e.accountId)
        .then((e) => {
          //console.log('e?.response', e);
          if (e?.data?.isAccountReports) {
            setChangeAccount(true);
          } else {
            remove(index);
          }
        })
        .catch(() => message.error('Something went wrong.'));
    } else {
      remove(index);
    }
  };

  const onAdd = () => {
    append(initQ);
    let leng = `${fields.length + 1}`;
  };
  useEffect(() => {
    dispatch(getAccountInfoFormData());
    dispatch(getFinancialInstitutes());
    // setLoading(true);
  }, []);

  useEffect(() => {
    if (formData && formData?.accounts && Object.keys(formData?.accounts).length > 0) {
      setContactToSelect(null);
      setValue('accounts_committee', formData?.accounts);
    }
  }, [formData]);

  const changeBankAccount = () => {
    dispatch(getAccountInfoFormData());
    setChangeAccount(false);
    message.success('Bank Account Deleted.');
  };

  const popNotDeleted = {
    closable: false,
    visibility: notDeleted,
    class: 'black-modal',
    content: (
      <ConfirmDelete
        title="Couldn't Delete the Bank Account(s)"
        paragraph="This account(s) is used in one or more transactions. Please delete these transactions first in order to delete this bank account(s)."
        onClose={() => setNotDeleted(false)}
        PpopUpNotDeleted={true}
      />
    ),
    width: 450,
    onCancel: () => setNotDeleted(false),
  };

  const changeFiledAccount = {
    closable: false,
    visibility: changeAccount,
    class: 'black-modal',
    content: (
      <ChangeBankAccount
        title="Delete Bank Account"
        onClose={() => setChangeAccount(false)}
        PpopUpNotDeleted={true}
        bankAccountDropdown={bankAccountDropdown}
        accountId={accountId}
        afterConfirm={() => changeBankAccount()}
      />
    ),
    width: 542,
    onCancel: () => setChangeAccount(false),
  };

  const onFinish = (val) => {
    setLoading(true);
    if (formData && formData?.accounts && Object.keys(formData?.accounts)?.length > 0 && !val?.effectiveDate) {
      formData?.accounts?.map((e, i) => {
        val?.accounts_committee.find((x, ind) => {
          if (
            (i === ind && x?.code !== e?.code && x?.accountId === e?.accountId) ||
            (i === ind &&
              x?.financial_institute_name?.value !== e?.financial_institute?.value &&
              x?.accountId === e?.accountId) ||
            (i === ind && x?.purpose !== e?.purpose && x?.accountId === e?.accountId)
            //|| x?.accountId === ''
          ) {
            setIsReportId(true);
            setReportVisible(true);
            setLoading(false);
            return;
          }
        });
      });
    }

    let accountArray = [];
    if (val && val.accounts_committee && val.accounts_committee.length > 0) {
      accountArray = val.accounts_committee.map((resp) => ({
        accountId: resp?.accountId,
        code: resp.code,
        type: resp.type.value,
        account_number: resp.account_number,
        contact_id:
          Object.prototype.toString.call(resp.financial_institute_name) === '[object Array]'
            ? resp.financial_institute_name[0].value
            : resp.financial_institute_name.value,
        purpose: resp.purpose,
        is_deleted: deletedIndexes.includes(resp?.accountId),
      }));
    }
    accountArray = [...accountArray, deletedIndexes];

    const payload = {
      dateEffective: val?.effectiveDate ? val?.effectiveDate : false,
      accounts: accountArray,
    };
    addObject(SAVE_ACCOUNTS, payload)
      .then((response) => {
        //console.log('response', response);
        setLoading(false);

        if (response?.data?.notDeleted?.length > 0) {
          setNotDeleted(true);
        } else {
          setNotDeleted(false);
          message.success('Information Updated.');
        }

        dispatch(getAccountInfoFormData());
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
    setTimeout(() => setLoading(false), 1000);
  };

  const accountType = [
    { label: 'CD', value: 'cd' },
    { label: 'Checking', value: 'checking' },
    { label: 'Credit Card', value: 'credit_card' },
    { label: 'Money Market', value: 'money_market' },
    { label: 'Savings', value: 'savings' },
  ];

  const changeValueByDropDown = () => {
    dispatch(getFinancialInstitutes());
  };

  const updateDropdownValues = () => {
    dispatch(getFinancialInstitutes());
  };

  const submitFileExternal = (date) => {
    const value = getValues();
    setReportVisible(false);
    value.effectiveDate = date;
    onFinish(value);
  };

  const financialCertificate = () => {
    try {
      setLoading(true);
      downloadFile(`${baseUrl}/getCertificatePDF`)
        .then((response) => {
          saveFile(
            response,
            `Electafile - Certification of Financial Account information - ${new Date().toISOString()}.pdf`,
          );
          setLoading(false);
        })
        .catch((error) => {
          message.error('Something went wrong');
          setLoading(false);
        });
    } catch (error) {
      message.error('Something went wrong');
      setLoading(false);
    }
  };

  return (
    <>
      <Spin indicator={antIcon} size="large" spinning={loading}>
        <Row justify="space-between">
          <Col>
            <Title level={3}>Bank Accounts</Title>
          </Col>
          <Col>
            <Button
              type="text"
              htmlType="button"
              className="font-gilroy white-btn setting_btn"
              onClick={financialCertificate}
              icon={<DownloadIcon />}
            >
              Cert. of Financial Account information
            </Button>
          </Col>
        </Row>
        <Row>
          <Col flex="0 0 558px">
            <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
              <Row gutter={[20, 30]} align="bottom" className="add_account">
                <Col span={24}>
                  <Collapse
                    className="setting_collapse"
                    // defaultActiveKey={['1']}
                    expandIconPosition="right"
                    expandIcon={() => <ArrowIconNew />}
                  >
                    {formData &&
                      fields?.map((item, index) => (
                        <Panel header={`Account #${index + 1}`} key={index + 1} forceRender={true}>
                          <Row gutter={[20, 20]} align="bottom" className="add_account_repeat">
                            <InputField
                              fieldname={`accounts_committee[${index}].accountId`}
                              label=""
                              control={control}
                              class="d-none mb-0"
                              initValue={item?.accountId}
                              iProps={{ size: 'large' }}
                            />
                            <Col span={24}>
                              <InputField
                                fieldname={`accounts_committee[${index}].code`}
                                label="Account Code"
                                control={control}
                                hintMessage="Come up with any combination of numbers or/and letters."
                                tooltip={true}
                                tooltipTitle="This code is a unique identifier (ex. 1 or A) used to reference the bank account number for the committee (the bank account number should not appear on the disclosure reporting forms). If new accounts are opened a unique code must be used."
                                class="mb-0"
                                //initValue={item?.code}
                                valueGot={item?.code}
                                setValue={setValue}
                                iProps={{ size: 'large' }}
                                rules={{ required: 'Account Code is required.' }}
                                validate={
                                  Object.entries(errors).length > 0 &&
                                  errors?.accounts_committee?.length > 0 &&
                                  errors?.accounts_committee[index]?.code &&
                                  'error'
                                }
                                validMessage={
                                  Object.entries(errors).length > 0 &&
                                  errors?.accounts_committee?.length > 0 &&
                                  errors?.accounts_committee[index]?.code &&
                                  errors?.accounts_committee[index]?.code?.message
                                }
                              />
                              {/* {index > 0 && ( */}
                              <div className="accounts_remove">
                                <Button type="link" className="p-0" onClick={() => onRemove(item, index)}>
                                  <Image src={trashIcon} preview={false} size={24} />
                                </Button>
                              </div>
                              {/* )} */}
                            </Col>

                            <Col span={24}>
                              <SelectField
                                fieldname={`accounts_committee[${index}].type`}
                                label="Account Type"
                                control={control}
                                class="mb-0"
                                //initValue={item.type ? accountType.find((e) => e.value == item.type) : ''}
                                iProps={{}}
                                valueGot={item.type ? accountType.find((e) => e.value == item.type) : ''}
                                setValue={setValue}
                                selectOption={accountType}
                                rules={{ required: 'Account Type is required.' }}
                                validate={
                                  Object.entries(errors).length > 0 &&
                                  errors?.accounts_committee?.length > 0 &&
                                  errors?.accounts_committee[index]?.type &&
                                  'error'
                                }
                                validMessage={
                                  Object.entries(errors).length > 0 &&
                                  errors?.accounts_committee?.length > 0 &&
                                  errors?.accounts_committee[index]?.type &&
                                  errors?.accounts_committee[index]?.type?.message
                                }
                              />
                            </Col>
                            <Col span={24}>
                              <InputField
                                fieldname={`accounts_committee[${index}].account_number`}
                                label="Account Number"
                                control={control}
                                class="mb-0"
                                //initValue={item?.account_number}
                                valueGot={item?.account_number}
                                setValue={setValue}
                                iProps={{ size: 'large' }}
                                rules={{ required: 'Account Number is required.' }}
                                validate={
                                  Object.entries(errors).length > 0 &&
                                  errors?.accounts_committee?.length > 0 &&
                                  errors?.accounts_committee[index]?.account_number &&
                                  'error'
                                }
                                validMessage={
                                  Object.entries(errors).length > 0 &&
                                  errors?.accounts_committee?.length > 0 &&
                                  errors?.accounts_committee[index]?.account_number &&
                                  errors?.accounts_committee[index]?.account_number?.message
                                }
                              />
                            </Col>

                            <ContactBox
                              control={control}
                              errors={errors}
                              selectData={getFinancialInstitutesData && getFinancialInstitutesData}
                              changeValueByDropDown={changeValueByDropDown}
                              updateDropdownValues={updateDropdownValues}
                              arraySelectedItem={item?.financial_institute}
                              contactBoxEditCase={contactBoxEditCase}
                              contactToSelect={contactToSelect}
                              setContactToSelect={setContactToSelect}
                              setValue={setValue}
                              clearErrors={clearErrors}
                              fieldname={`accounts_committee[${index}].financial_institute_name`}
                              errorField={
                                Object.entries(errors).length > 0 &&
                                errors?.accounts_committee?.length > 0 &&
                                errors?.accounts_committee[index]?.financial_institute_name
                              }
                              label="Financial Institution Name"
                              fixedContactType="financial_institution"
                            />

                            <Col span={24}>
                              <InputField
                                isRequired={true}
                                fieldname={`accounts_committee[${index}].purpose`}
                                label="Account Purpose"
                                control={control}
                                class="mb-0"
                                valueGot={item?.purpose}
                                setValue={setValue}
                                //initValue={item?.purpose}
                                iProps={{ size: 'large' }}
                                rules={{ required: 'Account Purpose is required.' }}
                                validate={
                                  Object.entries(errors).length > 0 &&
                                  errors?.accounts_committee?.length > 0 &&
                                  errors?.accounts_committee[index]?.purpose &&
                                  'error'
                                }
                                validMessage={
                                  Object.entries(errors).length > 0 &&
                                  errors?.accounts_committee?.length > 0 &&
                                  errors?.accounts_committee[index]?.purpose &&
                                  errors?.accounts_committee[index]?.purpose?.message
                                }
                                tooltip={true}
                                tooltipTitle="Provide the purpose for which this account is used. An example would be “for all campaign expenses”, “for House funds”, etc."
                              />
                            </Col>
                          </Row>
                        </Panel>
                      ))}
                  </Collapse>
                </Col>
                <Col span={24}>
                  <Button
                    type="text"
                    htmlType="button"
                    className="font-gilroy c-grey d-flex align-center"
                    onClick={onAdd}
                  >
                    <Image src={plusIcon} preview={false} size={24} />
                    Add Account
                  </Button>
                </Col>

                <Col span={24}>
                  <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={24} style={{ marginTop: 40 }}>
            <Button
              type="text"
              htmlType="button"
              className="font-gilroy c-grey d-flex align-center"
              onClick={() =>
                history.push({
                  pathname: `/previous-transactions`,
                  state: {
                    bankTransaction: 'settings',
                  },
                })
              }
            >
              Change the initial election cycle begin bAlance
            </Button>
          </Col>
        </Row>
      </Spin>
      <ReportAmendment
        reportVisible={reportVisible}
        setReportVisible={setReportVisible}
        submitFileExternal={submitFileExternal}
        dateEffective={true}
      />
      <Popup {...popNotDeleted} />
      <Popup {...changeFiledAccount} />
    </>
  );
};
