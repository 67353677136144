import React from 'react';
import Icon from '@ant-design/icons';

const CloseIcon = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.03 8.03a.75.75 0 00-1.06-1.06L12 10.94 8.03 6.97a.75.75 0 00-1.06 1.06L10.94 12l-3.97 3.97a.75.75 0 101.06 1.06L12 13.06l3.97 3.97a.75.75 0 001.06-1.06L13.06 12l3.97-3.97z"
      fill="currentColor"
    />
  </svg>
);

const CloseSvg = (props) => <Icon component={CloseIcon} {...props} />;
export default CloseSvg;
