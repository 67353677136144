import React from 'react';
import Icon from '@ant-design/icons';

const ContactList = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.92383" cy="7.85352" r="4" stroke="white" strokeWidth="1.5" />
    <path d="M3.61002 20.1465C3.13722 19.389 3.17525 18.06 3.61002 17.4525C4.33295 16.4422 5.73533 15.8535 8.92311 15.8535C12.1109 15.8535 13.5133 16.4422 14.2362 17.4525C14.671 18.0601 14.709 19.3891 14.2362 20.1465" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M17.1897 6H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M17.4046 8.99994L21 8.99999" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M17 12H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

const ContactListIcon = (props) => <Icon component={ContactList} {...props} />;
export default ContactListIcon;
