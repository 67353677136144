import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Spin, Descriptions, message } from 'antd';
import { useForm } from 'react-hook-form';
import { DateField } from '../../../../../../atoms/FormElement';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { countryList } from '../../../../../../../configs/constants';
import { countyList } from '../../../../../../../configs/county-list';
import { getReportFormData } from '../../../ducks/actions';
import { ReportTypeCode } from '../../../../../../../configs/constants';
import { addObject } from '../../../../../../../services/crudApis';
import { ADD_REPORT } from '../../../../../../../configs/endpoints';
import moment from 'moment';
import { useLocation } from 'react-router';

const { Title, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { currentStep, setCurrentStep, setstartDate, setendDate, setReportId, getYear } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState(false);
  const reportFormData = useSelector((state) => state.report.reportFormData);
  const location = useLocation();
  const url = location?.pathname?.split('/')[3]?.split('?')[0];
  useEffect(() => {
    dispatch(getReportFormData(ReportTypeCode.FEHR, false, getYear));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (reportFormData) {
      setValue(
        'start_date',
        reportFormData?.startPeriod ? moment(reportFormData?.startPeriod, 'MM-DD-YYYY') : '',
      );
      setValue('end_date', reportFormData?.endPeriod ? moment(reportFormData?.endPeriod, 'MM-DD-YYYY') : '');
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [reportFormData]);

  const onFinish = (values) => {
    setLoading(true);
    const startDate = reportFormData?.startPeriod;
    const enddate = reportFormData?.endPeriod;
    setstartDate(moment(startDate, 'MM-DD-YYYY').format('YYYY-MM-DD'));
    setendDate(moment(enddate, 'MM-DD-YYYY').format('YYYY-MM-DD'));

    let requestBody = {
      type: ReportTypeCode.FEHR,
      status: 'draft',
      start_date: values?.start_date && moment(values?.start_date).format('YYYY-MM-DD HH:mm:ss') + 'Z',
      end_date: values?.end_date && moment(values?.end_date).format('YYYY-MM-DD HH:mm:ss') + 'Z',
      id: url ?? undefined,
      parent_id: location?.state?.parent_id ?? undefined,
    };

    //console.log('values', JSON.stringify(values));

    addObject('fehrReport', requestBody)
      .then((response) => {
        //console.log('response', response);
        if (response.data) {
          setReportId(response.data);
        }
        setLoading(false);
        setTimeout(() => setCurrentStep(currentStep + 1), 1200);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
        //console.log(e);
      });
  };

  const disabledStartDate = (current) => {
    return current && moment().subtract(1, 'days') < current;
  };
  const disabledEndDate = (current) => {
    return current && moment() < current;
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row justify="center">
        <Col flex="0 0 500px">
          <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <div className="detail_label_ins">
                  <Descriptions layout="vertical" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
                    <Descriptions.Item label="Committee Name">{reportFormData?.committeeName}</Descriptions.Item>
                    <Descriptions.Item label="Board of Elections ID">{reportFormData?.boeId}</Descriptions.Item>
                  </Descriptions>
                </div>
              </Col>

              <Col span={12}>
                <DateField
                  fieldname="start_date"
                  label="Report Start Date"
                  control={control}
                  class="mb-0"
                  iProps={{ size: 'large', disabledDate: disabledStartDate }}
                  initValue=""
                  isRequired={true}
                  rules={{
                    required: 'Report Start Date is required.',
                  }}
                  validate={errors.start_date && 'error'}
                  validMessage={errors.start_date && errors.start_date.message}
                />
              </Col>

              <Col span={12}>
                <DateField
                  fieldname="end_date"
                  label="Report End Date"
                  control={control}
                  class="mb-0"
                  iProps={{ size: 'large', disabledDate: disabledEndDate }}
                  initValue=""
                  isRequired={true}
                  rules={{
                    required: 'Report End Date is required.',
                  }}
                  validate={errors.end_date && 'error'}
                  validMessage={errors.end_date && errors.end_date.message}
                />
              </Col>

              <Col span={24}>
                <Paragraph className="hint-text">
                  All contributions that fall within the selected period will be reported. <br />
                  If contribution was already reported before, it won't be included into this notice. <br />
                  <br />
                  Please keep in mind that the notice must be filed within 48 hours of receipt of contribution.
                </Paragraph>
              </Col>

              <Col span={24} className="text-center">
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Continue
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};
