import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Select, Form, Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Dashboard from '../../../../../pages/Dashboard';
import OperatingExpense from '../../ExpendituresForms/OperatingExpense';
import RefundReimburse from '../../ExpendituresForms/RefundReimburse';
import NonMonetaryGift from '../../ExpendituresForms/NonMonetaryGift';
import IndependentExpenditure from '../../ExpendituresForms/IndependentExpenditure';
import CoordinatedPartyExpenditure from '../../ExpendituresForms/CoordinatedPartyExpenditure';
import ContributionToCandidate from '../../ExpendituresForms/ContributionToCandidate';
import LoanRepayment from '../../ExpendituresForms/LoanRepayment';
import { addExpenditure } from '../../../../../../services/expenditure';
import { Popup } from '../../../../../atoms/Popup';
import LimitWarning from '../../../../../molecules/LimitWarning';
import DebtRepayment from '../../ExpendituresForms/DebtRepayment';
import Confirmation from '../../Popup/Confirmation';

const { Paragraph } = Typography;
const { Option } = Select;

const expenditureTypeList = [
  { label: 'Operating Expense', value: 'operating_expense' },
  {
    label: 'Contribution to Candidate or Political Committee',
    value: 'contribution_to_candidate_or_political_committee',
  },
  { label: 'Coordinated Party Expenditure', value: 'coordinated_party_expenditure' },
  { label: 'Loan Repayment', value: 'loan_repayment' },
  { label: 'Refund/Reimbursement from the Committee', value: 'refund' },
  { label: 'Non-Monetary Gift', value: 'gift' },
  { label: 'Debt Repayment', value: 'debt_repayment' },
  { label: 'Independent Expenditure', value: 'independent_expenditure' },
];

export default (props) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setError, setValue, errors, reset, watch, clearErrors } = useForm({
    shouldUnregister: false,
  });
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  let selectValue = url.split('/')[2].replace(/-/g, '_');
  const [expenditureType, setExpenditureType] = useState(selectValue);
  const [visibleAddReceipt, setVisibleAddReceipt] = useState(false);
  const [mediaWarning, setMediaWarning] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(null);
  const [confirmation, setIsConfirmation] = useState(false);
  const [oldAmount, setOldAmount] = useState(0);
  const [highLimit, setHighlimit] = useState(false);
  const [lowLimit, setLowlimit] = useState(false);
  const [popdata, setPopData] = useState();
  const [getdataValue, setdataValue] = useState('');

  const limitWarningPopup = {
    // closable: true,
    visibility: visibleAddReceipt,
    class: 'black-modal',
    content: (
      <LimitWarning
        title="Expenditure Limit Warning"
        errorHigh="Contributions made to other candidate or political action commettees cannot exceed $6,400."
        errorLow="Non-media expenditures of more than $50, except postage, may not be made in cash."
        btnTxt="Save Expenditure anyway"
        cashTitle="Cash Expenditure Warning"
        bottomText="If the current contribution doesn't exceed $6,400, please check all the contributions you have made to this committee."
        tableText="Expenditures That Counted Toward the Limit"
        visibleAddReceipt={visibleAddReceipt}
        mediaWarning={mediaWarning}
        onClose={() => setVisibleAddReceipt(false)}
        highLimit={highLimit}
        lowLimit={lowLimit}
        onFinish={() => onFinish(getdataValue, true)}
        getdataValue={getdataValue}
        popdata={popdata}
      />
    ),
    width: 542,
    onCancel: () => setVisibleAddReceipt(false),
  };

  const handleChange = (value) => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
    setTimeout(() => {
      setExpenditureType(value);
      history.push(value);
    }, 1200);
  };

  useEffect(() => {
    reset();
  }, []);

  const onFinish = (formData, force = false, confirmPopup = false) => {
    if (confirmPopup) {
      setIsConfirmation(false);
    }
    const mainAmount = oldAmount + remainingAmount;
    setdataValue(formData);
    if (formData?.purpose_code && formData?.purpose_code?.length == 0) {
      setError('purpose_code', { type: 'required', message: 'Purpose Code is required.' });
      return;
    }

    if (
      Math.round((+formData?.amount + Number.EPSILON) * 100) / 100 > mainAmount &&
      expenditureType == 'debt_repayment'
    ) {
      setError('amount', {
        type: 'required',
        message: 'Please enter an amount that does not exceed the remaining debt balance.',
      });
      return;
    }
    if (+formData?.amount === 0 && expenditureType == 'debt_repayment') {
      setError('amount', {
        type: 'required',
        message: 'The Amount Repaid cannot be 0.',
      });
      return;
    }
    if (
      Math.round((+formData?.amount + Number.EPSILON) * 100) / 100 === mainAmount &&
      !confirmPopup &&
      expenditureType == 'debt_repayment'
    ) {
      setIsConfirmation(true);
      return;
    }

    setLoading(true);
    if (formData?.purpose_code && formData?.purpose_code[0]?.value) {
      let pc = '';
      for (const code of formData.purpose_code) {
        pc += code.value;
      }
      formData.purpose_code = pc;
    }
    if (formData.coordinated_committee_name && formData.coordinated_committee_name.value) {
      formData.coordinated_committee_id = formData.coordinated_committee_name.value;
      delete formData.coordinated_committee_name;
    }
    if (formData.payee_id && formData.payee_id.value) {
      formData.payee_id = formData.payee_id.value;
    }

    if (formData.obligation_id && formData.obligation_id.value) {
      formData.obligation_id = formData.obligation_id.value;
    }

    if (formData.receipt_refunded_type && formData.receipt_refunded_type.value) {
      formData.receipt_refunded_type = formData.receipt_refunded_type.value;
    }
    formData.type = expenditureType;
    if (formData.account && formData.account.value) {
      formData.account_id = formData.account.value;
      delete formData.account;
    }
    if (formData.type_of_gift) {
      formData.type_of_gift =
        Object.prototype.toString.call(formData.type_of_gift) === '[object Array]'
          ? formData.type_of_gift[0].value
          : formData.type_of_gift.value;
    }
    if (formData.date) {
      formData.date = moment(formData.date).format('YYYY-MM-DD HH:mm:ss') + 'Z';
    }
    if (formData.delaration_is_supporting) {
      formData.delaration_is_supporting = formData.delaration_is_supporting == 'Support' ? true : false;
    }
    // if (formData.is_in_kind_contribution) {
    //   formData.is_in_kind_contribution = formData.is_in_kind_contribution == 'Support' ? true : false;
    // }
    if (formData.loan_id) {
      formData.loan_id = formData.loan_id.value;
    }
    formData.force = force === true;
    addExpenditure(formData)
      .then((response) => {
        //console.log('response', response);
        setTimeout(() => {
          setLoading(false);
          message.success('Expenditure added.');
        }, 800);
        setTimeout(() => {
          history.push('/expenditures');
        }, 1200);
      })
      .catch((e) => {
        setLoading(false);
        if (e && e?.response && e?.response['status'] == '412') {
          const message = JSON.parse(e?.response?.data?.error?.message);
          setMediaWarning(false);
          setHighlimit(false);
          setPopData();
          setLowlimit(false);

          if (message?.listOfEntities?.length > 0) {
            setHighlimit(true);
            setPopData(message?.listOfEntities);
          }

          if (message?.errors?.CONTRIBUTION_LIMIT_REACHED) {
            setHighlimit(true);
          }

          if (message?.errors?.CASH_FIFTY) {
            setLowlimit(true);
          }

          if (message?.errors?.MEDIA_EXPENDITURE) {
            setMediaWarning(true);
          }

          setVisibleAddReceipt(true);
        } else message.error('Something went wrong');
      });
  };

  const ConfirmationPopup = {
    closable: false,
    visibility: confirmation,
    class: 'black-modal confirmation_modal',
    content: (
      <Confirmation
        onClose={() => setIsConfirmation(false)}
        title="Confirmation Of Full Debt Repayment"
        onConfirm={() => onFinish(getdataValue, true, true)}
        paragraph={
          <>
            The entered amount fully covers the remaining debt outstanding balance. The debt will be marked as{' '}
            <b>Paid.</b>
            <br />
            <br />
            Please confirm if you would like to proceed.
          </>
        }
      />
    ),
    width: 542,
    onCancel: () => setIsConfirmation(false),
  };

  return (
    <>
      <Dashboard load={loading} heading="New Expenditure" backLink="/expenditures">
        <Card className="white-card w-100">
          <Row justify="center" gutter={[20, 20]}>
            <Col span={24}>
              <Select defaultValue={expenditureType} onChange={handleChange} className="custom-ant-select">
                {expenditureTypeList &&
                  expenditureTypeList?.map((item, index) => (
                    <Fragment key={index}>
                      <Option value={item?.value}>{item?.label}</Option>
                    </Fragment>
                  ))}
              </Select>
            </Col>

            {expenditureType == 'gift' && (
              <Col span={24}>
                <Paragraph className="c-blue">
                  Use this form to report an “in-kind”, non-monetary gift, service or item to another committee.
                  Non-Monetary gifts will
                  <br /> NOT be entered in the RECEIPTS or EXPENDITURES on the Detailed Summary, but will be included
                  for information <br />
                  purposes at the bottom of the Detailed Summary. The committee receiving the gift will continue to
                  report the gift as an <br />
                  “in-kind” contribution.
                </Paragraph>
              </Col>
            )}

            {expenditureType == 'operating_expense' && (
              <Col span={24}>
                <Paragraph>
                  <b>Expenditure limits note:</b>
                </Paragraph>
                <ol style={{ paddingLeft: '15px' }}>
                  <li>Media expenditures may not be made in cash.</li>
                  <li>Non-media expenditures of more than $50, except postage, may not be made in cash.</li>
                </ol>
              </Col>
            )}

            {expenditureType == 'contribution_to_candidate_or_political_committee' && (
              <Col span={24}>
                <Paragraph>
                  <b>Expenditure limits note:</b>
                </Paragraph>
                <ol style={{ paddingLeft: '15px' }}>
                  <li>
                    Contributions made to other candidate or political action commettees cannot exceed $6,400.{' '}
                    <a
                      href="https://www.ncsbe.gov/campaign-finance/candidate-committees"
                      className="c-doger-blue"
                      target="_blank"
                    >
                      Learn More
                    </a>
                  </li>
                </ol>
              </Col>
            )}
          </Row>

          <Form className="w-100 mt-2" layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            {expenditureType == 'operating_expense' && (
              <OperatingExpense control={control} errors={errors} setValue={setValue} setLoading={setLoading} />
            )}

            {expenditureType == 'debt_repayment' && (
              <DebtRepayment
                control={control}
                errors={errors}
                setValue={setValue}
                setLoading={setLoading}
                remainingAmount={remainingAmount}
                setRemainingAmount={setRemainingAmount}
                setOldAmount={setOldAmount}
                setError={setError}
                clearErrors={clearErrors}
                watch={watch}
              />
            )}

            {expenditureType == 'contribution_to_candidate_or_political_committee' && (
              <ContributionToCandidate control={control} errors={errors} setValue={setValue} setLoading={setLoading} />
            )}

            {expenditureType == 'coordinated_party_expenditure' && (
              <CoordinatedPartyExpenditure
                control={control}
                errors={errors}
                setValue={setValue}
                setLoading={setLoading}
              />
            )}

            {expenditureType == 'refund' && (
              <RefundReimburse control={control} errors={errors} setValue={setValue} setLoading={setLoading} />
            )}

            {expenditureType == 'loan_repayment' && (
              <LoanRepayment control={control} errors={errors} setValue={setValue} setLoading={setLoading} />
            )}

            {expenditureType == 'gift' && (
              <NonMonetaryGift control={control} errors={errors} setValue={setValue} setLoading={setLoading} />
            )}

            {expenditureType == 'independent_expenditure' && (
              <IndependentExpenditure control={control} errors={errors} setValue={setValue} setLoading={setLoading} />
            )}

            <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => history.push('/expenditures')}
                  htmlType="button"
                  className="font-gilroy white-btn"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Dashboard>
      <Popup {...limitWarningPopup} />
      <Popup {...ConfirmationPopup} />
    </>
  );
};
