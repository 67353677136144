import axiosInterceptor from '../../../../../services/axiosInterceptor';
import { baseUrl } from '../../../../../configs/constants';

export const addAdminSupports = (data) => {
  return axiosInterceptor.post(baseUrl + '/updateAdminSupport', data);
};

export const deleteSupport = (ids) => {
  return axiosInterceptor.delete(baseUrl + '/deleteAdminSupportByReportId/' + ids);
};

export const obligationBy = (data) => {
  return axiosInterceptor.post(baseUrl + '/updateObligation', data);
};

export const obligationTo = (data) => {
  return axiosInterceptor.post(baseUrl + '/updateObligation', data);
};

export const deleteDebts = (id) => {
  return axiosInterceptor.delete(baseUrl + `/obligations/${id}`);
};

export const deleteMultipleDebts = (ids) => {
  return axiosInterceptor.post(baseUrl + `/deleteObligations`, ids);
};

// export const deleteSupport = (ids) => {
//   return axiosInterceptor.delete(baseUrl + '/deleteAdminSupportByReportId/' + ids);
// };
