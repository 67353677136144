import React, { useState, useEffect } from 'react';
import Dashboard from '../../../pages/Dashboard';
import { Card, Table, Row, Col, Typography, Button, Menu, Dropdown, Tag, Image, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getAdminData } from './ducks/actions';

const { Text } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const adminData = useSelector((state) => state.adminData.adminDataList);

  //console.log('adminData', adminData);

  useEffect(() => {
    dispatch(getAdminData());
  }, []);

  const listColumns = [
    {
      title: 'Committee Name',
      dataIndex: 'committeeName',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Committee Email',
      dataIndex: 'email',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Treasurer Name',
      dataIndex: 'treasurerName',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Treasurer Email',
      dataIndex: 'treasurerEmail',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text) => (text ? text : '-'),
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
    },
    {
      title: 'Filed Date',
      dataIndex: 'filed_date',
      key: 'filed_date',
    },
  ];

  return (
    <Dashboard load={loading} heading="Admin Data">
      <Card className="white-card w-100 pcard-0">
        <Table
          columns={listColumns}
          dataSource={adminData}
          className="cursor_table"
          expandable={{
            expandedRowRender: (record) => <Table columns={columns} dataSource={record?.children} pagination={false} />,
            rowExpandable: (record) => record.children?.length > 0,
          }}
        />
      </Card>
    </Dashboard>
  );
};
