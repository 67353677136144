import React, { useState } from 'react';
import { Card, Spin, Button, Row, Col, Typography, Image, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import cancelPayment from '../../../../../assets/svg/cancel-payment.svg';
import Dashboard from '../../../../pages/Dashboard';
import { DownloadIcon } from '../../../../atoms/CustomIcons';

const antIcon = <LoadingOutlined spin />;
const { Title, Text, Paragraph } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  return (
    <Dashboard load={loading} heading="Cancel">
      <Card className="white-card w-100">
        <Row justify="center">
          <Col span={24}>
            <Title level={3} className="text-center c-red">
              Payment Didn't Go Through
            </Title>
          </Col>
          <Col flex="0 0 400px">
            <Row className="text-center" gutter={[30, 30]}>
              <Col span={24}>
                <Image width={136} src={cancelPayment} preview={false} />
              </Col>
              <Col span={24}>
                <Text className="smallFont14">
                  Something went wrong with the payment. <br />
                  Your report was not submitted.
                </Text>
              </Col>

              <Col span={24}>
                <Row justify="center" gutter={[20, 20]}>
                  <Col>
                    <Button
                      type="secondary"
                      htmlType="submit"
                      className="font-gilroy white-btn"
                      size="large"
                      onClick={() => history.push('/reports')}
                    >
                      Return to reports
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="font-gilroy"
                      size="large"
                      onClick={() => history.push('/reports')}
                    >
                      Try again
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Dashboard>
  );
};
