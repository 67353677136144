import React, { useState } from 'react';
import { Typography, Row, Col, Image, Button } from 'antd';
import rejected from '../../../../../assets/svg/rejected.svg';
import { useHistory } from 'react-router-dom';

const { Title, Paragraph } = Typography;

export default (props) => {
  const { onClose } = props;

  const history = useHistory();

  return (
    <Row gutter={[35, 35]} className="text-center">
      <Col span={24}>
        <Image src={rejected} preview={false} width={136} className="mb-1" />
        <Paragraph className="smallFont14">
          The importing process was interrupted.
          <br /> Please try again.
        </Paragraph>
      </Col>
      <Col span={24}>
        <Button type="primary" htmlType="submit" className="font-gilroy" size="large" onClick={onClose}>
          Try again
        </Button>
      </Col>
    </Row>
  );
};
