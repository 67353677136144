import { getFinancialInstitutes } from './actions';
import * as action_types from './constants';

const initialState = {
  committeeFormData: {},
  candidateFormData: {},
  accountFormData: [],
  officerFormData: {},
  getFinancialInstitutesData: [],
  getLogsData: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.COMMITTEE_FORM_DATA:
      return { ...state, committeeFormData: data };

    case action_types.CANDIDATE_FORM_DATA:
      return { ...state, candidateFormData: data };

    case action_types.OFFICER_FORM_DATA:
      return { ...state, officerFormData: data };

    case action_types.ACCOUNT_FORM_DATA:
      return { ...state, accountFormData: data };

    case action_types.COMMITTEE_DROP_DATA:
      return { ...state, committeeDropData: data };

    case action_types.COMMITTEE_LIST_DATA:
      return { ...state, committeeListData: data };

    case action_types.FINANCIAL_INSTITUTES:
      return { ...state, getFinancialInstitutesData: data };

    case action_types.LOGS_BY_COMMITTEE_ID:
      return { ...state, getLogsData: data };

    case action_types.CLEAR_DATA:
      return { ...state, getLogsData: [] }

    default:
      return state;
  }
};
