import React, { useState } from 'react';
import { Steps } from 'antd';
import AddCommittee from '../../templates/AddCommittee';
import { Candidate, Committee, Officers, Accounts, Party, Details } from './StepsForm';

const { Step } = Steps;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const committeeType = JSON.parse(localStorage.getItem('user')).committee_type;
  let steps = [];

  {
    committeeType == 'candidate' &&
      (steps = [
        {
          title: 'Committee',
          content: <Committee currentStep={current} setCurrentStep={setCurrent} />,
        },
        {
          title: 'Candidate',
          content: <Candidate currentStep={current} setCurrentStep={setCurrent} />,
        },
        {
          title: 'Officers',
          content: <Officers currentStep={current} setCurrentStep={setCurrent} />,
        },
        {
          title: 'Accounts',
          content: <Accounts currentStep={current} setCurrentStep={setCurrent} />,
        },
      ]);

    committeeType == 'independent-expenditure' &&
      (steps = [
        {
          title: 'Committee',
          content: <Committee currentStep={current} setCurrentStep={setCurrent} />,
        },
        {
          title: 'Officers',
          content: <Officers currentStep={current} setCurrentStep={setCurrent} />,
        },
        {
          title: 'Accounts',
          content: <Accounts currentStep={current} setCurrentStep={setCurrent} />,
        },
      ]);

    committeeType == 'party' &&
      (steps = [
        {
          title: 'Committee',
          content: <Committee currentStep={current} setCurrentStep={setCurrent} />,
        },
        {
          title: 'Party',
          content: <Party currentStep={current} setCurrentStep={setCurrent} />,
        },
        {
          title: 'Officers',
          content: <Officers currentStep={current} setCurrentStep={setCurrent} />,
        },
        {
          title: 'Accounts',
          content: <Accounts currentStep={current} setCurrentStep={setCurrent} />,
        },
      ]);

    committeeType == 'political-action' &&
      (steps = [
        {
          title: 'Committee',
          content: <Committee currentStep={current} setCurrentStep={setCurrent} />,
        },
        {
          title: 'Details',
          content: <Details currentStep={current} setCurrentStep={setCurrent} />,
        },
        {
          title: 'Officers',
          content: <Officers currentStep={current} setCurrentStep={setCurrent} />,
        },
        {
          title: 'Accounts',
          content: <Accounts currentStep={current} setCurrentStep={setCurrent} />,
        },
      ]);
  }

  return (
    <AddCommittee load={loading} heading="Add Committee">
      <Steps current={current} className={`type_${committeeType}`}>
        {steps.map((item, i) => (
          <React.Fragment key={i}>
            <Step key={item?.title} title={item?.title} />
          </React.Fragment>
        ))}
      </Steps>
      <div className="steps-content">{steps[current]?.content}</div>
    </AddCommittee>
  );
};
