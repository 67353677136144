import React, { Fragment } from 'react';
import { Row, Col, Button, Form, Image } from 'antd';
import { useForm, useFieldArray } from 'react-hook-form';
import { InputField, SelectField } from '../../../../../atoms/FormElement';
import { countryList } from '../../../../../../configs/constants';
import { useHistory } from 'react-router-dom';

const _ = require('lodash');

const accountType = [
  { label: 'CD', value: 'cd' },
  { label: 'Checking', value: 'checking' },
  { label: 'Credit Card', value: 'credit_card' },
  { label: 'Money Market', value: 'money_market' },
  { label: 'Savings', value: 'savings' },
];

const initQ = {
  name: '',
  structure_name: 'Accounts',
  account_code: '',
  account_type: '',
  account_numb: '',
  account_financial_name: '',
  account_street_address: '',
  account_country: '',
  account_city: '',
  account_zip: '',
  account_purpose: '',
};

export default (props) => {
  const { control, errors } = useForm({
    defaultValues: {
      accounts_committee: [{ name: 'useFieldArray' }],
    },
  });
  const history = useHistory();

  const { fields } = useFieldArray({
    control,
    name: 'accounts_committee',
  });



  return (
    <Row gutter={[20, 30]} align="bottom" className="add_account">
      <Col span={24}>
        {fields?.map((item, index) => (
          <Fragment key={item.id}>
            <Row gutter={[20, 20]} align="bottom" className="add_account_repeat">
              <InputField
                fieldname={`accounts_committee[${index}].name`}
                label=""
                control={control}
                class="d-none mb-0"
                initValue={item?.name}
                iProps={{ size: 'large' }}
              />
              <Col span={24}>
                <InputField
                  fieldname={`accounts_committee[${index}].account_code`}
                  label="Account Code"
                  control={control}
                  hintMessage="Come up with any combination of numbers and/or letters."
                  tooltip={true}
                  tooltipTitle="This code is a unique identifier (ex. 1 or A) used to reference the bank account number for the committee (the bank account number should not appear on the disclosure reporting forms). If new accounts are opened a unique code must be used."
                  class="mb-0"
                  initValue={item?.account_code}
                  iProps={{ size: 'large' }}
                  rules={{ required: 'Account Code is required.' }}
                  validate={
                    Object.entries(errors).length > 0 &&
                    errors?.accounts_committee?.length > 0 &&
                    errors?.accounts_committee[index]?.account_code &&
                    'error'
                  }
                  validMessage={
                    Object.entries(errors).length > 0 &&
                    errors?.accounts_committee?.length > 0 &&
                    errors?.accounts_committee[index]?.account_code &&
                    errors?.accounts_committee[index]?.account_code?.message
                  }
                />
              </Col>
              <Col span={24}>
                <SelectField
                  fieldname={`accounts_committee[${index}].account_type`}
                  label="Account Type"
                  control={control}
                  class="mb-0"
                  initValue={item?.account_type}
                  iProps={{}}
                  selectOption={accountType}
                  rules={{ required: 'Account Type is required.' }}
                  validate={
                    Object.entries(errors).length > 0 &&
                    errors?.accounts_committee?.length > 0 &&
                    errors?.accounts_committee[index]?.account_type &&
                    'error'
                  }
                  validMessage={
                    Object.entries(errors).length > 0 &&
                    errors?.accounts_committee?.length > 0 &&
                    errors?.accounts_committee[index]?.account_type &&
                    errors?.accounts_committee[index]?.account_type?.message
                  }
                />
              </Col>
              <Col span={24}>
                <InputField
                  fieldname={`accounts_committee[${index}].account_numb`}
                  label="Account Number"
                  control={control}
                  class="mb-0"
                  initValue={item?.account_numb}
                  iProps={{ size: 'large' }}
                  rules={{ required: 'Account Number is required.' }}
                  validate={
                    Object.entries(errors).length > 0 &&
                    errors?.accounts_committee?.length > 0 &&
                    errors?.accounts_committee[index]?.account_numb &&
                    'error'
                  }
                  validMessage={
                    Object.entries(errors).length > 0 &&
                    errors?.accounts_committee?.length > 0 &&
                    errors?.accounts_committee[index]?.account_numb &&
                    errors?.accounts_committee[index]?.account_numb?.message
                  }
                />
              </Col>
              <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname={`accounts_committee[${index}].account_financial_name`}
                  label="Financial Institution Name"
                  control={control}
                  class="mb-0"
                  initValue={item?.account_financial_name}
                  iProps={{ size: 'large' }}
                  rules={{ required: 'Financial Institution Name is required.' }}
                  validate={
                    Object.entries(errors).length > 0 &&
                    errors?.accounts_committee?.length > 0 &&
                    errors?.accounts_committee[index]?.account_financial_name &&
                    'error'
                  }
                  validMessage={
                    Object.entries(errors).length > 0 &&
                    errors?.accounts_committee?.length > 0 &&
                    errors?.accounts_committee[index]?.account_financial_name &&
                    errors?.accounts_committee[index]?.account_financial_name?.message
                  }
                />
              </Col>
              <Col span={24} className="mailingField">
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <InputField
                      isRequired={true}
                      fieldname={`accounts_committee[${index}].account_street_address`}
                      label="Mailing Address"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        placeholder: 'Street Address',
                      }}
                      rules={{ required: 'Mailing Address is required.' }}
                      validate={
                        Object.entries(errors).length > 0 &&
                        errors?.accounts_committee?.length > 0 &&
                        errors?.accounts_committee[index]?.account_street_address &&
                        'error'
                      }
                      validMessage={
                        Object.entries(errors).length > 0 &&
                        errors?.accounts_committee?.length > 0 &&
                        errors?.accounts_committee[index]?.account_street_address &&
                        errors?.accounts_committee[index]?.account_street_address?.message
                      }
                    />
                  </Col>

                  <Col span={9}>
                    <SelectField
                      isRequired={true}
                      fieldname={`accounts_committee[${index}].account_country`}
                      control={control}
                      class="mb-0"
                      iProps={{ placeholder: 'Please select' }}
                      initValue={{ label: 'North Carolina', value: 'NC' }}
                      selectOption={countryList}
                      rules={{ required: 'Mailing Address is required.' }}
                      validate={
                        Object.entries(errors).length > 0 &&
                        errors?.accounts_committee?.length > 0 &&
                        errors?.accounts_committee[index]?.account_country &&
                        'error'
                      }
                      validMessage={
                        Object.entries(errors).length > 0 &&
                        errors?.accounts_committee?.length > 0 &&
                        errors?.accounts_committee[index]?.account_country &&
                        errors?.accounts_committee[index]?.account_country?.message
                      }
                    />
                  </Col>

                  <Col span={9}>
                    <InputField
                      isRequired={true}
                      fieldname={`accounts_committee[${index}].account_city`}
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        placeholder: 'City',
                      }}
                      rules={{ required: 'Mailing Address is required.' }}
                      validate={
                        Object.entries(errors).length > 0 &&
                        errors?.accounts_committee?.length > 0 &&
                        errors?.accounts_committee[index]?.account_city &&
                        'error'
                      }
                      validMessage={
                        Object.entries(errors).length > 0 &&
                        errors?.accounts_committee?.length > 0 &&
                        errors?.accounts_committee[index]?.account_city &&
                        errors?.accounts_committee[index]?.account_city?.message
                      }
                    />
                  </Col>

                  <Col span={6}>
                    <InputField
                      isRequired={true}
                      fieldname={`accounts_committee[${index}].account_zip`}
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        placeholder: 'Zip',
                      }}
                      rules={{ required: 'Mailing Address is required.' }}
                      validate={
                        Object.entries(errors).length > 0 &&
                        errors?.accounts_committee?.length > 0 &&
                        errors?.accounts_committee[index]?.account_zip &&
                        'error'
                      }
                      validMessage={
                        Object.entries(errors).length > 0 &&
                        errors?.accounts_committee?.length > 0 &&
                        errors?.accounts_committee[index]?.account_zip &&
                        errors?.accounts_committee[index]?.account_zip?.message
                      }
                    />
                  </Col>
                  {Object.entries(errors).length > 0 &&
                    errors?.accounts_committee?.length > 0 &&
                    (errors?.accounts_committee[index]?.account_zip ||
                      errors?.accounts_committee[index]?.account_city ||
                      errors?.accounts_committee[index]?.account_country ||
                      errors?.accounts_committee[index]?.account_street_address) && (
                      <Col span={24} className="mailingError">
                        Mailing Address is required.
                      </Col>
                    )}
                </Row>
              </Col>
              <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname={`accounts_committee[${index}].account_purpose`}
                  label="Account Purpose"
                  control={control}
                  class="mb-0"
                  initValue={item?.account_purpose}
                  iProps={{ size: 'large' }}
                  rules={{ required: 'Account Purpose is required.' }}
                  validate={
                    Object.entries(errors).length > 0 &&
                    errors?.accounts_committee?.length > 0 &&
                    errors?.accounts_committee[index]?.account_purpose &&
                    'error'
                  }
                  validMessage={
                    Object.entries(errors).length > 0 &&
                    errors?.accounts_committee?.length > 0 &&
                    errors?.accounts_committee[index]?.account_purpose &&
                    errors?.accounts_committee[index]?.account_purpose?.message
                  }
                  tooltip={true}
                  tooltipTitle="Provide the purpose for which this account is used. An example would be “for all campaign expenses”, “for House funds”, etc."

                />
              </Col>
            </Row>
          </Fragment>
        ))}
      </Col>

      <Col span={24} className="text-center">
        <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
          Finish
        </Button>
      </Col>
    </Row>
  );
};
