import React, { useState, useEffect } from 'react';
import { Spin, Button, Row, Col, Typography, Image, Space, Card, Table, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import crossIcon from '../../../assets/svg/cross-icon.svg';
import infoIcon from '../../../assets/svg/info-icon.svg';
import { addObject } from '../../../services/crudApis';

const { Title, Paragraph, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { onClose, title, updateApi, receiptReportID, amendedData, responseData } = props;
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (receiptReportID) {
      setLoading(true);
      const payload = {
        dateEffective: false,
        reportIds: receiptReportID ? receiptReportID : false,
      };
      addObject('getAmendedReports', payload)
        .then((response) => {
          setReportData(response?.data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          message.error('Something went wrong');
        });
    }
  }, [receiptReportID]);

  useEffect(() => {
    setLoading(true);
    if (responseData?.length > 0) {
      setReportData(responseData);
      setLoading(false);
    } else {
      setReportData([]);
      setLoading(false);
    }
  }, [responseData]);

  const listColumns = [
    {
      title: 'Year',
      dataIndex: 'year',
    },
    {
      title: 'Report',
      dataIndex: 'label',
    },
    {
      title: 'Filed',
      dataIndex: 'date',
    },
  ];

  const reportListData = [
    {
      key: 0,
      year: '2022',
      report: 'Third Quarter Plus',
      filed_date: '10-23-2022, 05:10 PM',
    },
  ];

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row gutter={[20, 20]} justify="space-between" align="middle">
        <Col>
          <Title level={4} className="mb-0">
            {title}
          </Title>
        </Col>
        <Col>
          <Button size="large" type="link" className="p-0" onClick={onClose}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>
        <Col span={24}>
          <Card className="info-card">
            <Space size={20}>
              <Image src={infoIcon} width={30} preview={false} />
              <div className="info_text">
                <Paragraph className="smallFont14 w-100 mb-0">
                  These changes will affect the data in the reports you have filed. Report Amendments will be created
                  and should be filed accordingly.
                </Paragraph>
              </div>
            </Space>
          </Card>
        </Col>
        {/* <Col span={24}>
          <Paragraph>
            These changes will affect the data in the reports you have filed. Report Amendments will be created and
            should be filed accordingly.
          </Paragraph>
        </Col> */}

        <Col span={24}>
          <Title level={4} className="text-center font-gilroy RegularFont mt-2 mb-1">
            Reports to be Amended
          </Title>
          <div className="remove_margin">
            <Table
              columns={listColumns}
              dataSource={reportData && reportData?.length > 0 ? reportData : amendedData}
              pagination={false}
              className="reports_popup"
            />
          </div>
        </Col>

        <Col span={24}>
          <Row align="middle" justify="center" className="mt-1" gutter={[20, 20]}>
            <Col>
              <Button type="primary" onClick={onClose} htmlType="button" className="font-gilroy white-btn" size="large">
                Cancel
              </Button>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit" className="font-gilroy" size="large" onClick={updateApi}>
                {reportData && reportData?.length > 0 ? 'Confirm and Delete' : 'Confirm and Save'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};
