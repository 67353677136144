import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio, Button, Descriptions } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { InputRadio, InputField, TextAreaField, DateField, SelectField } from '../../../../../atoms/FormElement';
import { getExpenditureDropdownData } from '../../ducks/actions';
import { EXPENDITURE_PURPOSE_CODE, FORMS_OF_PAYMENTS } from '../../../../../../configs/constants';
import { getContactFormData, getContactFormDataPayee } from '../../../Contacts/ducks/actions';
import ContactBox from '../../../../../molecules/Dashboard/ContactBox';

const { Paragraph } = Typography;

export default (props) => {
  const { control, errors, formData, setValue, setLoading } = props;
  const [formOfPayment, setFormOfPayment] = useState('');
  const [purposeCode, setPurposeCode] = useState(false);
  const dispatch = useDispatch();
  const expenditureFormData = useSelector((state) => state.expenditure.expenditureDropData);

  const [contactBoxEditCase, setContactBoxEditCase] = useState('');
  const [contactToSelect, setContactToSelect] = useState(null);
  const [contactBoxEditCaseCommittee, setContactBoxEditCaseCommittee] = useState('');
  const [contactToSelectCommittee, setContactToSelectCommittee] = useState(null);

  const contributerData = useSelector((state) => state.contact.contactFormData);
  const contributerDataCommittee = useSelector((state) => state.contact.contactFormDataPayee);

  useEffect(() => {
    dispatch(getExpenditureDropdownData('coordinated_party_expenditure', 'null'));
  }, []);

  useEffect(() => {
    if (
      formData?.expenditureData &&
      Object.keys(expenditureFormData)?.length > 0 &&
      Object.keys(formData?.expenditureData)?.length > 0
    ) {
      const selectedAccountList = expenditureFormData?.accounts?.find(
        (e) => e?.value == formData?.expenditureData?.account_id,
      );
      const selectedcontributorList = expenditureFormData?.contributors?.find(
        (e) => e?.value == (contactToSelect ? contactToSelect : formData?.expenditureData?.payee_id),
      );
      const selectedcoordinatedCommitteeName = expenditureFormData?.contributors?.find(
        (e) =>
          e?.value ==
          (contactToSelectCommittee ? contactToSelectCommittee : formData?.expenditureData?.coordinated_committee_id),
      );
      let selectedReimburseList = [];
      formData?.expenditureData?.purpose_code?.map((x) => {
        selectedReimburseList.push({
          label: EXPENDITURE_PURPOSE_CODE.find((y) => y.value == x)?.label,
          value: x,
        });
      });
      setLoading(true);
      setValue('amount', formData.expenditureData?.amount);
      setValue(
        'date',
        formData?.expenditureData?.date ? moment(formData?.expenditureData?.date, 'YYYY-MM-DD HH:mm') : '',
      );
      setValue('comments', formData.expenditureData?.comments);
      setValue('payment_type', formData.expenditureData?.payment_type);
      setValue('payee_id', selectedcontributorList);
      setValue('purpose_code', selectedReimburseList);
      setValue('coordinated_committee_name', selectedcoordinatedCommitteeName);
      setFormOfPayment(formData.expenditureData?.payment_type);
      setContactToSelect(null);
      setContactToSelectCommittee(null);
      setTimeout(() => {
        setContactBoxEditCase(selectedcontributorList);
        setContactBoxEditCaseCommittee(selectedcoordinatedCommitteeName);
        setValue('check_number', formData.expenditureData?.check_number);
        setValue('remarks', formData.expenditureData?.remarks);
        setValue('in_kind_description', formData.receiptData?.in_kind_description);
        setValue('account', selectedAccountList);
        purposeSelection(selectedReimburseList);
      }, 1000);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [expenditureFormData, formData]);

  const purposeSelection = (e) => {
    if (
      e.some(
        (resp) =>
          resp.value === 'A' ||
          resp.value === 'B' ||
          resp.value === 'C' ||
          resp.value === 'F' ||
          resp.value === 'H' ||
          resp.value === 'K' ||
          resp.value === 'O',
      )
    ) {
      setPurposeCode(true);
    } else {
      setPurposeCode(false);
    }
  };

  const changeValueByDropDown = (e) => {
    dispatch(getContactFormData(e));
  };

  const changeValueByDropDownCommittee = (e) => {
    dispatch(getContactFormDataPayee(e));
  };

  const updateDropdownValues = () => {
    dispatch(getExpenditureDropdownData('coordinated_party_expenditure', 'null'));
  };

  const formPayment = (e) => {
    setFormOfPayment(e.target.value);

    if (expenditureFormData && expenditureFormData?.accounts?.length == 1) {
      setTimeout(() => {
        setValue('account', expenditureFormData?.accounts[0]);
      }, 500);
    }
  };

  return (
    <>
      <Row gutter={[48, 30]}>
        <Col span={12}>
          <Row gutter={[12, 30]}>
            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="amount"
                label="Amount"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  prefix: '$',
                  type: 'number',
                }}
                rules={{
                  required: 'Amount is required.',
                }}
                validate={errors.amount && 'error'}
                validMessage={errors.amount && errors.amount.message}
              />
            </Col>

            <Col span={24}>
              <DateField
                fieldname="date"
                label="Date"
                control={control}
                class="mb-0"
                iProps={{ size: 'large' }}
                initValue=""
                isRequired={true}
                rules={{
                  required: 'Date is required.',
                }}
                validate={errors.date && 'error'}
                validMessage={errors.date && errors.date.message}
              />
            </Col>

            <ContactBox
              control={control}
              errors={errors}
              selectData={expenditureFormData?.contributors}
              changeValueByDropDown={changeValueByDropDown}
              updateDropdownValues={updateDropdownValues}
              contributerData={contributerData}
              contactBoxEditCase={contactBoxEditCase}
              contactToSelect={contactToSelect}
              setContactToSelect={setContactToSelect}
              setValue={setValue}
              fieldname="payee_id"
              label="Payee"
            />

            <ContactBox
              control={control}
              errors={errors}
              selectData={expenditureFormData?.coordinatedCommittees}
              changeValueByDropDown={changeValueByDropDownCommittee}
              updateDropdownValues={updateDropdownValues}
              contributerData={contributerDataCommittee}
              contactBoxEditCase={contactBoxEditCaseCommittee}
              contactToSelect={contactToSelectCommittee}
              setContactToSelect={setContactToSelectCommittee}
              setValue={setValue}
              fieldname="coordinated_committee_name"
              label="Coordinated Committee Name"
              fixedContactType="political_committee"
            />

            <Col span={24}>
              <SelectField
                isRequired={true}
                fieldname="purpose_code"
                label="Purpose Code"
                control={control}
                initValue=""
                onChange={(e) => purposeSelection(e)}
                iProps={{
                  size: 'large',
                  placeholder: 'Please select',
                  isMulti: true,
                }}
                rules={{
                  required: 'Purpose Code is required.',
                }}
                selectOption={EXPENDITURE_PURPOSE_CODE}
                validate={errors.purpose_code && 'error'}
                validMessage={errors.purpose_code && errors.purpose_code.message}
              />
            </Col>

            {purposeCode && (
              <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname="remarks"
                  label="Required Remarks"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                  }}
                  rules={{
                    required: 'Required Remarks is required.',
                  }}
                  validate={errors.remarks && 'error'}
                  validMessage={errors.remarks && errors.remarks.message}
                />
              </Col>
            )}

            <Col span={24}>
              <TextAreaField
                fieldname="comments"
                label="Comments"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                optionalLabel={true}
              />
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <InputRadio
                fieldname="payment_type"
                label="Form of Payment"
                control={control}
                initValue={''}
                classField="custom-radio"
                onChange={(e) => formPayment(e)}
                options={
                  <>
                    {FORMS_OF_PAYMENTS &&
                      FORMS_OF_PAYMENTS?.map((item, index) => (
                        <Fragment key={index}>
                          <Radio.Button value={item?.value}>
                            <Image src={item?.image} preview={false} width={80} />
                            <Paragraph className="smallFont12">{item?.label}</Paragraph>
                          </Radio.Button>
                        </Fragment>
                      ))}
                  </>
                }
                rules={{
                  required: 'Form of Payment is required.',
                }}
                validate={errors.payment_type && 'error'}
                validMessage={errors.payment_type && errors.payment_type.message}
              />
            </Col>

            {formOfPayment && (
              <Col span={24}>
                {formOfPayment != 'in_kind' && (
                  <Row gutter={16}>
                    {formOfPayment == 'check' && (
                      <Col span={12}>
                        <InputField
                          fieldname="check_number"
                          label="Check Number"
                          control={control}
                          initValue=""
                          optionalLabel="(Optional)"
                          iProps={{
                            size: 'large',
                          }}
                        />
                      </Col>
                    )}
                    <Col span={formOfPayment == 'check' ? 12 : 24}>
                      <SelectField
                        isRequired={true}
                        fieldname="account"
                        label="Account"
                        control={control}
                        class="mb-0"
                        iProps={{ placeholder: 'Please select' }}
                        rules={{ required: 'Account is required.' }}
                        initValue=""
                        selectOption={expenditureFormData?.accounts}
                        validate={errors.account && 'error'}
                        validMessage={errors.account && errors.account.message}
                      />
                    </Col>
                  </Row>
                )}

                {formOfPayment == 'in_kind' && (
                  <Col span={24}>
                    <InputField
                      isRequired={true}
                      fieldname="in_kind_description"
                      label="In-Kind Description"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                      }}
                      rules={{
                        required: 'In-Kind Description is required.',
                      }}
                      validate={errors.in_kind_description && 'error'}
                      validMessage={errors.in_kind_description && errors.in_kind_description.message}
                    />
                  </Col>
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};
