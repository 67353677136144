import React, { useState, useEffect } from 'react';
import Dashboard from '../../../pages/Dashboard';
import { Card, Table, Row, Col, Typography, Button, Menu, Dropdown, Input, Image, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { MoreOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import plusIcon from '../../../../assets/svg/plus-icon-white.svg';
import { getExemptListing } from './ducks/actions';
import { deleteExemptPlan } from '../../../../services/expenditure';
import { Popup } from '../../../atoms/Popup';
import ConfirmDelete from '../../../molecules/ConfirmDelete';
import ReportConfirmDeletePopup from '../../../molecules/ReportConfirmDeletePopup';
import ConfirmExemptPopup from '../../../molecules/ConfirmExemptPopup';

const { Text } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteMultiple, setConfirmDeleteMultiple] = useState(false);
  const [confirmReport, setConfirmReport] = useState(false);
  const [confirmExemptplan, setConfirmExemptplan] = useState(false);
  const [confirmReportMultiple, setConfirmReportMultiple] = useState(false);
  const [receiptReportID, setReceiptReportID] = useState('');
  const [deleteRecordID, setDeleteRecordID] = useState();
  const [exemptData, setExemptData] = useState([]);
  const [amendedData, setAmendedData] = useState([]);
  const [dataSource, setDataSource] = useState();
  const [value, setValue] = useState('');
  const [tableRecord, setTableRecord] = useState();
  const exemptListData = useSelector((state) => state.exemptSales.exemptListData);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  //console.log('exemptListData', exemptListData);

  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    dispatch(getExemptListing());
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => editRecord(tableRecord)} type="link" className="btn-link">
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={() => deleteRecord(tableRecord)} type="link" className="btn-link">
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );
  const listColumns = [
    {
      title: 'Plan Description',
      dataIndex: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      sorter: (a, b) => +moment(a.startDate, 'MM-DD-YYYY').format('X') - +moment(b.startDate, 'MM-DD-YYYY').format('X'),
      render: (text) => (text ? moment(text).format('MM-DD-YYYY') : '-'),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      sorter: (a, b) => +moment(a.endDate, 'MM-DD-YYYY').format('X') - +moment(b.endDate, 'MM-DD-YYYY').format('X'),
      render: (text) => (text ? moment(text).format('MM-DD-YYYY') : '-'),
    },
    {
      title: 'Submission Date',
      dataIndex: 'submissionDate',
      sorter: (a, b) =>
        +moment(a.submissionDate, 'MM-DD-YYYY').format('X') - +moment(b.submissionDate, 'MM-DD-YYYY').format('X'),
      render: (text) => (text ? moment(text).format('MM-DD-YYYY') : '-'),
    },
    {
      title: 'Account Code',
      dataIndex: 'account',
      sorter: (a, b) => a.account.localeCompare(b.account),
      render: (text) => (text ? text : '-'),
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" onClick={() => handleButtonClick(record)}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleButtonClick = (e) => {
    setTableRecord(e);
  };

  const editRecord = (record) => {
    history.push(`/edit-exempt/${record?.id}`);
  };

  const deletePopup = {
    closable: false,
    visibility: confirmDelete,
    class: 'black-modal',
    content: (
      <ConfirmDelete
        title="Delete Exempt Sales"
        paragraph="Are you sure you want to delete this Exempt Sales?"
        onClose={() => setConfirmDelete(false)}
        onDelete={() => onDelete()}
        updateApi={() => (confirmDeleteMultiple ? updateApiMultiple() : updateApi())}
      />
    ),
    width: 450,
    onCancel: () => setConfirmDelete(false),
  };

  const deleteReport = {
    closable: false,
    visibility: confirmReport,
    class: 'black-modal',
    content: (
      <ReportConfirmDeletePopup
        title="Confirm Report Amendments"
        paragraph="Are you sure you want to delete this contact?"
        onClose={() => {
          setConfirmReport(false);
          setAmendedData([]);
        }}
        onDelete={() => onDelete()}
        amendedData={amendedData}
        updateApi={() => (confirmReportMultiple ? updateReportApiMultiple() : updateReportApi())}
      />
    ),
    width: 542,
    onCancel: () => setConfirmReport(false),
  };

  const exemptPlan = {
    closable: false,
    visibility: confirmExemptplan,
    class: 'black-modal',
    content: (
      <ConfirmExemptPopup
        title="Delete Exempt Sales Plan"
        onClose={() => {
          setConfirmExemptplan(false);
          setExemptData([]);
        }}
        onDelete={() => onDelete()}
        exemptData={exemptData}
      />
    ),
    width: 450,
    onCancel: () => setConfirmExemptplan(false),
  };

  const updateApi = () => {
    setLoading(true);
    const payload = {
      exemptPlan: [deleteRecordID],
      force: false,
    };
    deleteExemptPlan(payload)
      .then((res) => {
        //console.log('res', res);
        if (res?.data.caseType == 1) {
          dispatch(getExemptListing());
          setConfirmDelete(false);
        }
        if (res?.data.caseType == 2) {
          setConfirmExemptplan(true);
          setExemptData(res?.data.data);
          setConfirmDelete(false);
          setConfirmReport(false);
        }
        if (res?.data.caseType == 3) {
          setConfirmReport(true);
          setAmendedData(res?.data.data);
          setConfirmDelete(false);
          setConfirmExemptplan(false);
        }
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmDelete(false);
        message.error('Something went wrong');
      });
  };

  const updateApiMultiple = () => {
    let expenditureToBeDeleted = [];
    for (const keys of selectedRowKeys) {
      let exp = exemptListData.find((x) => x.key === keys);
      if (exp && exp.id) {
        expenditureToBeDeleted.push(exp.id);
      }
    }
    const payload = {
      exemptPlan: expenditureToBeDeleted,
      force: false,
    };
    deleteExemptPlan(payload)
      .then((res) => {
        if (res?.data.caseType == 1) {
          dispatch(getExemptListing());
          setConfirmDelete(false);
        }
        if (res?.data.caseType == 2) {
          setConfirmExemptplan(true);
          setExemptData(res?.data.data);
          setConfirmDelete(false);
          setConfirmReport(false);
        }
        if (res?.data.caseType == 3) {
          setConfirmReport(true);
          setConfirmReportMultiple(true);
          setAmendedData(res?.data.data);
          setConfirmDelete(false);
          setConfirmExemptplan(false);
        }
        setSelectedRowKeys([]);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmDelete(false);
        message.error('Something went wrong');
      });
  };

  const updateReportApi = () => {
    setLoading(true);
    const payload = {
      exemptPlan: [deleteRecordID],
      force: true,
    };
    deleteExemptPlan(payload)
      .then((res) => {
        //console.log('res', res);
        message.success('Exempt deleted.');
        dispatch(getExemptListing());
        setConfirmReport(false);
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmReport(false);
        message.error('Something went wrong');
      });
  };

  const updateReportApiMultiple = () => {
    let expenditureToBeDeleted = [];
    for (const keys of selectedRowKeys) {
      let exp = exemptListData.find((x) => x.key === keys);
      if (exp && exp.id) {
        expenditureToBeDeleted.push(exp.id);
      }
    }
    //console.log('expenditureToBeDeleted', expenditureToBeDeleted);
    const payload = {
      exemptPlan: expenditureToBeDeleted,
      force: true,
    };
    deleteExemptPlan(payload)
      .then((res) => {
        message.success('Exempt deleted.');
        dispatch(getExemptListing());
        setConfirmReport(false);
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmReport(false);
        message.error('Something went wrong');
      });
  };

  const deleteRecord = (record) => {
    setDeleteRecordID(record?.id);
    setConfirmDelete(true);
    setConfirmDeleteMultiple(false);
  };

  const deleteMultiple = () => {
    setConfirmDelete(true);
    setConfirmDeleteMultiple(true);
  };

  const fetchData = (params = {}, fromDate = 'null', toDate = 'null') => {
    setLoading(true);
    fetch(``).then(({ results }) => {
      setLoading(false);
      setPagination({
        ...params.pagination,
      });
    });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const handleTableChange = (newPagination, filters) => {
    fetchData({
      pagination: newPagination,
      ...filters,
    });
  };

  const onClickRow = (record) => {
    return {
      onDoubleClick: () => {
        history.push(`/edit-exempt/${record?.id}`);
      },
    };
  };

  useEffect(() => {
    setDataSource(exemptListData);
  }, [exemptListData]);

  const hasSelected = selectedRowKeys.length > 0;
  return (
    <>
      <Dashboard load={loading} heading="Exempt Sales">
        <Card className="white-card w-100 pcard-0">
          <Row justify="space-between" align="middle" className="reports_head" style={{ height: '50px' }}>
            <Col>
              {hasSelected ? (
                <Button onClick={() => deleteMultiple()} type="text" htmlType="button" className="font-gilroy c-blue">
                  Delete Selected
                </Button>
              ) : (
                <Button
                  onClick={() => history.push('/new-exempt')}
                  type="primary"
                  htmlType="submit"
                  className="w-100 font-gilroy"
                  size="large"
                >
                  <Image src={plusIcon} preview={false} width={22} />
                  Add Exempt Sale
                </Button>
              )}
            </Col>
            {hasSelected ? (
              <Col>
                <Text className="font-14">{selectedRowKeys.length} Selected </Text>
              </Col>
            ) : (
              <Col className="search_box">
                <Input
                  placeholder="Search..."
                  value={value}
                  onChange={(e) => {
                    const currValue = e.target.value.toLowerCase();
                    setValue(currValue);
                    const filteredData = exemptListData.filter(
                      (entry) =>
                        (entry?.description && entry?.description.toLowerCase().includes(currValue)) ||
                        (entry?.startDate && entry?.startDate.includes(currValue)) ||
                        (entry?.endDate && entry?.endDate.includes(currValue)) ||
                        (entry?.submissionDate && entry?.submissionDate.includes(currValue)) ||
                        (entry?.account && entry?.account.toLowerCase().includes(currValue)),
                    );
                    setDataSource(filteredData);
                  }}
                />
              </Col>
            )}
          </Row>
          <Table
            rowSelection={rowSelection}
            columns={listColumns}
            dataSource={dataSource}
            pagination={pagination}
            onChange={handleTableChange}
            onRow={onClickRow}
            className="cursor_table"
          />
        </Card>
      </Dashboard>
      <Popup {...deletePopup} />
      <Popup {...deleteReport} />
      <Popup {...exemptPlan} />
    </>
  );
};
