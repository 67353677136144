import axios from '../../../../../services/axiosInterceptor';
import * as action_types from './constants';
import { baseUrl } from '../../../../../configs/constants';
import { DOWNLOAD_EXPENDITURE_CSV } from '../../../../../configs/endpoints';
import { downloadFile, saveFile } from '../../../../../services/crudApis';
import { message } from 'antd';

export const getExpenditureListingData = (fromDate = 'null', toDate = 'null') => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getExpenditureList/${fromDate}/${toDate}`);
    dispatch({
      type: action_types.EXPENDITURE_LIST_DATA,
      data: data,
    });
  };
};

export const getUnimportedExpenditureList = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getUnimportedReceiptandExpenditureList`);
    dispatch({
      type: action_types.UNIMPORTED_EXPENDITURE_LIST_DATA,
      data: data,
    });
  };
};

export const getExpenditureFormData = (type, id = '') => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getExpenditureFormData/${type}/${id}`);
    dispatch({
      type: action_types.EXPENDITURE_FORM_DATA,
      data: data,
    });
  };
};

export const getUnimportedExpenditureFormData = (type, id = '') => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getUnimportedReceiptAndExpenditureFormData/${type}/${id}`);
    dispatch({
      type: action_types.EXPENDITURE_FORM_DATA,
      data: data,
    });
  };
};

export const getExpenditureDropdownData = (type, id = '') => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getExpenditureFormData/${type}/${id}`);
    dispatch({
      type: action_types.EXPENDITURE_DROP_DATA,
      data: data,
    });
  };
};

export const setFiltersOptionsExpenditure = (type, value) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS,
      payload: { filter: { [type]: value } },
    });
  };
};

export const clearExpenditureFilters = () => ({
  type: action_types.CLEAR_EXPENDITURE_FILTER,
});

export const setFiltersImport = (type, value) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS_IMPORT,
      payload: { filterImport: { [type]: value } },
    });
  };
};
