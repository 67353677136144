import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';

export default (props) => {
  return (
    <Dashboard
      load={props?.load}
      heading={props?.heading}
      backLink={props?.backLink}
      noLink={props?.noLink}
      tags={props?.tags}
    >
      {props.children}
    </Dashboard>
  );
};
