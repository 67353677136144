import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import moment from 'moment';
import {
  InputRadio,
  InputField,
  TextAreaField,
  DateField,
  SelectField,
  InputCheckbox,
} from '../../../../../atoms/FormElement';
import { countryList } from '../../../../../../configs/constants';

const { Paragraph } = Typography;

export default (props) => {
  const { control, errors, setValue, formData, exportContactId, setLoading, phoneNumber, setPhoneNumber } = props;
  const dispatch = useDispatch();
  const receiptFormData = useSelector((state) => state.receipt.receiptDropData);
  const [jobTitle, setJobTitle] = useState('JobTitle');
  const [employeeName, setEmployeeName] = useState('employersName');

  useEffect(() => {
    setValue('mailingcountryList', { label: 'North Carolina', value: 'NC' });
  }, []);

  useEffect(() => {
    if (formData && Object.keys(formData)?.length > 0) {
      setLoading(true);
      const selectedState = countryList?.find((e) => e?.value == formData?.state);

      setValue('financial_institution_name', formData?.financial_institution_name);
      //setValue('is_non_profit_organization', formData?.is_non_profit_organization);
      setValue('mailingStreet', formData?.mailing_address);
      setValue('mailingCity', formData?.city);
      setValue('mailingZip', formData?.post_code);
      if (selectedState) {
        setValue('mailingcountryList', selectedState);
      }
      setPhoneNumber(formData?.phone);
      setValue('email', formData?.email);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else if (exportContactId && Object.keys(exportContactId)?.length > 0) {
      setLoading(true);
      const selectedState = countryList?.find((e) => e?.value == exportContactId?.state);
      setValue('financial_institution_name', exportContactId?.financial_institution_name);
      setValue('mailingStreet', exportContactId?.mailing_address);
      setValue('mailingCity', exportContactId?.city);
      setValue('mailingZip', exportContactId?.post_code);
      if (selectedState) {
        setValue('mailingcountryList', selectedState);
      }
      setPhoneNumber(exportContactId?.phone);
      setValue('email', exportContactId?.email);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [formData, exportContactId]);

  const onChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const selectedCountry = (e) => {
    if (e?.value == 'WADC') {
      setValue('mailingCity', 'Washington DC');
    } else if (formData && Object.keys(formData)?.length > 0) {
      setValue('mailingCity', formData?.city);
    } else if (exportContactId && Object.keys(exportContactId)?.length > 0) {
      setValue('mailingCity', exportContactId?.city);
    } else {
      setValue('mailingCity', '');
    }
  };

  return (
    <Row gutter={[48, 20]}>
      <Col span={12}>
        <Row gutter={[30, 20]}>
          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="financial_institution_name"
              label="Financial Institution Name"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Financial Institution Name is required.',
              }}
              validate={errors.financial_institution_name && 'error'}
              validMessage={errors.financial_institution_name && errors.financial_institution_name.message}
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Row gutter={[30, 20]}>
          <Col span={24} className="mailingField">
            <Row gutter={[12, 8]}>
              <Col span={24}>
                <InputField
                  fieldname="mailingStreet"
                  label="Mailing Address"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'Street Address',
                  }}
                />
              </Col>

              <Col span={9}>
                <SelectField
                  fieldname="mailingcountryList"
                  control={control}
                  class="mb-0"
                  iProps={{ placeholder: 'Please select' }}
                  initValue={{}}
                  selectOption={countryList}
                  onChange={(e) => selectedCountry(e)}
                />
              </Col>

              <Col span={9}>
                <InputField
                  fieldname="mailingCity"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'City',
                  }}
                />
              </Col>

              <Col span={6}>
                <InputField
                  fieldname="mailingZip"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'Zip',
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-label">
                <label className="" title="Phone Number">
                  Phone Number
                </label>
                <div className="optional-label" style={{ top: '4px' }}>
                  (Optional)
                </div>
              </div>
            </div>
            <InputMask mask="(999) 999-9999" value={phoneNumber} onChange={onChange}>
              {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
            </InputMask>
          </Col>

          <Col span={12}>
            <InputField
              fieldname="email"
              label="Email"
              optionalLabel="(Optional)"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: 'Check if the email is valid',
                },
              }}
              validate={errors.email && 'error'}
              validMessage={errors.email && errors.email.message}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
