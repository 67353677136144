import React from 'react';
import { Col } from 'antd';
import { InputField, SelectField } from '../../../../../atoms/FormElement';

export default ({ purposeName, remarksName, control, watch, purposeDrop, item, isOld, errors, index, setValue }) => {
  let remarks = '';
  remarks = watch(purposeName);
  return (
    <>
      <Col span={24}>
        {/* {item?.purpose_code?.length > 0 && ( */}
        <SelectField
          label="Purpose Code"
          control={control}
          initValue={
            item?.purpose_code?.length > 0 && item?.purpose_code.map((x) => purposeDrop.find((y) => y.value === x))
          }
          setValue={setValue}
          fieldname={purposeName}
          iProps={{
            size: 'large',
            placeholder: 'Please select',
            isMulti: true,
          }}
          selectOption={purposeDrop}
          isRequired={!isOld ? true : false}
          rules={{
            required: !isOld ? 'Purpose Code is required.' : null,
          }}
          validate={
            !isOld &&
            Object.entries(errors).length > 0 &&
            errors?.incurred_debts?.length > 0 &&
            errors?.incurred_debts[index]?.purpose_code &&
            'error'
          }
          validMessage={
            !isOld &&
            Object.entries(errors).length > 0 &&
            errors?.incurred_debts?.length > 0 &&
            errors?.incurred_debts[index]?.purpose_code &&
            errors?.incurred_debts[index]?.purpose_code?.message
          }
        />
        {/* )} */}

        {/* {!item?.purpose_code?.length && ( */}
        {/* <SelectField
          label="Purpose Code"
          control={control}
          initValue=""
          fieldname={purposeName}
          iProps={{
            size: 'large',
            placeholder: 'Please select',
            isMulti: true,
          }}
          selectOption={purposeDrop}
          isRequired={!isOld ? true : false}
          rules={{
            required: !isOld ? 'Purpose Code is required.' : null,
          }}
          validate={
            !isOld &&
            Object.entries(errors).length > 0 &&
            errors?.incurred_debts?.length > 0 &&
            errors?.incurred_debts[index]?.purpose_code &&
            'error'
          }
          validMessage={
            !isOld &&
            Object.entries(errors).length > 0 &&
            errors?.incurred_debts?.length > 0 &&
            errors?.incurred_debts[index]?.purpose_code &&
            errors?.incurred_debts[index]?.purpose_code?.message
          }
        /> */}
        {/* )} */}
      </Col>

      {remarks &&
      remarks?.length > 0 &&
      remarks?.some(
        (resp) =>
          resp?.value === 'A' ||
          resp?.value === 'B' ||
          resp?.value === 'C' ||
          resp?.value === 'F' ||
          resp?.value === 'H' ||
          resp?.value === 'K' ||
          resp?.value === 'O',
      ) ? (
        <Col span={24}>
          <InputField
            fieldname={remarksName}
            label="Required Remarks"
            control={control}
            initValue={item?.required_remarks}
            iProps={{
              size: 'large',
            }}
          />
        </Col>
      ) : null}
    </>
  );
};
