import React from 'react';
import { Button, Row, Col, Typography, Image, Card } from 'antd';
import crossIcon from '../../../assets/svg/cross-icon.svg';

const { Title } = Typography;

export default (props) => {
  const { onClose, title, updateApi, dublicateArray, dublicateCurrentValue } = props;

  const closePopUp = () => {
    onClose();
  };

  return (
    <Row justify="space-between" align="middle" className="new_popup">
      <Col span={24}>
        <Row justify="space-between" align="middle" className="popup_header">
          <Col>
            <Title level={4} className="mb-0">
              {title}
            </Title>
          </Col>
          <Col className="popup_close_btn">
            <Button size="large" type="link" className="p-0" onClick={closePopUp}>
              <Image src={crossIcon} preview={false} size={48} />
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <p>
          The contact name{' '}
          <b>
            {(dublicateCurrentValue?.firstName ||
              dublicateCurrentValue?.middle_name ||
              dublicateCurrentValue?.lastName) &&
              dublicateCurrentValue?.firstName +
                ' ' +
                dublicateCurrentValue?.middle_name +
                ' ' +
                dublicateCurrentValue?.lastName}
            {dublicateCurrentValue?.organization_name && dublicateCurrentValue?.organization_name}
            {dublicateCurrentValue?.committee_name && dublicateCurrentValue?.committee_name}
            {dublicateCurrentValue?.financial_institution_name && dublicateCurrentValue?.financial_institution_name}
          </b>{' '}
          already exists in your contact list. You can proceed with import to keep both contacts or delete one.
        </p>
      </Col>

      <Col span={24}>
        {dublicateArray?.length > 0 &&
          dublicateArray?.map((e) => (
            <Card className="contact_select_card">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="top_card_contact">
                    <h2>
                      {(e?.first_name || e?.middle_name || e?.last_name) &&
                        e?.first_name + ' ' + (e?.middle_name ?? '') + ' ' + (e?.last_name ?? '')}
                      {e?.organization_name && e?.organization_name}
                      {e?.financial_institution_name && e?.financial_institution_name}
                      {e?.committee_name && e?.committee_name}
                    </h2>
                  </div>
                </Col>
                <Col span={24}>
                  <label>Address</label>
                  <p>
                    {e?.addresses?.length > 0 && e?.addresses[0]?.mailing_address
                      ? e?.addresses[0]?.mailing_address
                      : '-'}
                  </p>
                </Col>
                <Col span={12}>
                  <label>Phone</label>
                  <p>{e?.addresses?.length > 0 && e?.addresses[0]?.phone ? e?.addresses[0]?.phone : '-'}</p>
                </Col>
                <Col span={12}>
                  <label>Email</label>
                  <p>{e?.addresses?.length > 0 && e?.addresses[0]?.email ? e?.addresses[0]?.email : '-'}</p>
                </Col>
                {e?.type === 'individual' && (
                  <>
                    <Col span={12}>
                      <label>Job Title</label>
                      <p>{e?.job_title ? e?.job_title : '-'}</p>
                    </Col>
                    <Col span={12}>
                      <label>Employer</label>
                      <p>{e?.employer_name ? e?.employer_name : '-'}</p>
                    </Col>
                  </>
                )}
              </Row>
            </Card>
          ))}
      </Col>

      <Col span={24}>
        <div className="new_btn_group">
          <Row justify="center" gutter={[20, 20]}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                className="font-gilroy white-btn"
                size="large"
                onClick={closePopUp}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" className="font-gilroy" size="large" onClick={updateApi}>
                Confirm
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
