import React from 'react';
import { Button, Col, Image, Row, Typography } from 'antd';
import crossIcon from '../../../../../../assets/svg/cross-icon.svg';

const { Title, Paragraph } = Typography;
export default (props) => {
  const { onClose, title, paragraph, onConfirm } = props;
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Title level={2} className="m-0" style={{ fontSize: 18 }}>
          {title}
        </Title>
      </Col>
      <Col>
        <Button size="large" type="link" className="p-0" onClick={onClose}>
          <Image src={crossIcon} preview={false} size={48} />
        </Button>
      </Col>
      <Col span={24} style={{ marginTop: 16 }}>
        <Paragraph className="w-100 mb-3" style={{ color: 'rgba(30, 30, 32, 1)' }}>
          {paragraph}
        </Paragraph>
      </Col>

      <Col span={24}>
        <Row justify="center" gutter={[20, 20]}>
          <Col>
            <Button type="primary" htmlType="submit" className="font-gilroy white-btn" size="large" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" className="font-gilroy" size="large" onClick={onConfirm}>
              Confirm
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
