import * as action_types from './constants';

const initialState = {
  reportFormData: {},
  reportDialogData: {},
  reportListData: [],
  bankAccountsList: [],
  reportDetailData: {},
  adminSupportData: {},
  obligationsBy: {},
  filter: {
    searchReport: undefined,
    status: undefined
  }
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.REPORT_FORM_DATA:
      return { ...state, reportFormData: data };

    case action_types.REPORT_DIALOG_DATA:
      return { ...state, reportDialogData: data };

    case action_types.REPORT_LIST_DATA:
      return { ...state, reportListData: data };

    case action_types.BANK_ACCOUNTS_LIST:
      return { ...state, bankAccountsList: data };

    case action_types.REPORT_DETAIL_DATA:
      return { ...state, reportDetailData: data };

    case action_types.ADMIN_SUPPORT_DATA:
      return { ...state, adminSupportData: data };

    case action_types.OBLIGATION_BY:
      return { ...state, obligationsBy: data };

    case action_types.SET_FILTERS_OPTIONS:
      return { ...state, filter: { ...state.filter, ...action.payload.filter } }

    case action_types.CLEAR_REPORT_FILTER:
      return {
        ...state,
        filter: {
          searchReport: undefined,
          status: undefined,
        },
      };

    default:
      return state;
  }
};
