import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio, Button, Tooltip } from 'antd';
import { InputRadio, InputField, SelectField } from '../../../../../../atoms/FormElement';
import { getReceiptDropdownData } from '../../../ducks/actions';
import { getContactFormData, getContactFormDataPayee } from '../../../../Contacts/ducks/actions';
import plusIcon from '../../../../../../../assets/svg/plus.svg';
import trashIcon from '../../../../../../../assets/svg/trash-icon.svg';
import { InfoCircleFilled } from '@ant-design/icons';
import ContactBoxEndorser from '../../../../../../molecules/Dashboard/ContactBoxEndorser';

const { Paragraph, Title } = Typography;

const initQ = {
  endorser_id: '',
  contact_id: '',
  is_amount: '',
  amount: '',
};

export default (props) => {
  const {
    formData,
    fields,
    watch,
    control,
    errors,
    setValue,
    append,
    remove,
    selectData,
    deletedIndexes,
    setDeletedIndexes,
  } = props;

  const onRemove = (e, index) => {
    remove(index);
    //console.log('e?.end_id', e);
    if (e?.endorser_id) {
      setDeletedIndexes([...deletedIndexes, e?.endorser_id]);
    }
  };
  const onAdd = () => {
    //console.log('asas', append, initQ);
    append(initQ);
  };

  return (
    <>
      <Col span={24}>
        <Title level={4} className="text-center mb-0 mt-2">
          Endorsers/Makers
        </Title>
      </Col>

      <Col span={24}>
        {fields?.map((item, index) => {
          let isAmount = false;
          isAmount = watch(`endorser[${index}].is_amount`);
          return (
            <Fragment key={item.id}>
              <Row
                gutter={[20, 20]}
                align="bottom"
                style={{
                  marginBottom: '20px',
                  paddingTop: '15px',
                  position: 'relative',
                  borderTop: index == 0 ? '0' : '1px solid #D2D6E2',
                }}
              >
                <InputField
                  fieldname={`endorser[${index}].id`}
                  label=""
                  control={control}
                  class="d-none mb-0"
                  valueGot={item?.id}
                  setValue={setValue}
                  iProps={{ size: 'large' }}
                />
                <InputField
                  fieldname={`endorser[${index}].endorser_id`}
                  label=""
                  control={control}
                  class="d-none mb-0"
                  valueGot={formData?.endorsers && formData?.endorsers?.length && formData?.endorsers[index]?.id}
                  setValue={setValue}
                  iProps={{ size: 'large' }}
                />

                <ContactBoxEndorser
                  control={control}
                  errors={errors}
                  selectData={selectData}
                  arraySelectedItem={item?.contact_id}
                  // contactBoxEditCase={
                  //   item?.contact_id &&
                  //   item?.contact_id?.length &&
                  //   selectData?.find((e) => e?.value == item?.contact_id)
                  // }
                  setValue={setValue}
                  fieldname={`endorser[${index}].contact_id`}
                  label="Endorser/Maker"
                />
                {/* <Col span={11}>
                  <SelectField
                    fieldname={`endorser[${index}].contact_id`}
                    label="Endorser/Maker"
                    control={control}
                    initValue=""
                    valueGot={
                      item?.contact_id && receiptFormData?.contributors && item?.contact_id
                        ? receiptFormData?.contributors.find((e) => e.value == item.contact_id)
                        : ''
                    }
                    setValue={setValue}
                    iProps={{
                      size: 'large',
                      placeholder: 'Please select',
                    }}
                    selectOption={
                      receiptFormData?.contributors && receiptFormData?.contributors?.length > 0
                        ? receiptFormData?.contributors
                        : []
                    }
                    isRequired={true}
                    rules={{ required: 'Endorser/Maker is required.' }}
                    validate={
                      Object.entries(errors).length > 0 &&
                      errors?.endorser?.length > 0 &&
                      errors?.endorser[index]?.contact_id &&
                      'error'
                    }
                    validMessage={
                      Object.entries(errors).length > 0 &&
                      errors?.endorser?.length > 0 &&
                      errors?.endorser[index]?.contact_id &&
                      errors?.endorser[index]?.contact_id?.message
                    }
                  />
                </Col> */}

                <Col span={24}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <InputRadio
                      fieldname={`endorser[${index}].is_amount`}
                      control={control}
                      initValue={item?.is_amount && item?.is_amount ? 'amount' : 'percent'}
                      classField="small-radio"
                      onChange={(e) => {}}
                      options={
                        <>
                          <Radio value="percent">Percentage</Radio>
                          <Radio value="amount">Amount</Radio>
                        </>
                      }
                    />
                    <Tooltip title="The percentage or the amount of the loan that is guaranteed by the individual.">
                      <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px' }} />
                    </Tooltip>
                  </div>

                  {isAmount == 'percent' && (
                    <Row>
                      <Col span={24}>
                        <InputField
                          isRequired={true}
                          fieldname={`endorser[${index}].amount`}
                          label=""
                          control={control}
                          initValue={item?.amount}
                          iProps={{
                            size: 'large',
                            suffix: '%',
                            type: 'number',
                          }}
                          rules={{
                            required: 'Percentage is required.',
                          }}
                          validate={
                            Object.entries(errors).length > 0 &&
                            errors?.endorser?.length > 0 &&
                            errors?.endorser[index]?.amount &&
                            'error'
                          }
                          validMessage={
                            Object.entries(errors).length > 0 &&
                            errors?.endorser?.length > 0 &&
                            errors?.endorser[index]?.amount &&
                            errors?.endorser[index]?.amount?.message
                          }
                        />
                      </Col>
                    </Row>
                  )}

                  {isAmount && isAmount == 'amount' && (
                    <Row>
                      <Col span={24}>
                        <InputField
                          isRequired={true}
                          fieldname={`endorser[${index}].amount`}
                          label=""
                          control={control}
                          initValue={item?.amount}
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          rules={{
                            required: 'Amount is required.',
                          }}
                          validate={
                            Object.entries(errors).length > 0 &&
                            errors?.endorser?.length > 0 &&
                            errors?.endorser[index]?.amount &&
                            'error'
                          }
                          validMessage={
                            Object.entries(errors).length > 0 &&
                            errors?.endorser?.length > 0 &&
                            errors?.endorser[index]?.amount &&
                            errors?.endorser[index]?.amount?.message
                          }
                        />
                      </Col>
                    </Row>
                  )}
                </Col>

                <div className="accounts_remove">
                  <Button type="link" className="p-0" onClick={() => onRemove(item, index)}>
                    <Image src={trashIcon} preview={false} size={24} />
                  </Button>
                </div>
              </Row>
            </Fragment>
          );
        })}
      </Col>
      <Col span={24}>
        <Button type="text" htmlType="button" className="font-gilroy c-grey d-flex align-center" onClick={onAdd}>
          <Image src={plusIcon} preview={false} size={24} />
          Add Endorser/Maker
        </Button>
      </Col>
    </>
  );
};
