import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { InputField, SelectField } from '../../../../../atoms/FormElement';
import { countryList } from '../../../../../../configs/constants';
import { countyList } from '../../../../../../configs/county-list';
import {
  CONTACTS_POLITICAL_COMMITTEE_TYPE,
  CONTACTS_POLITICAL_COMMITTEE_ORG_LEVEL,
} from '../../../../../../configs/constants';

const { Paragraph } = Typography;

export default (props) => {
  const { control, errors, setValue, formData, exportContactId, setLoading, phoneNumber, setPhoneNumber } = props;
  const [organization, setOrganization] = useState(false);
  const [showJurisdiction, sethowJurisdiction] = useState(false);

  useEffect(() => {
    setValue('mailingcountryList', { label: 'North Carolina', value: 'NC' });
  }, []);

  useEffect(() => {
    //console.log(JSON.stringify(formData));
    if (formData && Object.keys(formData)?.length > 0) {
      const selectedCommitteeType = CONTACTS_POLITICAL_COMMITTEE_TYPE?.find(
        (e) => e?.value == formData?.committee_type,
      );
      const selectedCommitteeOrgLevel = CONTACTS_POLITICAL_COMMITTEE_ORG_LEVEL?.find(
        (e) => e?.value == formData?.committee_organization_level,
      );
      const selectedCounty = countyList?.find((e) => e?.value == formData?.committee_county_name);
      const selectedState = countryList?.find((e) => e?.value == formData?.state);
      setValue('committee_name', formData?.committee_name);
      setValue('committee_type', selectedCommitteeType);
      setValue('committee_organization_level', selectedCommitteeOrgLevel);
      setValue('committee_boe_id', formData?.committee_boe_id);
      setValue('mailingStreet', formData?.mailing_address);
      setValue('mailingCity', formData?.city);
      setValue('mailingZip', formData?.post_code);
      if (selectedState) {
        setValue('mailingcountryList', selectedState);
      }
      if (
        formData?.committee_type == 'joint-candidate-committee' ||
        formData?.committee_type == 'candidate-committee'
      ) {
        sethowJurisdiction(true);
        setValue('jurisdiction', formData?.jurisdiction);
        setValue('office_sought', formData?.office_sought);
      } else {
        sethowJurisdiction(false);
      }
      setPhoneNumber(formData?.phone);
      setValue('email', formData?.email);
      organizationChange(selectedCommitteeOrgLevel);
      setTimeout(() => {
        setValue('countyOrganization', selectedCounty);
        setLoading(false);
      }, 1000);
    } else if (exportContactId && Object.keys(exportContactId)?.length > 0) {
      const selectedCommitteeType = CONTACTS_POLITICAL_COMMITTEE_TYPE?.find(
        (e) => e?.value == exportContactId?.committee_type,
      );
      const selectedCommitteeOrgLevel = CONTACTS_POLITICAL_COMMITTEE_ORG_LEVEL?.find(
        (e) => e?.value == exportContactId?.committee_organization_level,
      );
      const selectedCounty = countyList?.find((e) => e?.value == exportContactId?.committee_county_name);
      const selectedState = countryList?.find((e) => e?.value == exportContactId?.state);
      setValue('committee_name', exportContactId?.committee_name);
      setValue('committee_type', selectedCommitteeType);
      setValue('committee_organization_level', selectedCommitteeOrgLevel);
      setValue('committee_boe_id', exportContactId?.committee_boe_id);
      setValue('mailingStreet', exportContactId?.mailing_address);
      setValue('mailingCity', exportContactId?.city);
      setValue('mailingZip', exportContactId?.post_code);
      if (selectedState) {
        setValue('mailingcountryList', selectedState);
      }
      if (
        exportContactId?.committee_type == 'joint-candidate-committee' ||
        exportContactId?.committee_type == 'candidate-committee'
      ) {
        sethowJurisdiction(true);
        setValue('jurisdiction', exportContactId?.jurisdiction);
        setValue('office_sought', exportContactId?.office_sought);
      } else {
        sethowJurisdiction(false);
      }
      setPhoneNumber(exportContactId?.phone);
      setValue('email', exportContactId?.email);
      organizationChange(selectedCommitteeOrgLevel);
      setTimeout(() => {
        setValue('countyOrganization', selectedCounty);
        setLoading(false);
      }, 1000);
    }
  }, [formData, exportContactId]);

  const organizationChange = (e) => {
    if (e?.value == 'county') {
      setOrganization(true);
    } else {
      setOrganization(false);
    }
  };

  const committeChange = (e) => {
    if (e?.value == 'joint-candidate-committee' || e?.value == 'candidate-committee') {
      sethowJurisdiction(true);
    } else {
      sethowJurisdiction(false);
    }
  };

  const onChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const selectedCountry = (e) => {
    if (e?.value == 'WADC') {
      setValue('mailingCity', 'Washington DC');
    } else if (formData && Object.keys(formData)?.length > 0) {
      setValue('mailingCity', formData?.city);
    } else if (exportContactId && Object.keys(exportContactId)?.length > 0) {
      setValue('mailingCity', exportContactId?.city);
    } else {
      setValue('mailingCity', '');
    }
  };

  return (
    <Row gutter={[48, 30]}>
      <Col span={12}>
        <Row gutter={[12, 30]}>
          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="committee_name"
              label="Committee Name"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Committee Name is required.',
              }}
              validate={errors.committee_name && 'error'}
              validMessage={errors.committee_name && errors.committee_name.message}
            />
          </Col>

          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="committee_type"
              label="Committee Type"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'Please select',
              }}
              rules={{
                required: 'Committee Type is required.',
              }}
              onChange={(e) => committeChange(e)}
              selectOption={CONTACTS_POLITICAL_COMMITTEE_TYPE}
              validate={errors.committee_type && 'error'}
              validMessage={errors.committee_type && errors.committee_type.message}
            />
          </Col>

          {showJurisdiction && (
            <>
              <Col span={12}>
                <InputField
                  isRequired={true}
                  fieldname="office_sought"
                  label="Office Sought"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                  }}
                  optionalLabel="(Optional)"
                />
              </Col>

              <Col span={12}>
                <InputField
                  isRequired={true}
                  fieldname="jurisdiction"
                  label="Jurisdiction"
                  class="optional_tooltip"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                  }}
                  optionalLabel="(Optional)"
                  tooltip={true}
                  tooltipTitle="District, county, or municipality of the office the candidate is seeking, if it is not a statewide office."
                />
              </Col>
            </>
          )}

          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="committee_organization_level"
              label="Committee Organization Level"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'Please select',
              }}
              rules={{
                required: 'Committee Organization Level is required.',
              }}
              onChange={(e) => organizationChange(e)}
              selectOption={CONTACTS_POLITICAL_COMMITTEE_ORG_LEVEL}
              validate={errors.committee_organization_level && 'error'}
              validMessage={errors.committee_organization_level && errors.committee_organization_level.message}
            />
          </Col>

          {organization && (
            <Col span={24}>
              <SelectField
                isRequired={true}
                fieldname="countyOrganization"
                control={control}
                label="County"
                class="mb-0"
                iProps={{ placeholder: 'Please select' }}
                rules={{ required: 'County is required.' }}
                initValue=""
                selectOption={countyList}
                validate={errors.countyOrganization && 'error'}
                validMessage={errors.countyOrganization && errors.countyOrganization.message}
              />
            </Col>
          )}

          <Col span={24}>
            <InputField
              fieldname="committee_boe_id"
              label="Board of Elections ID"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              hintMessage="Ex.: STA-123456-C-001."
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Row gutter={[30, 20]}>
          <Col span={24} className="mailingField">
            <Row gutter={[12, 8]}>
              <Col span={24}>
                <InputField
                  fieldname="mailingStreet"
                  label="Mailing Address"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'Street Address',
                  }}
                />
              </Col>

              <Col span={9}>
                <SelectField
                  fieldname="mailingcountryList"
                  control={control}
                  class="mb-0"
                  iProps={{ placeholder: 'Please select' }}
                  initValue={{}}
                  selectOption={countryList}
                  onChange={(e) => selectedCountry(e)}
                />
              </Col>

              <Col span={9}>
                <InputField
                  fieldname="mailingCity"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'City',
                  }}
                />
              </Col>

              <Col span={6}>
                <InputField
                  fieldname="mailingZip"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'Zip',
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-label">
                <label className="" title="Phone Number">
                  Phone Number
                </label>
                <div className="optional-label" style={{ top: '4px' }}>
                  (Optional)
                </div>
              </div>
            </div>
            <InputMask mask="(999) 999-9999" value={phoneNumber} onChange={onChange}>
              {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
            </InputMask>
          </Col>

          <Col span={12}>
            <InputField
              fieldname="email"
              label="Email"
              optionalLabel="(Optional)"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: 'Check if the email is valid',
                },
              }}
              validate={errors.email && 'error'}
              validMessage={errors.email && errors.email.message}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
