import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Typography, message, Button, Card, Descriptions } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import plusIcon from '../../../../assets/svg/plus.svg';
import penIcon from '../../../../assets/svg/pen-icon.svg';
import { SelectField } from '../../../atoms/FormElement';
import { specificField } from '../../../../configs/constants';
import { Popup } from '../../../atoms/Popup';
import NewContactType from '../NewContactPopup/NewContactType';
import NewContactIndividual from '../NewContactPopup/NewContactIndividual';
import { GET_BY_ID } from '../../../../configs/endpoints';
import { getObject } from '../../../../services/crudApis';
import { getReceiptDropdownData } from '../../../modules/Dashboard/Receipts/ducks/actions';

const { Paragraph } = Typography;

export default (props) => {
  const {
    control,
    errors,
    selectData,
    contactBoxEditCase,
    fieldname,
    label,
    setValue,
    arraySelectedItem,
    fixedContactType,
    column,
  } = props;
  const [contactID, setContctID] = useState('');
  const [openContactCard, setOpenContactCard] = useState(false);
  const [openContactTypePopup, setOpenContactTypePopup] = useState(false);
  const [contactToSelect, setContactToSelect] = useState(null);
  const [savePopupType, setSavePopupType] = useState('');
  const dispatch = useDispatch();
  const [popupType, setPopupType] = useState('');
  const [contctValue, setContctValue] = useState('');
  const [typeIndividual, setTypeIndividual] = useState(false);
  const [editPopupForm, setEditPopupForm] = useState(false);
  const [arrayIndex, setArrayIndex] = useState(null);
  const [endorserDropData, setEndorserDropData] = useState({});

  const changeValueByDropDown = (e) => {
    getObject(GET_BY_ID + '/' + e)
      .then((response) => {
        setEndorserDropData(response?.data);
      })
      .catch((e) => {
        message.error('Something went wrong');
      });
  };

  const specific_field = endorserDropData?.specific_field
    ? specificField?.find((e) => e?.value == endorserDropData?.specific_field)
    : '';

  useEffect(() => {
    if (setValue && selectData?.length > 0 && contactToSelect) {
      const selectedPayeeList = selectData?.find((e) => e?.value == contactToSelect);

      if (fieldname == arrayIndex) {
        setValue(fieldname, selectedPayeeList);
      }
      contributorData(selectedPayeeList);
      setContactToSelect(null);
    }
  }, [selectData]);

  useEffect(() => {
    if (setValue && arraySelectedItem) {
      let selectedPayeeList = {};
      if (typeof arraySelectedItem === 'string') {
        selectedPayeeList = selectData?.find((e) => e?.value == arraySelectedItem);
        setValue(fieldname, selectedPayeeList);
        contributorData(selectedPayeeList);
      } else {
        setValue(fieldname, arraySelectedItem);
        contributorData(arraySelectedItem);
      }
      setContactToSelect(null);
    }
  }, [arraySelectedItem, selectData]);

  // useEffect(() => {
  //   if (contactBoxEditCase) {
  //     contributorData(contactBoxEditCase);
  //   }
  // }, [contactBoxEditCase]);

  const contributorData = (e) => {
    changeValueByDropDown(e?.value);
    setContctValue(e?.value);
    setOpenContactCard(true);
  };

  const openContactType = () => {
    setOpenContactTypePopup(fixedContactType ? false : true);
    setTypeIndividual(fixedContactType ? true : false);
    setArrayIndex(fieldname);
    setContctValue('');
    setEditPopupForm(false);
  };

  const typeSelected = () => {
    setOpenContactTypePopup(false);
    setTypeIndividual(true);
  };

  const updateApi = (contactIds) => {
    setContactToSelect(contactIds);
    setTypeIndividual(false);
    setPopupType('');
    if (!editPopupForm) {
      dispatch(getReceiptDropdownData('general_contribution', 'null'));
    }
    if (contctValue) {
      changeValueByDropDown(contctValue);
    }
  };

  const editContributer = (e) => {
    if (contctValue) {
      changeValueByDropDown(contctValue);
    }
    setPopupType(e);
    typeSelected();
    setEditPopupForm(true);
  };

  const selectContactType = {
    closable: false,
    visibility: openContactTypePopup,
    class: 'black-modal',
    content: (
      <NewContactType
        title="New Contact"
        onClose={() => setOpenContactTypePopup(false)}
        typeSelected={typeSelected}
        setPopupType={setPopupType}
      />
    ),
    width: 542,
    onCancel: () => setOpenContactTypePopup(false),
  };

  const individualPopup = {
    closable: false,
    visibility: typeIndividual,
    class: 'black-modal',
    content: (
      <NewContactIndividual
        title="New Contact"
        onClose={() => setTypeIndividual(false)}
        setTypeIndividual={setTypeIndividual}
        popupType={fixedContactType ? fixedContactType : popupType}
        updateApi={(contctValue) => updateApi(contctValue)}
        editPopupForm={editPopupForm}
        data={editPopupForm ? endorserDropData : {}}
        contctValue={contctValue}
      />
    ),
    width: 542,
    onCancel: () => setTypeIndividual(false),
  };

  const handleKeyDownFunction = (event) => {
    if (event.keyCode === 8) {
      // Backspace key
      setValue(fieldname, null);
      setOpenContactCard(false);
    }
  };

  const keysToInclude = ['mailing_address', 'city', 'state', 'post_code'];
  const result = keysToInclude
    .filter((key) => endorserDropData[key] !== undefined && endorserDropData[key] !== '')
    .map((key) => endorserDropData[key])
    .join(', ');
  return (
    <>
      <Col span={column ? column : 24}>
        <SelectField
          isRequired={true}
          fieldname={fieldname}
          label={label}
          control={control}
          initValue=""
          iProps={{
            size: 'large',
            placeholder: 'Please select',
          }}
          rules={{
            required: `${label} is required.`,
          }}
          handleKeyDown={(e) => handleKeyDownFunction(e)}
          onChange={(e) => contributorData(e)}
          selectOption={selectData && selectData?.length > 0 ? selectData : []}
          validate={errors[fieldname] && 'error'}
          validMessage={errors[fieldname] && errors[fieldname].message}
        />

        <div className="add_new_contat">
          <Button
            type="text"
            htmlType="button"
            className="font-gilroy c-grey d-flex align-center"
            onClick={() => openContactType()}
          >
            <Image src={plusIcon} preview={false} size={24} />
            Add New
          </Button>
        </div>
      </Col>

      {openContactCard && endorserDropData && Object.keys(endorserDropData)?.length > 0 && (
        <Col span={24}>
          <div className="detail_label_ins">
            <Button onClick={() => editContributer(endorserDropData?.type)} type="text" className="right_pen">
              <Image src={penIcon} preview={false} width={18} />
            </Button>
            <Descriptions
              layout="vertical"
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              style={{ marginBottom: '10px' }}
            >
              <Descriptions.Item label="Address">
                {endorserDropData?.mailing_address || endorserDropData?.city || endorserDropData?.state ? result : '-'}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions layout="vertical" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
              <Descriptions.Item label="Phone">
                {endorserDropData?.phone ? endorserDropData?.phone : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {endorserDropData?.email ? endorserDropData?.email : '-'}
              </Descriptions.Item>
              {endorserDropData?.type == 'individual' ||
              endorserDropData?.type == 'treasurer' ||
              endorserDropData?.type == 'assistant_treasurer' ||
              endorserDropData?.type == 'custodian' ? (
                <>
                  {endorserDropData?.profession && endorserDropData?.profession?.length > 0 ? (
                    <Descriptions.Item label="Profession">
                      {endorserDropData?.profession ? endorserDropData?.profession : '-'}
                    </Descriptions.Item>
                  ) : (
                    <Descriptions.Item label="Job Title">
                      {endorserDropData?.job_title ? endorserDropData?.job_title : '-'}
                    </Descriptions.Item>
                  )}

                  {endorserDropData?.specific_field && endorserDropData?.specific_field?.length > 0 ? (
                    <Descriptions.Item label="Specific Field">
                      {endorserDropData?.specific_field ? specific_field.label : '-'}
                    </Descriptions.Item>
                  ) : (
                    <Descriptions.Item label="Employer">
                      {endorserDropData?.employer_name ? endorserDropData?.employer_name : '-'}
                    </Descriptions.Item>
                  )}
                </>
              ) : null}
            </Descriptions>
          </div>
        </Col>
      )}
      <Popup {...selectContactType} />
      <Popup {...individualPopup} />
    </>
  );
};
