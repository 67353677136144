import React, { useRef, useState } from 'react';
import { Row, Col, Typography, Image, Button, Carousel } from 'antd';
import Welcome from '../../pages/Welcome';
import addCommittee from '../../../assets/svg/add-committee.svg';
import recordTransactions from '../../../assets/svg/record-transactions.svg';
import fileReport from '../../../assets/svg/file-report.svg';
import { CircleArrowRed } from '../../atoms/CustomIcons';
import { useHistory } from 'react-router-dom';

const { Title, Paragraph } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [slide, setSlide] = useState(1);
  const slider = useRef();
  const history = useHistory();

  const goToSlide = (e) => {
    setSlide(e);
    slider.current.goTo(e);
  };
  return (
    <Welcome load={loading}>
      <Carousel
        dots={false}
        ref={(ref) => {
          slider.current = ref;
        }}
      >
        <div>
          <Row gutter={[30, 24]}>
            <Col span={24} className="text-center">
              <Image width={320} src={addCommittee} preview={false} />
            </Col>

            <Col span={24}>
              <Title level={2} className="text-center mb-0 font-gilroy">
                Add Your Committee
              </Title>
              <Paragraph className="text-center mt-1">
                Add your committee, candidate, officers, and <br />
                bank accounts. Needs to be done only once.
              </Paragraph>
              <Paragraph className="text-center smallFont12 c-grey">Takes 5 minutes</Paragraph>
            </Col>

            <Col span={24} className="circle-icon mb-0">
              <Button type="link" onClick={() => goToSlide(1)}>
                <CircleArrowRed className="redIcon" />
              </Button>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[30, 24]}>
            <Col span={24} className="text-center">
              <Image width={320} src={recordTransactions} preview={false} />
            </Col>

            <Col span={24}>
              <Title level={2} className="text-center mb-0 font-gilroy">
                Record Transactions
              </Title>
              <Paragraph className="text-center mt-1">
                Add all committee receipts and expenditures to <br />
                the system. The system will include these <br />
                transactions when reports are generated.
              </Paragraph>
              <Paragraph className="text-center smallFont12 c-grey">Takes 2 minutes per transaction</Paragraph>
            </Col>

            <Col span={24} className="circle-icon mb-0">
              <Button type="link" onClick={() => goToSlide(2)}>
                <CircleArrowRed className="redIcon" />
              </Button>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[30, 24]}>
            <Col span={24} className="text-center">
              <Image width={320} src={fileReport} preview={false} />
            </Col>

            <Col span={24}>
              <Title level={2} className="text-center mb-0 font-gilroy">
                Generate and File Reports
              </Title>
              <Paragraph className="text-center mt-1">
                Complete a couple of fields, click a button and your <br />
                report is filed!
              </Paragraph>
              <Paragraph className="text-center">
                We charge a flat fee for each report or amendment <br />
                submission. No upfront payments needed.
              </Paragraph>
            </Col>

            <Col span={24} className="mb-0 text-center">
              <Button
                type="primary"
                onClick={() => {
                  history.push('/import-data');
                }}
              >
                Import Data
              </Button>
            </Col>
          </Row>
        </div>
      </Carousel>
    </Welcome>
  );
};
