import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from './config/utils';
import Dashboard from '../app/modules/Dashboard';
import Login from '../app/modules/Login';
import ForgotPassword from '../app/modules/ForgotPassword';
import NotFound from '../app/modules/NoPage';
import CreateAccount from '../app/modules/CreateAccount';
import Welcome from '../app/modules/Welcome';
import OnBoard from '../app/modules/OnBoard';
import UiKit from '../app/modules/UiKit';
import EmailConfirmed from '../app/modules/EmailConfirmed';
import ResetPasswordInstructionSent from '../app/modules/ResetPasswordInstructionSent';
import UpdateNewPassword from '../app/modules/UpdateNewPassword';
import AddCommittee from '../app/modules/AddCommittee';
import CurrentElectionCycle from '../app/modules/Dashboard/InitialSetUp/CurrentElectionCycle';
import AddTransactions from '../app/modules/Dashboard/InitialSetUp/AddTransactions';
import OrganizationalReport from '../app/modules/Dashboard/InitialSetUp/OrganizationalReport';
import NewCommitees from '../app/modules/Dashboard/InitialSetUp/InformationNewCommitees';
import PreviousTransactions from '../app/modules/Dashboard/InitialSetUp/PreviousTransactions';
import Reports from '../app/modules/Dashboard/Reports';
import Receipts from '../app/modules/Dashboard/Receipts';
import ImportReceipts from '../app/modules/Dashboard/Receipts/ImportReceipts';
import Expenditures from '../app/modules/Dashboard/Expenditures';
import ImportExpenditures from '../app/modules/Dashboard/Expenditures/ImportExpenditures';
import SpecifyReport from '../app/modules/Dashboard/InitialSetUp/SpecifyReport';
import Contacts from '../app/modules/Dashboard/Contacts';
import ImportContactsList from '../app/modules/Dashboard/Contacts/importContacts';
import Success from '../app/modules/Dashboard/Stripe/Success';
import Cancel from '../app/modules/Dashboard/Stripe/Cancel';
import AddReceiptsType from '../app/modules/Dashboard/Receipts/AddReceipts/AddReceiptsType';
import NewReceipt from '../app/modules/Dashboard/Receipts/AddReceipts/NewReceipt';
import EditReceipt from '../app/modules/Dashboard/Receipts/EditReceipt';
import EditImportReceipt from '../app/modules/Dashboard/Receipts/EditImportReceipt';
import AddContactType from '../app/modules/Dashboard/Contacts/AddContacts/AddContactType';
import NewContact from '../app/modules/Dashboard/Contacts/AddContacts/NewContact';
import EditContact from '../app/modules/Dashboard/Contacts/EditContact';
import EditReceiveContact from '../app/modules/Dashboard/Contacts/EditReceiveContact';
import NewExpenditure from '../app/modules/Dashboard/Expenditures/AddExpenditure/NewExpenditure';
import AddExpenditureType from '../app/modules/Dashboard/Expenditures/AddExpenditure/AddExpenditureType';
import EditExpenditure from '../app/modules/Dashboard/Expenditures/EditExpenditure';
import EditImportExpenditure from '../app/modules/Dashboard/Expenditures/EditImportExpenditure';
import Settings from '../app/modules/Dashboard/Settings';
import AddReports from '../app/modules/Dashboard/Reports/AddReports';
import ImportData from '../app/modules/ImportData';
import EditReports from '../app/modules/Dashboard/Reports/EditReports';
import DataImportStatus from '../app/modules/ImportData/DataImportStatus';
import ViewReports from '../app/modules/Dashboard/Reports/ViewReports';
import Amendment from '../app/modules/Dashboard/Reports/Amendment';
import EditAmendment from '../app/modules/Dashboard/Reports/EditAmendment';
import ExemptSales from '../app/modules/Dashboard/ExemptSales';
import NewExempt from '../app/modules/Dashboard/ExemptSales/AddExempt/';
import EditExempt from '../app/modules/Dashboard/ExemptSales/EditExempt/';
import AdminData from '../app/modules/Dashboard/AdminData';
//import Debts from '../app/modules/Dashboard/Debts';
import AddDebt from '../app/modules/Dashboard/Debts/AddDebt';
import EditDebt from '../app/modules/Dashboard/Debts/EditDebts';
import OwedByTheComittee from '../app/modules/Dashboard/Debts/Tabs/OwedByTheComittee';
import OwedToTheComittee from '../app/modules/Dashboard/Debts/Tabs/OwedToTheComittee';

const Pages = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>
      <Route exact path="/create-account">
        <CreateAccount />
      </Route>
      <Route exact path="/ui-kit">
        <UiKit />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>

      <Route exact path="/confirm-email">
        <EmailConfirmed />
      </Route>

      <Route exact path="/instructions-sent">
        <ResetPasswordInstructionSent />
      </Route>
      <Route exact path="/update-password">
        <UpdateNewPassword />
      </Route>

      {isLoggedIn() ? (
        <>
          <Route exact path="/welcome">
            <Welcome />
          </Route>
          <Route exact path="/on-board">
            <OnBoard />
          </Route>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/add-committee">
            <AddCommittee />
          </Route>
          <Route exact path="/import-data">
            <ImportData />
          </Route>
          <Route exact path="/import-data/:id">
            <DataImportStatus />
          </Route>
          <Route exact path="/current-election-cycle">
            <CurrentElectionCycle />
          </Route>
          <Route exact path="/add-transactions">
            <AddTransactions />
          </Route>
          <Route exact path="/file-organizational-report">
            <OrganizationalReport />
          </Route>
          <Route exact path="/information-new-committees">
            <NewCommitees />
          </Route>
          <Route exact path="/previous-transactions">
            <PreviousTransactions />
          </Route>
          <Route exact path="/reports">
            <Reports />
          </Route>
          <Route exact path="/view-reports/:id/:id">
            <ViewReports />
          </Route>
          <Route exact path="/receipts">
            <Receipts />
          </Route>

          <Route exact path="/import-receipts">
            <ImportReceipts />
          </Route>

          <Route exact path="/expenditures">
            <Expenditures />
          </Route>

          <Route exact path="/import-expenditures">
            <ImportExpenditures />
          </Route>

          <Route exact path="/debts/owed-by-the-comittee">
            <OwedByTheComittee />
          </Route>

          <Route exact path="/debts/owed-to-the-comittee">
            <OwedToTheComittee />
          </Route>

          {/* <Route exact path="/debts">
            <Debts />
          </Route> */}

          <Route exact path="/new-debts/:id">
            <AddDebt />
          </Route>

          <Route exact path="/edit-debt/:id">
            <EditDebt />
          </Route>

          <Route exact path="/exempt-sales">
            <ExemptSales />
          </Route>

          <Route exact path="/new-exempt">
            <NewExempt />
          </Route>

          <Route exact path="/edit-exempt/:id">
            <EditExempt />
          </Route>

          <Route exact path="/confirm-email">
            <EmailConfirmed />
          </Route>
          <Route exact path="/instructions-sent">
            <ResetPasswordInstructionSent />
          </Route>

          <Route exact path="/specify-report">
            <SpecifyReport />
          </Route>

          <Route exact path="/reports/:id">
            <AddReports />
          </Route>

          <Route exact path="/reports-amendment/:id">
            <Amendment />
          </Route>

          <Route exact path="/edit-reports/:id/:id">
            <EditReports />
          </Route>

          <Route exact path="/edit-amendment/:id/:id">
            <EditAmendment />
          </Route>

          <Route exact path="/new-receipts">
            <NewReceipt />
          </Route>

          <Route exact path="/new-receipts/:id">
            <AddReceiptsType />
          </Route>

          <Route exact path="/edit-receipts/:id">
            <EditReceipt />
          </Route>

          <Route exact path="/edit-import-receipts/:id">
            <EditImportReceipt />
          </Route>

          <Route exact path="/contacts">
            <Contacts />
          </Route>

          <Route exact path="/import-contacts">
            <ImportContactsList />
          </Route>

          <Route exact path="/new-contact">
            <NewContact />
          </Route>

          <Route exact path="/new-contact/:id">
            <AddContactType />
          </Route>

          <Route exact path="/edit-contact/:id">
            <EditContact />
          </Route>

          <Route exact path="/edit-import-contact/:id">
            <EditReceiveContact />
          </Route>

          <Route exact path="/new-expenditure">
            <NewExpenditure />
          </Route>

          <Route exact path="/new-expenditure/:id">
            <AddExpenditureType />
          </Route>

          <Route exact path="/edit-expenditure/:id">
            <EditExpenditure />
          </Route>

          <Route exact path="/edit-import-expenditure/:id">
            <EditImportExpenditure />
          </Route>

          <Route exact path="/success">
            <Success />
          </Route>

          <Route exact path="/cancel">
            <Cancel />
          </Route>

          <Route exact path="/settings">
            <Settings />
          </Route>

          <Route exact path="/admin-data">
            <AdminData />
          </Route>
        </>
      ) : (
        <Redirect to="/" />
      )}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Pages;
