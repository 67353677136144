export const ADD_CONTACT = 'contacts';
export const EDIT_CONTACT = 'updateContactById';
export const ADD_IMPORTED_CONTACT = 'bulkUpdateContact';
export const ADD_IMPORTED_RECEIPT = 'bulkUnimportedReceiptAndExpenditure';
export const ADD_IMPORTED_EXPENDITURE = 'bulkUnimportedReceiptAndExpenditure';
export const EDIT_UNIMPORTED_CONTACT = 'updateUnimportedContactById';
export const DELETE_CONTACT = 'deleteContacts';
export const DELETE_IMPORT_CONTACT = 'deleteUnimportedContacts';
export const GET_CONTACT_LISTING = 'contacts';
export const GET_BY_ID = 'getContactFormData';
export const GET_UNIMPORTED_BY_ID = 'getUnimportedContactFormData';

export const ADD_REPORT = 'reports';
export const FILED_ORG_EXTERNALLY = 'filedORGExternally';
export const EDIT_REPORT = 'updateContactById';
export const DELETE_REPORT = 'deleteReports';
export const GET_REPORT_HTML_BY_ID = 'getReportHTML';
export const DOWNLOAD_REPORT_COVER_BY_ID = 'downloadReportCover';
export const DOWNLOAD_REPORT_SUMMARY_BY_ID = 'downloadReportSummary';
export const DOWNLOAD_REPORT_PDF_BY_ID = 'downloadReportPDFById';
export const DOWNLOAD_REPORT_CDF_BY_ID = 'downloadReportCFD';
export const GET_REPORT_LISTING = 'reports';
export const GET_CHECKOUT_LINK = 'getPaymentLink';
export const GET_REPORT_BY_ID = 'getReportsFormData';

export const SAVE_PAC_COMMITTEE = 'savePACInfo';
export const SAVE_COMMITTEE = 'saveCommitteeInfo';
export const SAVE_CANDIDATE = 'saveCandidateInfo';
export const SAVE_OFFICERS = 'saveOfficerInfo';
export const SAVE_ACCOUNTS = 'saveAccountInfo';
export const UPDATE_COMMITTEE = 'updateCommitteeInfo';
export const UPDATE_COMMITTEE_EMAIL = 'updateCommitteeEmail';

export const EDIT_COMMITTEE = 'updateContactById';
export const GET_COMMITTEE_BY_ID = 'getCommitteeInfo';
export const GET_CANDIDATE_BY_ID = 'getCandidateInfo';
export const GET_OFFICERS = 'getOfficersInfo';
export const GET_ACCOUNTS = 'getBankaccounts';
export const GET_ACCOUNTS_INFO = 'getAccountInfo';
export const GET_PAC_INFO = 'getPACInfo';

export const SUBMIT_REPORT = 'submitReport';
export const DATA_IMPORT = 'importData';
export const DATA_IMPORT_SELECTED_ID = 'importDataSelectedCommittee';
export const SAVE_INITIAL_BALANCE = 'saveInitialBalance';
export const FILED_REPORTS = 'filedReports';

export const DOWNLOAD_RECEIPT_CSV = 'downloadReceiptCSV';
export const DOWNLOAD_EXPENDITURE_CSV = 'downloadExpenditureCSV';
export const DOWNLOAD_CONTACT_CSV = 'downloadContactCSV';
export const DOWNLOAD_DEBTS_CSV = 'downloadDebtCSV';
export const BULK_IMPORT_CONTACT_CSV = 'bulkImportContactCSV';
