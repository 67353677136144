import React from 'react';
import Icon from '@ant-design/icons';

const Settings = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke="#fff"
      d="M12.8337 3.0381C13.4956 3.09889 14.018 3.59434 14.2125 4.2299V4.2299C14.3814 4.78203 14.8078 5.20903 15.3221 5.47132C15.5521 5.58856 15.775 5.7176 15.9901 5.85763C16.4746 6.17303 17.0583 6.32902 17.6217 6.19911V6.19911C18.2689 6.04985 18.9589 6.25383 19.3434 6.79546C19.6644 7.24752 19.9447 7.73051 20.1789 8.23907C20.4573 8.84347 20.2887 9.54432 19.835 10.0311V10.0311C19.4409 10.4539 19.2836 11.037 19.3136 11.6142C19.3202 11.7419 19.3235 11.8705 19.3235 11.9998C19.3235 12.1292 19.3202 12.2577 19.3136 12.3854C19.2836 12.9626 19.441 13.5458 19.8351 13.9686V13.9686C20.2888 14.4554 20.4574 15.1563 20.179 15.7607C19.9449 16.2692 19.6646 16.7521 19.3438 17.2041C18.9592 17.7458 18.2691 17.9499 17.6218 17.8006V17.8006C17.0584 17.6706 16.4746 17.8267 15.99 18.1421C15.775 18.2821 15.5522 18.4111 15.3223 18.5283C14.8078 18.7906 14.3814 19.2177 14.2124 19.7699V19.7699C14.0179 20.4056 13.4954 20.9012 12.8334 20.9619C12.559 20.9871 12.281 21 12 21C11.719 21 11.441 20.9871 11.1666 20.9619C10.5047 20.9011 9.9822 20.4056 9.78769 19.77V19.77C9.61872 19.2178 9.19229 18.7908 8.67782 18.5284C8.44783 18.4112 8.22487 18.2821 8.00971 18.1421C7.52521 17.8267 6.94152 17.6707 6.37819 17.8006V17.8006C5.73088 17.9499 5.04092 17.7459 4.65636 17.2042C4.33541 16.7521 4.05516 16.2691 3.82094 15.7605C3.54263 15.1562 3.71124 14.4555 4.16487 13.9688V13.9688C4.5589 13.546 4.71619 12.9629 4.68622 12.3858C4.67959 12.258 4.67623 12.1293 4.67623 11.9998C4.67623 11.8703 4.67959 11.7416 4.68623 11.6138C4.71619 11.0367 4.55892 10.4537 4.16492 10.0309V10.0309C3.71131 9.54425 3.54272 8.84354 3.82103 8.23925C4.05531 7.73059 4.33564 7.24751 4.65668 6.79536C5.04121 6.2538 5.73107 6.04983 6.37828 6.19908V6.19908C6.94156 6.32898 7.52518 6.17301 8.00963 5.85765C8.22486 5.71754 8.4479 5.58844 8.67797 5.47115C9.19235 5.20889 9.6187 4.78191 9.78765 4.2298V4.2298C9.98211 3.5943 10.5045 3.0989 11.1663 3.03811C11.4408 3.01289 11.7189 3 12 3C12.2811 3 12.5592 3.01289 12.8337 3.0381Z"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <circle stroke="#fff" cx="12" cy="12" r="2.39441" strokeWidth="1.5" />
  </svg>
);

const SettingsIcon = (props) => <Icon component={Settings} {...props} />;
export default SettingsIcon;
