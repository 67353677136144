import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Spin, message } from 'antd';
import { useForm } from 'react-hook-form';
import { SelectField, InputField } from '../../../../atoms/FormElement';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { editObject } from '../../../../../services/crudApis';
import { UPDATE_COMMITTEE } from '../../../../../configs/endpoints';
import { getCommitteeFormData } from '../../../Dashboard/Settings/ducks/actions';

const { Title, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { currentStep, setCurrentStep } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, handleSubmit, errors, setValue } = useForm({
    shouldUnregister: false,
  });
  const [loading, setLoading] = useState(false);
  const formData = useSelector((state) => state.setting.committeeFormData);
  const partyTypeList = [
    { label: 'Executive', value: 'Executive' },
    { label: 'Affiliated', value: 'Affiliated' },
    { label: 'Subordinate', value: 'Subordinate' },
  ];

  useEffect(() => {
    dispatch(getCommitteeFormData());
    setLoading(true);
  }, []);

  useEffect(() => {
    if (formData && Object.keys(formData)?.length > 0) {
      setLoading(true);
      const selectedType = partyTypeList?.find((e) => e?.value == formData?.party_type);

      setValue('partyName', formData?.party_name);
      setValue('partyType', selectedType);

      setLoading(false);
    }
  }, [formData]);

  const onFinish = (values) => {
    setLoading(true);
    const payload = {
      party_name: values.partyName,
      party_type: values.partyType ? values.partyType.value : '',
    };

    editObject(UPDATE_COMMITTEE, payload)
      .then((response) => {
        //console.log('response', response);
        setTimeout(() => {
          setLoading(false);
          message.success('Information Updated.');
          setTimeout(() => setCurrentStep(currentStep + 1), 1200);
        }, 800);
        setTimeout(() => {
          dispatch(getCommitteeFormData());
        }, 1200);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });

    //console.log('property', payload);
    setTimeout(() => setLoading(false), 1000);
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="partyName"
              label="Party Name"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Party Name is required.',
              }}
              validate={errors.partyName && 'error'}
              validMessage={errors.partyName && errors.partyName.message}
            />
          </Col>

          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="partyType"
              label="Party Type"
              control={control}
              class="mb-0"
              iProps={{ placeholder: 'Please select' }}
              rules={{ required: 'Party Type is required.' }}
              initValue=""
              selectOption={partyTypeList}
              validate={errors.partyType && 'error'}
              validMessage={errors.partyType && errors.partyType.message}
            />
          </Col>

          <Col span={24} className="text-center">
            <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
              Continue
            </Button>
          </Col>

          <Col span={24} className="text-center mt-2 mb-1">
            <Button
              type="text"
              htmlType="button"
              className="font-gilroy c-grey"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              BACK
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
