import React, { useState, useEffect } from 'react';
import Dashboard from '../../../../pages/Dashboard';
import {
  Card,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Menu,
  Dropdown,
  Input,
  Image,
  message,
  Tooltip,
  DatePicker,
  Slider,
  InputNumber,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { MoreOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import plusIcon from '../../../../../assets/svg/plus-icon-white.svg';
import exportCSV from '../../../../../assets/svg/export-csv.svg';
import arrowIcon from '../../../../../assets/svg/select-icon.svg';
import clearIcon from '../../../../../assets/svg/clear-filters.svg';
import reviewIcon from '../../../../../assets/svg/review-icon.svg';
import defaultExclamation from '../../../../../assets/svg/default-exclamation.svg';
import redExclamation from '../../../../../assets/svg/red-exclamation.svg';
import closeBlue from '../../../../../assets/svg/close-blue.svg';
import { getUnimportedReceiptList, setFiltersImport } from '../ducks/actions';
import { deleteUnimportedReceipt } from '../../../../../services/receipt';
import ConfirmDelete from '../../../../molecules/ConfirmDelete';
import ReportConfirmDeletePopup from '../../../../molecules/ReportConfirmDeletePopup';
import { Popup } from '../../../../atoms/Popup';
import moment from 'moment';
import ExportPeriod from '../../../../molecules/ExportPeriod';
import { ADD_IMPORTED_RECEIPT, DOWNLOAD_RECEIPT_CSV } from '../../../../../configs/endpoints';
import AmountDropdown from '../../../../molecules/AmountDropdown';
import ImportReceiptsExpenditure from '../../../../molecules/ImportReceiptsExpenditure';
import { addObject, getObject } from '../../../../../services/crudApis';
import { UnImportedExpenditureType } from '../../../../../configs/constants';
import DiscardImport from '../../../../molecules/DiscardImport';
import { CloseIcon } from '../../../../atoms/CustomIcons';

const { Text } = Typography;
const { RangePicker } = DatePicker;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [discardTransactions, setDiscardTransactions] = useState(false);
  const [confirmDeleteMultiple, setConfirmDeleteMultiple] = useState(false);
  const [confirmReport, setConfirmReport] = useState(false);
  const [periodPDF, setPeriodPDF] = useState(false);
  const [rangeBox, setRangeBox] = useState(false);
  const [confirmReportMultiple, setConfirmReportMultiple] = useState(false);
  const [importCSV, setImportCSV] = useState(false);
  const [receiptReportID, setReceiptReportID] = useState('');
  const [multipleReportID, setMultipleReportID] = useState([]);
  const [amountRange, setAmountRange] = useState([]);
  const [dataSource, setDataSource] = useState();
  const [filterRange, setFilterRange] = useState();
  const [value, setValue] = useState('');
  const [deleteRecordID, setDeleteRecordID] = useState();
  const [tableRecord, setTableRecord] = useState();
  const [inputValue, setInputValue] = useState([]);
  const [highestAmount, setHighestAmount] = useState(0);
  const [countError, setCountError] = useState(null);

  const receiptListData = useSelector((state) => state.receipt.receiptUnimportedListData);
  const selectedFilters = useSelector((state) => state.receipt.filter);
  const selectedImportFilters = useSelector((state) => state.receipt.filterImport);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    dispatch(getUnimportedReceiptList());
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => editRecord(tableRecord)} type="link" className="btn-link">
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={() => updateReportApi(tableRecord)} type="link" className="btn-link">
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );

  const storeStateInLocalStorage = (sort, newPagination) => {
    localStorage.setItem('antdTableStateReceipt', JSON.stringify({ sorting: sort, pagination: newPagination }));
  };

  useEffect(() => {
    const storedState = localStorage.getItem('antdTableStateReceipt');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      setSorting({
        column: parsedState.sorting?.field,
        order: parsedState.sorting?.order,
      });
      setPagination({
        current: parsedState.pagination?.current,
        pageSize: parsedState.pagination?.pageSize,
      });
    }
  }, []);

  const listColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: (a, b) => +moment(a.date, 'MM-DD-YYYY').format('X') - +moment(b.date, 'MM-DD-YYYY').format('X'),
      sortOrder: sorting?.column === 'date' ? sorting.order : null,
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => +a.amount.replace('$', '').split(',').join('') - +b.amount.replace('$', '').split(',').join(''),
      sortOrder: sorting?.column === 'amount' ? sorting.order : null,
      render: (text, record) => <span style={{ color: record?.is_color_changed ? '#069E79' : '#1E1E20' }}>{text}</span>,
    },
    {
      title: 'Transaction Type',
      dataIndex: 'type',
      render: (text) => (
        <span className="text-capitalize">
          {text
            ? text === 'debt_repayment_owed_to' || text === 'debt_repayment_owed_by'
              ? 'Debt Repayment'
              : text.replace(/_/g, ' ')
            : '-'}
        </span>
      ),
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortOrder: sorting?.column === 'type' ? sorting.order : null,
    },
    {
      title: 'Contact',
      dataIndex: 'contributor',
      sorter: (a, b) => a.contributor.localeCompare(b.contributor),
      sortOrder: sorting?.column === 'contributor' ? sorting.order : null,
    },
    {
      title: 'Form',
      dataIndex: 'payment_type',
      sorter: (a, b) => a.payment_type.localeCompare(b.payment_type),
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
      sortOrder: sorting?.column === 'payment_type' ? sorting.order : null,
    },
    {
      title: 'Account Code',
      dataIndex: 'bankaccount',
      sorter: (a, b) => a.bankaccount.localeCompare(b.bankaccount),
      sortOrder: sorting?.column === 'bankaccount' ? sorting.order : null,
    },
    {
      title: '',
      dataIndex: 'hasError',
      render: (text, record) => (
        <>
          {record?.hasError && record?.hasError !== 'false' && (
            <span className="error_message">
              {text}
              <Tooltip
                defaultOpen={true}
                title="Mandatory data is missing or invalid data is missing or can’t be accepted. Click on this entry (table row) to complete the details."
                placement="topRight"
              >
                <Image src={redExclamation} preview={false} width={11} />
              </Tooltip>
            </span>
          )}
          {record?.hasWarning && record?.hasWarning !== 'false' && (
            <span className="warning_message">
              {record?.hasWarning}{' '}
              <Tooltip
                title="There’s an identical contact in this list."
                placement="topRight"
                align={{
                  offsetX: 50,
                }}
              >
                <Image src={defaultExclamation} preview={false} width={11} />
              </Tooltip>
            </span>
          )}
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" onClick={() => handleButtonClick(record)}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleButtonClick = (e) => {
    setTableRecord(e);
  };

  const editRecord = (record) => {
    //console.log('click', record);

    let str = record?.type;
    str = str.replace(/_/g, '-').toLowerCase();

    history.push({
      pathname: `${
        UnImportedExpenditureType.includes(record?.type) ? '/edit-import-expenditure' : '/edit-import-receipts'
      }/${str}`,
      state: {
        receiptId: record?.id,
      },
    });
  };

  const deletePopup = {
    closable: false,
    visibility: confirmDelete,
    class: 'black-modal',
    content: (
      <ConfirmDelete
        title="Delete Receipt"
        paragraph="Are you sure you want to delete this Receipt?"
        onClose={() => setConfirmDelete(false)}
        onDelete={() => onDelete()}
        updateApi={() => (confirmDeleteMultiple ? updateApiMultiple() : updateApi())}
      />
    ),
    width: 450,
    onCancel: () => setConfirmDelete(false),
  };

  const deleteReport = {
    closable: false,
    visibility: confirmReport,
    class: 'black-modal',
    content: (
      <ReportConfirmDeletePopup
        title="Confirm Report Amendments"
        paragraph="Are you sure you want to delete this contact?"
        onClose={() => setConfirmReport(false)}
        onDelete={() => onDelete()}
        receiptReportID={multipleReportID && multipleReportID?.length > 0 ? multipleReportID : [receiptReportID]}
        updateApi={() => (confirmReportMultiple ? updateReportApiMultiple() : updateReportApi())}
      />
    ),
    width: 542,
    onCancel: () => setConfirmReport(false),
  };

  const exportPeriod = {
    closable: false,
    visibility: periodPDF,
    class: 'black-modal',
    content: (
      <ExportPeriod
        title="Select a Period For Export"
        paragraph="Transactions for the selected period will be exported."
        csvEndPoint={DOWNLOAD_RECEIPT_CSV}
        type="Receipt"
        onClose={() => setPeriodPDF(false)}
      />
    ),
    width: 542,
    onCancel: () => setPeriodPDF(false),
  };

  const updateApi = () => {
    setLoading(true);
    deleteUnimportedReceipt(deleteRecordID)
      .then((response) => {
        message.success('Transaction deleted.');
        setSelectedRowKeys([]);
        setConfirmDelete(false);
        dispatch(getUnimportedReceiptList());
        setTimeout(() => {
          setLoading(false);
          dispatch(setFiltersImport('showMandatory', undefined));
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmDelete(false);
        message.error('Something went wrong');
      });
  };

  const updateApiMultiple = () => {
    let receiptsToBeDeleted = [];
    for (const keys of selectedRowKeys) {
      receiptsToBeDeleted.push({
        id: receiptListData.find((x) => x.key === keys).id,
        type: receiptListData.find((x) => x.key === keys).type,
      });
    }
    deleteUnimportedReceipt(receiptsToBeDeleted)
      .then((response) => {
        message.success('Transactions deleted.');
        setConfirmDelete(false);
        setSelectedRowKeys([]);
        dispatch(getUnimportedReceiptList());
        setTimeout(() => {
          setLoading(false);
          dispatch(setFiltersImport('showMandatory', undefined));
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmDelete(false);
        message.error('Something went wrong');
      });
  };

  const updateReportApi = (record) => {
    setLoading(true);
    const payload = {
      id: record?.id,
      type: record?.type,
    };
    deleteUnimportedReceipt([payload])
      .then((response) => {
        message.success('Transaction deleted.');
        dispatch(getUnimportedReceiptList());
        setConfirmReport(false);
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
          dispatch(setFiltersImport('showMandatory', undefined));
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmReport(false);
        message.error('Something went wrong');
      });
  };

  const updateReportApiMultiple = () => {
    let receiptsToBeDeleted = [];
    for (const keys of selectedRowKeys) {
      receiptsToBeDeleted.push({
        id: receiptListData.find((x) => x.key === keys).id,
        type: receiptListData.find((x) => x.key === keys).type,
      });
    }
    deleteUnimportedReceipt(receiptsToBeDeleted)
      .then((response) => {
        message.success('Transactions deleted.');
        dispatch(getUnimportedReceiptList());
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
          dispatch(setFiltersImport('showMandatory', undefined));
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const deleteRecord = (record) => {
    setLoading(true);
    setDeleteRecordID([
      {
        id: record?.id,
        type: record?.type,
      },
    ]);
    if (record?.report_id) {
      setMultipleReportID([]);
      setConfirmReport(true);
      setReceiptReportID(record?.report_id);
    } else {
      setConfirmDelete(true);
    }
    setLoading(false);
  };

  const deleteMultiple = () => {
    let reportArray = [];
    for (const e of selectedRows) {
      if (e?.report_id) {
        reportArray.push(e?.report_id);
        setMultipleReportID(reportArray);
        setConfirmDelete(false);
        setConfirmReport(true);
        setConfirmReportMultiple(true);
        //console.log('yes');
      } else {
        //console.log('no');
        setConfirmReport(false);
        setConfirmDelete(true);
        setConfirmDeleteMultiple(true);
        setMultipleReportID([]);
      }
    }
  };

  // const fetchData = (params = {}, fromDate = 'null', toDate = 'null') => {
  //   setLoading(true);
  //   fetch(``).then(({ results }) => {
  //     setLoading(false);
  //     setPagination({
  //       ...params.pagination,
  //     });
  //   });
  // };

  // useEffect(() => {
  //   fetchData({
  //     pagination,
  //   });
  // }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setSorting({
      column: sorter?.field,
      order: sorter?.order,
    });
    setPagination(pagination);
    storeStateInLocalStorage(sorter, pagination);
    // fetchData({
    //   pagination: newPagination,
    //   ...filters,
    // });
  };

  const onClickRow = (record) => {
    return {
      onDoubleClick: () => {
        let str = record?.type;
        str = str.replace(/_/g, '-').toLowerCase();

        history.push({
          pathname: `${
            UnImportedExpenditureType.includes(record?.type) ? '/edit-import-expenditure' : '/edit-import-receipts'
          }/${str}`,
          state: {
            receiptId: record?.id,
          },
        });
      },
    };
  };

  useEffect(() => {
    setDataSource(receiptListData);

    if (receiptListData && receiptListData?.length > 0) {
      const numericalValues = receiptListData.map((res) => {
        // Remove the "$" sign and parse the string as an integer
        return parseInt(res?.amount.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);
      setInputValue([0, highestValue]);
      setHighestAmount(highestValue);
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
    }
  }, [receiptListData]);

  const hasSelected = selectedRowKeys.length > 0;

  const dateRange = (date) => {
    setFilterRange(date);
    dispatch(setFiltersOptions('date', date));
  };

  const emptyDateRange = () => {
    setFilterRange(null);
    setDataSource(receiptListData);
  };

  const rangeValue = (value) => {
    setAmountRange(value);
    setInputValue(value);
  };

  const applyRange = () => {
    dispatch(setFiltersOptions('amount', inputValue));
    setRangeBox(false);
  };

  const clearFilters = () => {
    if (receiptListData && receiptListData?.length > 0) {
      const numericalValues = receiptListData.map((res) => {
        return parseInt(res?.amount.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);
      setInputValue([0, highestValue]);
      setHighestAmount(highestValue);
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
    }
    setFilterRange(null);
    setAmountRange([]);
    setDataSource(receiptListData);
    setValue('');
  };

  const amountStart = (value) => {
    setInputValue([value, inputValue[1]]);
    setAmountRange([value, inputValue[1]]);
  };

  const amountEnd = (value) => {
    setInputValue([inputValue[0], value]);
    setAmountRange([inputValue[0], value]);
  };

  const clearAmount = () => {
    if (receiptListData && receiptListData?.length > 0) {
      const numericalValues = receiptListData.map((res) => {
        // Remove the "$" sign and parse the string as an integer
        return parseInt(res?.amount.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);
      setInputValue([0, highestValue]);
      setHighestAmount(highestValue);
      setAmountRange([]);
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
      setAmountRange([]);
    }
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    setRangeBox((prevState) => !prevState);
  };

  const closeDropdown = () => {
    setRangeBox(false);
  };

  const importCSVList = {
    closable: false,
    visibility: importCSV,
    class: 'black-modal new_modal',
    content: (
      <ImportReceiptsExpenditure
        title="Add Contacts"
        paragraph="You can add contacts manually one by one or by uploading them in bulk using a CSV file."
        onClose={() => setImportCSV(false)}
        manualLink="/new-receipts"
        afterUploadLink="/import-contacts"
      />
    ),
    width: 542,
    onCancel: () => setImportCSV(false),
  };

  const contactMurge = () => {
    setLoading(true);
    addObject(ADD_IMPORTED_RECEIPT)
      .then((response) => {
        dispatch(getUnimportedReceiptList());
        setLoading(false);
        if (dataSource?.length === 1) {
          message.success(`${dataSource?.length} transaction imported.`);
        } else {
          message.success(`${dataSource?.length} transactions imported.`);
        }
        history.push('/receipts');
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const hasErrorInList = (array) => {
    if (array && array?.length > 0) {
      for (let obj of array) {
        if (obj.hasOwnProperty('hasError')) {
          if (obj['hasError'] !== 'false' && obj['hasError']) {
            return true; // Key has the specified value, return true
          }
        }
      }
      return false; // Key does not exist in any object
    }
  };

  const countObjects = (key) => {
    let count = null;
    receiptListData?.forEach((obj) => {
      if (obj[key] !== 'false') {
        count++;
      }
    });
    return count;
  };

  useEffect(() => {
    if (receiptListData) {
      let countError = countObjects('hasError');
      if (countError) {
        setCountError(countError);
      } else {
        setCountError(null);
      }
    }
  }, [receiptListData]);

  useEffect(() => {
    const contactData = [...receiptListData];
    let filteredData = [];
    filteredData = contactData?.filter((entry) => {
      return selectedImportFilters?.showMandatory && entry?.hasError !== 'false';
    });
    if (filteredData?.length > 0) {
      setDataSource(filteredData);
    } else {
      setDataSource(receiptListData);
    }
  }, [selectedImportFilters, receiptListData]);

  const discardButton = () => {
    setLoading(true);
    getObject('discardUnimportedTransactions')
      .then(() => {
        setLoading(false);
        dispatch(setFiltersImport('showMandatory', undefined));
        setDiscardTransactions(false);
        history.push('/dashboard');
      })
      .catch(() => {});
  };

  const discardImport = {
    closable: false,
    visibility: discardTransactions,
    class: 'black-modal',
    content: (
      <DiscardImport
        title="Discard Transactions Import"
        paragraph="Are you sure you want to discard import? Any changes you made here will be lost."
        onClose={() => setDiscardTransactions(false)}
        updateApi={discardButton}
      />
    ),
    width: 450,
    onCancel: () => setDiscardTransactions(false),
  };

  return (
    <>
      <Dashboard load={loading} heading="Import Transactions">
        <Card className="white-card w-100 pcard-0">
          <div className="reports_head review_head">
            {hasSelected ? (
              <Row align="middle" justify="space-between" gutter={[10, 10]}>
                <Col>
                  <Button
                    onClick={updateReportApiMultiple}
                    type="text"
                    htmlType="button"
                    className="font-gilroy c-blue"
                  >
                    Delete Selected
                  </Button>
                </Col>
                <Col>
                  <Text className="font-14">{selectedRowKeys.length} Selected </Text>
                </Col>
              </Row>
            ) : (
              <div className="review_cantacts">
                <Image src={reviewIcon} preview={false} width={72} />
                <div className="review_text">
                  <h4>Review {receiptListData?.length} Transactions You’re Importing</h4>
                  <p>The following transactions will be imported to your account.</p>
                </div>
              </div>
            )}
          </div>

          {countError && (
            <div className="show_warning_cards">
              {countError && (
                <div className="error_card">
                  <Image src={redExclamation} preview={false} width={20} />
                  {countError === 1 ? (
                    <>
                      <div className="e_card_text">
                        There is {countError} incomplete or not acceptable transaction. Fix this transaction or{' '}
                        <b>it will not be imported</b>. <br />
                        <div className="e_card_btn">
                          {selectedImportFilters?.showMandatory ? (
                            <Button type="text" onClick={() => dispatch(setFiltersImport('showMandatory', undefined))}>
                              Clear Filter
                            </Button>
                          ) : (
                            <Button type="text" onClick={() => dispatch(setFiltersImport('showMandatory', true))}>
                              Show transactions
                            </Button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="e_card_text">
                        There are {countError} incomplete or not acceptable transactions. Fix these transactions or{' '}
                        <b>they will not be imported</b>. <br />
                        <div className="e_card_btn">
                          {selectedImportFilters?.showMandatory ? (
                            <Button type="text" onClick={() => dispatch(setFiltersImport('showMandatory', undefined))}>
                              Clear Filter
                            </Button>
                          ) : (
                            <Button type="text" onClick={() => dispatch(setFiltersImport('showMandatory', true))}>
                              Show transactions
                            </Button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}

          <Table
            rowSelection={rowSelection}
            columns={listColumns}
            dataSource={dataSource}
            pagination={{
              showSizeChanger: dataSource?.length > 10 ? true : false,
              ...pagination,
            }}
            onChange={handleTableChange}
            onRow={onClickRow}
            className="cursor_table"
          />
          <Col span={24}>
            <div className="new_table_btn">
              <div className="discard_btn">
                <Button
                  type="link"
                  htmlType="button"
                  className="font-gilroy"
                  size="large"
                  icon={<CloseIcon />}
                  onClick={() => setDiscardTransactions(true)}
                >
                  Discard & Close
                </Button>
              </div>

              <Row justify="center" gutter={[20, 20]}>
                <Col>
                  <Button
                    type="primary"
                    htmlType="button"
                    className="font-gilroy white-btn"
                    size="large"
                    onClick={() => {
                      dispatch(setFiltersImport('showMandatory', undefined));
                      history.push('/dashboard');
                    }}
                  >
                    Finish Later
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="secondary"
                    htmlType="button"
                    className="font-gilroy bg-red"
                    size="large"
                    onClick={contactMurge}
                    disabled={hasErrorInList(dataSource)}
                  >
                    Complete Import
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Card>
      </Dashboard>
      <Popup {...deletePopup} />
      <Popup {...deleteReport} />
      <Popup {...exportPeriod} />
      <Popup {...importCSVList} />
      <Popup {...discardImport} />
    </>
  );
};
