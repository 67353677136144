import React, { useState } from 'react';
import { Row, Col, Space, Button, Avatar, Dropdown, Typography, Menu, Image, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../../features/userSlice';
import backBtn from '../../../../assets/svg/backBtn.svg';
import { Popup } from '../../../atoms/Popup';
import ContactSupport from '../../../molecules/Dashboard/ContactSupport';

const { Text, Title } = Typography;
export default (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [contactPopVisible, setContactPopVisible] = useState(false);
  const isSetupComplete = JSON.parse(localStorage.getItem('user'))?.is_setup_complete;
  const logoutHandler = () => {
    dispatch(logout());
    localStorage.clear();
    window.location.assign('/');
  };

  const menu = (
    <Menu className="pofile-menu">
      {isSetupComplete && (
        <Menu.Item key="userAccount">
          <Button onClick={() => history.push('/settings')} type="link" className="btn-link">
            User Account
          </Button>
        </Menu.Item>
      )}

      <Menu.Item key="logOut">
        <Button type="link" className="btn-link" onClick={logoutHandler}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  const contactPopup = {
    closable: false,
    visibility: contactPopVisible,
    class: 'black-modal',
    content: <ContactSupport title="Contact Support" onClose={() => setContactPopVisible(false)} />,
    width: 540,
    onCancel: () => setContactPopVisible(false),
  };

  return (
    <>
      <Row gutter={24} align="middle" className="mb-3">
        <Col span={17}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {props?.backLink && (
              <Button
                type="text"
                htmlType="button"
                className="padding-none"
                style={{ marginRight: '20px' }}
                onClick={() => history.goBack()}
              >
                <Image src={backBtn} preview={false} width={24} />
              </Button>
            )}
            <Title level={2} className="font-gilroy mb-0 c-blue text-capitalize">
              {props?.heading}
            </Title>
            {props?.tags && <Tag className="sent_report">{props?.tags}</Tag>}
          </div>
        </Col>
        <Col span={7} className="text-right">
          <Space size={20}>
            {props?.contactButton && (
              <Button type="secondary" onClick={() => setContactPopVisible(true)}>
                Contact Support
              </Button>
            )}
            <Dropdown className="userDropdown" overlay={menu} placement="bottomRight">
              <Avatar size="small" icon={<UserOutlined />} />
            </Dropdown>
          </Space>
        </Col>
      </Row>
      <Popup {...contactPopup} />
    </>
  );
};
