import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Form, Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Dashboard from '../../../../pages/Dashboard';
import OperatingExpense from '../ExpendituresForms/OperatingExpense';
import RefundReimburse from '../ExpendituresForms/RefundReimburse';
import CoordinatedPartyExpenditure from '../ExpendituresForms/CoordinatedPartyExpenditure';
import ContributionToCandidate from '../ExpendituresForms/ContributionToCandidate';
import { getUnimportedExpenditureFormData } from '../ducks/actions';
import NonMonetaryGift from '../ExpendituresForms/NonMonetaryGift';
import { updateUnimportedExpenditure } from '../../../../../services/expenditure';
import { Popup } from '../../../../atoms/Popup';
import ReportConfirmationPopup from '../../../../molecules/ReportConfirmationPopup';
import ReportAmendment from '../../../../molecules/ReportAmendment';
import IndependentExpenditure from '../ExpendituresForms/IndependentExpenditure';
import LoanRepayment from '../ExpendituresForms/LoanRepayment';
import LimitWarning from '../../../../molecules/LimitWarning';
import DebtRepayment from '../ExpendituresForms/DebtRepayment';
import Confirmation from '../Popup/Confirmation';

const { Paragraph, Title } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [formOfPayment, setFormOfPayment] = useState('');
  const { control, handleSubmit, setError, setValue, getValues, errors, watch, clearErrors } = useForm({
    shouldUnregister: false,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname;
  const [reportVisible, setReportVisible] = useState(false);
  const [isReportId, setIsReportId] = useState(false);
  const [isAmendment, setIsAmendment] = useState(true);
  const [mediaWarning, setMediaWarning] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(null);
  const [receiptReportID, setReceiptReportID] = useState('');
  const [confirmation, setIsConfirmation] = useState(false);
  const [oldAmount, setOldAmount] = useState(0);
  let expenditureType = url.split('/')[2];
  const title = expenditureType.replace(/-/g, ' ').toLowerCase();
  const receiptId = location?.state?.receiptId;
  const formData = useSelector((state) => state.expenditure.expenditureFormData);
  const [visibleAddReceipt, setVisibleAddReceipt] = useState(false);
  const [highLimit, setHighlimit] = useState(false);
  const [popdata, setPopData] = useState();
  const [lowLimit, setLowlimit] = useState(false);
  const [getdataValue, setdataValue] = useState('');
  const apiType = expenditureType.replace(/-/g, '_').toLowerCase();

  const limitWarningPopup = {
    // closable: true,
    visibility: visibleAddReceipt,
    class: 'black-modal',
    content: (
      <LimitWarning
        title="Expenditure Limit Warning"
        errorHigh="Contributions made to other candidate or political action commettees cannot exceed $6,400."
        errorLow="Non-media expenditures of more than $50, except postage, may not be made in cash."
        btnTxt="Save Expenditure anyway"
        cashTitle="Cash Expenditure Warning"
        bottomText="If the current contribution doesn't exceed $6,400, please check all the contributions you have made to this committee."
        tableText="Expenditures That Counted Toward the Limit"
        visibleAddReceipt={visibleAddReceipt}
        mediaWarning={mediaWarning}
        onClose={() => setVisibleAddReceipt(false)}
        highLimit={highLimit}
        lowLimit={lowLimit}
        onFinish={() => onFinish(getdataValue, true)}
        getdataValue={getdataValue}
        popdata={popdata}
      />
    ),
    width: 542,
    onCancel: () => setVisibleAddReceipt(false),
  };

  let selectValue = url.split('/')[2].replace(/-/g, '_');
  const [expenditureTypeForm, setExpenditureTypeForm] = useState(selectValue);

  useEffect(() => {
    dispatch(getUnimportedExpenditureFormData(selectValue, receiptId));
    // setLoading(true);
  }, []);

  const onFinish = (values, force = false, confirmPopup = false) => {
    if (confirmPopup) {
      setIsConfirmation(false);
    }
    const mainAmount = oldAmount + remainingAmount;

    setdataValue(values);
    if (values?.purpose_code && values?.purpose_code?.length == 0) {
      setError('purpose_code', { type: 'required', message: 'Purpose Code is required.' });
      return;
    }
    if (
      Math.round((+values?.amount + Number.EPSILON) * 100) / 100 > mainAmount &&
      (expenditureType == 'debt-repayment' || expenditureType == 'debt-repayment-owed-by')
    ) {
      setError('amount', {
        type: 'required',
        message: 'Please enter an amount that does not exceed the remaining debt balance.',
      });
      return;
    }
    if (+values?.amount === 0 && (expenditureType == 'debt-repayment' || expenditureType == 'debt-repayment-owed-by')) {
      setError('amount', {
        type: 'required',
        message: 'The Amount Repaid cannot be 0.',
      });
      return;
    }
    if (
      Math.round((+values?.amount + Number.EPSILON) * 100) / 100 === mainAmount &&
      !confirmPopup &&
      (expenditureType == 'debt-repayment' || expenditureType == 'debt-repayment-owed-by') &&
      mainAmount !== formData?.expenditureData?.amount
    ) {
      setIsConfirmation(true);
      return;
    }

    setLoading(true);
    setReportVisible(false);
    setIsReportId(false);
    if (formData && !values?.effectiveDate && formData?.expenditureData && formData?.expenditureData?.report_id) {
      if (values?.purpose_code) {
        var purposeUnique = values?.purpose_code.filter(function (obj) {
          return !formData?.expenditureData?.purpose_code.some(function (obj2) {
            return obj.value == obj2;
          });
        });
      }

      if (
        values?.amount !== formData.expenditureData?.amount ||
        // (values?.date &&
        //   moment(values?.date, 'YYYY-MM-DD') !== formData.expenditureData?.date &&
        //   moment(formData.expenditureData?.date, 'YYYY-MM-DD')) ||
        (values?.payee_id && values?.payee_id?.value !== formData.expenditureData?.payee_id) ||
        (values?.purpose_code && purposeUnique?.length > 0) ||
        (values?.purpose_code && values?.purpose_code?.length < formData?.expenditureData?.purpose_code?.length) ||
        (values?.comments && values?.comments !== formData.expenditureData?.comments) ||
        (values?.payment_type && values?.payment_type !== formData.expenditureData?.payment_type) ||
        (values?.in_kind_description &&
          values?.in_kind_description !== formData.expenditureData?.in_kind_description) ||
        (values?.account && values?.account?.value !== formData.expenditureData?.account_id) ||
        (values?.remarks && values?.remarks !== formData.expenditureData?.remarks) ||
        (values?.type_of_gift && values?.type_of_gift?.value !== formData.expenditureData?.type_of_gift) ||
        (values?.gift_description && values?.gift_description !== formData.expenditureData?.gift_description) ||
        (values?.receipt_refunded_type &&
          values?.receipt_refunded_type !== formData.expenditureData?.receipt_refunded_type) ||
        (values?.coordinated_committee_name &&
          values?.coordinated_committee_name !== formData.expenditureData?.coordinated_committee_id)
      ) {
        setIsReportId(true);
        setReportVisible(true);
        setLoading(false);
        setIsAmendment(false);
        return;
      } else {
        //console.log('else 0');
        setReportVisible(false);
        setIsReportId(false);
        setIsAmendment(false);
      }
    }
    setIsAmendment(false);

    if (values?.purpose_code?.length && values?.purpose_code[0]?.value) {
      let pc = '';
      for (const code of values.purpose_code) {
        pc += code.value;
      }
      values.purpose_code = pc;
    }
    if (values.coordinated_committee_name?.value) {
      values.coordinated_committee_id = values.coordinated_committee_name.value;
      delete values.coordinated_committee_name;
    }

    if (values.payee_id?.value) {
      //console.log('formData.payee_id', values.payee_id);
      values.payee_id = values.payee_id.value;
    }
    if (values.obligation_id && values.obligation_id.value) {
      values.obligation_id = values.obligation_id.value;
    }
    if (values.type_of_gift?.value) {
      values.type_of_gift = values.type_of_gift.value;
    }
    if (values.receipt_refunded_type?.value) {
      values.receipt_refunded_type = values.receipt_refunded_type.value;
    }
    values.type = expenditureTypeForm;
    if (values.date) {
      values.date = moment(values.date).format('YYYY-MM-DD HH:mm:ss') + 'Z';
    }
    if (values.account) {
      values.account_id =
        Object.prototype.toString.call(values.account) === '[object Array]'
          ? values.account[0].value
          : values.account.value;
      delete values.account;
    }
    if (values.amount) {
      values.amount = +values.amount;
    }
    if (values.effectiveDate) {
      delete values.effectiveDate;
    }

    if (values.delaration_is_supporting) {
      values.delaration_is_supporting = values.delaration_is_supporting == 'Support' ? true : false;
    }

    if (values.loan_id) {
      values.loan_id = values.loan_id.value;
    }
    values.force = force === true;
    updateUnimportedExpenditure(values, apiType, receiptId)
      .then((response) => {
        //console.log('response', response);
        setIsAmendment(false);
        setTimeout(() => {
          setLoading(false);
        }, 800);
        setTimeout(() => {
          history.goBack();
        }, 1200);
      })
      .catch((e) => {
        setLoading(false);
        if (e && e?.response && e?.response['status'] == '412') {
          const message = JSON.parse(e?.response?.data?.error?.message);
          setMediaWarning(false);
          setHighlimit(false);
          setPopData();
          setLowlimit(false);

          if (message?.listOfEntities?.length > 0) {
            setHighlimit(true);
            setPopData(message?.listOfEntities);
          }

          if (message?.errors?.CONTRIBUTION_LIMIT_REACHED) {
            setHighlimit(true);
          }

          if (message?.errors?.CASH_FIFTY) {
            setLowlimit(true);
          }

          if (message?.errors?.MEDIA_EXPENDITURE) {
            setMediaWarning(true);
          }

          setVisibleAddReceipt(true);
        } else message.error('Something went wrong');
      });
  };

  const confirmReport = () => {
    setReportVisible(true);
  };

  useEffect(() => {
    if (formData && formData?.expenditureData?.report_id) {
      setReceiptReportID(formData?.expenditureData?.report_id);
      setIsReportId(true);
    } else {
      //console.log('else');
      setIsReportId(false);
    }
  }, [formData]);

  const submitFileExternal = (values) => {
    setReportVisible(false);
    const value = getValues();
    value.effectiveDate = 'no Data';
    onFinish(value);
  };

  const confirmReportPopup = {
    closable: false,
    visibility: reportVisible,
    class: 'black-modal',
    content: (
      <ReportConfirmationPopup
        title="Confirm Report Amendments"
        onClose={() => setReportVisible(false)}
        submitFileExternal={submitFileExternal}
        receiptReportID={receiptReportID}
        onDelete={() => onDelete()}
      />
    ),
    width: 542,
    onCancel: () => setReportVisible(false),
  };

  const ConfirmationPopup = {
    closable: false,
    visibility: confirmation,
    class: 'black-modal confirmation_modal',
    content: (
      <Confirmation
        onClose={() => setIsConfirmation(false)}
        title="Confirmation Of Full Debt Repayment"
        onConfirm={() => onFinish(getdataValue, true, true)}
        paragraph={
          <>
            The entered amount fully covers the remaining debt outstanding balance. The debt will be marked as{' '}
            <b>Paid.</b>
            <br />
            <br />
            Please confirm if you would like to proceed.
          </>
        }
      />
    ),
    width: 542,
    onCancel: () => setIsConfirmation(false),
  };

  return (
    <>
      <Dashboard load={loading} heading="Expenditure" backLink={() => history.goBack()}>
        <Card className="white-card w-100">
          <Row justify="center" gutter={[20, 20]}>
            <Col span={24}>
              <Title level={2} className="text-capitalize">
                {title}
              </Title>
            </Col>

            {expenditureType == 'gift' && (
              <Col span={24}>
                <Paragraph className="c-blue">
                  Use this form to report an “in-kind”, non-monetary gift, service or item to another committee.
                  Non-Monetary gifts will
                  <br /> NOT be entered in the RECEIPTS or EXPENDITURES on the Detailed Summary, but will be included
                  for information <br />
                  purposes at the bottom of the Detailed Summary. The committee receiving the gift will continue to
                  report the gift as an <br />
                  “in-kind” contribution.
                </Paragraph>
              </Col>
            )}

            {expenditureType == 'operating_expense' && (
              <Col span={24}>
                <Paragraph>
                  <b>Expenditure limits note:</b>
                </Paragraph>
                <ol style={{ paddingLeft: '15px' }}>
                  <li>Media expenditures may not be made in cash.</li>
                  <li>Non-media expenditures of more than $50, except postage, may not be made in cash.</li>
                </ol>
              </Col>
            )}

            {expenditureType == 'contribution_to_candidate_or_political_committee' && (
              <Col span={24}>
                <Paragraph>
                  <b>Expenditure limits note:</b>
                </Paragraph>
                <ol style={{ paddingLeft: '15px' }}>
                  <li>
                    Contributions made to other candidate or political action commettees cannot exceed $6,400.{' '}
                    <a
                      href="https://www.ncsbe.gov/campaign-finance/candidate-committees"
                      className="c-doger-blue"
                      target="_blank"
                    >
                      Learn More
                    </a>
                  </li>
                </ol>
              </Col>
            )}
          </Row>

          <Form className="w-100 mt-2" layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            {expenditureType == 'operating-expense' && (
              <OperatingExpense
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
              />
            )}

            {(expenditureType == 'debt-repayment' || expenditureType == 'debt-repayment-owed-by') && (
              <DebtRepayment
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
                remainingAmount={remainingAmount}
                setRemainingAmount={setRemainingAmount}
                setOldAmount={setOldAmount}
                oldAmount={oldAmount}
                setError={setError}
                clearErrors={clearErrors}
                watch={watch}
              />
            )}

            {expenditureType == 'contribution-to-candidate-or-political-committee' && (
              <ContributionToCandidate
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
              />
            )}

            {expenditureType == 'coordinated-party-expenditure' && (
              <CoordinatedPartyExpenditure
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
              />
            )}

            {expenditureType == 'refund' && (
              <RefundReimburse
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
              />
            )}

            {expenditureType == 'loan-repayment' && (
              <LoanRepayment
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
              />
            )}

            {expenditureType == 'gift' && (
              <NonMonetaryGift
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                setLoading={setLoading}
              />
            )}

            {expenditureType == 'independent-expenditure' && (
              <IndependentExpenditure control={control} errors={errors} setValue={setValue} setLoading={setLoading} />
            )}

            <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => history.goBack()}
                  htmlType="button"
                  className="font-gilroy white-btn"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Save Changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Dashboard>
      <ReportAmendment
        reportVisible={reportVisible}
        setReportVisible={setReportVisible}
        submitFileExternal={submitFileExternal}
        dateEffective={false}
        receiptId={formData?.expenditureData?.report_id}
      />
      <Popup {...limitWarningPopup} />
      <Popup {...ConfirmationPopup} />
    </>
  );
};
