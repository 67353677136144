import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Typography, Radio, Button, Card, Descriptions } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import plusIcon from '../../../../assets/svg/plus.svg';
import penIcon from '../../../../assets/svg/pen-icon2.svg';
import trashIcon from '../../../../assets/svg/trash-icon.svg';
import { SelectField } from '../../../atoms/FormElement';
import { specificField } from '../../../../configs/constants';
import { Popup } from '../../../atoms/Popup';
import NewContactIndividual from './ExemptPopup/NewContactIndividual';

const { Paragraph } = Typography;

export default (props) => {
  const {
    control,
    errors,
    selectData,
    changeValueByDropDown,
    updateDropdownValues,
    contributerData,
    contactBoxEditCase,
    fieldname,
    label,
    setValue,
    contactToSelect,
    setContactToSelect,
    deleteDropDownValue,
    fixedContactType,
    editExemptPlanData,
    setSalesDropdown,
  } = props;
  const [contactID, setContctID] = useState('');
  const [openContactCard, setOpenContactCard] = useState(false);
  const [openContactTypePopup, setOpenContactTypePopup] = useState(false);
  const [savePopupType, setSavePopupType] = useState('');

  const [popupType, setPopupType] = useState('');

  const [dropValue, setDropValue] = useState('');
  const [contctValue, setContctValue] = useState('');
  const [typeIndividual, setTypeIndividual] = useState(false);
  const [editPopupForm, setEditPopupForm] = useState(false);
  const [arrayIndex, setArrayIndex] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (setValue && selectData?.length > 0 && contactToSelect) {
      const selectedPayeeList = selectData?.find((e) => e?.value == contactToSelect);
      setValue(fieldname, selectedPayeeList);
      contributorData(selectedPayeeList);
      setDropValue(selectedPayeeList?.value);
      setContactToSelect(null);
    }
  }, [selectData]);

  useEffect(() => {
    if (contactBoxEditCase) {
      contributorData(contactBoxEditCase);
    }
  }, [contactBoxEditCase]);

  const contributorData = (e) => {
    setDropValue(e?.value);
    setOpenContactCard(true);
    setSalesDropdown(e);
  };

  const openContactType = () => {
    setOpenContactTypePopup(fixedContactType ? false : true);
    setIsEdit(false);
    setTypeIndividual(true);
    setArrayIndex(fieldname);
    setContctValue('');
    setEditPopupForm(false);
  };
  const editContactType = () => {
    setIsEdit(true);
    setEditPopupForm(true);
    setTypeIndividual(true);
    if (dropValue) {
      changeValueByDropDown(dropValue);
    }
  };
  const deleteContactType = () => {
    if (dropValue) {
      deleteDropDownValue(dropValue);
    }
  };

  const typeSelected = () => {
    setOpenContactTypePopup(false);
  };

  const updateApi = (contactIds) => {
    setContactToSelect(contactIds);
    setTypeIndividual(false);
    setPopupType('');
    if (!editPopupForm) {
      updateDropdownValues();
    }
    if (contctValue) {
      changeValueByDropDown(contctValue);
    }
  };

  const editContributer = (e) => {
    if (contctValue) {
      changeValueByDropDown(contctValue);
    }
    setPopupType(e);
    typeSelected();
    setEditPopupForm(true);
  };

  const individualPopup = {
    closable: false,
    visibility: typeIndividual,
    class: 'black-modal',
    content: (
      <NewContactIndividual
        onClose={() => setTypeIndividual(false)}
        isEdit={isEdit}
        setTypeIndividual={setTypeIndividual}
        updateApi={(contctValue) => updateApi(contctValue)}
        editPopupForm={editPopupForm}
        data={editPopupForm ? editExemptPlanData : {}}
        contctValue={contctValue}
      />
    ),
    width: 542,
    onCancel: () => setTypeIndividual(false),
  };
  return (
    <>
      <Col span={24}>
        <SelectField
          isRequired={true}
          fieldname={fieldname}
          label={label}
          control={control}
          initValue=""
          iProps={{
            size: 'large',
            placeholder: 'Please select',
          }}
          rules={{
            required: `${label} is required.`,
          }}
          onChange={(e) => contributorData(e)}
          selectOption={selectData && selectData?.length > 0 ? selectData : []}
          validate={errors[fieldname] && 'error'}
          validMessage={errors[fieldname] && errors[fieldname].message}
        />

        <div className="add_new_exempt">
          {openContactCard && (
            <div className="exempt_edit_delete">
              <Button
                type="text"
                htmlType="button"
                className="font-gilroy c-grey d-flex align-center"
                onClick={() => editContactType()}
              >
                <Image src={penIcon} preview={false} size={24} />
              </Button>
              <Button
                type="text"
                htmlType="button"
                className="font-gilroy c-grey d-flex align-center"
                onClick={() => deleteContactType()}
              >
                <Image src={trashIcon} preview={false} size={24} />
              </Button>
            </div>
          )}
          <Button
            type="text"
            htmlType="button"
            className="font-gilroy c-grey d-flex align-center"
            onClick={() => openContactType()}
          >
            <Image src={plusIcon} preview={false} size={24} />
          </Button>
        </div>
      </Col>

      <Popup {...individualPopup} />
    </>
  );
};
