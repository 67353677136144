import React, { useState } from 'react';
import { Row, Col, Typography, Button, Form, Radio } from 'antd';
import { useForm } from 'react-hook-form';
import { InputField, SelectField, InputCheckbox, InputRadio } from '../../atoms/FormElement';
import { useDispatch, useSelector } from 'react-redux';
import { countryList } from '../../../configs/constants';

const { Title } = Typography;

export default (props) => {
  const { control, handleSubmit, errors } = useForm();

  const onFinish = (values) => {
    //console.log('value', values);
  };
  return (
    <Row align="middle" justify="center">
      <Col flex="0 0 600px">
        <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
          <Row gutter={[12, 30]}>
            <Col span={24}>
              <Title level={2} className="text-center mb-0">
                UI Kit
              </Title>
            </Col>

            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="default"
                label="Default"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                rules={{
                  required: 'Please fix this error',
                }}
                validate={errors.default && 'error'}
                validMessage={errors.default && errors.default.message}
              />
            </Col>

            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="disabled"
                label="Disabled"
                control={control}
                initValue="This is Disabled"
                iProps={{
                  size: 'large',
                  disabled: true,
                }}
                rules={{
                  required: 'Please fix this error',
                }}
                validate={errors.disabled && 'error'}
                validMessage={errors.disabled && errors.disabled.message}
              />
            </Col>

            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="hintError"
                label="Hint + Error"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                rules={{
                  required: 'Please fix this error',
                }}
                hintMessage="Hint text"
                validate={errors.hintError && 'error'}
                validMessage={errors.hintError && errors.hintError.message}
              />
            </Col>

            <Col span={24}>
              <Title level={3} className="text-center mb-0">
                Rate
              </Title>
            </Col>

            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="rate"
                label="rate"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  suffix: '%',
                }}
                rules={{
                  required: 'Please fix this error',
                }}
                validate={errors.rate && 'error'}
                validMessage={errors.rate && errors.rate.message}
              />
            </Col>

            <Col span={24}>
              <Title level={3} className="text-center mb-0">
                Amount
              </Title>
            </Col>

            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="amount"
                label="Amount"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  prefix: '$',
                  type: 'number',
                }}
                rules={{
                  required: 'Please fix this error',
                }}
                validate={errors.amount && 'error'}
                validMessage={errors.amount && errors.amount.message}
              />
            </Col>

            <Col span={24}>
              <Title level={3} className="text-center mb-0">
                Mailing Address
              </Title>
            </Col>

            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="mailing"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                rules={{
                  required: 'Please fix this error',
                }}
                validate={errors.mailing && 'error'}
                validMessage={errors.mailing && errors.mailing.message}
              />
            </Col>

            <Col span={9}>
              <SelectField
                isRequired={true}
                fieldname="countryList"
                control={control}
                class="mb-0"
                iProps={{ placeholder: 'Please select' }}
                rules={{ required: 'Please fix this error' }}
                initValue=""
                selectOption={countryList}
                validate={errors.countryList && 'error'}
                validMessage={errors.countryList && errors.countryList.message}
              />
            </Col>

            <Col span={9}>
              <InputField
                isRequired={true}
                fieldname="city"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  placeholder: 'City',
                }}
                rules={{
                  required: 'Please fix this error',
                }}
                validate={errors.city && 'error'}
                validMessage={errors.city && errors.city.message}
              />
            </Col>

            <Col span={6}>
              <InputField
                isRequired={true}
                fieldname="zip"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  placeholder: 'Zip',
                }}
                rules={{
                  required: 'Please fix this error',
                }}
                validate={errors.zip && 'error'}
                validMessage={errors.zip && errors.zip.message}
              />
            </Col>

            <Col span={24}>
              <Title level={3} className="text-center mb-0">
                Checkbox
              </Title>
            </Col>

            <Col span={24}>
              <InputCheckbox
                isRequired={true}
                fieldname="checkbox"
                control={control}
                label="Label goes here"
                class={`mb-0 one-checbox`}
                rules={{
                  required: 'Please fix this error',
                }}
                validate={errors.checkbox && 'error'}
                validMessage={errors.checkbox && errors.checkbox.message}
              />
            </Col>

            <Col span={24}>
              <Title level={3} className="text-center mb-0">
                Radio
              </Title>
            </Col>

            <Col span={24}>
              <InputRadio
                isRequired={true}
                fieldname="radio"
                control={control}
                initValue={'Radio Label goes here'}
                options={
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Radio value="Yes">Yes</Radio>
                    </Col>
                    <Col span={24}>
                      <Radio value="No">No</Radio>
                    </Col>
                  </Row>
                }
                rules={{
                  required: 'Please fix this error',
                }}
                validate={errors.radio && 'error'}
                validMessage={errors.radio && errors.radio.message}
              />
            </Col>

            <Col span={24}>
              <Button type="primary" htmlType="submit" className="w-100" size="large">
                Log In
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
