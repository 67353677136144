import React, { Fragment } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Spin, Button, Image, Row, Col, Typography, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { InputField, SelectField, DateField2 } from '../../../../../../../../../atoms/FormElement';
import plusIcon from '../../../../../../../../../../assets/svg/plus.svg';
import trashIcon from '../../../../../../../../../../assets/svg/trash-icon.svg';
import { countryList } from '../../../../../../../../../../configs/constants';
import moment from 'moment';

const { Title } = Typography;

const initQ = {
  incurred_id: '',
  purchase_place: '',
  date: '',
  amount: '',
  description: '',
};

export default ({ nestIndex, control, setValue, deletedIndexesInc, setDeletedIndexesInc }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `obligation[${nestIndex}].incurreds`,
  });

  const onAdd = () => {
    append(initQ);
    let leng = `${fields.length + 1}`;
  };
  const onRemove = (e, index) => {
    remove(index);
    if (e?.incurred_id) {
      setDeletedIndexesInc([...deletedIndexesInc, e?.incurred_id]);
    }
  };
  return (
    <Row>
      {fields.map((item, k) => {
        return (
          <Fragment key={item.id}>
            <Row gutter={[20, 20]} align="bottom" className="nested_array">
              <Col span={24}>
                {k == 0 && (
                  <Title level={4} className="text-center mb-0 font-gilroy">
                    Incurred Debts
                    <Tooltip title="What the committee received this period.">
                      <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px', marginLeft: '10px' }} />
                    </Tooltip>
                  </Title>
                )}

                <div className="accounts_remove" style={{ right: 0, top: -5 }}>
                  <Button type="link" className="p-0" onClick={() => onRemove(item, k)}>
                    <Image src={trashIcon} preview={false} size={24} />
                  </Button>
                </div>
              </Col>
              <InputField
                fieldname={`obligation[${nestIndex}].incurreds[${k}].id`}
                label=""
                control={control}
                class="d-none mb-0"
                initValue={item?.id}
                iProps={{ size: 'large' }}
              />
              <InputField
                fieldname={`obligation[${nestIndex}].incurreds[${k}].incurred_id`}
                label=""
                control={control}
                class="d-none mb-0"
                initValue={item?.incurred_id}
                iProps={{ size: 'large' }}
              />

              <Col span={12}>
                <DateField2
                  fieldname={`obligation[${nestIndex}].incurreds[${k}].date`}
                  label="Date"
                  control={control}
                  class="mb-0"
                  iProps={{ size: 'large' }}
                  initValue={item?.date}
                />
              </Col>

              <Col span={12}>
                <InputField
                  fieldname={`obligation[${nestIndex}].incurreds[${k}].amount`}
                  label="Amount"
                  control={control}
                  valueGot={item?.amount && item?.amount > 0 ? item?.amount : '0'}
                  setValue={setValue}
                  class="mb-0"
                  //initValue={item?.purpose}
                  iProps={{ size: 'large', prefix: '$', type: 'number' }}
                />
              </Col>

              <Col span={24}>
                <InputField
                  fieldname={`obligation[${nestIndex}].incurreds[${k}].description`}
                  label="Item Description"
                  control={control}
                  class="mb-0"
                  //initValue={item?.code}
                  valueGot={item?.description}
                  setValue={setValue}
                  iProps={{ size: 'large' }}
                />
              </Col>
            </Row>
          </Fragment>
        );
      })}

      <Col span={24}>
        <Button type="text" htmlType="button" className="font-gilroy c-grey d-flex align-center" onClick={onAdd}>
          <Image src={plusIcon} preview={false} size={24} />
          Add Incurred Debt
        </Button>
      </Col>
    </Row>
  );
};
