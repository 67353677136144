import React from 'react';
import Icon from '@ant-design/icons';

const ExemptSales = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.11863 16.3788C1.94706 15.2073 1.94706 13.3078 3.11863 12.1362L10.0156 5.23922C10.6969 4.55796 11.6639 4.24534 12.615 4.39887L17.9175 5.25477C18.343 5.32346 18.6767 5.6571 18.7454 6.08264L19.6013 11.3851C19.7548 12.3363 19.4422 13.3033 18.7609 13.9845L11.8639 20.8815C10.6924 22.0531 8.79288 22.0531 7.62131 20.8815L3.11863 16.3788Z"
      stroke="#ffffff"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="13.7679"
      cy="10.2322"
      r="1.50609"
      transform="rotate(-45 13.7679 10.2322)"
      stroke="#ffffff"
      strokeWidth="1.5"
    />
    <path d="M7.96362 14.3275L9.67259 16.0365" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M18.5061 5.46231C20.6052 4.74171 20.9141 3.14387 20.9141 1.77679"
      stroke="#ffffff"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const ExemptSalesIcon = (props) => <Icon component={ExemptSales} {...props} />;
export default ExemptSalesIcon;
