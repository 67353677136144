import React from 'react';
import { Button, Col, Image, Row, Typography, Card, Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import crossIcon from '../../../../../assets/svg/cross-icon.svg';
import infoIcon from '../../../../../assets/svg/info-icon.svg';

const { Title, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { onClose, title, paragraph, onConfirm, loading } = props;
  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={2} className="m-0" style={{ fontSize: 18 }}>
            {title}
          </Title>
        </Col>
        <Col>
          <Button size="large" type="link" className="p-0" onClick={onClose}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>
        <Col span={24} style={{ marginTop: 16 }}>
          <Paragraph className="w-100" style={{ color: 'rgba(30, 30, 32, 1)' }}>
            {paragraph}
          </Paragraph>
        </Col>
        <Col span={24} style={{ marginBottom: 20 }}>
          <Card className="info-card">
            <Space size={20}>
              <Image src={infoIcon} width={30} preview={false} />
              <div className="info_text">
                <h2 style={{ fontSize: '14px', fontWeight: 700 }}>This action can’t be undone. </h2>
                <Paragraph className="smallFont14 w-100 mb-0">
                  Please contact{' '}
                  <a href="mailto:support@electafile.com" style={{ color: '#1D5FDA' }}>
                    support@electafile.com
                  </a>{' '}
                  if you’re facing any issues.
                </Paragraph>
              </div>
            </Space>
          </Card>
        </Col>

        <Col span={24}>
          <Row justify="center" gutter={[20, 20]}>
            <Col>
              <Button type="primary" htmlType="submit" className="font-gilroy white-btn" size="large" onClick={onClose}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" className="font-gilroy" size="large" onClick={onConfirm}>
                Change treasurer
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};
