import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Card, Button, Image, message } from 'antd';
import moment from 'moment';
import Dashboard from '../../../../pages/Dashboard';
import addTransactionIcon from '../../../../../assets/svg/add-transaction-icon.svg';

const { Title, Text } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const organisedReport = JSON.parse(localStorage.getItem('organisedReport'));
  const todayDate = moment().format('YYYY-MM-DD');
  const compareDate = moment(organisedReport).format('YYYY-MM-DD');
  const dayeDiff = moment(todayDate).diff(compareDate, 'days');

  return (
    <Dashboard load={loading} noLink={true}>
      <Row align="middle" justify="center">
        <Col flex="0 0 740px" style={{ overflow: 'hidden', padding: '0 20px' }}>
          <Card className="white-card text-center">
            <Row justify="center" gutter={[20, 25]}>
              <Col span={24}>
                <Title level={2}>Add Transactions. Then, File Reports</Title>
              </Col>
              <Col span={24}>
                <Image src={addTransactionIcon} preview={false} />
              </Col>
              <Col span={24}>
                <Text>
                  Add all the transactions from the committee's bank account(s). <br />
                  When the time comes, Electafile&reg; suggests a report that needs to be filed.
                </Text>
              </Col>
              <Col flex="0 0 604px" className="inner-grey-card text-left">
                <Card type="inner">
                  <Title level={3} className="font-gilroy">
                    Practical Tip
                  </Title>
                  <Text>
                    Add transactions as they come. This way, we can notify you as soon as possible if anything doesn't
                    comply with the law or requires any actions from your side.
                  </Text>
                </Card>
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="text-uppercase"
                  size="large"
                  onClick={() =>
                    history.push(
                      dayeDiff < 10 || dayeDiff == 0 ? '/information-new-committees' : '/previous-transactions',
                    )
                  }
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Dashboard>
  );
};
