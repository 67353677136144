import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Popover, message } from 'antd';
import { useForm } from 'react-hook-form';
import { InputField, InputPassword } from '../../atoms/FormElement';
import { useDispatch, useSelector } from 'react-redux';
import Login from '../../pages/Login';
import { resetPassword } from '../../../services/services';

const { Title, Paragraph } = Typography;

export default (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(false);

  useEffect(() => {
    setToken(new URLSearchParams(window.location.search).get('auth'));
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    resetPassword(values.password, token)
      .then((response) => {
        setLoading(false);
        message.success(response?.data?.Message);
        setTimeout(() => {
          history.push('/');
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        if (error && error?.response && error?.response.status == 401) {
          message.error('Verification Token Expired.');
        } else {
          message.error('An error occoured while trying to change password');
        }
      });
  };
  return (
    <Login load={loading}>
      <Row gutter={[30, 24]}>
        <Col span={24}>
          <Title level={2} className="text-center mb-0 font-gilroy">
            Create New Password
          </Title>
        </Col>
        <Col span={24}>
          <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Row gutter={[20, 30]}>
              <Col span={24}>
                <InputPassword
                  fieldname="password"
                  label="New Password"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                  }}
                  rules={{
                    required: 'Please enter password',
                  }}
                  validate={errors.password && 'error'}
                  validMessage={errors.password && errors.password.message}
                />
              </Col>

              <Col span={24}>
                <Button type="primary" htmlType="submit" className="w-100" size="large">
                  Change Password
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Login>
  );
};
