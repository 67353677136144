import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio, Card, Button, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  InputRadio,
  InputField,
  TextAreaField,
  SelectField,
  DateField,
  InputNumberField,
} from '../../../../../atoms/FormElement';
import plusIcon from '../../../../../../assets/svg/blue-plus.svg';
import trashIcon from '../../../../../../assets/svg/trash-icon.svg';
import infoIcon from '../../../../../../assets/svg/info-orange-icon.svg';
import remainingIcon from '../../../../../../assets/svg/remaining-icon.svg';
import { getReceiptDropdownData } from '../../ducks/actions';
import { FORMS_OF_PAYMENTS } from '../../../../../../configs/constants';
import moment from 'moment';

const { Paragraph, Title } = Typography;
const initQ = {
  check_number: '',
  amount: '',
};

export default (props) => {
  const {
    control,
    errors,
    formData,
    setValue,
    setLoading,
    fields,
    append,
    remove,
    watch,
    setError,
    clearErrors,
    remainingBalance,
    setRemainingBalance,
    formOfPayment,
    setFormOfPayment,
    breakDown,
    setBreakDown,
  } = props;
  const dispatch = useDispatch();
  const receiptFormData = useSelector((state) => state.receipt.receiptDropData);

  let amount = watch('amount');
  const breakdownBalance = watch('breakdown');

  useEffect(() => {
    const sum = breakdownBalance.reduce((accumulator, currentValue) => accumulator + +currentValue.amount, 0);
    const totalSum = amount - (sum ?? 0);
    setRemainingBalance(totalSum);
  }, [breakdownBalance, amount]);

  useEffect(() => {
    if (
      formData?.receiptData &&
      Object.keys(receiptFormData)?.length > 0 &&
      Object.keys(formData?.receiptData)?.length > 0
    ) {
      const selectedAccountList = receiptFormData?.accounts?.find((e) => e?.value == formData?.receiptData?.account_id);
      const selectedExpenditureList = receiptFormData?.contributors?.find(
        (e) => e?.value == formData?.receiptData?.reimbursed_expenditure_id,
      );
      setValue('amount', formData.receiptData?.amount);
      setValue('remimbursement_purpose', formData.receiptData?.remimbursement_purpose);
      setValue('reimbursed_expenditure_id', selectedExpenditureList);
      setValue('date', formData?.receiptData?.date ? moment(formData?.receiptData?.date, 'YYYY-MM-DD HH:mm') : '');
      setValue('comments', formData.receiptData?.comments);
      setValue('formOfPayment', formData.receiptData?.payment_type);
      setFormOfPayment(formData.receiptData?.payment_type);
      setTimeout(() => {
        setValue('check_number', formData.receiptData?.check_number);
        setValue('in_kind_description', formData.receiptData?.in_kind_description);
        setValue('account', selectedAccountList);
        setLoading(false);
      }, 1000);
    }
  }, [receiptFormData, formData]);

  useEffect(() => {
    dispatch(getReceiptDropdownData('refund_reimbursement_to_the_committee', 'null'));
  }, []);

  const expenditureList = [
    { label: '$100.00 to Wade Warren', value: '$100.00 to Wade Warren' },
    { label: '$5,520.00 to Company Abcz', value: '$5,520.00 to Company Abcz' },
  ];

  const formPayment = (e) => {
    setFormOfPayment(e.target.value);
    setBreakDown('No');
    setValue('breakdown', [
      {
        check_number: '',
        amount: '',
      },
    ]);
    if (receiptFormData && receiptFormData?.accounts?.length == 1) {
      setTimeout(() => {
        setValue('account', receiptFormData?.accounts[0]);
      }, 500);
    }
  };

  const onRemove = (e, index) => {
    remove(index);
  };
  const onAdd = () => {
    append(initQ);
  };

  return (
    <Row gutter={[48, 30]}>
      <Col span={12}>
        <Row gutter={[12, 30]}>
          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="reimbursed_expenditure_id"
              label="Expenditure Refunded/Reimbursed"
              control={control}
              class="mb-0"
              iProps={{ placeholder: 'Please select' }}
              rules={{ required: 'Expenditure Refunded/Reimbursed is required.' }}
              initValue=""
              selectOption={receiptFormData?.contributors}
              validate={errors.reimbursed_expenditure_id && 'error'}
              validMessage={errors.reimbursed_expenditure_id && errors.reimbursed_expenditure_id.message}
            />
          </Col>

          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="amount"
              label="Amount Refunded/Reimbursed"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                prefix: '$',
                type: 'number',
              }}
              rules={{
                required: 'Amount Refunded/Reimbursed is required.',
              }}
              validate={errors.amount && 'error'}
              validMessage={errors.amount && errors.amount.message}
            />
          </Col>

          <Col span={24}>
            <DateField
              fieldname="date"
              label="Date"
              control={control}
              class="mb-0"
              iProps={{ size: 'large' }}
              initValue=""
              isRequired={true}
              rules={{
                required: 'Date is required.',
              }}
              validate={errors.date && 'error'}
              validMessage={errors.date && errors.date.message}
            />
          </Col>

          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="remimbursement_purpose"
              label="Refund/Reimbursement Purpose"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Refund/Reimbursement Purpose is required.',
              }}
              validate={errors.remimbursement_purpose && 'error'}
              validMessage={errors.remimbursement_purpose && errors.remimbursement_purpose.message}
            />
          </Col>

          <Col span={24}>
            <TextAreaField
              fieldname="comments"
              label="Comments"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              optionalLabel={true}
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputRadio
              fieldname="formOfPayment"
              label="Form of Payment"
              control={control}
              initValue={''}
              classField="custom-radio"
              onChange={(e) => formPayment(e)}
              options={
                <>
                  {FORMS_OF_PAYMENTS &&
                    FORMS_OF_PAYMENTS?.map((item, index) => (
                      <Fragment key={index}>
                        <Radio.Button value={item?.value}>
                          <Image src={item?.image} preview={false} width={80} />
                          <Paragraph className="smallFont12">{item?.label}</Paragraph>
                        </Radio.Button>
                      </Fragment>
                    ))}
                </>
              }
              rules={{
                required: 'Form of Payment is required.',
              }}
              validate={errors.formOfPayment && 'error'}
              validMessage={errors.formOfPayment && errors.formOfPayment.message}
            />
          </Col>

          {formOfPayment && (
            <>
              <Col span={24}>
                {formOfPayment != 'in_kind' && (
                  <SelectField
                    isRequired={true}
                    fieldname="account"
                    label="Account"
                    control={control}
                    class="mb-0"
                    iProps={{ placeholder: 'Please select' }}
                    rules={{ required: 'Account is required.' }}
                    initValue=""
                    selectOption={receiptFormData?.accounts}
                    validate={errors.account && 'error'}
                    validMessage={errors.account && errors.account.message}
                  />
                )}

                {formOfPayment == 'in_kind' && (
                  <Col span={24}>
                    <InputField
                      isRequired={true}
                      fieldname="in_kind_description"
                      label="In-Kind Description"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                      }}
                      rules={{
                        required: 'In-Kind Description is required.',
                      }}
                      validate={errors.in_kind_description && 'error'}
                      validMessage={errors.in_kind_description && errors.in_kind_description.message}
                    />
                  </Col>
                )}
              </Col>

              {formOfPayment == 'check' && (
                <>
                  <Col span={24}>
                    <InputRadio
                      fieldname="delaration_is_supporting"
                      label="Would you like to break down this transaction?"
                      control={control}
                      classField="tab-type-radio"
                      initValue="No"
                      tooltip={true}
                      tooltipPlacement="topRight"
                      tooltipTitle="Use this option if the transaction involves a deposit of multiple checks. It will split this deposit into separate receipts for each check."
                      onChange={(e) => {
                        setBreakDown(e.target.value);
                        setValue('breakdown', [
                          {
                            check_number: '',
                            amount: '',
                          },
                        ]);
                      }}
                      options={
                        <>
                          <Row gutter={[20, 20]}>
                            <Col span={12}>
                              <Radio value="No">No</Radio>
                            </Col>
                            <Col span={12}>
                              <Radio value="Yes">Yes</Radio>
                            </Col>
                          </Row>
                        </>
                      }
                    />
                  </Col>
                  {breakDown === 'No' ? (
                    <Col span={24}>
                      <InputField
                        fieldname="check_number"
                        label="Check Number"
                        control={control}
                        optionalLabel="(Optional)"
                        class="optional_tooltip"
                        initValue=""
                        iProps={{
                          size: 'large',
                        }}
                      />
                    </Col>
                  ) : (
                    <Col span={24}>
                      <Card className="breakdown_wrapper_card">
                        <Row>
                          <Col span={24}>
                            <Card className="breakdown_card">
                              <Row gutter={14} align="middle">
                                <Col flex="0 0 28px" style={{ display: 'flex' }}>
                                  <Image src={remainingIcon} preview={false} width={28} />
                                </Col>
                                <Col>
                                  <Paragraph className="remaining_text">Remaining Amount to Break Down</Paragraph>
                                  <Title className="remaining_heading">
                                    {remainingBalance && remainingBalance > 0
                                      ? `$${Number(remainingBalance).toLocaleString()}`
                                      : 0}
                                  </Title>
                                </Col>
                              </Row>
                            </Card>
                          </Col>

                          <Col span={24}>
                            {fields?.map((item, index) => {
                              return (
                                <Fragment key={item.id}>
                                  <Row
                                    gutter={16}
                                    align="bottom"
                                    style={{
                                      position: 'relative',
                                      marginBottom: 16,
                                      paddingRight: 31,
                                    }}
                                  >
                                    <InputField
                                      fieldname={`breakdown[${index}].id`}
                                      label=""
                                      control={control}
                                      class="d-none mb-0"
                                      valueGot={item?.id}
                                      setValue={setValue}
                                      iProps={{ size: 'large' }}
                                    />

                                    <Col span={12}>
                                      <InputField
                                        isRequired={true}
                                        fieldname={`breakdown[${index}].check_number`}
                                        label={index === 0 ? 'Check Number' : ''}
                                        control={control}
                                        initValue={item?.check_number}
                                        iProps={{
                                          size: 'large',
                                          placeholder: 'Specify check number',
                                        }}
                                        rules={{
                                          required: 'Check number is required.',
                                        }}
                                        validate={
                                          Object.entries(errors).length > 0 &&
                                          errors?.breakdown?.length > 0 &&
                                          errors?.breakdown[index]?.check_number &&
                                          'error'
                                        }
                                        validMessage={
                                          Object.entries(errors).length > 0 &&
                                          errors?.breakdown?.length > 0 &&
                                          errors?.breakdown[index]?.check_number &&
                                          errors?.breakdown[index]?.check_number?.message
                                        }
                                      />
                                    </Col>

                                    <Col span={12}>
                                      <InputNumberField
                                        isRequired={true}
                                        fieldname={`breakdown[${index}].amount`}
                                        label={index === 0 ? 'Amount' : ''}
                                        control={control}
                                        initValue={item?.amount}
                                        iProps={{
                                          size: 'large',
                                          placeholder: 'Specify amount',
                                        }}
                                        rules={{
                                          required: 'Amount is required.',
                                        }}
                                        validate={
                                          Object.entries(errors).length > 0 &&
                                          errors?.breakdown?.length > 0 &&
                                          errors?.breakdown[index]?.amount &&
                                          'error'
                                        }
                                        validMessage={
                                          Object.entries(errors).length > 0 &&
                                          errors?.breakdown?.length > 0 &&
                                          errors?.breakdown[index]?.amount &&
                                          errors?.breakdown[index]?.amount?.message
                                        }
                                      />
                                    </Col>

                                    {/* {index > 0 && ( */}
                                    <div className={index === 0 ? 'accounts_remove' : 'accounts_remove remove_other'}>
                                      <Button type="link" className="p-0" onClick={() => onRemove(item, index)}>
                                        <Image src={trashIcon} preview={false} size={24} />
                                      </Button>
                                    </div>
                                    {/* )} */}
                                  </Row>
                                </Fragment>
                              );
                            })}
                          </Col>
                          {(remainingBalance < 0 || remainingBalance > 0) && (
                            <Col span={24}>
                              <Card className="info-card exceed_card">
                                <Space size={20}>
                                  <Image src={infoIcon} width={30} preview={false} />
                                  <div className="info_text">
                                    <Paragraph className="smallFont14 w-100 m-0">
                                      The total amount of the checks must be equal to the amount of the transaction.
                                    </Paragraph>
                                  </div>
                                </Space>
                              </Card>
                            </Col>
                          )}
                          <Col span={24}>
                            <Button
                              type="text"
                              htmlType="button"
                              className="font-gilroy d-flex align-center add_check_btn"
                              onClick={onAdd}
                            >
                              <Image src={plusIcon} preview={false} size={24} />
                              Add Check
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  )}
                </>
              )}
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};
