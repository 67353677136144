export { default as CircleArrowRed } from './CircleArrowRed';
export { default as DashboardIcon } from './Dashboard';
export { default as ReportIcon } from './Reports';
export { default as ReceiptsIcon } from './Receipts';
export { default as ExpendituresIcon } from './Expenditures';
export { default as DebtsIcon } from './Debts';
export { default as ExemptSalesIcon } from './ExemptSales';
export { default as ContactListIcon } from './ContactList';
export { default as SettingsIcon } from './Settings';
export { default as DownloadIcon } from './Download';
export { default as ArrowIcon } from './ArrowIconNew';
export { default as CloseIcon } from './CloseIcon';
