import React from 'react';
import Icon from '@ant-design/icons';

const Dashboard = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 22V2" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M4 4.99975C10.1913 2.93584 12.1235 4.65815 15.0053 5.6085C17.6968 6.49609 19.0426 6.93988 19.4255 7.52332C19.8085 8.10676 19.7197 9.08357 19.5421 11.0372L19.3041 13.6549C19.1499 15.3506 19.0729 16.1985 18.3619 16.7319C17.6509 17.2654 16.9845 17.1354 15.6518 16.8753C12.7921 16.3174 11.9706 14.6938 8 15.0826"
      stroke="#fff"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 8L9.99619 8.08716" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

const DashboardIcon = (props) => <Icon component={Dashboard} {...props} />;
export default DashboardIcon;
