import React, { useState, useEffect } from 'react';
import Dashboard from '../../../pages/Dashboard';
import {
  Card,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Menu,
  Dropdown,
  Input,
  Image,
  message,
  Tooltip,
  DatePicker,
  Slider,
  InputNumber,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { MoreOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import plusIcon from '../../../../assets/svg/plus-icon-white.svg';
import exportCSV from '../../../../assets/svg/export-csv.svg';
import arrowIcon from '../../../../assets/svg/select-icon.svg';
import clearIcon from '../../../../assets/svg/clear-filters.svg';
import { clearReceiptFilters, getReceiptListingData, setFiltersOptions } from './ducks/actions';
import { deleteReceipts } from '../../../../services/receipt';
import ConfirmDelete from '../../../molecules/ConfirmDelete';
import ReportConfirmDeletePopup from '../../../molecules/ReportConfirmDeletePopup';
import { Popup } from '../../../atoms/Popup';
import moment from 'moment';
import ExportPeriod from '../../../molecules/ExportPeriod';
import { DOWNLOAD_RECEIPT_CSV } from '../../../../configs/endpoints';
import AmountDropdown from '../../../molecules/AmountDropdown';
import ImportReceiptsExpenditure from '../../../molecules/ImportReceiptsExpenditure';

const { Text } = Typography;
const { RangePicker } = DatePicker;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteMultiple, setConfirmDeleteMultiple] = useState(false);
  const [confirmReport, setConfirmReport] = useState(false);
  const [periodPDF, setPeriodPDF] = useState(false);
  const [rangeBox, setRangeBox] = useState(false);
  const [confirmReportMultiple, setConfirmReportMultiple] = useState(false);
  const [importCSV, setImportCSV] = useState(false);
  const [receiptReportID, setReceiptReportID] = useState('');
  const [multipleReportID, setMultipleReportID] = useState([]);
  const [amountRange, setAmountRange] = useState([]);
  const [dataSource, setDataSource] = useState();
  const [filterRange, setFilterRange] = useState();
  const [value, setValue] = useState('');
  const [deleteRecordID, setDeleteRecordID] = useState();
  const [tableRecord, setTableRecord] = useState();
  const [inputValue, setInputValue] = useState([]);
  const [highestAmount, setHighestAmount] = useState(0);
  const receiptListData = useSelector((state) => state.receipt.receiptListData);
  const selectedFilters = useSelector((state) => state.receipt.filter);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    const contactData = [...receiptListData];
    const currValue = selectedFilters?.search?.toLowerCase();
    const startDate =
      selectedFilters?.date &&
      selectedFilters?.date?.length > 0 &&
      moment(selectedFilters?.date[0]).format('MM-DD-YYYY');
    const endDate =
      selectedFilters?.date &&
      selectedFilters?.date?.length > 0 &&
      moment(selectedFilters?.date[1]).format('MM-DD-YYYY');
    let filteredData = [];
    setValue(currValue);

    filteredData = contactData.filter((entry) => {
      const amount = +entry?.amount.replace('$', '').split(',').join('');
      const date = moment(entry?.date).format('MM-DD-YYYY');
      const typedData = currValue
        ? (entry?.date && entry?.date.includes(currValue)) ||
          (entry?.amount && entry?.amount?.includes(currValue)) ||
          (entry?.type && entry?.type?.replace(/_/g, ' ')?.toLowerCase().includes(currValue)) ||
          (entry?.contributor && entry?.contributor.toLowerCase().includes(currValue)) ||
          (entry?.payment_type && entry?.payment_type?.replace(/_/g, ' ')?.toLowerCase().includes(currValue)) ||
          (entry?.bankaccount && entry?.bankaccount.toLowerCase().includes(currValue))
        : true;

      const amountData =
        selectedFilters?.amount?.length > 0 ? amount >= inputValue[0] && amount <= inputValue[1] : true;
      const dateData = startDate && endDate ? moment(date).isBetween(startDate, endDate, null, '[]') : true;
      return amountData && dateData && typedData;
    });
    setDataSource(filteredData);
  }, [selectedFilters]);

  useEffect(() => {
    dispatch(clearReceiptFilters());
    dispatch(getReceiptListingData());
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => editRecord(tableRecord)} type="link" className="btn-link">
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={() => deleteRecord(tableRecord)} type="link" className="btn-link">
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );

  const listColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: (a, b) => +moment(a.date, 'MM-DD-YYYY').format('X') - +moment(b.date, 'MM-DD-YYYY').format('X'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => +a.amount.replace('$', '').split(',').join('') - +b.amount.replace('$', '').split(',').join(''),
    },
    {
      title: 'Receipt Type',
      dataIndex: 'type',
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Contributor',
      dataIndex: 'contributor',
      sorter: (a, b) => a.contributor.localeCompare(b.contributor),
    },
    {
      title: 'Form',
      dataIndex: 'payment_type',
      sorter: (a, b) => a.payment_type.localeCompare(b.payment_type),
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
    },
    {
      title: 'Account Code',
      dataIndex: 'bankaccount',
      sorter: (a, b) => a.bankaccount.localeCompare(b.bankaccount),
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" onClick={() => handleButtonClick(record)}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleButtonClick = (e) => {
    setTableRecord(e);
  };

  const editRecord = (record) => {
    //console.log('click', record);

    let str = record?.type;
    str = str.replace(/_/g, '-').toLowerCase();

    history.push({
      pathname: `/edit-receipts/${str}`,
      state: {
        receiptId: record?.id,
      },
    });
  };

  const deletePopup = {
    closable: false,
    visibility: confirmDelete,
    class: 'black-modal',
    content: (
      <ConfirmDelete
        title="Delete Receipt"
        paragraph="Are you sure you want to delete this Receipt?"
        onClose={() => setConfirmDelete(false)}
        onDelete={() => onDelete()}
        updateApi={() => (confirmDeleteMultiple ? updateApiMultiple() : updateApi())}
      />
    ),
    width: 450,
    onCancel: () => setConfirmDelete(false),
  };

  const deleteReport = {
    closable: false,
    visibility: confirmReport,
    class: 'black-modal',
    content: (
      <ReportConfirmDeletePopup
        title="Confirm Report Amendments"
        paragraph="Are you sure you want to delete this contact?"
        onClose={() => setConfirmReport(false)}
        onDelete={() => onDelete()}
        receiptReportID={multipleReportID && multipleReportID?.length > 0 ? multipleReportID : [receiptReportID]}
        updateApi={() => (confirmReportMultiple ? updateReportApiMultiple() : updateReportApi())}
      />
    ),
    width: 542,
    onCancel: () => setConfirmReport(false),
  };

  const exportPeriod = {
    closable: false,
    visibility: periodPDF,
    class: 'black-modal',
    content: (
      <ExportPeriod
        title="Select a Period For Export"
        paragraph="Transactions for the selected period will be exported."
        csvEndPoint={DOWNLOAD_RECEIPT_CSV}
        type="Receipt"
        onClose={() => setPeriodPDF(false)}
      />
    ),
    width: 542,
    onCancel: () => setPeriodPDF(false),
  };

  const updateApi = () => {
    setLoading(true);
    deleteReceipts([deleteRecordID])
      .then((response) => {
        message.success('Receipt deleted.');
        setSelectedRowKeys([]);
        setConfirmDelete(false);
        dispatch(getReceiptListingData());
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmDelete(false);
        message.error('Something went wrong');
      });
  };

  const updateApiMultiple = () => {
    let receiptsToBeDeleted = [];
    for (const keys of selectedRowKeys) {
      receiptsToBeDeleted.push(receiptListData.find((x) => x.key === keys).id);
    }
    deleteReceipts(receiptsToBeDeleted)
      .then((response) => {
        message.success('Receipts deleted.');
        setConfirmDelete(false);
        setSelectedRowKeys([]);
        dispatch(getReceiptListingData());
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmDelete(false);
        message.error('Something went wrong');
      });
  };

  const updateReportApi = () => {
    setLoading(true);
    deleteReceipts([deleteRecordID])
      .then((response) => {
        message.success('Receipt deleted.');
        dispatch(getReceiptListingData());
        setConfirmReport(false);
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmReport(false);
        message.error('Something went wrong');
      });
  };

  const updateReportApiMultiple = () => {
    let receiptsToBeDeleted = [];
    for (const keys of selectedRowKeys) {
      receiptsToBeDeleted.push(receiptListData.find((x) => x.key === keys).id);
    }
    deleteReceipts(receiptsToBeDeleted)
      .then((response) => {
        message.success('Receipts deleted.');
        dispatch(getReceiptListingData());
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const deleteRecord = (record) => {
    setLoading(true);
    setDeleteRecordID(record?.id);
    if (record?.report_id) {
      setMultipleReportID([]);
      setConfirmReport(true);
      setReceiptReportID(record?.report_id);
    } else {
      setConfirmDelete(true);
    }
    setLoading(false);
  };

  const deleteMultiple = () => {
    let reportArray = [];
    for (const e of selectedRows) {
      if (e?.report_id) {
        reportArray.push(e?.report_id);
        setMultipleReportID(reportArray);
        setConfirmDelete(false);
        setConfirmReport(true);
        setConfirmReportMultiple(true);
        //console.log('yes');
      } else {
        //console.log('no');
        setConfirmReport(false);
        setConfirmDelete(true);
        setConfirmDeleteMultiple(true);
        setMultipleReportID([]);
      }
    }
  };

  const fetchData = (params = {}, fromDate = 'null', toDate = 'null') => {
    setLoading(true);
    fetch(``).then(({ results }) => {
      setLoading(false);
      setPagination({
        ...params.pagination,
      });
    });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const handleTableChange = (newPagination, filters) => {
    fetchData({
      pagination: newPagination,
      ...filters,
    });
  };

  const onClickRow = (record) => {
    return {
      onDoubleClick: () => {
        let str = record?.type;
        str = str.replace(/_/g, '-').toLowerCase();

        history.push({
          pathname: `/edit-receipts/${str}`,
          state: {
            receiptId: record?.id,
          },
        });
      },
    };
  };

  useEffect(() => {
    setDataSource(receiptListData);

    if (receiptListData && receiptListData?.length > 0) {
      const numericalValues = receiptListData.map((res) => {
        // Remove the "$" sign and parse the string as an integer
        return parseInt(res?.amount.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);
      setInputValue([0, highestValue]);
      setHighestAmount(highestValue);
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
    }
  }, [receiptListData]);

  const hasSelected = selectedRowKeys.length > 0;

  const dateRange = (date) => {
    setFilterRange(date);
    dispatch(setFiltersOptions('date', date));
  };

  const emptyDateRange = () => {
    setFilterRange(null);
    setDataSource(receiptListData);
  };

  const rangeValue = (value) => {
    setAmountRange(value);
    setInputValue(value);
  };

  const applyRange = () => {
    dispatch(setFiltersOptions('amount', inputValue));
    setRangeBox(false);
  };

  const clearFilters = () => {
    if (receiptListData && receiptListData?.length > 0) {
      const numericalValues = receiptListData.map((res) => {
        return parseInt(res?.amount.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);
      setInputValue([0, highestValue]);
      setHighestAmount(highestValue);
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
    }
    setFilterRange(null);
    setAmountRange([]);
    setDataSource(receiptListData);
    setValue('');
  };

  const amountStart = (value) => {
    setInputValue([value, inputValue[1]]);
    setAmountRange([value, inputValue[1]]);
  };

  const amountEnd = (value) => {
    setInputValue([inputValue[0], value]);
    setAmountRange([inputValue[0], value]);
  };

  const clearAmount = () => {
    if (receiptListData && receiptListData?.length > 0) {
      const numericalValues = receiptListData.map((res) => {
        // Remove the "$" sign and parse the string as an integer
        return parseInt(res?.amount.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);
      setInputValue([0, highestValue]);
      setHighestAmount(highestValue);
      setAmountRange([]);
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
      setAmountRange([]);
    }
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    setRangeBox((prevState) => !prevState);
  };

  const closeDropdown = () => {
    setRangeBox(false);
  };

  const importCSVList = {
    closable: false,
    visibility: importCSV,
    class: 'black-modal new_modal',
    content: (
      <ImportReceiptsExpenditure
        title="Add Receipts"
        paragraph="You can add transactions  manually one by one or by uploading them in bulk using a CSV file."
        onClose={() => setImportCSV(false)}
        manualLink="/new-receipts"
        afterUploadLink="/import-receipts"
        bulkImportLink="bulkImportReceiptandExpenditureCSV"
      />
    ),
    width: 542,
    onCancel: () => setImportCSV(false),
  };
  return (
    <>
      <Dashboard load={loading} heading="Receipts">
        <Card className="white-card w-100 pcard-0">
          <Row justify="space-between" align="middle" className="reports_head" style={{ height: '50px' }}>
            <Col>
              <Row align="middle" gutter={[10, 10]}>
                <Col>
                  {hasSelected ? (
                    <Button
                      onClick={() => deleteMultiple()}
                      type="text"
                      htmlType="button"
                      className="font-gilroy c-blue"
                    >
                      Delete Selected
                    </Button>
                  ) : (
                    <Button
                      // onClick={() => history.push('/new-receipts')}
                      onClick={() => setImportCSV(true)}
                      type="primary"
                      htmlType="submit"
                      className="w-100 font-gilroy"
                      size="large"
                    >
                      <Image src={plusIcon} preview={false} width={22} />
                      Add Receipts
                    </Button>
                  )}
                </Col>
                <Col>
                  {hasSelected ? (
                    <Col>
                      <Text className="font-14">{selectedRowKeys.length} Selected </Text>
                    </Col>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Col className="search_box">
                        <Input
                          placeholder="Search..."
                          value={value}
                          onChange={(e) => dispatch(setFiltersOptions('search', e.target.value))}
                        />
                      </Col>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col>
              <Row align="middle" gutter={[10, 10]}>
                <Col>
                  <RangePicker
                    className="date_range"
                    onChange={dateRange}
                    onCalendarChange={emptyDateRange}
                    value={filterRange}
                    format={['MM-DD-YYYY', 'MM-DD-YYYY']}
                  />
                </Col>
                <Col>
                  <Button className={rangeBox ? 'sum_select box_open' : 'sum_select'} onClick={handleToggle}>
                    {amountRange && amountRange?.length > 0 ? (
                      <Tooltip title="Filter: Amount">
                        <div className="r_heading_amount">{`$ ${Number(amountRange[0]).toLocaleString()} - ${Number(
                          amountRange[1],
                        ).toLocaleString()}`}</div>
                      </Tooltip>
                    ) : (
                      <div className="sum_heading">Amount</div>
                    )}

                    <div className="sum_icon">
                      <img src={arrowIcon} alt="arrowIcon" />
                    </div>
                  </Button>
                  {rangeBox && (
                    <AmountDropdown
                      clearAmount={clearAmount}
                      rangeValue={rangeValue}
                      amountStart={amountStart}
                      amountEnd={amountEnd}
                      setRangeBox={setRangeBox}
                      setAmountRange={setAmountRange}
                      setInputValue={setInputValue}
                      applyRange={applyRange}
                      inputValue={inputValue}
                      closeDropdown={() => closeDropdown()}
                      highestAmount={highestAmount}
                    />
                  )}
                </Col>
                <Col>
                  <Button type="ghost" htmlType="button" className="p-0" onClick={clearFilters}>
                    <img src={clearIcon} alt="clearIcon" />
                  </Button>
                </Col>
                <Col>
                  <Tooltip placement="topLeft" title="Download data in a CSV file.">
                    <Button
                      type="ghost"
                      htmlType="button"
                      className="p-0"
                      // onClick={handleCSVDownloadButtonClick}
                      onClick={() => setPeriodPDF(true)}
                    >
                      <img src={exportCSV} alt="export CSV" />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>

          <Table
            rowSelection={rowSelection}
            columns={listColumns}
            dataSource={dataSource}
            pagination={{
              showSizeChanger: dataSource?.length > 10 ? true : false,
              pagination,
            }}
            onChange={handleTableChange}
            onRow={onClickRow}
            className="cursor_table"
          />
        </Card>
      </Dashboard>
      <Popup {...deletePopup} />
      <Popup {...deleteReport} />
      <Popup {...exportPeriod} />
      <Popup {...importCSVList} />
    </>
  );
};
