import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Spin, Radio, message } from 'antd';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { SelectField, InputField, InputRadio } from '../../../../atoms/FormElement';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { countryList, PACCategoryList } from '../../../../../configs/constants';
import { getCommitteeFormData } from '../../../Dashboard/Settings/ducks/actions';
import { SAVE_PAC_COMMITTEE } from '../../../../../configs/endpoints';
import { addObject } from '../../../../../services/crudApis';

const { Title, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { currentStep, setCurrentStep } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState('');
  const committeeType = JSON.parse(localStorage.getItem('user')).committee_type;
  const formData = useSelector((state) => state.setting.committeeFormData);
  const { control, handleSubmit, setValue, errors } = useForm({
    shouldUnregister: false,
  });
  const [loading, setLoading] = useState(false);
  const [affiliated, setAffiliated] = useState('affiliatedNo');

  const PACType = [
    { label: 'Parent Entity', value: 'parent_entity' },
    { label: 'Economic Interest', value: 'economic_interest' },
    { label: 'Political Purpose', value: 'political_purpose' },
  ];

  const organizationListpac = [
    { label: 'Cooperative', value: 'Cooperative' },
    { label: 'Corporation', value: 'Corporation' },
    { label: 'Corporation without Capital Stocks', value: 'Corporation without Capital Stocks' },
    { label: 'Labor Organization', value: 'Labor Organization' },
    { label: 'Membership Organization', value: 'Membership Organization' },
    { label: 'Political Committee', value: 'Political Committee' },
    { label: 'Trade Association', value: 'Trade Association' },
    { label: 'Other', value: 'Other' },
  ];

  const organizationList = [
    { label: 'Political Committee', value: 'Political Committee' },
    { label: 'Other', value: 'Other' },
  ];

  useEffect(() => {
    dispatch(getCommitteeFormData());
    setLoading(true);
  }, []);

  useEffect(() => {
    setValue('officersMailingcountryList', { label: 'North Carolina', value: 'NC' });
  }, []);

  useEffect(() => {
    if (formData && Object.keys(formData)?.length > 0) {
      setLoading(true);
      const bean = formData.pacDetail;
      if (bean) {
        const selectedPacCat = PACCategoryList?.find((e) => e?.value == bean?.pac_category);
        const selectedPacType = PACType?.find((e) => e.value == bean.pac_type);
        setValue('PAC_Category', selectedPacCat);
        setValue('PAC_Type', selectedPacType);
        setValue('DefinitionOfType', bean?.definition_of_type);
        setValue('memberDefinition', bean?.member_definition);
        setAffiliated(bean?.addresses && bean?.addresses.length > 0 ? 'affiliatedYes' : 'affiliatedNo');
        setValue('affiliated', bean?.is_org_connected ? 'affiliatedYes' : 'affiliatedNo');

        setTimeout(() => {
          const selectedOrgType =
            committeeType === 'political-action'
              ? organizationListpac?.find((e) => e?.value == bean?.org_type)
              : organizationList?.find((e) => e?.value == bean?.org_type);
          const address = bean?.addresses && bean?.addresses.length > 0 ? bean?.addresses[0] : false;
          setValue('affiliatedFullName', bean?.org_full_name);
          setValue('organizationType', selectedOrgType);
          setValue('relationship', bean?.org_relationship);
          if (address) {
            const selectedState = countryList?.find((e) => e?.value == address?.state);
            setValue('officersMailingcountryList', selectedState);
            setValue('officersMailingCity', address?.city);
            setValue('officersMailingZip', address?.post_code);
            setPhoneNumber(address?.phone);
            setValue('officersMailingStreet', address?.mailing_address);
          }
        }, 10);
      }
      setLoading(false);
    }
  }, [formData]);

  const onFinish = (value) => {
    setLoading(true);
    const payload = {
      pac_category: value.PAC_Category.value,
      pac_type: value.PAC_Type.value,
      definition_of_type: value.DefinitionOfType,
      member_definition: value.memberDefinition,
      is_org_connected: affiliated == 'affiliatedYes' ? 1 : 0,
      org_full_name: affiliated == 'affiliatedYes' ? value.affiliatedFullName : '',
      org_type: affiliated == 'affiliatedYes' ? value.organizationType.value : '',
      org_relationship: affiliated == 'affiliatedYes' ? value.relationship : '',
    };
    if (affiliated == 'affiliatedYes') {
      payload.pac_address = {
        state: affiliated == 'affiliatedYes' ? value.officersMailingcountryList.value : '',
        city: affiliated == 'affiliatedYes' ? value.officersMailingCity : '',
        post_code: affiliated == 'affiliatedYes' ? value.officersMailingZip : '',
        phone: affiliated == 'affiliatedYes' ? phoneNumber : '',
        mailing_address: affiliated == 'affiliatedYes' ? value.officersMailingStreet : '',
      };
    }
    addObject(SAVE_PAC_COMMITTEE, payload)
      .then((response) => {
        //console.log('response', response);
        setTimeout(() => {
          setLoading(false);
          message.success('Information Updated.');
          dispatch(getCommitteeFormData());
        }, 800);
        setTimeout(() => {
          setTimeout(() => setCurrentStep(currentStep + 1), 1200);
        }, 1200);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
    setTimeout(() => setLoading(false), 1000);
  };

  const onChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="PAC_Category"
              control={control}
              label="PAC Category"
              class="mb-0"
              iProps={{ placeholder: 'Please select' }}
              rules={{ required: 'PAC Category is required.' }}
              initValue=""
              selectOption={PACCategoryList}
              validate={errors.PAC_Category && 'error'}
              validMessage={errors.PAC_Category && errors.PAC_Category.message}
            />
          </Col>

          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="PAC_Type"
              control={control}
              label="PAC Type"
              class="mb-0"
              iProps={{ placeholder: 'Please select' }}
              rules={{ required: 'PAC Type is required.' }}
              tooltip={true}
              tooltipTitle={
                <div className="tooltip_text">
                  Parent entity: If officials, employees, or members and their families of any business entity create a
                  PAC. <br />
                  <br />
                  Economic interest: If the organizer's principle purpose is identifiable as economically based.
                  <br />
                  <br /> Political purpose: If the committee has a political interest and is not a parent entity or an
                  economic interest (this includes the support or opposition of a particular party, candidate or
                  specific issues).
                </div>
              }
              initValue=""
              selectOption={PACType}
              validate={errors.PAC_Type && 'error'}
              validMessage={errors.PAC_Type && errors.PAC_Type.message}
            />
          </Col>

          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="DefinitionOfType"
              label="Definition of Type"
              control={control}
              initValue=""
              tooltip={true}
              tooltipTitle={
                'Provide the business entity for a parent entity type (eg: The ABC Company PAC would list “ABC Company”), the economic interest for an economic interest type (eg: A group of restaurant workers organizing a PAC would list “to increase the wages of restaurant workers”), and the political purpose for a political purpose type (eg: A group concerned with the environment, organizing a PAC, would list “to support candidates that support environmental issues”).'
              }
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Definition of Type is required.',
              }}
              validate={errors.DefinitionOfType && 'error'}
              validMessage={errors.DefinitionOfType && errors.DefinitionOfType.message}
            />
          </Col>

          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="memberDefinition"
              label="Member Definition"
              control={control}
              initValue=""
              tooltip={true}
              tooltipTitle="(eg: The ABC Company PAC would list “employees of the ABC Company”). The committee may solicit contributions only from defined members of the committee."
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Member Definition is required.',
              }}
              validate={errors.memberDefinition && 'error'}
              validMessage={errors.memberDefinition && errors.memberDefinition.message}
            />
          </Col>

          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <InputRadio
                  fieldname="affiliated"
                  label="Is there any organization connected or committee affiliated with your committee?"
                  control={control}
                  onChange={(e) => setAffiliated(e.target.value)}
                  classField="tab-type-radio"
                  options={
                    <>
                      <Row gutter={[20, 20]}>
                        <Col span={12}>
                          <Radio value="affiliatedNo">No</Radio>
                        </Col>
                        <Col span={12}>
                          <Radio value="affiliatedYes">Yes</Radio>
                        </Col>
                      </Row>
                    </>
                  }
                />
              </Col>
              {affiliated == 'affiliatedYes' && (
                <>
                  <Col span={24}>
                    <Title level={2} className="text-center mb-0 font-gilroy w-100">
                      Connected Organization or Affiliated Committee
                    </Title>
                  </Col>

                  <Col span={24}>
                    <Paragraph className="smallFont14 text-center mb-0">
                      The organization or committee that started this PAC
                    </Paragraph>
                  </Col>

                  <Col span={24}>
                    <InputField
                      isRequired={true}
                      fieldname="affiliatedFullName"
                      label="Full Name"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                      }}
                      rules={{
                        required: 'Full Name is required.',
                      }}
                      validate={errors.affiliatedFullName && 'error'}
                      validMessage={errors.affiliatedFullName && errors.affiliatedFullName.message}
                    />
                  </Col>

                  <Col span={24}>
                    <SelectField
                      isRequired={true}
                      fieldname="organizationType"
                      control={control}
                      label="Organization Type"
                      class="mb-0"
                      iProps={{ placeholder: 'Please select' }}
                      rules={{ required: 'Organization Type is required.' }}
                      initValue=""
                      selectOption={committeeType === 'political-action' ? organizationListpac : organizationList}
                      validate={errors.organizationType && 'error'}
                      validMessage={errors.organizationType && errors.organizationType.message}
                    />
                  </Col>

                  <Col span={24}>
                    <InputField
                      isRequired={true}
                      fieldname="relationship"
                      label="Relationship"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                      }}
                      rules={{
                        required: 'Relationship is required.',
                      }}
                      validate={errors.relationship && 'error'}
                      validMessage={errors.relationship && errors.relationship.message}
                    />
                  </Col>

                  <Col span={24} className="mailingField">
                    <Row gutter={[8, 8]}>
                      <Col span={24}>
                        <InputField
                          isRequired={true}
                          fieldname="officersMailingStreet"
                          label="Mailing Address"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: 'Street Address',
                          }}
                          rules={{
                            required: 'Mailing Address is required.',
                          }}
                          validate={errors.officersMailingStreet && 'error'}
                          validMessage={errors.officersMailingStreet && errors.officersMailingStreet.message}
                        />
                      </Col>

                      <Col span={9}>
                        <SelectField
                          isRequired={true}
                          fieldname="officersMailingcountryList"
                          control={control}
                          class="mb-0"
                          iProps={{ placeholder: 'Please select' }}
                          rules={{ required: 'Mailing Address is required.' }}
                          initValue={{}}
                          selectOption={countryList}
                          validate={errors.officersMailingcountryList && 'error'}
                          validMessage={errors.officersMailingcountryList && errors.officersMailingcountryList.message}
                        />
                      </Col>

                      <Col span={9}>
                        <InputField
                          isRequired={true}
                          fieldname="officersMailingCity"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: 'City',
                          }}
                          rules={{
                            required: 'Mailing Address is required.',
                          }}
                          validate={errors.officersMailingCity && 'error'}
                          validMessage={errors.officersMailingCity && errors.officersMailingCity.message}
                        />
                      </Col>

                      <Col span={6}>
                        <InputField
                          isRequired={true}
                          fieldname="officersMailingZip"
                          control={control}
                          initValue=""
                          iProps={{
                            size: 'large',
                            placeholder: 'Zip',
                          }}
                          rules={{
                            required: 'Mailing Address is required.',
                          }}
                          validate={errors.officersMailingZip && 'error'}
                          validMessage={errors.officersMailingZip && errors.officersMailingZip.message}
                        />
                      </Col>
                      {(errors.officersMailingZip ||
                        errors.officersMailingCity ||
                        errors.officersMailingcountryList ||
                        errors.officersMailingStreet) && (
                        <Col span={24} className="mailingError">
                          Mailing Address is required.
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Col span={24}>
                    <div className="ant-row ant-form-item">
                      <div className="ant-col ant-form-item-label">
                        <label className="" title="Website">
                          Phone Number
                        </label>
                        <div className="optional-label" style={{ top: '4px' }}>
                          (Optional)
                        </div>
                      </div>
                    </div>
                    <InputMask mask="(999) 999-9999" value={phoneNumber} onChange={onChange}>
                      {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
                    </InputMask>
                  </Col>
                </>
              )}
            </Row>
          </Col>

          <Col span={24} className="text-center">
            <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
              Continue
            </Button>
          </Col>

          <Col span={24} className="text-center mt-2 mb-1">
            <Button
              type="text"
              htmlType="button"
              className="font-gilroy c-grey"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              BACK
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
