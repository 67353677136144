import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Form, Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Dashboard from '../../../../pages/Dashboard';
import Individual from '../ContactsForms/Individual';
import OtherOrganization from '../ContactsForms/OtherOrganization';
import PoliticalCommittee from '../ContactsForms/PoliticalCommittee';
import FinancialInstitution from '../ContactsForms/FinancialInstitution';
import { getUnimportedContactFormData } from '../ducks/actions';
import { editObject } from '../../../../../services/crudApis';
import { EDIT_UNIMPORTED_CONTACT } from '../../../../../configs/endpoints';
import { Popup } from '../../../../atoms/Popup';
import ReportAmendment from '../../../../molecules/ReportAmendment';
import DublicateContact from '../../../../molecules/DublicateContact';

const { Paragraph, Title } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [reportVisible, setReportVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const { control, handleSubmit, setValue, getValues, errors } = useForm({
    shouldUnregister: false,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname;
  let receiptType = url.split('/')[2];
  const title = receiptType.replace(/-/g, ' ').toLowerCase();
  const [dublicateContact, setDublicateContact] = useState(false);
  const [dublicateArray, setDublicateArray] = useState([]);
  const [dublicateCurrentValue, setDublicateCurrentValue] = useState();
  const [dataValue, setDataValue] = useState({});
  const contactId = location?.state?.contactId;
  const formData = useSelector((state) => state.contact.unimportedContactFormData);

  useEffect(() => {
    dispatch(getUnimportedContactFormData(contactId));
    setLoading(true);
  }, []);

  const onFinish = (values, force) => {
    setLoading(true);
    setDataValue(values);
    setReportVisible(false);
    if (!force) {
      setDublicateContact(false);
    }
    let requestBody = {
      first_name: values.firstName,
      last_name: values.lastName,
      middle_name: values.middle_name,
      suffix: values.suffix?.value ?? '',
      is_candidate_spouse: values.candidate_spouse,
      office_sought: values?.office_sought ?? undefined,
      jurisdiction: values?.jurisdiction ?? undefined,
      type: formData?.type,
      checkForDuplicate: force !== false,
      address: {
        state: values.mailingcountryList?.value,
        city: values.mailingCity,
        post_code: values.mailingZip,
        phone: phoneNumber,
        email: values.email,
        mailing_address: values.mailingStreet,
      },
      organization_name: values.hasOwnProperty('organization_name') ? values.organization_name : undefined,
      is_non_profit_organization: values.hasOwnProperty('is_non_profit_organization')
        ? values.is_non_profit_organization == true
        : undefined,
      committee_name: values?.committee_name,
      committee_type: values.committee_type
        ? values.committee_type.value
          ? values.committee_type.value
          : values.committee_type[0].value
        : undefined,
      committee_organization_level: values.committee_organization_level
        ? values.committee_organization_level.value
          ? values.committee_organization_level.value
          : values.committee_organization_level[0].value
        : undefined,
      committee_boe_id: values?.committee_boe_id,
      financial_institution_name: values?.financial_institution_name,
      committee_county_name:
        values.committee_organization_level?.value == 'county' ? values?.countyOrganization?.value : '',
      dateEffective: values?.effectiveDate ? values?.effectiveDate : false,
    };
    if (values.jobRadio == 'Job_title') {
      requestBody.job_title = values.jobTitle ? values?.jobTitle : '';
      requestBody.profession = null;
    } else if (values.jobRadio == 'Profession') {
      requestBody.job_title = null;
      requestBody.profession = values.profession ? values?.profession : '';
    }
    if (values.employeeRadio == 'employersName') {
      requestBody.employer_name = values.employerName ? values.employerName : '';
      requestBody.specific_field = null;
    } else if (values.employeeRadio == 'specificField') {
      requestBody.employer_name = null;
      requestBody.specific_field = values.specificField?.value ? values?.specificField?.value : '';
    }

    if (values?.firstName !== formData?.first_name || values?.email !== formData?.email) {
      requestBody.is_change = true;
    } else {
      requestBody.is_change = false;
    }

    editObject(EDIT_UNIMPORTED_CONTACT + '/' + contactId, requestBody)
      .then((response) => {
        //console.log('response', response);
        setTimeout(() => {
          setLoading(false);
        }, 800);
        setTimeout(() => {
          history.push('/import-contacts');
        }, 1200);
      })
      .catch((e) => {
        setLoading(false);
        if (e && e?.response && e?.response['status'] == '400') {
          const error = JSON.parse(e?.response?.data?.error?.message);
          setDublicateCurrentValue(values);
          setDublicateArray(error?.duplicateContacts);
          setDublicateContact(true);
          //console.log('error?.duplicateContacts', error?.duplicateContacts);
        } else {
          message.error('Something went wrong');
        }
      });
  };

  const submitFileExternal = (date) => {
    const value = getValues();
    value.effectiveDate = date;
    onFinish(value);
  };

  const dublicatedContact = {
    closable: false,
    visibility: dublicateContact,
    class: 'white-modal',
    content: (
      <DublicateContact
        title="Duplicative Contact"
        onClose={() => setDublicateContact(false)}
        dublicateCurrentValue={dublicateCurrentValue}
        dublicateArray={dublicateArray}
        updateApi={() => onFinish(dataValue, false)}
      />
    ),
    width: 542,
    onCancel: () => setDublicateContact(false),
  };

  return (
    <>
      <Dashboard load={loading} heading="Contact" backLink="/import-contacts">
        <Card className="white-card w-100">
          <Row justify="center" gutter={[20, 20]}>
            <Col span={24}>
              <Title level={2} className="text-capitalize">
                {title}
              </Title>
            </Col>

            {receiptType == 'individual' && (
              <Col span={24}>
                <Paragraph className="c-blue">
                  You may not collect personal information from individuals who contribute $50 or less during the
                  election. However, regardless of contribution amount, it is easier to acquire this information up
                  front rather than chase it later if they give again and surpass the $50 threshold to appear
                  individually on your reports.
                </Paragraph>
              </Col>
            )}
          </Row>

          <Form className="w-100 mt-2" layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            {receiptType == 'individual' && (
              <Individual
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                exportContactId={contactId}
                setLoading={setLoading}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            )}

            {receiptType == 'other-organization' ||
            receiptType == 'treasurer' ||
            receiptType == 'custodian' ||
            receiptType == 'assistant-treasurer' ? (
              <OtherOrganization
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                exportContactId={contactId}
                setLoading={setLoading}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            ) : null}

            {receiptType == 'political-committee' && (
              <PoliticalCommittee
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                exportContactId={contactId}
                setLoading={setLoading}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            )}

            {receiptType == 'financial-institution' && (
              <FinancialInstitution
                control={control}
                errors={errors}
                setValue={setValue}
                formData={formData}
                exportContactId={contactId}
                setLoading={setLoading}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            )}

            <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => history.push('/import-contacts')}
                  htmlType="button"
                  className="font-gilroy white-btn"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Save Changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Dashboard>
      <ReportAmendment
        reportVisible={reportVisible}
        setReportVisible={setReportVisible}
        submitFileExternal={submitFileExternal}
        dateEffective={true}
      />
      <Popup {...dublicatedContact} />
    </>
  );
};
