import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Spin, message, Descriptions } from 'antd';
import { useForm, useFieldArray } from 'react-hook-form';
import { InputField, DateField } from '../../../../../../atoms/FormElement';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { DATE_FORMAT, ReportTypeCode } from '../../../../../../../configs/constants';
import { getReportFormData } from '../../../ducks/actions';
import moment from 'moment';
import { addObject } from '../../../../../../../services/crudApis';
import { ADD_REPORT } from '../../../../../../../configs/endpoints';
import { getAccountInfoFormData } from '../../../../Settings/ducks/actions';

const { Title, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { currentStep, setCurrentStep, setstartDate, setendDate, setReportId, getYear } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, errors, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      prev_transaction: [{ name: 'useFieldArray' }],
    },
  });
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState(false);
  const reportFormData = useSelector((state) => state.report.reportFormData);
  const formData = useSelector((state) => state.setting.accountFormData);

  const { fields } = useFieldArray({
    control,
    name: 'prev_transaction',
  });

  useEffect(() => {
    dispatch(getReportFormData(ReportTypeCode.ORG, true, getYear));
  }, []);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    setLoading(true);
    if (reportFormData && Object.keys(reportFormData)?.length > 0) {
      setValue('committeeName', reportFormData?.committeeName);
      setValue('startdate', moment(reportFormData?.startPeriod));
      setValue('enddate', moment(reportFormData?.endPeriod));

      setValue('startdate', reportFormData?.startPeriod ? moment(reportFormData?.startPeriod) : '');
      setValue('enddate', reportFormData?.endPeriod ? moment(reportFormData?.endPeriod) : '');

      setValue('cashAtHand', reportFormData?.cashAtHand);
      setValue('fundraisers', reportFormData?.noOfFundraisers);
      setValue('boardElectionsID', reportFormData?.boeId);

      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [reportFormData]);

  const onFinish = (values) => {
    setLoading(true);

    let requestBody = {
      type: ReportTypeCode.ORG,
      status: 'draft',
      start_date: moment(reportFormData?.startPeriod).format('YYYY-MM-DD HH:mm:ss') + 'Z',
      end_date: moment(reportFormData?.endPeriod).format('YYYY-MM-DD HH:mm:ss') + 'Z',
      cash_at_hand_at_start: values.cashAtHand,
      no_of_fundraiser: values.fundraisers,
      boardElectionsID: values.boardElectionsID,
      isAmendment: true,
    };
    let accounts = [];
    if (values.prev_transaction) {
      for (const iterator of values.prev_transaction) {
        accounts.push({ id: iterator.id, balance: iterator.balance });
      }
    }
    requestBody.accounts = accounts;
    addObject(ADD_REPORT, requestBody)
      .then((response) => {
        //console.log('response', response);
        if (response.data) {
          setReportId(response.data);
        }
        setLoading(false);
        dispatch(getAccountInfoFormData());
        setTimeout(() => setCurrentStep(currentStep + 1), 1200);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
        //console.log(e);
      });
  };

  useEffect(() => {
    dispatch(getAccountInfoFormData());
  }, []);

  useEffect(() => {
    if (formData && formData?.accounts && Object.keys(formData?.accounts).length > 0) {
      setValue('prev_transaction', formData.accounts);
    }
  }, [formData]);

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row justify="center">
        <Col flex="0 0 462px">
          <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <div className="detail_label_ins">
                  <Descriptions layout="vertical" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
                    <Descriptions.Item label="Committee Name">{reportFormData?.committeeName}</Descriptions.Item>
                    <Descriptions.Item label="Board of Elections ID">{reportFormData?.boeId}</Descriptions.Item>
                    <Descriptions.Item label="Report Period Start Date">
                      {moment(reportFormData?.startPeriod).isValid()
                        ? moment(reportFormData?.startPeriod).format(DATE_FORMAT)
                        : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Report Period End Date">
                      {' '}
                      {moment(reportFormData?.endPeriod).isValid()
                        ? moment(reportFormData?.endPeriod).format(DATE_FORMAT)
                        : '-'}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </Col>

              <Col span={24}>
                <InputField
                  fieldname="boardElectionsID"
                  label="North Carolina Board of Elections ID"
                  hintMessage="Ex.: STA-123456-C-001. Leave empty if you haven't gotten it yet."
                  control={control}
                  initValue=""
                  tooltip={true}
                  tooltipTitle="Committee receives it after filing the Statement of Organization. You can search for your ID here."
                  iProps={{
                    size: 'large',
                  }}
                />
              </Col>

              {/* <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname="cashAtHand"
                  label="Cash at Hand at Start of This Report"
                  control={control}
                  initValue=""
                  tooltip={true}
                  tooltipTitle="Total amount of funds available on all committee bank acounts at the start of the reporting period."
                  iProps={{
                    size: 'large',
                    prefix: '$',
                    type: 'number',
                  }}
                  rules={{
                    required: 'Cash at Hand at Start of This Report is required.',
                  }}
                  validate={errors.cashAtHand && 'error'}
                  validMessage={errors.cashAtHand && errors.cashAtHand.message}
                />
              </Col> */}

              <Col span={24}>
                {fields?.map((item, index) => (
                  <Fragment key={item.id}>
                    <Row gutter={[20, 20]} align="bottom" style={{ marginBottom: '20px' }}>
                      <InputField
                        fieldname={`prev_transaction[${index}].id`}
                        label=""
                        control={control}
                        class="d-none mb-0"
                        initValue={item?.id}
                        iProps={{ size: 'large' }}
                      />
                      <Col span={12}>
                        <InputField
                          fieldname={`prev_transaction[${index}].code`}
                          label="Account Code"
                          control={control}
                          class="mb-0 label_inside"
                          initValue={item?.code}
                          iProps={{ size: 'large' }}
                          readOnly={true}
                        />
                      </Col>
                      <Col span={12}>
                        <InputField
                          isRequired={true}
                          fieldname={`prev_transaction[${index}].balance`}
                          label="Election Cycle Begin Balance"
                          tooltip={true}
                          tooltipTitle="Total amount of funds available on this bank account at the start of this election cycle."
                          control={control}
                          initValue={item?.begin_balance}
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          rules={{
                            required: 'Election Cycle Begin Balance is required.',
                          }}
                          validate={
                            Object.entries(errors).length > 0 &&
                            errors?.prev_transaction?.length > 0 &&
                            errors?.prev_transaction[index]?.balance &&
                            'error'
                          }
                          validMessage={
                            Object.entries(errors).length > 0 &&
                            errors?.prev_transaction?.length > 0 &&
                            errors?.prev_transaction[index]?.balance &&
                            errors?.prev_transaction[index]?.balance?.message
                          }
                        />
                      </Col>
                    </Row>
                  </Fragment>
                ))}
              </Col>

              <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname="fundraisers"
                  label="Number of Fundraisers"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                  }}
                  rules={{
                    required: 'Number of Fundraisers is required.',
                  }}
                  validate={errors.amount && 'error'}
                  validMessage={errors.amount && errors.amount.message}
                />
              </Col>

              <Col span={24} className="text-center">
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Continue
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};
