import React, { useEffect, useState, Fragment } from 'react';
import { Spin, Button, Image, Row, Col, Typography, Form, message } from 'antd';
import { useForm, useFieldArray } from 'react-hook-form';
import crossIcon from '../../../../../../../../../assets/svg/cross-icon.svg';
import { InputField, SelectField, TextAreaField } from '../../../../../../../../atoms/FormElement';
import plusIcon from '../../../../../../../../../assets/svg/plus.svg';
import trashIcon from '../../../../../../../../../assets/svg/trash-icon.svg';
import { EXPENDITURE_PURPOSE_CODE } from '../../../../../../../../../configs/constants';
import { obligationBy } from '../../../../../ducks/services';
import { getObligationsData } from '../../../../../ducks/actions';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import IncurredDebts from './IncurredDebts';
import moment from 'moment';

const { Title, Paragraph, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

const initQ = {
  contact_id: '',
  description: '',
  beginning_balance: '',
  amount_paid: '',
  amount_incurred: '',
  remaining_balance: '',
  incurreds: [
    {
      incurred_id: '',
      contact_id: '',
      date: '',
      amount: '',
      purpose_code: '',
      required_remarks: '',
    },
  ],
};

const defaultValues = {
  obligation: [
    {
      name: 'useFieldArray',
      incurreds: [
        {
          incurred_id: '',
          contact_id: '',
          date: '',
          amount: '',
          purpose_code: '',
          required_remarks: '',
        },
      ],
    },
  ],
};

export default (props) => {
  const { onClose, link, title, reportId, debtsOwedBy, setDebtsOwedBy, hideBtn } = props;
  const [support, setSupport] = useState('support_no');
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [deletedIndexesOblig, setDeletedIndexesOblig] = useState([]);
  const [deletedIndexesInc, setDeletedIndexesInc] = useState([]);
  const dispatch = useDispatch();
  const obligationsByData = useSelector((state) => state.report.obligationsBy);

  const { control, errors, setValue, reset, watch, handleSubmit } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'obligation',
  });
  const onRemove = (e, index) => {
    remove(index);
    if (e?.obligation_id && e?.obligation_id != '') {
      setDeletedIndexesOblig([...deletedIndexesOblig, e?.obligation_id]);
    }
  };
  const onAdd = () => {
    append(initQ);
    let leng = `${fields.length + 1}`;
  };

  const onFinish = (val) => {
    setLoading(true);

    let obligationArray = [];
    let obligArray = [];
    let incArray = [];

    obligArray = val?.obligation?.map((e) => {
      let incurredArray = [];
      let pc = '';
      e?.incurreds?.map((x) => {
        if (x?.purpose_code) {
          pc = '';
          for (const code of x.purpose_code) {
            pc += code.value;
          }
        }
        //console.log('pc', pc);
        incArray = incurredArray.push({
          date: x?.date ? moment(x?.date).format('MM-DD-YYYY') : '',
          amount: x?.amount,
          description: x?.description,
          report_id: props?.reportID?.split('?')[0],
          contact_id: x?.contact_id?.value,
          id: x?.incurred_id,
          purpose_code: pc,
          required_remarks: x?.required_remarks,
        });
      });
      obligationArray.push({
        id: e?.obligation_id,
        contact_id: e?.contact_id?.value,
        description: e?.description,
        beginning_balance: e?.beginning_balance,
        amount_paid: e?.amount_paid,
        amount_incurred: e?.amount_incurred,
        remaining_balance: e?.remaining_balance,
        report_id: props?.reportID?.split('?')[0],
        incurred: incurredArray,
        type: 'BY',
      });
    });

    const payload = {
      report_id: props?.reportID?.split('?')[0],
      deleted_obligations: deletedIndexesOblig,
      deleted_incurreds: deletedIndexesInc,
      obligation: obligationArray,
    };
    //console.log('payload', payload);
    obligationBy(payload)
      .then((response) => {
        message.success('Debt and Obligation updated.');
        setLoading(false);
        onClose();
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  useEffect(() => {
    setLoading(true);
    if (obligationsByData && obligationsByData?.obligation?.length) {
      setValue('obligation', obligationsByData?.obligation);
    } else {
      setValue('obligation', []);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, [obligationsByData]);

  useEffect(() => {
    reset();
    dispatch(getObligationsData(props?.reportID?.split('?')[0], 'BY'));
  }, [props]);

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={4} className="mb-0">
            {title}
          </Title>
        </Col>
        <Col>
          <Button size="large" type="link" className="p-0" onClick={onClose}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>
        <Col span={24}>
          <Paragraph>
            Use this form to report any unpaid debts or obligations owed by the committee, to include campaign credit
            card purchases.
          </Paragraph>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            {fields?.map((item, index) => (
              <Fragment key={item.id}>
                <Row gutter={[20, 30]} align="bottom" className="administrative_array">
                  <InputField
                    fieldname={`obligation[${index}].id`}
                    label=""
                    control={control}
                    class="d-none mb-0"
                    initValue={item?.id}
                    iProps={{ size: 'large' }}
                  />
                  <InputField
                    fieldname={`obligation[${index}].obligation_id`}
                    label=""
                    control={control}
                    class="d-none mb-0"
                    initValue={item?.obligation_id}
                    iProps={{ size: 'large' }}
                  />

                  <Col span={24}>
                    <Title level={2} className="text-center mb-0 font-gilroy">
                      Creditor #{index + 1}
                    </Title>

                    <div className="accounts_remove" style={{ right: 0 }}>
                      <Button type="link" className="p-0" onClick={() => onRemove(item, index)}>
                        <Image src={trashIcon} preview={false} size={24} />
                      </Button>
                    </div>
                  </Col>

                  <Col span={24}>
                    <SelectField
                      fieldname={`obligation[${index}].contact_id`}
                      label="Creditor"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                        placeholder: 'Please select',
                      }}
                      selectOption={
                        obligationsByData && obligationsByData?.contacts?.length > 0 ? obligationsByData?.contacts : ''
                      }
                      valueGot={
                        item?.contact_id &&
                        obligationsByData?.contacts &&
                        obligationsByData?.contacts.find((x) => item?.contact_id == x?.value)
                      }
                      setValue={setValue}
                      isRequired={true}
                      rules={{ required: 'Creditor is required.' }}
                      validate={
                        Object.entries(errors).length > 0 &&
                        errors?.obligation?.length > 0 &&
                        errors?.obligation[index]?.contact_id &&
                        'error'
                      }
                      validMessage={
                        Object.entries(errors).length > 0 &&
                        errors?.obligation?.length > 0 &&
                        errors?.obligation[index]?.contact_id &&
                        errors?.obligation[index]?.contact_id?.message
                      }
                    />
                  </Col>

                  <Col span={24}>
                    <TextAreaField
                      fieldname={`obligation[${index}].description`}
                      label="Description of Creditor"
                      control={control}
                      class="mb-0 textarea_input"
                      //initValue={item?.code}
                      valueGot={item?.description}
                      setValue={setValue}
                      iProps={{ size: 'large', showCount: true, maxLength: '70' }}
                    />
                  </Col>

                  <Col span={24}>
                    <Row gutter={[20, 20]}>
                      <Col span={12}>
                        <InputField
                          fieldname={`obligation[${index}].beginning_balance`}
                          label="Beginning Balance"
                          control={control}
                          class="mb-0"
                          //initValue={item?.code}
                          valueGot={
                            item?.beginning_balance && item?.beginning_balance > 0 ? item?.beginning_balance : '0'
                          }
                          setValue={setValue}
                          iProps={{ size: 'large', prefix: '$', type: 'number' }}
                          isRequired={true}
                          rules={{ required: 'Beginning Balance is required.' }}
                          validate={
                            Object.entries(errors).length > 0 &&
                            errors?.obligation?.length > 0 &&
                            errors?.obligation[index]?.beginning_balance &&
                            'error'
                          }
                          validMessage={
                            Object.entries(errors).length > 0 &&
                            errors?.obligation?.length > 0 &&
                            errors?.obligation[index]?.beginning_balance &&
                            errors?.obligation[index]?.beginning_balance?.message
                          }
                        />
                      </Col>

                      <Col span={12}>
                        <InputField
                          fieldname={`obligation[${index}].amount_paid`}
                          label="Total Amount Paid"
                          control={control}
                          class="mb-0"
                          //initValue={item?.code}
                          valueGot={item?.amount_paid && item?.amount_paid > 0 ? item?.amount_paid : '0'}
                          setValue={setValue}
                          iProps={{ size: 'large', prefix: '$', type: 'number' }}
                          isRequired={true}
                          rules={{ required: 'Total Amount Paid is required.' }}
                          validate={
                            Object.entries(errors).length > 0 &&
                            errors?.obligation?.length > 0 &&
                            errors?.obligation[index]?.amount_paid &&
                            'error'
                          }
                          validMessage={
                            Object.entries(errors).length > 0 &&
                            errors?.obligation?.length > 0 &&
                            errors?.obligation[index]?.amount_paid &&
                            errors?.obligation[index]?.amount_paid?.message
                          }
                        />
                      </Col>

                      <Col span={12}>
                        <InputField
                          fieldname={`obligation[${index}].amount_incurred`}
                          label="Total Amount Incurred"
                          control={control}
                          class="mb-0"
                          //initValue={item?.code}
                          valueGot={item?.amount_incurred && item?.amount_incurred > 0 ? item?.amount_incurred : '0'}
                          setValue={setValue}
                          iProps={{ size: 'large', prefix: '$', type: 'number' }}
                          isRequired={true}
                          rules={{ required: 'Total Amount Incurred is required.' }}
                          validate={
                            Object.entries(errors).length > 0 &&
                            errors?.obligation?.length > 0 &&
                            errors?.obligation[index]?.amount_incurred &&
                            'error'
                          }
                          validMessage={
                            Object.entries(errors).length > 0 &&
                            errors?.obligation?.length > 0 &&
                            errors?.obligation[index]?.amount_incurred &&
                            errors?.obligation[index]?.amount_incurred?.message
                          }
                        />
                      </Col>

                      <Col span={12}>
                        <InputField
                          fieldname={`obligation[${index}].remaining_balance`}
                          label="Remaining Balance"
                          control={control}
                          class="mb-0"
                          //initValue={item?.code}
                          valueGot={
                            item?.remaining_balance && item?.remaining_balance > 0 ? item?.remaining_balance : '0'
                          }
                          setValue={setValue}
                          iProps={{ size: 'large', prefix: '$', type: 'number' }}
                          isRequired={true}
                          rules={{ required: 'Remaining Balance is required.' }}
                          validate={
                            Object.entries(errors).length > 0 &&
                            errors?.obligation?.length > 0 &&
                            errors?.obligation[index]?.remaining_balance &&
                            'error'
                          }
                          validMessage={
                            Object.entries(errors).length > 0 &&
                            errors?.obligation?.length > 0 &&
                            errors?.obligation[index]?.remaining_balance &&
                            errors?.obligation[index]?.remaining_balance?.message
                          }
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24}>
                    <IncurredDebts
                      setValue={setValue}
                      watch={watch}
                      nestIndex={index}
                      {...{ control }}
                      deletedIndexesInc={deletedIndexesInc}
                      setDeletedIndexesInc={setDeletedIndexesInc}
                      phoneNumber={phoneNumber}
                      setPhoneNumber={setPhoneNumber}
                      purposeDrop={EXPENDITURE_PURPOSE_CODE}
                      obligationsByData={obligationsByData}
                    />
                  </Col>
                </Row>
              </Fragment>
            ))}

            <Row gutter={[20, 30]} align="bottom" className="add_account">
              <Col span={24}>
                <Button
                  type="text"
                  htmlType="button"
                  className="font-gilroy c-grey d-flex align-center"
                  onClick={onAdd}
                >
                  <Image src={plusIcon} preview={false} size={24} />
                  Add Creditor
                </Button>
              </Col>

              <Col span={24} className="text-center mt-2 mb-1">
                <Row align="middle" justify="center" className="mt-1" gutter={[20, 20]}>
                  <Col>
                    <Button
                      type="primary"
                      onClick={onClose}
                      htmlType="button"
                      className="font-gilroy white-btn"
                      size="large"
                    >
                      Cancel
                    </Button>
                  </Col>

                  {!hideBtn && (
                    <Col>
                      <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                        Save
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};
