import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Card, Button, Tooltip, message } from 'antd';
import Dashboard from '../../../../pages/Dashboard';
import dashboardCycle from '../../../../../assets/svg/dashboard-cycle.svg';
import { getCurrentElectionData } from '../../ducks/actions';
import { useSelector, useDispatch } from 'react-redux';
import { InfoCircleFilled } from '@ant-design/icons';

const { Title, Text } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentCycleData = useSelector((state) => state.dashboard.currentCycleData);

  useEffect(() => {
    dispatch(getCurrentElectionData());
  }, []);

  useEffect(() => {
    if (currentCycleData?.isFederalCommittee) {
      localStorage.setItem('organization', 'federal');
    }
    if (currentCycleData?.mailingAddress) {
      localStorage.setItem('mailingAddress', JSON.stringify(currentCycleData?.mailingAddress));
    }
  }, [currentCycleData]);

  return (
    <Dashboard load={loading} noLink={true}>
      <Row align="middle" justify="center">
        <Col flex="0 0 740px" style={{ overflow: 'hidden', padding: '0 20px' }}>
          <Card className="white-card text-center">
            <Row justify="center" gutter={[20, 25]}>
              <Col span={24}>
                <Title level={2}>Current Election Cycle</Title>
                <Text>
                  This is the current election cycle for State and County elections.
                  <br /> The reports will be filed for this cycle.{' '}
                </Text>
              </Col>
              <Col flex="0 0 270px" className="inner-white-card" style={{ backgroundImage: `url(${dashboardCycle})` }}>
                <Card type="inner">
                  <Text className="smallFont12">Statewide General Elections</Text>
                  <Title level={2} className="font-gilroy c-red w-100">
                    {currentCycleData?.startYear} - {currentCycleData?.endYear}
                  </Title>

                  <div className="w-100 mt-auto" style={{ position: 'relative' }}>
                    <Row justify="space-between">
                      <Col className="text-left">
                        <Text className="smallFont12">Primary</Text>
                      </Col>
                      <Col className="text-right">
                        <Text className="smallFont12 f-bold">{currentCycleData?.primaryDate}</Text>
                      </Col>
                      {currentCycleData?.primaryDate == 'Date will be defined' && (
                        <div className="not_defined">
                          <Tooltip title="Election dates will be published by the Board of Elections closer to the election year.">
                            <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px' }} />
                          </Tooltip>
                        </div>
                      )}
                    </Row>
                    <Row justify="space-between">
                      <Col className="text-left">
                        <Text className="smallFont12">General</Text>
                      </Col>
                      <Col className="text-right">
                        <Text className="smallFont12 f-bold">{currentCycleData?.generalDate}</Text>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="text-uppercase"
                  size="large"
                  onClick={() => history.push('/add-transactions')}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Dashboard>
  );
};
