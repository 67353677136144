import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Card, Button, Image, message } from 'antd';
import Dashboard from '../../../../pages/Dashboard';
import organizationalReport from '../../../../../assets/svg/organizational-report.svg';
import FiledExternal from '../../../../molecules/FiledExternal';
import { Popup } from '../../../../atoms/Popup';
import { addObject } from '../../../../../services/crudApis';
import { FILED_ORG_EXTERNALLY } from '../../../../../configs/endpoints';
import moment from 'moment';
const { Title, Text } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const user = JSON.parse(localStorage.getItem('user')).date_organized;
  const electionYear = user && moment(user).year();

  const submitFileExternal = (values) => {
    setLoading(true);
    let payload = [];
    if (values && values.external_filed && values.external_filed.length > 0) {
      payload = values.external_filed.map((resp) => ({
        id: resp?.id,
        balance: resp.balance,
      }));
    }

    let requestBody = {
      is_filed_externally: true,
      report_end_date: moment(values?.report_end_date).format('MM-DD-YYYY'),
      account: payload,
    };

    addObject(FILED_ORG_EXTERNALLY, requestBody)
      .then((response) => {
        setLoading(false);
        history.push('/dashboard');
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
        //console.log(e);
      });
  };

  const popup = {
    closable: false,
    visibility: visible,
    class: 'black-modal',
    content: (
      <FiledExternal
        title="Filed Externally"
        onClose={() => setVisible(false)}
        onDelete={() => onDelete()}
        submitFileExternal={submitFileExternal}
        popHeading="ORG"
      />
    ),
    width: 450,
    onCancel: () => setVisible(false),
  };

  return (
    <>
      <Dashboard load={loading} noLink={true}>
        <Row align="middle" justify="center">
          <Col flex="0 0 740px" style={{ overflow: 'hidden', padding: '0 20px' }}>
            <Card className="white-card text-center">
              <Row justify="center" gutter={[20, 25]}>
                <Col span={24}>
                  <Title level={2}>File Organizational Report</Title>
                </Col>
                <Col span={24}>
                  <Image src={organizationalReport} preview={false} />
                </Col>
                <Col span={24}>
                  <Text>You should file Organizational report within 10 days of the committe registration date.</Text>
                </Col>
                <Col span={24}>
                  <Text>Would you like to do it now?</Text>
                </Col>
                <Col span={24}>
                  <Row gutter={20} justify="center">
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="text-uppercase white-btn"
                        size="large"
                        onClick={() => history.push('/dashboard')}
                      >
                        file later
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="text-uppercase"
                        size="large"
                        onClick={() => history.push(`/reports/organizational?year=${electionYear}`)}
                      >
                        File Now
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Button
                    type="text"
                    htmlType="button"
                    className="font-gilroy c-grey text-normal"
                    onClick={() => setVisible(true)}
                  >
                    Already filed somewhere else?
                  </Button>
                </Col>
                <Col span={24}>
                  <Button
                    type="text"
                    htmlType="button"
                    className="font-gilroy c-grey"
                    onClick={() => history.push('/information-new-committees')}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Dashboard>
      <Popup {...popup} />
    </>
  );
};
