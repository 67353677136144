import React, { Fragment, useState } from 'react';
import { Row, Col, Card, Typography, Select, Form, Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Dashboard from '../../../../../pages/Dashboard';
import Individual from '../../ContactsForms/Individual';
import OtherOrganization from '../../ContactsForms/OtherOrganization';
import PoliticalCommittee from '../../ContactsForms/PoliticalCommittee';
import FinancialInstitution from '../../ContactsForms/FinancialInstitution';
import { addObject, editObject } from '../../../../../../services/crudApis';
import { ADD_CONTACT, EDIT_CONTACT } from '../../../../../../configs/endpoints';
import { Popup } from '../../../../../atoms/Popup';
import DublicateContact from '../../../../../molecules/DublicateContact';
import ReportAmendment from '../../../../../molecules/ReportAmendment';

const { Paragraph } = Typography;
const { Option } = Select;

const contactTypeList = [
  { value: 'individual', label: 'Individual' },
  { value: 'other_organization', label: 'Other Organization' },
  { value: 'political_committee', label: 'Political Committee' },
  { value: 'financial_institution', label: 'Financial Institution' },
];

export default (props) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, errors } = useForm();
  const history = useHistory();
  const location = useLocation();

  const url = location.pathname;
  let selectValue = url.split('/')[2].replace(/-/g, '_');
  const [contactType, setContactType] = useState(selectValue);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dublicateContact, setDublicateContact] = useState(false);
  const [dublicateArray, setDublicateArray] = useState([]);
  const [dublicateCurrentValue, setDublicateCurrentValue] = useState();
  const [dataValue, setDataValue] = useState({});
  const [reportVisible, setReportVisible] = useState(false);

  const handleChange = (value) => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
    setTimeout(() => {
      setContactType(value);
      setPhoneNumber('');
      const newUrl = value.replace(/_/g, '-');
      history.push(newUrl);
    }, 1200);
  };

  const onFinish = (values, force) => {
    setLoading(true);
    setDataValue(values);

    if (!force) {
      setDublicateContact(false);
    }

    let requestBody = {
      first_name: values.firstName,
      last_name: values.lastName,
      middle_name: values.middle_name,
      suffix: values.suffix?.value,
      type: contactType,
      is_contributor: true,
      email_notices: false,
      is_candidate_spouse: values.candidate_spouse,
      office_sought: values?.office_sought ?? undefined,
      jurisdiction: values?.jurisdiction ?? undefined,
      checkForDuplicate: force !== false,
      address: {
        state: values.mailingcountryList?.value,
        city: values.mailingCity,
        post_code: values.mailingZip,
        phone: phoneNumber,
        email: values.email,
        mailing_address: values.mailingStreet,
      },
      organization_name: values.hasOwnProperty('organization_name') ? values.organization_name : undefined,
      is_non_profit_organization: values.hasOwnProperty('is_non_profit_organization')
        ? values.is_non_profit_organization == true
        : undefined,
      committee_name: values?.committee_name,
      committee_type: values.committee_type ? values.committee_type.value : undefined,
      committee_organization_level: values.committee_organization_level
        ? values.committee_organization_level.value
        : undefined,
      committee_boe_id: values?.committee_boe_id,
      financial_institution_name: values?.financial_institution_name,
      committee_county_name:
        values.committee_organization_level?.value == 'county' ? values?.countyOrganization?.value : '',
    };
    if (values.jobRadio == 'Job_title') {
      requestBody.job_title = values.jobTitle ? values?.jobTitle : '';
      requestBody.profession = null;
    } else if (values.jobRadio == 'Profession') {
      requestBody.job_title = null;
      requestBody.profession = values.profession ? values?.profession : '';
    } else if (values.jobRadio == 'Retired') {
      requestBody.job_title = 'Retired';
      requestBody.profession = null;
    }
    if (values.employeeRadio == 'employersName') {
      requestBody.employer_name = values.employerName ? values.employerName : '';
      requestBody.specific_field = null;
    } else if (values.employeeRadio == 'specificField') {
      requestBody.employer_name = null;
      requestBody.specific_field = values.specificField?.value ? values?.specificField?.value : '';
    }

    // const dublicatePayload = {
    //   first_name: values?.firstName,
    //   middle_name: values?.middle_name,
    //   last_name: values?.lastName,
    //   job_title: values?.jobTitle,
    //   employer_name: values?.employerName,
    //   addresses: [
    //     {
    //       email: values?.email,
    //       mailing_address: values?.mailingStreet,
    //       phone: phoneNumber,
    //     },
    //   ],
    // };

    addObject(ADD_CONTACT, requestBody)
      .then((response) => {
        //console.log('response', response);
        setTimeout(() => {
          setLoading(false);
          message.success('Contact saved');
        }, 800);
        setTimeout(() => {
          history.push('/contacts');
        }, 1200);
      })
      .catch((e) => {
        setLoading(false);
        if (e && e?.response && e?.response['status'] == '400') {
          const error = JSON.parse(e?.response?.data?.error?.message);
          //console.log('message', error?.duplicateContacts);
          setDublicateCurrentValue(values);
          setDublicateArray(error?.duplicateContacts);
          setDublicateContact(true);
        } else {
          message.error('Something went wrong');
        }
      });
  };

  const submitFileExternal = (date) => {
    dataValue.effectiveDate = date;
    updateContact();
  };

  const dublicatedContact = {
    closable: false,
    visibility: dublicateContact,
    class: 'white-modal',
    content: (
      <DublicateContact
        title="Duplicative Contact"
        onClose={() => setDublicateContact(false)}
        dublicateCurrentValue={dublicateCurrentValue}
        dublicateArray={dublicateArray}
        updateApi={() => onFinish(dataValue, false)}
      />
    ),
    width: 542,
    onCancel: () => setDublicateContact(false),
  };

  return (
    <Dashboard load={loading} heading="New Contact" backLink="/contacts">
      <Card className="white-card w-100">
        <Row justify="center" gutter={[20, 20]}>
          <Col span={24}>
            <Select defaultValue={contactType} onChange={handleChange} className="custom-ant-select">
              {contactTypeList &&
                contactTypeList?.map((item, index) => (
                  <Fragment key={index}>
                    <Option value={item?.value}>{item?.label}</Option>
                  </Fragment>
                ))}
            </Select>
          </Col>

          {contactType == 'individual' && (
            <Col span={24}>
              <Paragraph className="c-blue">
                You may not collect personal information from individuals who contribute $50 or less during the
                election. However, regardless of contribution amount, it is easier to acquire this information up front
                rather than chase it later if they give again and surpass the $50 threshold to appear individually on
                your reports.
              </Paragraph>
            </Col>
          )}
        </Row>

        <Form className="w-100 mt-2" layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
          {contactType == 'individual' && (
            <Individual
              control={control}
              errors={errors}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              setValue={setValue}
            />
          )}

          {contactType == 'other_organization' && (
            <OtherOrganization
              control={control}
              errors={errors}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              setValue={setValue}
            />
          )}

          {contactType == 'political_committee' && (
            <PoliticalCommittee
              control={control}
              errors={errors}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              setValue={setValue}
            />
          )}

          {contactType == 'financial_institution' && (
            <FinancialInstitution
              control={control}
              errors={errors}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              setValue={setValue}
            />
          )}

          <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
            <Col>
              <Button
                type="primary"
                onClick={() => history.push('/contacts')}
                htmlType="button"
                className="font-gilroy white-btn"
                size="large"
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Popup {...dublicatedContact} />
      <ReportAmendment
        reportVisible={reportVisible}
        setReportVisible={setReportVisible}
        submitFileExternal={submitFileExternal}
        dateEffective={true}
      />
    </Dashboard>
  );
};
