import React, { useState, useEffect } from 'react';
import { Table, Spin, Button, Row, Col, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getReceiptListingData, get48HourReceiptListingData } from '../../../../Receipts/ducks/actions.js';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { currentStep, setCurrentStep, startDate, endDate, reportId, isFor48HourReport } = props;

  const [loading, setLoading] = useState(false);
  const receiptListData = useSelector((state) => state.receipt.receiptListData);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [dataSource, setDataSource] = useState();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (isFor48HourReport) {
      dispatch(get48HourReceiptListingData(reportId));
    } else {
      dispatch(getReceiptListingData(startDate, endDate));
    }
  }, [startDate, endDate, isFor48HourReport]);

  //console.log('startDate', startDate, endDate);

  const listColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text) => moment(text).format('MM-DD-YYYY'),
      sorter: (a, b) => +moment(a.date, 'MM-DD-YYYY').format('X') - +moment(b.date, 'MM-DD-YYYY').format('X'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => +a.amount.replace('$', '').split(',').join('') - +b.amount.replace('$', '').split(',').join(''),
    },
    {
      title: 'Receipt Type',
      dataIndex: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
    },
    {
      title: 'Contributor',
      dataIndex: 'contributor',
      sorter: (a, b) => a.contributor.localeCompare(b.contributor),
    },
    {
      title: 'Form',
      dataIndex: 'payment_type',
      sorter: (a, b) => a.payment_type.localeCompare(b.payment_type),
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
    },
    {
      title: 'Account Code',
      dataIndex: 'bankaccount',
      sorter: (a, b) => a.bankaccount.localeCompare(b.bankaccount),
    },
  ];

  const moveToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const fetchData = (params = {}, fromDate = 'null', toDate = 'null') => {
    setLoading(true);
    fetch(``).then(({ results }) => {
      setPagination({
        ...params.pagination,
      });
    });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const handleTableChange = (newPagination, filters) => {
    fetchData({
      pagination: newPagination,
      ...filters,
    });
  };

  useEffect(() => {
    setDataSource(receiptListData);
    if (receiptListData) {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [receiptListData]);

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row>
        <Col className="search_box" style={{ margin: '0 24px 24px' }}>
          <Input
            placeholder="Search..."
            value={value}
            onChange={(e) => {
              const currValue = e.target.value.toLowerCase();
              setValue(currValue);
              const filteredData = receiptListData.filter(
                (entry) =>
                  (entry?.date && entry?.date.includes(currValue)) ||
                  (entry?.amount && entry?.amount.includes(currValue)) ||
                  (entry?.type && entry?.type.toLowerCase().includes(currValue)) ||
                  (entry?.contributor && entry?.contributor.toLowerCase().includes(currValue)) ||
                  (entry?.payment_type && entry?.payment_type.toLowerCase().includes(currValue)) ||
                  (entry?.bankaccount && entry?.bankaccount.toLowerCase().includes(currValue)),
              );
              setDataSource(filteredData);
            }}
          />
        </Col>
      </Row>
      <Table columns={listColumns} dataSource={dataSource} pagination={pagination} onChange={handleTableChange} />
      <Row align="middle" justify="center" gutter={[20, 20]} style={{ marginTop: '100px' }}>
        <Col>
          <Button
            type="primary"
            onClick={() => setCurrentStep(currentStep - 1)}
            htmlType="button"
            className="font-gilroy white-btn"
            size="large"
          >
            Back
          </Button>
        </Col>

        <Col>
          <Button
            type="primary"
            onClick={() => moveToNextStep()}
            htmlType="button"
            className="font-gilroy"
            size="large"
          >
            Continue
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};
