import React, { useState, Fragment, useEffect } from 'react';
import { Row, Col, Image, Typography, Form, Button, Select, Radio, message, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import { InputRadio, InputField, SelectField, InputCheckbox } from '../../../../atoms/FormElement';
import crossIcon from '../../../../../assets/svg/cross-icon.svg';
import {
  CONTACTS_POLITICAL_COMMITTEE_TYPE,
  CONTACTS_POLITICAL_COMMITTEE_ORG_LEVEL,
  countryList,
  specificField,
} from '../../../../../configs/constants';
import { ADD_CONTACT, EDIT_CONTACT } from '../../../../../configs/endpoints';
import { addObject, editObject } from '../../../../../services/crudApis';
import { countyList } from '../../../../../configs/county-list';

const { Text, Title } = Typography;
const { Option } = Select;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const {
    onClose,
    title,
    popupType,
    updateApi,
    data,
    editPopupForm,
    contctValue,
    setTypeIndividual,
    setTypeIndividualPayee,
  } = props;
  const { control, handleSubmit, errors, setValue, reset } = useForm({
    shouldUnregister: false,
  });
  const [jobTitle, setJobTitle] = useState('Job_title');
  const [employeeName, setEmployeeName] = useState('employersName');
  const [contactType, setContactType] = useState(popupType);
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [contribute, setContribute] = useState('contributingNo');
  const [phoneNumber, setPhoneNumber] = useState('');
  const heading = popupType.replace(/_/g, ' ');

  useEffect(() => {
    setContactType(popupType);
    handleChange(popupType);
  }, [popupType]);

  useEffect(() => {
    setValue('mailingcountryList', { label: 'North Carolina', value: 'NC' });
  }, []);

  useEffect(() => {
    if (data && Object.keys(data)?.length > 0) {
      setLoading(true);
      const selectedState = countryList?.find((e) => e?.value == data?.state);
      const specificDrop = specificField?.find((e) => e?.value == data?.specific_field);
      const selectedCounty = countyList?.find((e) => e?.value == data?.committee_county_name);
      const selectedCommitteeType = CONTACTS_POLITICAL_COMMITTEE_TYPE?.find((e) => e?.value == data?.committee_type);
      const selectedCommitteeOrgLevel = CONTACTS_POLITICAL_COMMITTEE_ORG_LEVEL?.find(
        (e) => e?.value == data?.committee_organization_level,
      );
      const suffixDrop = suffixData?.find((e) => e?.value == data?.suffix);

      setTimeout(() => {
        setValue('firstName', data?.first_name);
        setValue('lastName', data?.last_name);
        setValue('middle_name', data?.middle_name);
        setValue('suffix', suffixDrop);
        setValue('candidate_spouse', data?.is_candidate_spouse);
        setValue('mailingStreet', data?.mailing_address);
        setValue('mailingCity', data?.city);
        setValue('mailingZip', data?.post_code);
        if (selectedState) {
          setValue('mailingcountryList', selectedState);
        }
        setPhoneNumber(data?.phone);
        setValue('email', data?.email);
        setValue('financial_institution_name', data?.financial_institution_name);
        setValue('organization_name', data?.organization_name);
        setValue('is_non_profit_organization', data?.is_non_profit_organization);
        setValue('committee_name', data?.committee_name);
        setValue('committee_type', selectedCommitteeType);
        setValue('committee_organization_level', selectedCommitteeOrgLevel);
        setValue('committee_boe_id', data?.committee_boe_id);
        organizationChange(selectedCommitteeOrgLevel);
        setValue(
          'contributing',
          data.is_contributor && data.is_contributor != null ? 'contributingYes' : 'contributingNo',
        );
        setContribute(data.is_contributor ? 'contributingYes' : 'contributingNo');
        setTimeout(() => {
          setValue('countyOrganization', selectedCounty);
          setLoading(false);
        }, 1000);

        if (data?.profession && data?.profession?.length > 0) {
          setJobTitle('Profession');
        }  else if (data?.job_title === "Retired") {
          setJobTitle('Retired');
        } else {
          setJobTitle('Job_title');
        }
        if (data?.specific_field && data?.specific_field?.length > 0) {
          setEmployeeName('specificField');
        } else {
          setEmployeeName('employersName');
        }

        setTimeout(() => {
          setValue('jobTitle', data?.job_title === 'Retired' ? 'No Job Title or Profession' : data?.job_title);
          setValue('profession', data?.profession);
          setValue('employerName', data?.employer_name);
          setValue('specificField', specificDrop);
          setValue('jobRadio', data?.profession && data?.profession?.length > 0 ? 'Profession' : data?.job_title === 'Retired' ? 'Retired' : 'Job_title');
          setValue(
            'employeeRadio',
            data?.specific_field && data?.specific_field?.length > 0 ? 'specificField' : 'employersName',
          );
          setLoading(false);
        }, 1200);
      }, 1200);
    }
  }, [data, editPopupForm]);

  const contactTypeList = [
    { value: 'individual', label: 'Individual' },
    { value: 'other_organization', label: 'Other Organization' },
    { value: 'political_committee', label: 'Political Committee' },
    { value: 'financial_institution', label: 'Financial Institution' },
  ];

  const organizationChange = (e) => {
    if (e?.value == 'county') {
      setOrganization(true);
    } else {
      setOrganization(false);
    }
  };

  const handleChange = (value) => {
    setLoading(true);
    setPhoneNumber('');
    setTimeout(() => setLoading(false), 1000);
    setContactType(value);
  };

  const onFinish = (values) => {
    setLoading(true);
    let requestBody = {
      type: popupType,
      first_name: values.firstName,
      last_name: values.lastName,
      middle_name: values.middle_name,
      suffix: values.suffix?.value,
      type: contactType,
      is_contributor: true,
      email_notices: false,
      is_candidate_spouse: values.candidate_spouse,
      address: {
        state: values.mailingcountryList?.value,
        city: values.mailingCity,
        post_code: values.mailingZip,
        phone: phoneNumber,
        email: values.email,
        mailing_address: values.mailingStreet,
      },
      is_contributor: values?.contributing == 'contributingYes' ? true : false,
      organization_name: values.hasOwnProperty('organization_name') ? values.organization_name : undefined,
      is_non_profit_organization: values.hasOwnProperty('is_non_profit_organization')
        ? values.is_non_profit_organization == true
        : undefined,
      committee_name: values?.committee_name,
      committee_type: values.committee_type ? values.committee_type.value : undefined,
      committee_organization_level: values.committee_organization_level
        ? values.committee_organization_level.value
        : undefined,
      committee_boe_id: values?.committee_boe_id,
      financial_institution_name: values?.financial_institution_name,
      committee_county_name:
        values.committee_organization_level?.value == 'county' ? values?.countyOrganization?.value : '',
    };
    if (values.jobRadio == 'Job_title') {
      requestBody.job_title = values.jobTitle ? values?.jobTitle : '';
      requestBody.profession = null;
    } else if (values.jobRadio == 'Profession') {
      requestBody.job_title = null;
      requestBody.profession = values.profession ? values?.profession : '';
    } else if (values.jobRadio == 'Retired') {
      requestBody.job_title = 'Retired';
      requestBody.profession = null;
    }
    if (values.employeeRadio == 'employersName') {
      requestBody.employer_name = values.employerName ? values.employerName : '';
      requestBody.specific_field = null;
    } else if (values.employeeRadio == 'specificField') {
      requestBody.employer_name = null;
      requestBody.specific_field = values.specificField?.value ? values?.specificField?.value : '';
    }

    if (editPopupForm) {
      editObject(EDIT_CONTACT + '/' + contctValue, requestBody)
        .then(() => {
          setTimeout(() => {
            setLoading(false);
            message.success('Contact updated.');
          }, 800);
          setTimeout(() => {
            reset();
            updateApi(contctValue);
          }, 1200);
        })
        .catch((e) => {
          setLoading(false);
          message.error('Something went wrong');
        });
    } else {
      addObject(ADD_CONTACT, requestBody)
        .then((response) => {
          setTimeout(() => {
            setLoading(false);
            message.success('Contact saved');
          }, 800);
          setTimeout(() => {
            reset();
            updateApi(response?.data?.id);
          }, 1200);
        })
        .catch((e) => {
          setLoading(false);
          message.error('Something went wrong');
        });
    }
  };

  const onChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const closingPopUp = () => {
    setTypeIndividual && setTypeIndividual(false);
    setTypeIndividualPayee && setTypeIndividualPayee(false);
    reset();
  };

  const suffixData = [
    { label: 'JR', value: 'JR' },
    { label: 'SR', value: 'SR' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' },
    { label: 'V', value: 'V' },
    { label: 'VI', value: 'VI' },
    { label: 'VII', value: 'VII' },
    { label: 'VIII', value: 'VIII' },
  ];

  const selectedCountry = (e) => {
    if (e?.value == 'WADC') {
      setValue('mailingCity', 'Washington DC');
    } else if (data && Object.keys(data)?.length > 0) {
      setValue('mailingCity', data?.city);
    } else {
      setValue('mailingCity', '');
    }
  };

  useEffect(() => {
    if(jobTitle === 'Retired') {
      setValue('employerName', 'Not Employed');
      setValue('jobTitle', 'No Job Title or Profession');
    } else {
      setValue('employerName', '');
      setValue('jobTitle', '');
    }
  }, [jobTitle])

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row gutter={[48, 20]} justify="space-between" align="middle">
        <Col>
          {editPopupForm && (
            <Title level={4} className="mb-0">
              {title}
            </Title>
          )}
        </Col>
        <Col>
          <Button size="large" type="link" className="p-0" onClick={closingPopUp}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>
        <Col span={24}>
          {/* <Select
            defaultValue={contactType && contactType}
            onChange={handleChange}
            className="custom-ant-select transparent-select"
          >
            {contactTypeList &&
              contactTypeList?.map((item, index) => (
                <Fragment key={index}>
                  <Option value={item?.value}>{item?.label}</Option>
                </Fragment>
              ))}
          </Select> */}
          <Title level={3} className="text-capitalize mb-0 text-center">
            {heading}
          </Title>
        </Col>
        <Col span={24}>
          <Form className="w-100 mt-2" layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            {contactType == 'individual' ||
            contactType == 'treasurer' ||
            contactType == 'custodian' ||
            contactType == 'assistant_treasurer' ? (
              <Row gutter={[20, 10]}>
                <Col span={24}>
                  <Row gutter={[8, 10]}>
                    <Col span={24} style={{ marginBottom: '-8px' }}>
                      <Text style={{ fontSize: '12px', color: '#596780' }}>Name</Text>
                    </Col>
                    <Col span={6}>
                      <InputField
                        isRequired={true}
                        fieldname="firstName"
                        label=""
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                          placeholder: 'First',
                        }}
                        rules={{
                          required: 'Name is required.',
                        }}
                        validate={errors.firstName && 'error'}
                        validMessage={errors.firstName && errors.firstName.message}
                      />
                    </Col>

                    <Col span={6}>
                      <InputField
                        fieldname="middle_name"
                        label=""
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                          placeholder: 'Middle',
                        }}
                      />
                    </Col>

                    <Col span={6}>
                      <InputField
                        fieldname="lastName"
                        label=""
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                          placeholder: 'Last',
                        }}
                      />
                    </Col>

                    <Col span={6}>
                      <SelectField
                        fieldname="suffix"
                        control={control}
                        class="mb-0"
                        iProps={{ placeholder: 'Suffix', isClearable: true }}
                        initValue=""
                        selectOption={suffixData}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[30, 20]}>
                    <Col span={24} className="mailingField">
                      <Row gutter={[12, 8]}>
                        <Col span={24}>
                          <InputField
                            fieldname="mailingStreet"
                            label="Mailing Address"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'Street Address',
                            }}
                          />
                        </Col>

                        <Col span={9}>
                          <SelectField
                            fieldname="mailingcountryList"
                            control={control}
                            class="mb-0"
                            iProps={{ placeholder: 'Please select' }}
                            initValue={{}}
                            selectOption={countryList}
                            onChange={(e) => selectedCountry(e)}
                          />
                        </Col>

                        <Col span={9}>
                          <InputField
                            fieldname="mailingCity"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'City',
                            }}
                          />
                        </Col>

                        <Col span={6}>
                          <InputField
                            fieldname="mailingZip"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'Zip',
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col span={12}>
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-form-item-label">
                          <label className="" title="Phone Number">
                            Phone Number
                          </label>
                          <div className="optional-label" style={{ top: '4px' }}>
                            (Optional)
                          </div>
                        </div>
                      </div>
                      <InputMask mask="(999) 999-9999" value={phoneNumber} onChange={onChange}>
                        {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
                      </InputMask>
                    </Col>

                    <Col span={12}>
                      <InputField
                        fieldname="email"
                        label="Email"
                        optionalLabel="(Optional)"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                        }}
                        rules={{
                          pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: 'Check if the email is valid',
                          },
                        }}
                        validate={errors.email && 'error'}
                        validMessage={errors.email && errors.email.message}
                      />
                    </Col>
                  </Row>
                </Col>

                {contactType !== 'individual' && (
                  <Col span={24}>
                    <InputRadio
                      fieldname="contributing"
                      label="Will this person be contributing to this committee?"
                      control={control}
                      onChange={(e) => setContribute(e.target.value)}
                      classField="tab-type-radio"
                      options={
                        <>
                          <Row gutter={[20, 20]}>
                            <Col span={12}>
                              <Radio value="contributingNo">No</Radio>
                            </Col>
                            <Col span={12}>
                              <Radio value="contributingYes">Yes</Radio>
                            </Col>
                          </Row>
                        </>
                      }
                      isRequired={true}
                      rules={{
                        required: 'Will this person be contributing to this committee? is required.',
                      }}
                      validate={errors.contributing && 'error'}
                      validMessage={errors.contributing && errors.contributing.message}
                    />
                  </Col>
                )}

                {contribute == 'contributingYes' || contactType == 'individual' ? (
                  <>
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <InputRadio
                            fieldname="jobRadio"
                            control={control}
                            initValue={'Job_title'}
                            classField="small-radio"
                            onChange={(e) => setJobTitle(e.target.value)}
                            options={
                              <>
                                <Radio value="Job_title">Job Title</Radio>
                                <Radio value="Profession">Profession</Radio>
                                <Radio value="Retired">Retired</Radio>
                              </>
                            }
                          />
                        </Col>
                        <Col span={24}>
                        {(jobTitle == 'Job_title' || jobTitle == 'Retired') && (
                            <InputField
                              //isRequired={true}
                              fieldname="jobTitle"
                              control={control}
                              initValue=""
                              iProps={{
                                size: 'large',
                                placeholder: jobTitle == 'Retired' ? 'No Job Title or Profession' : 'Job Title',
                                disabled: jobTitle === 'Retired'
                              }}
                            />
                          )}

                          {jobTitle == 'Profession' && (
                            <InputField
                              //isRequired={true}
                              fieldname="profession"
                              control={control}
                              initValue=""
                              iProps={{
                                size: 'large',
                                placeholder: 'Profession',
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <InputRadio
                            fieldname="employeeRadio"
                            control={control}
                            initValue={'employersName'}
                            onChange={(e) => setEmployeeName(e.target.value)}
                            classField="small-radio"
                            options={
                              <>
                                <Radio value="employersName" disabled={jobTitle === 'Retired'}>Employer's Name</Radio>
                                <Radio value="specificField" disabled={jobTitle === 'Retired'}>Specific Field</Radio>
                              </>
                            }
                          />
                        </Col>
                        <Col span={24}>
                          {employeeName == 'employersName' && (
                            <InputField
                              fieldname="employerName"
                              control={control}
                              initValue=""
                              iProps={{
                                size: 'large',
                                placeholder: "Employer's Name",
                                disabled: jobTitle === 'Retired'
                              }}
                            />
                          )}

                          {employeeName == 'specificField' && (
                            <SelectField
                              //isRequired={true}
                              fieldname="specificField"
                              control={control}
                              class="mb-0"
                              iProps={{ placeholder: 'Specific Field' }}
                              initValue=""
                              selectOption={specificField}
                              // rules={{ required: 'Specific Field is required.' }}
                              // validate={errors.specificField && 'error'}
                              // validMessage={errors.specificField && errors.specificField.message}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </>
                ) : null}

                {contactType == 'individual' && (
                  <Col span={24}>
                    <InputCheckbox
                      fieldname="candidate_spouse"
                      label="Candidate Spouse"
                      class={`mb-0 one-checbox`}
                      control={control}
                    />
                  </Col>
                )}
              </Row>
            ) : null}

            {contactType == 'other_organization' && (
              <Row gutter={[20, 10]}>
                <Col span={24}>
                  <Row gutter={[30, 20]}>
                    <Col span={24}>
                      <InputField
                        isRequired={true}
                        fieldname="organization_name"
                        label="Organization Name"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                        }}
                        rules={{
                          required: 'Organization Name is required.',
                        }}
                        validate={errors.organization_name && 'error'}
                        validMessage={errors.organization_name && errors.organization_name.message}
                      />
                    </Col>

                    <Col span={24}>
                      <InputCheckbox
                        fieldname="is_non_profit_organization"
                        label="Non-for-Profit Organization"
                        class={`mb-0 one-checbox`}
                        control={control}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[30, 20]}>
                    <Col span={24} className="mailingField">
                      <Row gutter={[12, 8]}>
                        <Col span={24}>
                          <InputField
                            fieldname="mailingStreet"
                            label="Mailing Address"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'Street Address',
                            }}
                          />
                        </Col>

                        <Col span={9}>
                          <SelectField
                            fieldname="mailingcountryList"
                            control={control}
                            class="mb-0"
                            iProps={{ placeholder: 'Please select' }}
                            initValue={{}}
                            selectOption={countryList}
                            onChange={(e) => selectedCountry(e)}
                          />
                        </Col>

                        <Col span={9}>
                          <InputField
                            fieldname="mailingCity"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'City',
                            }}
                          />
                        </Col>

                        <Col span={6}>
                          <InputField
                            fieldname="mailingZip"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'Zip',
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col span={12}>
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-form-item-label">
                          <label className="" title="Phone Number">
                            Phone Number
                          </label>
                          <div className="optional-label" style={{ top: '4px' }}>
                            (Optional)
                          </div>
                        </div>
                      </div>
                      <InputMask mask="(999) 999-9999" value={phoneNumber} onChange={onChange}>
                        {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
                      </InputMask>
                    </Col>

                    <Col span={12}>
                      <InputField
                        fieldname="email"
                        label="Email"
                        optionalLabel="(Optional)"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                        }}
                        rules={{
                          pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: 'Check if the email is valid',
                          },
                        }}
                        validate={errors.email && 'error'}
                        validMessage={errors.email && errors.email.message}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            {contactType == 'political_committee' && (
              <Row gutter={[20, 10]}>
                <Col span={24}>
                  <Row gutter={[12, 30]}>
                    <Col span={24}>
                      <InputField
                        isRequired={true}
                        fieldname="committee_name"
                        label="Committee Name"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                        }}
                        rules={{
                          required: 'Committee Name is required.',
                        }}
                        validate={errors.committee_name && 'error'}
                        validMessage={errors.committee_name && errors.committee_name.message}
                      />
                    </Col>

                    <Col span={24}>
                      <SelectField
                        isRequired={true}
                        fieldname="committee_type"
                        label="Committee Type"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                          placeholder: 'Please select',
                        }}
                        rules={{
                          required: 'Committee Type is required.',
                        }}
                        selectOption={CONTACTS_POLITICAL_COMMITTEE_TYPE}
                        validate={errors.committee_type && 'error'}
                        validMessage={errors.committee_type && errors.committee_type.message}
                      />
                    </Col>

                    <Col span={24}>
                      <SelectField
                        isRequired={true}
                        fieldname="committee_organization_level"
                        label="Committee Organization Level"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                          placeholder: 'Please select',
                        }}
                        rules={{
                          required: 'Committee Organization Level is required.',
                        }}
                        onChange={(e) => organizationChange(e)}
                        selectOption={CONTACTS_POLITICAL_COMMITTEE_ORG_LEVEL}
                        validate={errors.committee_organization_level && 'error'}
                        validMessage={
                          errors.committee_organization_level && errors.committee_organization_level.message
                        }
                      />
                    </Col>

                    {organization && (
                      <Col span={24}>
                        <SelectField
                          isRequired={true}
                          fieldname="countyOrganization"
                          control={control}
                          label="County"
                          class="mb-0"
                          iProps={{ placeholder: 'Please select' }}
                          rules={{ required: 'County is required.' }}
                          initValue=""
                          selectOption={countyList}
                          validate={errors.countyOrganization && 'error'}
                          validMessage={errors.countyOrganization && errors.countyOrganization.message}
                        />
                      </Col>
                    )}

                    <Col span={24}>
                      <InputField
                        fieldname="committee_boe_id"
                        label="Board of Elections ID"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                        }}
                        hintMessage="Ex.: STA-123456-C-001."
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[30, 20]}>
                    <Col span={24} className="mailingField">
                      <Row gutter={[12, 8]}>
                        <Col span={24}>
                          <InputField
                            fieldname="mailingStreet"
                            label="Mailing Address"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'Street Address',
                            }}
                          />
                        </Col>

                        <Col span={9}>
                          <SelectField
                            fieldname="mailingcountryList"
                            control={control}
                            class="mb-0"
                            iProps={{ placeholder: 'Please select' }}
                            initValue={{}}
                            selectOption={countryList}
                            onChange={(e) => selectedCountry(e)}
                          />
                        </Col>

                        <Col span={9}>
                          <InputField
                            fieldname="mailingCity"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'City',
                            }}
                          />
                        </Col>

                        <Col span={6}>
                          <InputField
                            fieldname="mailingZip"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'Zip',
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col span={12}>
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-form-item-label">
                          <label className="" title="Phone Number">
                            Phone Number
                          </label>
                          <div className="optional-label" style={{ top: '4px' }}>
                            (Optional)
                          </div>
                        </div>
                      </div>
                      <InputMask mask="(999) 999-9999" value={phoneNumber} onChange={onChange}>
                        {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
                      </InputMask>
                    </Col>

                    <Col span={12}>
                      <InputField
                        fieldname="email"
                        label="Email"
                        optionalLabel="(Optional)"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                        }}
                        rules={{
                          pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: 'Check if the email is valid',
                          },
                        }}
                        validate={errors.email && 'error'}
                        validMessage={errors.email && errors.email.message}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            {contactType == 'financial_institution' && (
              <Row gutter={[20, 10]}>
                <Col span={24}>
                  <Row gutter={[30, 20]}>
                    <Col span={24}>
                      <InputField
                        isRequired={true}
                        fieldname="financial_institution_name"
                        label="Financial Institution Name"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                        }}
                        rules={{
                          required: 'Financial Institution Name is required.',
                        }}
                        validate={errors.financial_institution_name && 'error'}
                        validMessage={errors.financial_institution_name && errors.financial_institution_name.message}
                      />
                    </Col>

                    {/* <Col span={24}>
                  <InputCheckbox
                    fieldname="is_non_profit_organization"
                    label="Non-for-Profit Organization"
                    class={`mb-0 one-checbox`}
                    control={control}
                  />
                </Col> */}
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[30, 20]}>
                    <Col span={24} className="mailingField">
                      <Row gutter={[12, 8]}>
                        <Col span={24}>
                          <InputField
                            fieldname="mailingStreet"
                            label="Mailing Address"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'Street Address',
                            }}
                          />
                        </Col>

                        <Col span={9}>
                          <SelectField
                            fieldname="mailingcountryList"
                            control={control}
                            class="mb-0"
                            iProps={{ placeholder: 'Please select' }}
                            initValue={{}}
                            selectOption={countryList}
                            onChange={(e) => selectedCountry(e)}
                          />
                        </Col>

                        <Col span={9}>
                          <InputField
                            fieldname="mailingCity"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'City',
                            }}
                          />
                        </Col>

                        <Col span={6}>
                          <InputField
                            fieldname="mailingZip"
                            control={control}
                            initValue=""
                            iProps={{
                              size: 'large',
                              placeholder: 'Zip',
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col span={12}>
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-form-item-label">
                          <label className="" title="Phone Number">
                            Phone Number
                          </label>
                          <div className="optional-label" style={{ top: '4px' }}>
                            (Optional)
                          </div>
                        </div>
                      </div>
                      <InputMask mask="(999) 999-9999" value={phoneNumber} onChange={onChange}>
                        {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
                      </InputMask>
                    </Col>

                    <Col span={12}>
                      <InputField
                        fieldname="email"
                        label="Email"
                        optionalLabel="(Optional)"
                        control={control}
                        initValue=""
                        iProps={{
                          size: 'large',
                        }}
                        rules={{
                          pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: 'Check if the email is valid',
                          },
                        }}
                        validate={errors.email && 'error'}
                        validMessage={errors.email && errors.email.message}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
              <Col>
                <Button
                  type="primary"
                  onClick={closingPopUp}
                  htmlType="button"
                  className="font-gilroy white-btn"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};
