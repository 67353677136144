import React, { useState, useEffect, Fragment } from 'react';
import { Spin, Button, Row, Col, Typography, Image, Form } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { SelectField, InputField, InputPassword, DateField } from '../../atoms/FormElement';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import crossIcon from '../../../assets/svg/cross-icon.svg';
import { getAccountInfoFormData } from '../../modules/Dashboard/Settings/ducks/actions';

const { Title, Paragraph, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { onClose, title, submitFileExternal, popHeading } = props;
  const { control, errors, handleSubmit, setValue } = useForm({
    defaultValues: {
      external_filed: [{ name: 'useFieldArray' }],
    },
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.setting.accountFormData);
  const { fields } = useFieldArray({
    control,
    name: 'external_filed',
  });

  useEffect(() => {
    dispatch(getAccountInfoFormData());
  }, []);

  useEffect(() => {
    if (formData && Object.keys(formData).length > 0) {
      setValue('external_filed', formData?.accounts);
    }
  }, [formData]);

  const onFinish = (values) => {
    setLoading(true)
    submitFileExternal(values);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  };
  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[20, 20]} justify="space-between" align="middle">
          <Col>
            <Title level={4} className="mb-0">
              {title}
            </Title>
          </Col>
          <Col>
            <Button size="large" type="link" className="p-0" onClick={onClose}>
              <Image src={crossIcon} preview={false} size={48} />
            </Button>
          </Col>
          <Col span={24}>
            <Paragraph>
              If you filed this report outside ElectaFile&reg;, you can mark it as filed and accepted in our system so
              that we know it's done and do not bother you with reminders that this report must be filed.
            </Paragraph>
          </Col>
          {popHeading && popHeading == 'ORG' && (
            <>
              <Col span={24}>
                <DateField
                  fieldname="report_end_date"
                  label="Organizational Report End Date"
                  control={control}
                  class="mb-0"
                  iProps={{ size: 'large' }}
                  initValue=""
                  isRequired={true}
                  rules={{
                    required: 'Organizational Report End Date is required.',
                  }}
                  validate={errors.report_end_date && 'error'}
                  validMessage={errors.report_end_date && errors.report_end_date.message}
                />
              </Col>

              <Col span={24}>
                {fields?.map((item, index) => (
                  <Fragment key={item.id}>
                    <Row gutter={[20, 20]} align="bottom" style={{ marginBottom: '20px' }}>
                      <InputField
                        fieldname={`external_filed[${index}].id`}
                        label=""
                        control={control}
                        class="d-none mb-0"
                        initValue={item?.id}
                        iProps={{ size: 'large' }}
                      />
                      <Col span={12}>
                        <InputField
                          fieldname={`external_filed[${index}].code`}
                          label="Account Code"
                          control={control}
                          class="mb-0 label_inside"
                          initValue={item?.code}
                          iProps={{ size: 'large' }}
                          readOnly={true}
                        />
                      </Col>
                      <Col span={12}>
                        <InputField
                          isRequired={true}
                          fieldname={`external_filed[${index}].balance`}
                          label="Election Cycle Begin Balance"
                          tooltip={true}
                          tooltipTitle="Total amount of funds available on this bank account at the start of this election cycle."
                          control={control}
                          initValue={item?.begin_balance}
                          iProps={{
                            size: 'large',
                            prefix: '$',
                            type: 'number',
                          }}
                          rules={{
                            required: 'Election Cycle Begin Balance is required.',
                          }}
                          validate={
                            Object.entries(errors).length > 0 &&
                            errors?.external_filed?.length > 0 &&
                            errors?.external_filed[index]?.balance &&
                            'error'
                          }
                          validMessage={
                            Object.entries(errors).length > 0 &&
                            errors?.external_filed?.length > 0 &&
                            errors?.external_filed[index]?.balance &&
                            errors?.external_filed[index]?.balance?.message
                          }
                        />
                      </Col>
                    </Row>
                  </Fragment>
                ))}
              </Col>
            </>
          )}

          <Col span={24}>
            <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
              <Col>
                <Button
                  type="primary"
                  onClick={onClose}
                  htmlType="button"
                  className="font-gilroy white-btn"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Mark as filed externally
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
