import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Typography, Radio, Image } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { InputField, InputRadio, DateField, SelectField } from '../../../../../atoms/FormElement';
import { getExpenditureFormData } from '../../ducks/actions';
import { getContactFormData } from '../../../Contacts/ducks/actions';
import ContactBox from '../../../../../molecules/Dashboard/ContactBox';
import { EXPENDITURE_PURPOSE_CODE, FORMS_OF_PAYMENTS_Expenditure } from '../../../../../../configs/constants';

const { Title, Paragraph } = Typography;

export default (props) => {
  const { control, errors, formData, setValue, setLoading } = props;
  const dispatch = useDispatch();
  const [contactBoxEditCase, setContactBoxEditCase] = useState('');
  const [contactToSelect, setContactToSelect] = useState(null);
  const [purposeCode, setPurposeCode] = useState(false);
  const [formOfPayment, setFormOfPayment] = useState(null);
  const expenditureFormData = useSelector((state) => state.expenditure.expenditureFormData);
  const contributerData = useSelector((state) => state.contact.contactFormData);

  useEffect(() => {
    dispatch(getExpenditureFormData('general_contribution', 'null'));
  }, []);

  useEffect(() => {
    if (
      expenditureFormData?.expenditureData &&
      Object.keys(expenditureFormData)?.length > 0 &&
      Object.keys(expenditureFormData?.expenditureData)?.length > 0
    ) {
      setLoading(true);
      const selectedAccountList = expenditureFormData?.accounts?.find(
        (e) => e?.value == expenditureFormData?.expenditureData?.account_id,
      );
      const selectedPayeeList = expenditureFormData?.contributors?.find(
        (e) => e?.value == (contactToSelect ? contactToSelect : expenditureFormData?.expenditureData?.payee_id),
      );
      setValue('amount', expenditureFormData.expenditureData?.amount);
      setValue(
        'date',
        expenditureFormData?.expenditureData?.date
          ? moment(expenditureFormData?.expenditureData?.date, 'YYYY-MM-DD HH:mm')
          : '',
      );
      setValue('purpose_code', expenditureFormData.expenditureData?.purpose_code);
      setValue(
        'delaration_is_supporting',
        expenditureFormData.expenditureData?.delaration_is_supporting ? 'Support' : 'Oppose',
      );
      setValue('payment_type', expenditureFormData.expenditureData?.payment_type);
      setValue('payee_id', selectedPayeeList);
      setValue('candidate_full_name', expenditureFormData.expenditureData?.candidate_full_name);
      setValue('office_sought', expenditureFormData.expenditureData?.office_sought);
      setContactToSelect(null);
      setFormOfPayment(expenditureFormData?.expenditureData?.payment_type);
      setTimeout(() => {
        setContactBoxEditCase(selectedPayeeList);
        setValue('check_number', expenditureFormData.expenditureData?.check_number);
        setValue('account', selectedAccountList);
        setValue('remarks', expenditureFormData.expenditureData?.remarks);
      }, 1000);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [expenditureFormData, formData]);

  const changeValueByDropDown = (e) => {
    dispatch(getContactFormData(e));
  };

  const updateDropdownValues = () => {
    dispatch(getExpenditureFormData('general_contribution', 'null'));
  };

  const formPayment = (e) => {
    setFormOfPayment(e.target.value);

    if (expenditureFormData && expenditureFormData?.accounts?.length == 1) {
      setTimeout(() => {
        setValue('account', expenditureFormData?.accounts[0]);
      }, 500);
    }
  };

  return (
    <Row gutter={[48, 30]}>
      <Col span={24}>
        <Paragraph className="c-blue">
          In addition to the disclosure reports, independent expenditures should also be reported separately in{' '}
          <a
            href="https://s3.amazonaws.com/dl.ncsbe.gov/Campaign_Finance/Forms/cro2520/ecro2520.pdf"
            target="_blank"
            className="c-doger-blue"
          >
            CRO-2520.
          </a>
          <br />
          <a
            href="https://s3.amazonaws.com/dl.ncsbe.gov/Campaign_Finance/Forms/cro2520/cro2520instructions.pdf"
            target="_blank"
            className="c-doger-blue"
          >
            (Instructions).
          </a>
        </Paragraph>
      </Col>
      <Col span={12}>
        <Row gutter={[12, 30]}>
          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="amount"
              label="Amount"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                prefix: '$',
                type: 'number',
              }}
              rules={{
                required: 'Fair Market Amount is required.',
              }}
              validate={errors.amount && 'error'}
              validMessage={errors.amount && errors.amount.message}
            />
          </Col>

          <Col span={24}>
            <DateField
              fieldname="date"
              label="Date"
              control={control}
              class="mb-0"
              iProps={{ size: 'large' }}
              initValue=""
              isRequired={true}
              rules={{
                required: 'Date is required.',
              }}
              validate={errors.date && 'error'}
              validMessage={errors.date && errors.date.message}
            />
          </Col>

          <ContactBox
            control={control}
            errors={errors}
            selectData={expenditureFormData?.contributors}
            changeValueByDropDown={changeValueByDropDown}
            updateDropdownValues={updateDropdownValues}
            contributerData={contributerData}
            contactBoxEditCase={contactBoxEditCase}
            contactToSelect={contactToSelect}
            setContactToSelect={setContactToSelect}
            setValue={setValue}
            fieldname="payee_id"
            label="Payee"
          />

          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="purpose_code"
              label="Purpose"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Purpose is required.',
              }}
              validate={errors.purpose_code && 'error'}
              validMessage={errors.purpose_code && errors.purpose_code.message}
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputRadio
              fieldname="payment_type"
              label="Form of Payment"
              control={control}
              initValue={''}
              classField="custom-radio"
              onChange={(e) => formPayment(e)}
              options={
                <>
                  {FORMS_OF_PAYMENTS_Expenditure &&
                    FORMS_OF_PAYMENTS_Expenditure?.map((item, index) => (
                      <Fragment key={index}>
                        <Radio.Button value={item?.value}>
                          <Image src={item?.image} preview={false} width={80} />
                          <Paragraph className="smallFont12">{item?.label}</Paragraph>
                        </Radio.Button>
                      </Fragment>
                    ))}
                </>
              }
              rules={{
                required: 'Form of Payment is required.',
              }}
              validate={errors.payment_type && 'error'}
              validMessage={errors.payment_type && errors.payment_type.message}
            />
          </Col>

          {formOfPayment && (
            <Col span={24}>
              <Row gutter={16}>
                {formOfPayment == 'check' && (
                  <Col span={12}>
                    <InputField
                      fieldname="check_number"
                      label="Check Number"
                      control={control}
                      initValue=""
                      optionalLabel="(Optional)"
                      iProps={{
                        size: 'large',
                      }}
                    />
                  </Col>
                )}
                <Col span={formOfPayment == 'check' ? 12 : 24}>
                  <SelectField
                    isRequired={true}
                    fieldname="account"
                    label="Account"
                    control={control}
                    class="mb-0"
                    iProps={{ placeholder: 'Please select' }}
                    rules={{ required: 'Account is required.' }}
                    initValue=""
                    selectOption={expenditureFormData?.accounts}
                    validate={errors.account && 'error'}
                    validMessage={errors.account && errors.account.message}
                  />
                </Col>
              </Row>
            </Col>
          )}

          <Col span={24}>
            <Title level={3} className="text-center mb-0 mt-2">
              Candidate Information
            </Title>
          </Col>
          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="candidate_full_name"
              label="Full Name"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Full Name is required.',
              }}
              validate={errors.candidate_full_name && 'error'}
              validMessage={errors.candidate_full_name && errors.candidate_full_name.message}
            />
          </Col>
          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="office_sought"
              label="Office Sought"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Office Sought is required.',
              }}
              validate={errors.office_sought && 'error'}
              validMessage={errors.office_sought && errors.office_sought.message}
            />
          </Col>
          <Col span={24}>
            <InputRadio
              fieldname="delaration_is_supporting"
              label="Declaration"
              control={control}
              classField="tab-type-radio"
              onChange={(e) => {}}
              options={
                <>
                  <Row gutter={[20, 20]}>
                    <Col span={12}>
                      <Radio value="Support">Support</Radio>
                    </Col>
                    <Col span={12}>
                      <Radio value="Oppose">Oppose</Radio>
                    </Col>
                  </Row>
                </>
              }
              rules={{
                required: 'Please make a choice.',
              }}
              validate={errors.delaration_is_supporting && 'error'}
              validMessage={errors.delaration_is_supporting && errors.delaration_is_supporting.message}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
