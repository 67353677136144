import React, { useState } from 'react';
import { Space, Button, Row, Col, Typography, Image, Card, Table } from 'antd';
import LimitWarningIcon from '../../../assets/svg/report-status-icon.svg';
import InfoBoxIcon from '../../../assets/svg/info-box-img.svg';
import warningIcon from '../../../assets/svg/warning-icon.svg';
import AddExpenditureType from '../../modules/Dashboard/Expenditures/AddExpenditure/AddExpenditureType';
import AddReceiptsType from '../../modules/Dashboard/Receipts/AddReceipts/AddReceiptsType';
import { ConstantText } from './constantText';
//import {LimitWarning} from './limitWarning'

const { Title, Paragraph, Text } = Typography;

export default (props) => {
  const {
    onClose,
    title,
    errorHigh,
    errorLow,
    btnTxt,
    mediaWarning,
    cashTitle,
    updateApi,
    lowlimitParagraph,
    PpopUpNotDeleted,
    highlimitParagraph,
    receiptTypeText,
    highLimit,
    lowLimit,
    expenditureTypeText,
    onFinish,
    getdataValue,
    setForce,
    cashExpenditure,
    bottomText,
    popdata,
    tableText,
  } = props;

  let TitleText = '';
  let HighlimitParagraph = '';
  let LowlimitParagraph = '';
  let commonParagraph = '';
  let buttonText = '';
  let maxParagraph = '';

  // if (props.VisibleExpenditure) {
  //   TitleText = ConstantText.expenditureTitle;
  //   HighlimitParagraph = ConstantText.maxExpenditureLimit;
  //   LowlimitParagraph = ConstantText.minExpenditureLimit;
  //   commonParagraph = ConstantText.commonLimitParagraph;
  //   buttonText = ConstantText.expenditureButtonText;
  //   maxParagraph = ConstantText.maxLimitExpenditureParagraph;
  // }
  // if (props.visibleAddReceipt === true) {
  //   HighlimitParagraph = ConstantText.maxRecieptLimit;
  //   commonParagraph = ConstantText.commonLimitParagraph;

  //   if (receiptTypeText === 'loan_proceed') {
  //     maxParagraph = ConstantText.maxLimitLoanRecieptParagraph;
  //   } else {
  //     maxParagraph = ConstantText.maxLimitRecieptParagraph;
  //   }

  //   if (cashExpenditure) {
  //     TitleText = ConstantText.cashexpenditureTitle;
  //     buttonText = ConstantText.expenditureButtonText;
  //     LowlimitParagraph = ConstantText.cashExpenditureLimit;
  //   } else {
  //     TitleText = ConstantText.recieptTitle;
  //     LowlimitParagraph = ConstantText.minRecieptLimit;
  //     buttonText = ConstantText.recieptButtonText;
  //   }
  // }

  const listColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: 145,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text) => <span className="text-capitalize">{text ? text.replace(/_/g, ' ') : '-'}</span>,
    },
  ];

  return (
    <Row justify="space-between" align="middle" gutter={[20, 20]}>
      <Col span={24}>
        <Title level={2} className="text-center smallFont24 mt-2">
          {mediaWarning ? cashTitle : title}
        </Title>
      </Col>
      <Col span={24} className="text-center">
        <Image src={LimitWarningIcon} preview={false} width={136} />
      </Col>
      {highLimit && (
        <Col span={24}>
          <Card className="warning-card mb-0">
            <Space size={20}>
              <Image src={warningIcon} preview={false} width={24} />
              <div className="info_text">
                {' '}
                <Paragraph className="smallFont14 w-100 mb-0">{errorHigh}</Paragraph>
              </div>
            </Space>
          </Card>
        </Col>
      )}
      {lowLimit && (
        <Col span={24}>
          <Card className="warning-card mt-0">
            <Space size={24}>
              <Image src={warningIcon} preview={false} width={24} />
              <div className="info_text">
                {' '}
                <Paragraph className="smallFont14 w-100 mb-0">{errorLow}</Paragraph>
              </div>
            </Space>
          </Card>
        </Col>
      )}
      {mediaWarning && (
        <Col span={24}>
          <Card className="warning-card mt-0">
            <Space size={24}>
              <Image src={warningIcon} preview={false} width={24} />
              <div className="info_text">
                {' '}
                <Paragraph className="smallFont14 w-100 mb-0">Media expenditures may not be made in cash.</Paragraph>
              </div>
            </Space>
          </Card>
        </Col>
      )}

      <Col span={24}>
        {popdata && (
          <Paragraph className="smallFont14 w-100">
            The committee may receive $6,400 from a contributor between the beginning of the election cycle and the day
            of the primary, and another $6,400 from the same contributor beginning the day after the primary through the
            end of the election year.
            <br />
            <br />
            {bottomText}
          </Paragraph>
        )}
        {highLimit && !popdata && (
          <Paragraph>
            The committee may receive $6,400 from a contributor between the beginning of the election cycle and the day
            of the primary, and another $6,400 from the same contributor beginning the day after the primary through the
            end of the election year.
          </Paragraph>
        )}

        {(mediaWarning || lowLimit) && <Paragraph>Are you sure you want to proceed?</Paragraph>}
      </Col>

      {popdata && (
        <>
          <Col span={24}>
            <Title level={4} className="text-center font-gilroy RegularFont mt-0 mb-1">
              {tableText}
            </Title>
            <div className="remove_margin">
              <Table columns={listColumns} dataSource={popdata} pagination={false} className="reports_popup" />
            </div>
          </Col>
        </>
      )}

      <Col span={24}>
        <Row justify="center" gutter={[20, 20]}>
          <Col>
            <Button type="primary" htmlType="submit" className="font-gilroy white-btn" size="large" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              className="font-gilroy"
              size="large"
              onClick={() => {
                onFinish(getdataValue, true);
              }}
            >
              {btnTxt}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
