import React from 'react';

export const ConstantText = {
  // Reciept
  recieptTitle: 'Contribution Limit Warning',
  minRecieptLimit: <>Committee may not accept cash contributions in excess of $50.</>,
  maxRecieptLimit: (
    <>
      Contributions received from individuals, candidate or political action commettees cannot exceed <b>$6,400</b>.
    </>
  ),
  minLimitRecieptParagraph: '',
  //maxLimitRecieptParagraph : {<>The committee may receive $6,400 from a contributor between the beginning of the election cycle and the day of the primary, and another $6,400 from the same contributor beginning the day after the primary through the end of the election year.<br/><br/>If the current receipt amount doesn’t exceed $6,400, please check all the receipts from this contributor.<br/><br/></>}
  maxLimitRecieptParagraph: (
    <>
      The committee may receive $6,400 from a contributor between the beginning of the election cycle and the day of the
      primary, and another $6,400 from the same contributor beginning the day after the primary through the end of the
      election year. <br />
      <br />
      If the current receipt amount doesn’t exceed $6,400, please check all the receipts from this contributor.
    </>
  ),
  maxLimitLoanRecieptParagraph: (
    <>
      The committee may receive $6,400 from a contributor (or lender, or endorser) between the beginning of the election
      cycle and the day of the primary, and another $6,400 from the same contributor beginning the day after the primary
      through the end of the election year.
      <br />
      <br /> If the lender has previously made a contribution, the loan amount is added to the previous contribution.
      The same rule applies to the endorser(s) on this loan. <br />
      <br />
      If the current loan amount doesn’t exceed $6,400, please check all the receipts from this lender and the loan
      endorser(s).
    </>
  ),

  recieptButtonText: 'Save receipt anyway',

  // Expenditure
  expenditureTitle: 'Expenditure Limit Warning',
  minExpenditureLimit: <>Non-media expenditures of more than $50, except postage, may not be made in cash.</>,
  maxExpenditureLimit: (
    <>
      Contributions made to other candidate or political action commettees cannot exceed <b>$6,400</b>.
    </>
  ),
  minLimitExpenditureParagraph: '',
  maxLimitExpenditureParagraph: (
    <>
      "The committee may receive $6,400 from a contributor between the beginning of the election cycle and the day of
      the primary, and another $6,400 from the same contributor beginning the day after the primary through the end of
      the election year. <br />
      <br />
      If the current contribution doesn’t exceed $6,400, please check all the contributions you have made to this
      committee."
    </>
  ),

  cashexpenditureTitle: 'Cash Expenditure Warning',
  cashExpenditureLimit: 'Media expenditures may not be made in cash.',

  expenditureButtonText: 'Save Expenditure anyway',

  commonLimitParagraph: 'Are you sure you want to proceed?',
};
