import React from 'react';
import Icon from '@ant-design/icons';

const Debts = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8732 2.92988C14.1826 2.80885 14.5346 2.90568 14.7386 3.16786L17.5186 6.74219H18.8691C19.7575 6.74219 20.4777 7.4624 20.4777 8.35082V10.6047H18.9777V8.35082C18.9777 8.29082 18.9291 8.24219 18.8691 8.24219H17.1595C17.1547 8.24223 17.1499 8.24223 17.1451 8.24219H4.27221C4.21221 8.24219 4.16357 8.29082 4.16357 8.35082V19.5131C4.16357 19.5731 4.21221 19.6218 4.27221 19.6218H18.8691C18.9291 19.6218 18.9777 19.5731 18.9777 19.5131V16.8286H17.7958C16.0775 16.8286 14.6845 15.4356 14.6845 13.7173C14.6845 11.999 16.0775 10.6061 17.7958 10.6061H20.5863C21.0005 10.6061 21.3363 10.9418 21.3363 11.3561V16.0786C21.3363 16.4928 21.0005 16.8286 20.5863 16.8286H20.4777V19.5131C20.4777 20.4015 19.7575 21.1218 18.8691 21.1218H4.27221C3.38378 21.1218 2.66357 20.4015 2.66357 19.5131V8.35082C2.66357 7.51712 3.29779 6.83155 4.11007 6.75026L13.8732 2.92988ZM8.24706 6.74219H15.6183L13.8983 4.53081L8.24706 6.74219ZM17.7958 12.1061C16.9059 12.1061 16.1845 12.8274 16.1845 13.7173C16.1845 14.6072 16.9059 15.3286 17.7958 15.3286H19.8363V12.1061H17.7958Z"
    />
  </svg>
);

const DebtsIcon = (props) => <Icon component={Debts} {...props} />;
export default DebtsIcon;
