import axios from '../../../../../services/axiosInterceptor';
import * as action_types from './constants';
import { baseUrl } from '../../../../../configs/constants';
import { DOWNLOAD_EXPENDITURE_CSV } from '../../../../../configs/endpoints';
import { downloadFile, saveFile } from '../../../../../services/crudApis';
import { message } from 'antd';

export const getobligationByListingBY = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/obligationByListing/BY`);
    dispatch({
      type: action_types.OBLIGATION_BY_LISTING,
      data: data,
    });
  };
};

export const getobligationByListingTO = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/obligationByListing/TO`);
    dispatch({
      type: action_types.OBLIGATION_TO_LISTING,
      data: data,
    });
  };
};

export const getObligationById = (id) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getObligationById/${id}`);
    dispatch({
      type: action_types.OBLIGATION_BY_ID,
      data: data,
    });
  };
};


export const setFiltersOptionsDebts = (type, value) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS_DEBTS,
      payload: { filterDebts: { [type]: value } },
    })
  }
}

export const setFiltersOptionsDebtsTo = (type, value) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS_DEBTS_TO,
      payload: { filterDebtsTo: { [type]: value } },
    })
  }
}

export const clearDebtsFilters = () => ({
  type: action_types.CLEAR_DEBTS_FILTER,
})

export const clearDebtsFiltersTo = () => ({
  type: action_types.CLEAR_DEBTS_FILTER_TO,
})