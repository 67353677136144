import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Layout } from 'antd';
import LoginSidebar from '../../molecules/Login/Sidebar';
import LoginHeader from '../../molecules/Login/Header';

const { Title, Text } = Typography;

export default (props) => {
  return (
    <Layout className="login-layout">
      <LoginHeader />
      <Row align="middle" justify="center">
        <Col span={16}>
          <Row align="middle" justify="center">
            <Col flex="0 0 420px" style={{ overflow: 'hidden', padding: '0 10px' }}>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <Title level={1} className="text-center mb-0 font-gilroy">
                    404
                  </Title>
                </Col>
                <Col span={24}>
                  <Title level={3} className="text-center mb-0">
                    Page Not found
                  </Title>
                </Col>
                <Col span={24} className="text-center">
                  <Link to="/dashboard">Go to Dashboard</Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <LoginSidebar />
        </Col>
      </Row>
    </Layout>
  );
};
