import * as action_types from './constants';

const initialState = {
  receiptFormData: {},
  unimportReceiptFormData: {},
  receiptDropData: {},
  receiptListData: [],
  receiptUnimportedListData: [],
  bankAccountsList: [],
  getExemptPlan: [],
  editExemptPlan: [],
  filter: {
    search: undefined,
    date: undefined,
    amount: undefined,
  },
  filterImport: {
    showMandatory: undefined,
    showDublicative: undefined,
  },
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.RECEIPT_FORM_DATA:
      return { ...state, receiptFormData: data };

    case action_types.UNIMPORT_RECEIPT_FORM_DATA:
      return { ...state, unimportReceiptFormData: data };

    case action_types.RECEIPT_UNIMPORTED_LIST_DATA:
      return { ...state, receiptUnimportedListData: data };

    case action_types.RECEIPT_DROP_DATA:
      return { ...state, receiptDropData: data };

    case action_types.RECEIPT_LIST_DATA:
      return { ...state, receiptListData: data };

    case action_types.BANK_ACCOUNTS_LIST:
      return { ...state, bankAccountsList: data };

    case action_types.GET_EXEMPT_PLAN:
      return { ...state, getExemptPlan: data };

    case action_types.EDIT_EXEMPT_PLAN:
      return { ...state, editExemptPlan: data };

    case action_types.SET_FILTERS_OPTIONS:
      return { ...state, filter: { ...state.filter, ...action.payload.filter } };

    case action_types.SET_FILTERS_OPTIONS_IMPORT:
      return { ...state, filterImport: { ...state.filterImport, ...action.payload.filterImport } };

    case action_types.CLEAR_RECEIPT_FILTER:
      return {
        ...state,
        filter: {
          search: undefined,
          date: undefined,
          amount: undefined,
        },
      };

    default:
      return state;
  }
};
