import React, { Fragment, useState } from 'react';
import { Row, Col, Card, Typography, Select, Form, Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useFieldArray } from 'react-hook-form';
import Dashboard from '../../../../../pages/Dashboard';
import GeneralContribution from '../../ReceiptsForms/GeneralContribution';
import Interest from '../../ReceiptsForms/Interest';
import ReimbursementsCommittee from '../../ReceiptsForms/ReimbursementsCommittee';
import ContributionReimbursed from '../../ReceiptsForms/ContributionReimbursed';
import { addReceipts } from '../../../../../../services/receipt';
import OutsideSourceofIncome from '../../ReceiptsForms/OutsideSourceofIncome';
import ExemptPurchasePrice from '../../ReceiptsForms/ExemptPurchasePrice';
import ForgivenLoan from '../../ReceiptsForms/ForgivenLoan';
import LoanProceed from '../../ReceiptsForms/LoanProceed';
import { Popup } from '../../../../../atoms/Popup';
import LearnMore from '../../../../../molecules/ExemptPurchase/LearnMore';
import LimitWarning from '../../../../../molecules/LimitWarning';
import DebtRepayment from '../../ReceiptsForms/DebtRepayment';
import Confirmation from '../../../Expenditures/Popup/Confirmation';

const { Paragraph } = Typography;
const { Option } = Select;

const receiptTypeList = [
  { value: 'general_contribution', label: 'General Contribution' },
  { value: 'contribution_to_be_reimbursed', label: 'Contribution to be Reimbursed' },
  { value: 'refund_reimbursement_to_the_committee', label: 'Refund / Reimbursement to the Committee' },
  { value: 'interest', label: 'Interest' },
  { value: 'outside_source_of_income', label: 'Outside Source of Income' },
  { value: 'loan_proceed', label: 'Loan Proceed' },
  { label: 'Debt Repayment', value: 'debt_repayment' },
  { value: 'forgiven_loan', label: 'Forgiven Loan' },
  { value: 'exempt_purchase_price_sale', label: 'Exempt Purchase Price Sale' },
];

export default (props) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, watch, reset, errors, setError, clearErrors } = useForm({
    defaultValues: {
      breakdown: [{ name: 'useFieldArray' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'breakdown',
  });
  const [learnMorePopup, setLearnMorePopup] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  const [deletedIndexes, setDeletedIndexes] = useState([]);
  let selectValue = url.split('/')[2].replace(/-/g, '_');
  const [receiptType, setReceiptType] = useState(selectValue);
  const [visibleAddReceipt, setVisibleAddReceipt] = useState(false);
  const [confirmation, setIsConfirmation] = useState(false);
  const [oldAmount, setOldAmount] = useState(0);
  const [highLimit, setHighlimit] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(null);
  const [lowLimit, setLowlimit] = useState(false);
  const [getdataValue, setdataValue] = useState('');
  const [popdata, setPopData] = useState();
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [formOfPayment, setFormOfPayment] = useState('');
  const [breakDown, setBreakDown] = useState('No');

  const limitWarningPopup = {
    // closable: true,
    visibility: visibleAddReceipt,
    class: 'black-modal',
    content: (
      <LimitWarning
        title="Contribution Limit Warning"
        errorHigh="Contributions received from individuals, candidate or political action commettees cannot exceed $6,400."
        errorLow="Committee may not accept cash contributions in excess of $50."
        btnTxt="Save receipt anyway"
        bottomText="If the current receipt amount doesn't exceed $6,400, please check all the receipts from this contributor."
        tableText="Receipts That Counted Toward the Limit"
        visibleAddReceipt={visibleAddReceipt}
        onClose={() => setVisibleAddReceipt(false)}
        highLimit={highLimit}
        lowLimit={lowLimit}
        onFinish={() => onFinish(getdataValue, true)}
        getdataValue={getdataValue}
        popdata={popdata}
      />
    ),
    width: 542,
    onCancel: () => setVisibleAddReceipt(false),
  };

  const handleChange = (value) => {
    setLoading(true);
    setBreakDown('No');
    setTimeout(() => setLoading(false), 1000);
    setTimeout(() => {
      setReceiptType(value);
      history.push(value);
    }, 1200);
  };

  const ConfirmationPopup = {
    closable: false,
    visibility: confirmation,
    class: 'black-modal confirmation_modal',
    content: (
      <Confirmation
        onClose={() => setIsConfirmation(false)}
        title="Confirmation Of Full Debt Repayment"
        onConfirm={() => onFinish(getdataValue, true, true)}
        paragraph={
          <>
            The entered amount fully covers the remaining debt outstanding balance. The debt will be marked as{' '}
            <b>Paid.</b>
            <br />
            <br />
            Please confirm if you would like to proceed.
          </>
        }
      />
    ),
    width: 542,
    onCancel: () => setIsConfirmation(false),
  };

  const onFinish = (values, force = false, confirmPopup = false) => {
    if (confirmPopup) {
      setIsConfirmation(false);
    }
    const mainAmount = oldAmount + remainingAmount;
    setdataValue(values);
    if (Math.round((+values?.amount + Number.EPSILON) * 100) / 100 > mainAmount && receiptType == 'debt_repayment') {
      setError('amount', {
        type: 'required',
        message: 'Please enter an amount that does not exceed the remaining debt balance.',
      });
      return;
    }

    if (+values?.amount === 0 && receiptType == 'debt_repayment') {
      setError('amount', {
        type: 'required',
        message: 'The Amount Repaid cannot be 0.',
      });
      return;
    }

    if (
      Math.round((+values?.amount + Number.EPSILON) * 100) / 100 === mainAmount &&
      !confirmPopup &&
      receiptType == 'debt_repayment'
    ) {
      setIsConfirmation(true);
      return;
    }

    setLoading(true);
    let endorsers = [];
    values?.endorser?.map((en) => {
      endorsers.push({
        endorser_id: en?.endorser_id,
        is_amount: en?.is_amount == 'amount' ? true : false,
        amount: en?.amount ?? '',
        contact_id: en?.contact_id?.value ?? '',
      });
    });
    const data = {
      type: receiptType,
      payment_type: values?.formOfPayment ? values?.formOfPayment : '',
      date: values?.date ? values?.date.format('YYYY-MM-DD HH:mm:ss') + 'Z' : moment(),
      amount: values?.amount ? parseFloat(values?.amount) : '',
      in_kind_description: values?.in_kind_description ? values?.in_kind_description : '',
      comments: values?.comments ? values?.comments : '',
      report_id: '',
      credit_used: values?.credit_used && values?.credit_used == 'Yes' ? true : false,
      reimbursed_expenditure_id: values?.reimbursed_expenditure_id ? values?.reimbursed_expenditure_id?.value : '',
      remimbursement_purpose: values?.remimbursement_purpose ? values?.remimbursement_purpose : '',
      financial_institution_id: values?.financial_institution_id ? values?.financial_institution_id?.value : '',
      created_date: moment().format('YYYY-MM-DD HH:mm:ss') + 'Z',
      modfied_date: moment().format('YYYY-MM-DD HH:mm:ss') + 'Z',
      contributor_id: values?.contributor ? values?.contributor?.value : '',
      payee_id: values?.payee ? values?.payee.value : '',
      reimbursee_id: values?.reimbursee ? values?.reimbursee.value : '',
      account_id: values?.account ? values?.account?.value : '',
      outside_source_explaination: values?.outside_source_explaination ?? '',
      check_number: values?.check_number ?? '',
      total_exceded_comment: values?.total_exceded_comment ? values?.total_exceded_comment : '',
      force: force === true,
      obligation_id: values?.obligation_id?.value ?? undefined,
    };
    if (values?.item_sold) {
      data.item_sold = parseInt(values?.item_sold);
    }

    if (values?.sale_per_sbe) {
      data.sale_per_sbe = values?.sale_per_sbe && values?.sale_per_sbe == 'Yes' ? true : false;
    }

    if (values?.total_purchase_exceeded) {
      data.total_purchase_exceeded =
        values?.total_purchase_exceeded && values?.total_purchase_exceeded == 'Yes' ? true : false;
    }

    let url;
    if (receiptType === 'loan_proceed') {
      data.start_date = values?.start_date ?? '';
      values?.end_date ? (data.end_date = values.end_date) : null;
      data.rate = values?.rate ?? '';
      data.security_pledged = values?.security_pledged ?? '';
      data.loan_number = values?.loan_number ?? '';
      data.contributor_id = values?.contributor_id?.value ?? '';
      data.lending_institution_id = values?.lending_institution?.value ?? '';
      data.endorsers = endorsers;
      data.deleted_endorsers = deletedIndexes;

      url = '/updateReceiptEndorsement';
    } else if (receiptType === 'forgiven_loan') {
      data.amount = values?.amount ?? '';
      data.loan_id = values?.loan_forgiven?.value ?? '';
    } else if (receiptType === 'exempt_purchase_price_sale') {
      let itemsArray = [];
      values?.item_sold?.map((en) => {
        itemsArray.push({
          item_id: en?.item_id?.value ?? '',
          qty: en?.qty ?? '',
        });
      });

      data.exempt_plan_id = values?.exempt_plan_id ? values?.exempt_plan_id?.value : '';
      data.item_sold = itemsArray;
    }

    let newData = [];
    if (values?.breakdown?.length > 0 && values?.delaration_is_supporting === 'Yes') {
      let dataArray = [];
      values?.breakdown?.map((e) => {
        const newObject = { ...data };
        newObject.check_number = e?.check_number ?? '';
        newObject.amount = e?.amount ? parseFloat(e?.amount) : '';

        dataArray.push(newObject);
        return dataArray;
      });
      newData = dataArray;
    } else if (receiptType === 'loan_proceed' || receiptType === 'loan-proceed') {
      newData = data;
    } else {
      newData = [data];
    }

    addReceipts(newData, url)
      .then(() => {
        setTimeout(() => {
          message.success('Receipt created.');
        }, 800);
        setTimeout(() => {
          history.push('/receipts');
        }, 1200);
      })
      .catch((e) => {
        setLoading(false);
        if (e && e?.response && e?.response['status'] == '412') {
          const message = JSON.parse(e?.response?.data?.error?.message);
          setHighlimit(false);
          setPopData();
          setLowlimit(false);

          if (message?.listOfEntities?.length > 0) {
            setHighlimit(true);
            setPopData(message?.listOfEntities);
          }

          if (message?.errors?.CONTRIBUTION_LIMIT_REACHED) {
            setHighlimit(true);
          }

          if (message?.errors?.CASH_FIFTY) {
            setLowlimit(true);
          }

          setVisibleAddReceipt(true);
        } else message.error('Something went wrong');
      });
  };

  const learnMore = {
    closable: false,
    visibility: learnMorePopup,
    class: 'black-modal',
    content: <LearnMore onClose={() => setLearnMorePopup(false)} />,
    width: 450,
    onCancel: () => setLearnMorePopup(false),
  };

  return (
    <>
      <Dashboard load={loading} heading="New Receipt" backLink="/receipts">
        <Card className="white-card w-100">
          <Row justify="center" gutter={[20, 20]}>
            <Col span={24}>
              <Select defaultValue={receiptType} onChange={handleChange} className="custom-ant-select">
                {receiptTypeList &&
                  receiptTypeList?.map((item, index) => (
                    <Fragment key={index}>
                      <Option value={item?.value}>{item?.label}</Option>
                    </Fragment>
                  ))}
              </Select>
            </Col>

            {receiptType == 'general_contribution' && (
              <Col span={24}>
                <Paragraph>
                  <b>Contribution limits note:</b>
                </Paragraph>
                <ol style={{ paddingLeft: '15px' }}>
                  <li>
                    For candidate committees and auxiliary committees, e.g., men’s groups, women’s groups, young party
                    clubs, and others, contributions received from individuals, candidate committees, or political
                    action committees cannot exceed $6,400 per contributor per election.{' '}
                    <a href="https://www.ncsbe.gov/campaign-finance/candidate-committees" target="_blank">
                      Learn More
                    </a>
                  </li>
                  <li>Committee may not accept cash contributions in excess of $50.</li>
                </ol>
              </Col>
            )}

            {receiptType == 'contribution_to_be_reimbursed' && (
              <Col span={24}>
                <Paragraph className="c-blue">
                  Use this form to report in-kind contributions of $1,000 or less where there was an agreement between
                  the committee and the contributor that they would be reimbursed for the money spent on the committee's
                  behalf. These in-kind contributions may not exceed $1,000, the committee must be notified with 45 days
                  that money was spent on the committee's behalf, and the refund/reimbursement to the contributor must
                  be made within 7 days (of the notification).
                </Paragraph>
                <Paragraph className="c-blue">
                  The reimbursement must also be disclosed on the Refunds/Reimbursements from the Committee form.
                </Paragraph>
              </Col>
            )}

            {receiptType == 'refund_reimbursement_to_the_committee' && (
              <Col span={24}>
                <Paragraph className="c-blue">
                  Use this form to report a refund or reimbursement for a previous expenditure.
                </Paragraph>
              </Col>
            )}

            {receiptType == 'exempt_purchase_price_sale' && (
              <Col span={24}>
                <Paragraph className="c-blue">
                  Political Party Executive Committees use this form to report purchases from an approved Exempt Sales
                  Plan.{' '}
                  <Button
                    type="link"
                    className="c-doger-blue p-0 text-capitalize"
                    onClick={() => setLearnMorePopup(true)}
                  >
                    Learn more.
                  </Button>
                </Paragraph>
              </Col>
            )}
          </Row>

          <Form className="w-100 mt-2" layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            {receiptType == 'general_contribution' && (
              <GeneralContribution
                control={control}
                errors={errors}
                setValue={setValue}
                setLoading={setLoading}
                fields={fields}
                append={append}
                remove={remove}
                watch={watch}
                setError={setError}
                clearErrors={clearErrors}
                remainingBalance={remainingBalance}
                setRemainingBalance={setRemainingBalance}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
                breakDown={breakDown}
                setBreakDown={setBreakDown}
              />
            )}

            {receiptType == 'debt_repayment' && (
              <DebtRepayment
                control={control}
                errors={errors}
                setValue={setValue}
                setLoading={setLoading}
                setRemainingAmount={setRemainingAmount}
                oldAmount={oldAmount}
                setOldAmount={setOldAmount}
                setError={setError}
                clearErrors={clearErrors}
                watch={watch}
                remainingAmount={remainingAmount}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
              />
            )}

            {receiptType == 'contribution_to_be_reimbursed' && (
              <ContributionReimbursed control={control} errors={errors} setValue={setValue} setLoading={setLoading} />
            )}

            {receiptType == 'refund_reimbursement_to_the_committee' && (
              <ReimbursementsCommittee
                control={control}
                errors={errors}
                setValue={setValue}
                setLoading={setLoading}
                fields={fields}
                append={append}
                remove={remove}
                watch={watch}
                setError={setError}
                clearErrors={clearErrors}
                remainingBalance={remainingBalance}
                setRemainingBalance={setRemainingBalance}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
                breakDown={breakDown}
                setBreakDown={setBreakDown}
              />
            )}

            {receiptType == 'interest' && (
              <Interest
                control={control}
                errors={errors}
                setValue={setValue}
                setLoading={setLoading}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
              />
            )}

            {receiptType == 'outside_source_of_income' && (
              <OutsideSourceofIncome
                control={control}
                errors={errors}
                setValue={setValue}
                setLoading={setLoading}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
              />
            )}

            {receiptType == 'exempt_purchase_price_sale' && (
              <ExemptPurchasePrice
                control={control}
                errors={errors}
                setValue={setValue}
                setLoading={setLoading}
                deletedIndexes={deletedIndexes}
                setDeletedIndexes={setDeletedIndexes}
              />
            )}

            {receiptType == 'forgiven_loan' && (
              <ForgivenLoan control={control} errors={errors} setValue={setValue} setLoading={setLoading} />
            )}

            {receiptType == 'loan_proceed' && (
              <LoanProceed
                control={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
                setLoading={setLoading}
                deletedIndexes={deletedIndexes}
                setDeletedIndexes={setDeletedIndexes}
                formOfPayment={formOfPayment}
                setFormOfPayment={setFormOfPayment}
              />
            )}
            <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
              <Col>
                <Button
                  onClick={() => history.push('/receipts')}
                  htmlType="button"
                  className="font-gilroy white-btn"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="font-gilroy"
                  size="large"
                  disabled={
                    formOfPayment === 'check' && breakDown === 'Yes' && (remainingBalance < 0 || remainingBalance > 0)
                  }
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Dashboard>
      <Popup {...learnMore} />
      <Popup {...limitWarningPopup} />
      <Popup {...ConfirmationPopup} />
    </>
  );
};
