import React, { useEffect, useState } from 'react';
import { Steps, Card } from 'antd';
import { useLocation } from 'react-router';
import Dashboard from '../../../../pages/Dashboard';
import {
  OrganizationalDetail,
  Expenditures,
  FileReport,
  Other,
  Receipts,
  QuarterDetail,
  HourDetail,
} from '../AddReports/StepsForm';
import { GET_REPORT_LISTING } from '../../../../../configs/endpoints';
import { getObject } from '../../../../../services/crudApis';
import moment from 'moment';

const { Step } = Steps;

export default (props) => {
  const location = useLocation();
  const url = location.pathname.split('/')[2];
  const locationID = location.pathname.split('/')[3];
  const heading = url.replace(/-/g, ' ');

  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [startDate, setstartDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [reportId, setreportId] = useState(locationID);
  const [status, setStatus] = useState('not ready');
  const currentStatus = location?.state?.status;
  let geturl = new URL(window.location.href);
  let searchParams = new URLSearchParams(geturl.search);
  let getYear = searchParams.get('year');
  const reportCase = searchParams.get('case');
  const federalPAC = localStorage.getItem('organization');
  //const activeTab = location?.state?.payFileReport ? setCurrent(3) : setCurrent(0);

  useEffect(() => {
    // setLoading(true)
    if (reportId && reportId.length > 0) {
      getObject(GET_REPORT_LISTING + '/' + reportId)
        .then(async (response) => {
          const startDate = moment(response?.data?.start_date).format('YYYY-MM-DD');
          const endDate = moment(response?.data?.end_date).format('YYYY-MM-DD');
          setendDate(endDate);
          setstartDate(startDate);
          // setLoading(false);
          //console.log('startDate', startDate, endDate);
        })
        .catch((e) => {
          // setLoading(false);
          message.error('Something went wrong');
        });
    }
  }, [reportId]);

  //console.log('startDate', startDate, endDate);

  let steps = [];

  {
    url == 'organizational' || url == 'organizational-amendment'
      ? (steps =
          federalPAC !== 'federal'
            ? [
                {
                  title: 'Details',
                  content: (
                    <OrganizationalDetail
                      getYear={getYear}
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      setstartDate={(date) => {
                        setstartDate(date);
                      }}
                      setendDate={(date) => {
                        setendDate(date);
                      }}
                      setReportId={(Id) => {
                        setreportId(Id);
                      }}
                    />
                  ),
                },
                {
                  title: 'Receipts',
                  content: (
                    <Receipts
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                },
                {
                  title: 'Expenditures',
                  content: (
                    <Expenditures
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                      reportId={reportId}
                    />
                  ),
                },
                {
                  title: 'Other',
                  content: (
                    <Other
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                      reportId={reportId}
                    />
                  ),
                },
                {
                  title: reportCase === '5' ? 'Preview Report' : 'File Report',
                  content: (
                    <FileReport
                      reportId={reportId}
                      setStatus={setStatus}
                      status={status}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                },
              ]
            : [
                {
                  title: 'Details',
                  content: (
                    <OrganizationalDetail
                      getYear={getYear}
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      setstartDate={(date) => {
                        setstartDate(date);
                      }}
                      setendDate={(date) => {
                        setendDate(date);
                      }}
                      setReportId={(Id) => {
                        setreportId(Id);
                      }}
                    />
                  ),
                },
                {
                  title: 'Expenditures',
                  content: (
                    <Expenditures
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                      reportId={reportId}
                    />
                  ),
                },
                {
                  title: reportCase === '5' ? 'Preview Report' : 'File Report',
                  content: (
                    <FileReport
                      reportId={reportId}
                      setStatus={setStatus}
                      status={status}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                },
              ])
      : null;

    url == 'mid-year-semiannual' ||
    url == 'year-end-semiannual' ||
    url == 'first-quarter-plus' ||
    url == 'second-quarter' ||
    url == 'secord-quarter' ||
    url == 'third-quarter-plus' ||
    url == 'fourth-quarter' ||
    url == 'mid-year-semiannual-amendment' ||
    url == 'year-end-semiannual-amendment' ||
    url == 'first-quarter-plus-amendment' ||
    url == 'second-quarter-amendment' ||
    url == 'secord-quarter-amendment' ||
    url == 'third-quarter-plus-amendment' ||
    url == 'fourth-quarter-amendment'
      ? (steps =
          federalPAC !== 'federal'
            ? [
                {
                  title: 'Details',
                  content: (
                    <QuarterDetail
                      currentStep={current}
                      getYear={getYear}
                      setCurrentStep={setCurrent}
                      type={url}
                      setstartDate={(date) => {
                        setstartDate(date);
                      }}
                      setendDate={(date) => {
                        setendDate(date);
                      }}
                      setReportId={(Id) => {
                        setreportId(Id);
                      }}
                    />
                  ),
                },
                {
                  title: 'Receipts',
                  content: (
                    <Receipts
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                },
                {
                  title: 'Expenditures',
                  content: (
                    <Expenditures
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                      reportId={reportId}
                    />
                  ),
                },
                {
                  title: 'Other',
                  content: (
                    <Other
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                      reportId={reportId}
                    />
                  ),
                },
                {
                  title: reportCase === '5' ? 'Preview Report' : 'File Report',
                  content: (
                    <FileReport
                      reportId={reportId}
                      setStatus={setStatus}
                      status={status}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                },
              ]
            : [
                {
                  title: 'Details',
                  content: (
                    <QuarterDetail
                      getYear={getYear}
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      type={url}
                      setstartDate={(date) => {
                        setstartDate(date);
                      }}
                      setendDate={(date) => {
                        setendDate(date);
                      }}
                      setReportId={(Id) => {
                        setreportId(Id);
                      }}
                    />
                  ),
                },
                {
                  title: 'Expenditures',
                  content: (
                    <Expenditures
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                      reportId={reportId}
                    />
                  ),
                },
                {
                  title: reportCase === '5' ? 'Preview Report' : 'File Report',
                  content: (
                    <FileReport
                      reportId={reportId}
                      setStatus={setStatus}
                      status={status}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                },
              ])
      : null;

    url == '48-hour-notice' || url == '48-hour-notice-amendment'
      ? (steps =
          federalPAC !== 'federal'
            ? [
                {
                  title: 'Details',
                  content: (
                    <HourDetail
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      getYear={getYear}
                      setstartDate={(date) => {
                        setstartDate(date);
                      }}
                      setendDate={(date) => {
                        setendDate(date);
                      }}
                      setReportId={(Id) => {
                        setreportId(Id);
                      }}
                    />
                  ),
                },
                {
                  title: 'Receipts',
                  content: (
                    <Receipts
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      reportId={reportId}
                      isFor48HourReport={true}
                    />
                  ),
                },
                {
                  title: reportCase === '5' ? 'Preview Report' : 'File Report',
                  content: (
                    <FileReport
                      reportId={reportId}
                      setStatus={setStatus}
                      status={status}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                },
              ]
            : [
                {
                  title: 'Details',
                  content: (
                    <HourDetail
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      getYear={getYear}
                      setstartDate={(date) => {
                        setstartDate(date);
                      }}
                      setendDate={(date) => {
                        setendDate(date);
                      }}
                      setReportId={(Id) => {
                        setreportId(Id);
                      }}
                    />
                  ),
                },
                {
                  title: reportCase === '5' ? 'Preview Report' : 'File Report',
                  content: (
                    <FileReport
                      reportId={reportId}
                      setStatus={setStatus}
                      status={status}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                },
              ])
      : null;
  }

  useEffect(() => {
    //console.log('steps?.length ', location?.state?.payFileReport);
    setCurrent(location?.state?.payFileReport ? steps?.length - 1 : 0);
  }, [location]);

  const onChange = (value) => {
    if (currentStatus == 'ready' || currentStatus == 'sent' || currentStatus == 'accepted') {
      setCurrent(value);
    }
  };

  return (
    <Dashboard load={loading} heading={heading} backLink="/reports">
      <Card className="white-card w-100 pcard-0 steps-reports">
        <Steps current={current} onChange={onChange}>
          {steps.map((item, i) => (
            <React.Fragment key={i}>
              <Step key={item?.title} title={item?.title} />
            </React.Fragment>
          ))}
        </Steps>
        <div className="steps-content">{steps[current]?.content}</div>
      </Card>
    </Dashboard>
  );
};
