import React, { useState, useEffect } from 'react';
import { Spin, Button, Row, Col, Typography, Image, Space, Card, Table, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import crossIcon from '../../../assets/svg/cross-icon.svg';
import infoIcon from '../../../assets/svg/info-icon.svg';
import { addObject } from '../../../services/crudApis';

const { Title, Paragraph, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { onClose, title, exemptData } = props;
  const [loading, setLoading] = useState(false);

  const listColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Amount Raised',
      dataIndex: 'amount',
    },
  ];

  const reportListData = [
    {
      key: 0,
      date: '07-30-2022',
      amount_raised: '$10.50',
    },
  ];

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row gutter={[20, 20]} justify="space-between" align="middle">
        <Col>
          <Title level={4} className="mb-0">
            {title}
          </Title>
        </Col>
        <Col>
          <Button size="large" type="link" className="p-0" onClick={onClose}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>
        <Col span={24}>
          <Paragraph>
            Some transactions refer to this plan. In order to proceed with the deletion, you need to delete them.
          </Paragraph>
        </Col>

        <Col span={24}>
          <Title level={4} className="text-center font-gilroy RegularFont mt-2 mb-1">
            Transactions That Refer to This Plan
          </Title>
          <div className="remove_margin">
            <Table columns={listColumns} dataSource={exemptData} pagination={false} className="reports_popup" />
          </div>
        </Col>

        <Col span={24}>
          <Row align="middle" justify="center" className="mt-1" gutter={[20, 20]}>
            <Col>
              <Button type="secondary" onClick={onClose} htmlType="button" className="font-gilroy" size="large">
                Understood
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};
