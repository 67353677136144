import React from 'react';
import Icon from '@ant-design/icons';

const Reports = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke="#fff"
      d="M5 6V17C5 18.6569 6.34315 20 8 20H16C17.6569 20 19 18.6569 19 17V9.24264C19 8.44699 18.6839 7.68393 18.1213 7.12132L14.8787 3.87868C14.3161 3.31607 13.553 3 12.7574 3H8C6.34315 3 5 4.34315 5 6Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="#fff"
      d="M13 3.5V7C13 8.10457 13.8954 9 15 9H18.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path stroke="#fff" d="M9 16H13" strokeWidth="1.5" strokeLinecap="round" />
    <path stroke="#fff" d="M9 13H11" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

const ReportIcon = (props) => <Icon component={Reports} {...props} />;
export default ReportIcon;
