import React, { Fragment } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Button, Image, Row, Col, Typography, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { InputField, SelectField, DateField2 } from '../../../../../../../../../atoms/FormElement';
import plusIcon from '../../../../../../../../../../assets/svg/plus.svg';
import trashIcon from '../../../../../../../../../../assets/svg/trash-icon.svg';
import { countryList } from '../../../../../../../../../../configs/constants';
import moment from 'moment';
import PurposeCode from './PurposeCode';

const { Title } = Typography;

const initQ = {
  incurred_id: '',
  contact_id: '',
  date: '',
  amount: '',
  purpose_code: '',
  required_remarks: '',
};

export default ({
  nestIndex,
  control,
  setValue,
  watch,
  deletedIndexesInc,
  setDeletedIndexesInc,
  purposeDrop,
  obligationsByData,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `obligation[${nestIndex}].incurreds`,
  });

  const onAdd = () => {
    append(initQ);
    let leng = `${fields.length + 1}`;
  };

  const onRemove = (e, index) => {
    remove(index);
    if (e?.incurred_id) {
      setDeletedIndexesInc([...deletedIndexesInc, e?.incurred_id]);
    }
  };

  return (
    <Row>
      {fields.map((item, k) => {
        return (
          <Fragment key={item.id}>
            <Row gutter={[20, 20]} align="bottom" className="nested_array">
              <Col span={24}>
                {k == 0 && (
                  <Title level={4} className="text-center mb-0 font-gilroy">
                    Incurred Debts
                    <Tooltip title="What the committee received this period.">
                      <InfoCircleFilled style={{ color: '#AFBED7', fontSize: '14px', marginLeft: '10px' }} />
                    </Tooltip>
                  </Title>
                )}

                <div className="accounts_remove" style={{ right: 0, top: -5 }}>
                  <Button type="link" className="p-0" onClick={() => onRemove(item, k)}>
                    <Image src={trashIcon} preview={false} size={24} />
                  </Button>
                </div>
              </Col>
              <InputField
                fieldname={`obligation[${nestIndex}].incurreds[${k}].id`}
                label=""
                control={control}
                class="d-none mb-0"
                initValue={item?.id}
                iProps={{ size: 'large' }}
              />
              <InputField
                fieldname={`obligation[${nestIndex}].incurreds[${k}].incurred_id`}
                label=""
                control={control}
                class="d-none mb-0"
                initValue={item?.incurred_id}
                iProps={{ size: 'large' }}
              />

              <Col span={24}>
                <SelectField
                  fieldname={`obligation[${nestIndex}].incurreds[${k}].contact_id`}
                  label="Purchase Place"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'Please select',
                  }}
                  selectOption={
                    obligationsByData && obligationsByData?.contacts?.length > 0 ? obligationsByData?.contacts : ''
                  }
                  valueGot={
                    item?.contact_id &&
                    obligationsByData?.contacts &&
                    obligationsByData?.contacts.find((x) => item?.contact_id == x?.value)
                  }
                  setValue={setValue}
                />
              </Col>

              {/* <Col span={12}>
                <InputField
                  fieldname={`obligation[${nestIndex}].incurreds[${k}].purchase_place`}
                  label="Purchase Place Full Name"
                  control={control}
                  class="mb-0"
                  // valueGot={item?.purchase_place}
                  // setValue={setValue}
                  initValue={item?.purchase_place}
                  iProps={{ size: 'large' }}
                />
              </Col>

              <Col span={12}>
                <InputField
                  fieldname={`obligation[${nestIndex}].incurreds[${k}].phone`}
                  label="Phone Number"
                  control={control}
                  class="mb-0"
                  initValue={item?.phone}
                  //valueGot={item?.phone}
                  //setValue={setValue}
                  iProps={{ size: 'large' }}
                />
              </Col>

              <Col span={24} className="mailingField">
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <InputField
                      fieldname={`obligation[${nestIndex}].incurreds[${k}].mailing_address`}
                      label="Purchase Place Mailing Address"
                      control={control}
                      initValue={item?.mailing_address}
                      //valueGot={item?.mailing_address}
                      //setValue={setValue}
                      iProps={{
                        size: 'large',
                        placeholder: 'Street Address',
                      }}
                    />
                  </Col>

                  <Col span={9}>
                    <SelectField
                      fieldname={`obligation[${nestIndex}].incurreds[${k}].state`}
                      control={control}
                      class="mb-0"
                      iProps={{ placeholder: 'Please select' }}
                      rules={{ required: 'Mailing Address is required.' }}
                      initValue={{}}
                      selectOption={countryList}
                    />
                  </Col>

                  <Col span={9}>
                    <InputField
                      fieldname={`obligation[${nestIndex}].incurreds[${k}].city`}
                      control={control}
                      initValue={item?.city}
                      //valueGot={item?.city}
                      //setValue={setValue}
                      iProps={{
                        size: 'large',
                        placeholder: 'City',
                      }}
                    />
                  </Col>

                  <Col span={6}>
                    <InputField
                      fieldname={`obligation[${nestIndex}].incurreds[${k}].post_code`}
                      control={control}
                      initValue={item?.post_code}
                      //valueGot={item?.post_code}
                      //setValue={setValue}
                      iProps={{
                        size: 'large',
                        placeholder: 'Zip',
                      }}
                    />
                  </Col>
                </Row>
              </Col> */}

              <Col span={12}>
                <DateField2
                  fieldname={`obligation[${nestIndex}].incurreds[${k}].date`}
                  label="Date"
                  control={control}
                  class="mb-0"
                  iProps={{ size: 'large' }}
                  //valueGot={item?.date}
                  //setValue={setValue}
                  initValue={item?.date}
                />
              </Col>

              <Col span={12}>
                <InputField
                  fieldname={`obligation[${nestIndex}].incurreds[${k}].amount`}
                  label="Amount"
                  control={control}
                  // valueGot={item?.amount}
                  // setValue={setValue}
                  class="mb-0"
                  initValue={item?.amount && item?.amount > 0 ? item?.amount : '0'}
                  iProps={{ size: 'large', prefix: '$', type: 'number' }}
                />
              </Col>

              <PurposeCode
                purposeName={`obligation[${nestIndex}].incurreds[${k}].purpose_code`}
                remarksName={`obligation[${nestIndex}].incurreds[${k}].required_remarks`}
                {...{ control }}
                setValue={setValue}
                watch={watch}
                purposeDrop={purposeDrop}
                item={item}
              />

              {/* <Col span={12}>
                <SelectField
                  fieldname={`obligation[${nestIndex}].incurreds[${k}].purpose_code`}
                  label="Purpose Code"
                  control={control}
                  initValue={
                    item?.purpose_code &&
                    item?.purpose_code?.length &&
                    item?.purpose_code.map((x) => purposeDrop.find((y) => y.value == x))
                  }
                  valueGot={
                    item?.purpose_code &&
                    item?.purpose_code?.length &&
                    item?.purpose_code.map((x) => purposeDrop.find((y) => y.value == x))
                  }
                  setValue={setValue}
                  iProps={{
                    size: 'large',
                    placeholder: 'Please select',
                    isMulti: true,
                  }}
                  selectOption={purposeDrop}
                />
              </Col> */}

              {/* {//console.log(
                'rem',
                `obligation[${nestIndex}].incurreds[${k}].purpose_code`.map((x) =>
                  purposeDrop.find((y) => y.value == x),
                ),
              )} */}

              {/* {remarks &&
                remarks?.length > 0 &&
                remarks?.map(
                  (resp) =>
                    resp?.value === 'A' ||
                    resp?.value === 'B' ||
                    resp?.value === 'C' ||
                    resp?.value === 'F' ||
                    resp?.value === 'H' ||
                    resp?.value === 'K' ||
                    resp?.value === 'O',
                ) && (
                  <Col span={12}>
                    <InputField
                      fieldname={`obligation[${nestIndex}].incurreds[${k}].required_remarks`}
                      label="Required Remarks"
                      control={control}
                      initValue=""
                      valueGot={item?.required_remarks}
                      setValue={setValue}
                      iProps={{
                        size: 'large',
                      }}
                    />
                  </Col>
                )} */}
            </Row>
          </Fragment>
        );
      })}

      <Col span={24}>
        <Button type="text" htmlType="button" className="font-gilroy c-grey d-flex align-center" onClick={onAdd}>
          <Image src={plusIcon} preview={false} size={24} />
          Add Incurred Debt
        </Button>
      </Col>
    </Row>
  );
};
