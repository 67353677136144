import React, { useState, useEffect } from 'react';
import { Steps, Card } from 'antd';
import { useLocation } from 'react-router';
import Dashboard from '../../../../pages/Dashboard';
import {
  OrganizationalDetail,
  Expenditures,
  FileReport,
  Other,
  Receipts,
  QuarterDetail,
  HourDetail,
} from './StepsForm';

const { Step } = Steps;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [startDate, setstartDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [reportId, setreportId] = useState(false);
  const [status, setStatus] = useState('not ready');

  const location = useLocation();
  const url = location.pathname?.split('/')[2]?.split('?')[0];
  const heading = url.replace(/-/g, ' ') + ' ' + 'Amendment';
  let steps = [];
  let geturl = new URL(window.location.href);
  let searchParams = new URLSearchParams(geturl.search);
  let getYear = searchParams.get('year');
  const reportCase = searchParams.get('case');
  const federalPAC = localStorage.getItem('organization');

  {
    url == 'organizational' &&
      (steps =
        federalPAC !== 'federal'
          ? [
              {
                title: 'Details',
                content: (
                  <OrganizationalDetail
                    getYear={getYear}
                    currentStep={current}
                    setCurrentStep={setCurrent}
                    setstartDate={(date) => {
                      setstartDate(date);
                    }}
                    setendDate={(date) => {
                      setendDate(date);
                    }}
                    setReportId={(Id) => {
                      setreportId(Id);
                    }}
                  />
                ),
              },
              {
                title: 'Receipts',
                content: (
                  <Receipts currentStep={current} setCurrentStep={setCurrent} startDate={startDate} endDate={endDate} />
                ),
              },
              {
                title: 'Expenditures',
                content: (
                  <Expenditures
                    currentStep={current}
                    setCurrentStep={setCurrent}
                    startDate={startDate}
                    endDate={endDate}
                    reportId={reportId}
                  />
                ),
              },
              {
                title: 'Other',
                content: (
                  <Other
                    currentStep={current}
                    setCurrentStep={setCurrent}
                    startDate={startDate}
                    endDate={endDate}
                    reportId={reportId}
                  />
                ),
              },
              {
                title: 'File Report',
                content: (
                  <FileReport
                    reportId={reportId}
                    setStatus={setStatus}
                    status={status}
                    startDate={startDate}
                    endDate={endDate}
                  />
                ),
              },
            ]
          : [
              {
                title: 'Details',
                content: (
                  <OrganizationalDetail
                    getYear={getYear}
                    currentStep={current}
                    setCurrentStep={setCurrent}
                    setstartDate={(date) => {
                      setstartDate(date);
                    }}
                    setendDate={(date) => {
                      setendDate(date);
                    }}
                    setReportId={(Id) => {
                      setreportId(Id);
                    }}
                  />
                ),
              },
              {
                title: 'Expenditures',
                content: (
                  <Expenditures
                    currentStep={current}
                    setCurrentStep={setCurrent}
                    startDate={startDate}
                    endDate={endDate}
                    reportId={reportId}
                  />
                ),
              },
              {
                title: reportCase === '5' ? 'Preview Report' : 'File Report',
                content: (
                  <FileReport
                    reportId={reportId}
                    setStatus={setStatus}
                    status={status}
                    startDate={startDate}
                    endDate={endDate}
                  />
                ),
              },
            ]);

    url == 'mid-year-semiannual' ||
    url == 'year-end-semiannual' ||
    url == 'first-quarter-plus' ||
    url == 'second-quarter' ||
    url == 'secord-quarter' ||
    url == 'third-quarter-plus' ||
    url == 'fourth-quarter'
      ? (steps =
          federalPAC !== 'federal'
            ? [
                {
                  title: 'Details',
                  content: (
                    <QuarterDetail
                      getYear={getYear}
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      type={url}
                      setstartDate={(date) => {
                        setstartDate(date);
                      }}
                      setendDate={(date) => {
                        setendDate(date);
                      }}
                      setReportId={(Id) => {
                        setreportId(Id);
                      }}
                    />
                  ),
                },
                {
                  title: 'Receipts',
                  content: (
                    <Receipts
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                },
                {
                  title: 'Expenditures',
                  content: (
                    <Expenditures
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                      reportId={reportId}
                    />
                  ),
                },
                {
                  title: 'Other',
                  content: (
                    <Other
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                      reportId={reportId}
                    />
                  ),
                },
                {
                  title: 'File Report',
                  content: (
                    <FileReport
                      reportId={reportId}
                      setStatus={setStatus}
                      status={status}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                },
              ]
            : [
                {
                  title: 'Details',
                  content: (
                    <QuarterDetail
                      getYear={getYear}
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      type={url}
                      setstartDate={(date) => {
                        setstartDate(date);
                      }}
                      setendDate={(date) => {
                        setendDate(date);
                      }}
                      setReportId={(Id) => {
                        setreportId(Id);
                      }}
                    />
                  ),
                },
                {
                  title: 'Expenditures',
                  content: (
                    <Expenditures
                      currentStep={current}
                      setCurrentStep={setCurrent}
                      startDate={startDate}
                      endDate={endDate}
                      reportId={reportId}
                    />
                  ),
                },
                {
                  title: reportCase === '5' ? 'Preview Report' : 'File Report',
                  content: (
                    <FileReport
                      reportId={reportId}
                      setStatus={setStatus}
                      status={status}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                },
              ])
      : null;

    url == '48-hour-notice' &&
      (steps =
        federalPAC !== 'federal'
          ? [
              {
                title: 'Details',
                content: (
                  <HourDetail
                    currentStep={current}
                    setCurrentStep={setCurrent}
                    getYear={getYear}
                    setstartDate={(date) => {
                      setstartDate(date);
                    }}
                    setendDate={(date) => {
                      setendDate(date);
                    }}
                    setReportId={(Id) => {
                      setreportId(Id);
                    }}
                  />
                ),
              },
              {
                title: 'Receipts',
                content: (
                  <Receipts
                    currentStep={current}
                    setCurrentStep={setCurrent}
                    reportId={reportId}
                    isFor48HourReport={true}
                  />
                ),
              },
              {
                title: 'File Report',
                content: (
                  <FileReport
                    reportId={reportId}
                    setStatus={setStatus}
                    status={status}
                    startDate={startDate}
                    endDate={endDate}
                  />
                ),
              },
            ]
          : [
              {
                title: 'Details',
                content: (
                  <HourDetail
                    currentStep={current}
                    setCurrentStep={setCurrent}
                    getYear={getYear}
                    setstartDate={(date) => {
                      setstartDate(date);
                    }}
                    setendDate={(date) => {
                      setendDate(date);
                    }}
                    setReportId={(Id) => {
                      setreportId(Id);
                    }}
                  />
                ),
              },
              {
                title: reportCase === '5' ? 'Preview Report' : 'File Report',
                content: (
                  <FileReport
                    reportId={reportId}
                    setStatus={setStatus}
                    status={status}
                    startDate={startDate}
                    endDate={endDate}
                  />
                ),
              },
            ]);
  }

  const onChange = (value) => {
    if (status == 'ready') {
      setCurrent(value);
    }
  };

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1500);
  // }, [])

  return (
    <Dashboard load={loading} heading={heading} backLink="/reports">
      <Card className="white-card w-100 pcard-0 steps-reports">
        <Steps current={current} onChange={onChange}>
          {steps.map((item, i) => (
            <React.Fragment key={i}>
              <Step key={item?.title} title={item?.title} />
            </React.Fragment>
          ))}
        </Steps>
        <div className="steps-content">{steps[current]?.content}</div>
      </Card>
    </Dashboard>
  );
};
