import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio, message, Descriptions } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { InputRadio, InputField, TextAreaField, DateField, SelectField } from '../../../../../atoms/FormElement';
import { getReceiptDropdownData, getExemptPlan, editExemptPlan } from '../../ducks/actions';
import { getContactFormData, getContactFormDataPayee } from '../../../Contacts/ducks/actions';
import ExemptPurchaseDrop from '../../../../../molecules/Dashboard/ExemptPurchaseDrop';
import { useFieldArray } from 'react-hook-form';
import { deleteExemptPlans } from '../../ducks/services';
import ItemSold from './ItemSold';

const { Paragraph } = Typography;

export default (props) => {
  const { control, errors, setValue, formData, setLoading, deletedIndexes, setDeletedIndexes } = props;
  const dispatch = useDispatch();
  // const [contactBoxEditCase, setContactBoxEditCase] = useState('');
  // const [contactToSelect, setContactToSelect] = useState(null);
  // const [contactToSelectReimbursee, setContactToSelectReimbursee] = useState(null);
  const [salesDropdown, setSalesDropdown] = useState(null);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'item_sold',
  });
  const receiptFormData = useSelector((state) => state.receipt.receiptDropData);
  // const editExemptPlanData = useSelector((state) => state.receipt.editExemptPlan);
  // let contributerData = useSelector((state) => state.contact.contactFormData);
  // let contributerDataReimbursee = useSelector((state) => state.contact.contactFormDataPayee);

  useEffect(() => {
    dispatch(getReceiptDropdownData('exempt_purchase_price_sale', 'null'));
  }, []);

  useEffect(() => {
    if (
      formData?.receiptData &&
      Object.keys(receiptFormData)?.length > 0 &&
      Object.keys(formData?.receiptData)?.length > 0
    ) {
      // const selectedPayeeList = receiptFormData?.contributors?.find(
      //   (e) => e?.value == (contactToSelect ? contactToSelect : formData?.receiptData?.payee_id),
      // );
      const selectedReimburseList = receiptFormData?.exemptData?.find(
        (e) => e?.value == formData?.receiptData?.exempt_plan_id,
      );
      setValue('amount', formData.receiptData?.amount);
      setValue('exempt_plan_id', selectedReimburseList);
      setValue('item_sold', formData.receiptData?.item_sold);
      setValue('comments', formData.receiptData?.comments);
      setValue('total_exceded_comment', formData.receiptData?.total_exceded_comment);
      setValue('date', formData?.receiptData?.date ? moment(formData?.receiptData?.date, 'YYYY-MM-DD HH:mm') : '');

      setValue('sale_per_sbe', formData.receiptData?.sale_per_sbe == true ? 'Yes' : 'No');
      setValue('total_purchase_exceeded', formData.receiptData?.total_purchase_exceeded == true ? 'Yes' : 'No');

      // setContactToSelect(null);
      // setContactToSelectReimbursee(null);
      setTimeout(() => {
        //setContactBoxEditCase(selectedReimburseList);
        setLoading(false);
        setSalesDropdown(selectedReimburseList);
      }, 2000);
    }
  }, [receiptFormData, formData]);

  // const changeValueByDropDown = (e) => {
  //   dispatch(editExemptPlan(e));
  // };

  // const updateDropdownValues = () => {
  //   dispatch(getExemptPlan());
  // };

  // const deleteDropDownValue = (e) => {
  //   setLoading(true);
  //   deleteExemptPlans(e)
  //     .then((response) => {
  //       message.success('Exempt Sales Plan Deleted.');
  //       dispatch(getExemptPlan());
  //       setValue('exempt_plan_id', '');
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //       message.error('Something went wrong');
  //     });
  // };

  const planChange = (e) => {
    setValue('item_sold', [{ item_id: '', qty: '' }]);
    setSalesDropdown(e);
  };

  return (
    <>
      <Row gutter={[48, 30]}>
        <Col span={12}>
          <Row gutter={[12, 30]}>
            {/* <ExemptPurchaseDrop
              control={control}
              errors={errors}
              selectData={receiptFormData?.exemptData}
              changeValueByDropDown={changeValueByDropDown}
              updateDropdownValues={updateDropdownValues}
              contributerData={contributerDataReimbursee}
              contactBoxEditCase={contactBoxEditCase}
              contactToSelect={contactToSelectReimbursee}
              setContactToSelect={setContactToSelectReimbursee}
              editExemptPlanData={editExemptPlanData}
              deleteDropDownValue={deleteDropDownValue}
              setSalesDropdown={setSalesDropdown}
              setValue={setValue}
              fieldname="exempt_plan_id"
              label="Exempt Sales Plan"
            /> */}

            <Col span={24}>
              <SelectField
                isRequired={true}
                fieldname="exempt_plan_id"
                label="Exempt Sales Plan"
                control={control}
                class="mb-0"
                iProps={{ placeholder: 'Please select' }}
                rules={{ required: 'Exempt Sales Plan is Required' }}
                initValue=""
                onChange={(e) => planChange(e)}
                selectOption={receiptFormData?.exemptData}
                validate={errors.exempt_plan_id && 'error'}
                validMessage={errors.exempt_plan_id && errors.exempt_plan_id.message}
              />
            </Col>

            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="amount"
                label="Total Amount Raised (all the items)"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  prefix: '$',
                  type: 'number',
                }}
                rules={{
                  required: 'Total Amount Raised is required',
                }}
                validate={errors.amount && 'error'}
                validMessage={errors.amount && errors.amount.message}
              />
            </Col>

            {/* <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="item_sold"
                label="Number of Items Sold"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  type: 'number',
                }}
                rules={{
                  required: 'Number of Items Sold is required',
                }}
                validate={errors.item_sold && 'error'}
                validMessage={errors.item_sold && errors.item_sold.message}
              />
            </Col> */}

            <Col span={24}>
              <DateField
                fieldname="date"
                label="Date"
                control={control}
                class="mb-0"
                iProps={{ size: 'large' }}
                initValue=""
                isRequired={true}
                rules={{
                  required: 'Date is required.',
                }}
                validate={errors.date && 'error'}
                validMessage={errors.date && errors.date.message}
              />
            </Col>

            <Col span={24}>
              <ItemSold
                formData={formData}
                fields={fields}
                control={control}
                errors={errors}
                setValue={setValue}
                append={append}
                remove={remove}
                selectData={receiptFormData?.contributors}
                deletedIndexes={deletedIndexes}
                setDeletedIndexes={setDeletedIndexes}
                salesDropdown={salesDropdown}
              />
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <InputRadio
                fieldname="sale_per_sbe"
                label="Was this sale conducted according to the Exempt Sales Plan approved by the State Board of Elections?"
                control={control}
                classField="tab-type-radio"
                onChange={(e) => {}}
                options={
                  <>
                    <Row gutter={[20, 20]}>
                      <Col span={12}>
                        <Radio value="No">No</Radio>
                      </Col>
                      <Col span={12}>
                        <Radio value="Yes">Yes</Radio>
                      </Col>
                    </Row>
                  </>
                }
                rules={{
                  required: 'Please make a choice.',
                }}
                validate={errors.sale_per_sbe && 'error'}
                validMessage={errors.sale_per_sbe && errors.sale_per_sbe.message}
              />
            </Col>

            <Col span={24}>
              <TextAreaField
                fieldname="comments"
                label="Comments"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                optionalLabel={true}
              />
            </Col>

            <Col span={24}>
              <InputRadio
                fieldname="total_purchase_exceeded"
                label="Did any purchaser make total purchases exceeding $50?"
                control={control}
                classField="tab-type-radio"
                onChange={(e) => {}}
                options={
                  <>
                    <Row gutter={[20, 20]}>
                      <Col span={12}>
                        <Radio value="No">No</Radio>
                      </Col>
                      <Col span={12}>
                        <Radio value="Yes">Yes</Radio>
                      </Col>
                    </Row>
                  </>
                }
                rules={{
                  required: 'Please make a choice.',
                }}
                validate={errors.total_purchase_exceeded && 'error'}
                validMessage={errors.total_purchase_exceeded && errors.total_purchase_exceeded.message}
              />
            </Col>

            <Col span={24}>
              <TextAreaField
                fieldname="total_exceded_comment"
                label="Comments"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                optionalLabel={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
