import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { InputRadio, InputField, TextAreaField, DateField, SelectField } from '../../../../../atoms/FormElement';
import { getReceiptDropdownData } from '../../ducks/actions';
import { FORMS_OF_PAYMENTS, specificField } from '../../../../../../configs/constants';
import { getContactFormData } from '../../../Contacts/ducks/actions';
import ContactBox from '../../../../../molecules/Dashboard/ContactBox';
const { Paragraph } = Typography;

export default (props) => {
  const {
    control,
    errors,
    formData,
    setValue,
    setLoading,
    setRemainingAmount,
    setOldAmount,
    oldAmount,
    setError,
    remainingAmount,
    watch,
    clearErrors,
    formOfPayment,
    setFormOfPayment,
  } = props;
  const dispatch = useDispatch();
  const [contactBoxEditCase, setContactBoxEditCase] = useState('');
  const [contactToSelect, setContactToSelect] = useState(null);
  const amountPaid = watch('amount');
  let contributerData = useSelector((state) => state.contact.contactFormData);
  const receiptFormData = useSelector((state) => state.receipt.receiptDropData);

  useEffect(() => {
    if (remainingAmount && amountPaid) {
      const mainAmount = oldAmount + remainingAmount;

      if (Math.round((+amountPaid + Number.EPSILON) * 100) / 100 > mainAmount) {
        setError('amount', {
          type: 'required',
          message: 'Please enter an amount that does not exceed the remaining debt balance.',
        });
        return;
      } else {
        clearErrors('amount');
      }
    }
  }, [amountPaid, remainingAmount]);

  useEffect(() => {
    dispatch(getReceiptDropdownData('debt_repayment', 'null'));
  }, []);

  useEffect(() => {
    if (
      formData?.receiptData &&
      Object.keys(receiptFormData)?.length > 0 &&
      Object.keys(formData?.receiptData)?.length > 0
    ) {
      const selectedAccountList = receiptFormData?.accounts?.find((e) => e?.value == formData?.receiptData?.account_id);
      // const selectedcontributorList = receiptFormData?.contributors?.find(
      //   (e) => e?.value == (contactToSelect ? contactToSelect : formData?.receiptData?.contributor_id),
      // );
      const selectedObligationID = receiptFormData?.obligations?.find(
        (e) => e?.value == formData.receiptData?.obligation_id,
      );

      if (selectedObligationID) {
        setRemainingAmount(selectedObligationID?.amounRemaining);
      }
      if (formData.receiptData?.amount) {
        setOldAmount(formData.receiptData?.amount);
      }
      setLoading(true);
      setValue('amount', formData.receiptData?.amount);
      setValue('obligation_id', selectedObligationID);
      setValue('date', formData?.receiptData?.date ? moment(formData?.receiptData?.date, 'YYYY-MM-DD HH:mm') : '');
      setValue('comments', formData.receiptData?.comments);
      setValue('formOfPayment', formData.receiptData?.payment_type);
      //setValue('contributor', selectedcontributorList);
      setFormOfPayment(formData.receiptData?.payment_type);
      setContactToSelect(null);
      setTimeout(() => {
        setValue('check_number', formData.receiptData?.check_number);
        setValue('in_kind_description', formData.receiptData?.in_kind_description);
        setValue('account', selectedAccountList);
        //setContactBoxEditCase(selectedcontributorList);
      }, 1000);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [receiptFormData, formData]);

  const changeValueByDropDown = (e) => {
    dispatch(getContactFormData(e));
  };

  const updateDropdownValues = () => {
    dispatch(getReceiptDropdownData('general_contribution', 'null'));
  };

  const formPayment = (e) => {
    setFormOfPayment(e.target.value);

    if (receiptFormData && receiptFormData?.accounts?.length == 1) {
      setTimeout(() => {
        setValue('account', receiptFormData?.accounts[0]);
      }, 500);
    }
  };

  const getObligationValue = (e) => {
    setRemainingAmount(e?.amounRemaining);
  };

  return (
    <>
      <Row gutter={[48, 30]}>
        <Col span={12}>
          <Row gutter={[12, 30]}>
            <Col span={24}>
              <SelectField
                isRequired={true}
                fieldname="obligation_id"
                label="Debt That Debtor Repaid"
                control={control}
                initValue=""
                onChange={getObligationValue}
                iProps={{
                  size: 'large',
                  placeholder: 'Please select',
                }}
                rules={{
                  required: 'Debt That Debtor Repaid is required.',
                }}
                selectOption={receiptFormData?.obligations}
                validate={errors.obligation_id && 'error'}
                validMessage={errors.obligation_id && errors.obligation_id.message}
              />
            </Col>

            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="amount"
                label="Amount Repaid"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  prefix: '$',
                  type: 'number',
                }}
                rules={{
                  required: 'Amount Repaid is required.',
                }}
                validate={errors.amount && 'error'}
                validMessage={errors.amount && errors.amount.message}
              />
            </Col>

            <Col span={24}>
              <DateField
                fieldname="date"
                label="Repayment Date"
                control={control}
                class="mb-0"
                iProps={{ size: 'large' }}
                initValue=""
                isRequired={true}
                rules={{
                  required: 'Repayment Date is required.',
                }}
                validate={errors.date && 'error'}
                validMessage={errors.date && errors.date.message}
              />
            </Col>

            {/* <ContactBox
              control={control}
              errors={errors}
              selectData={receiptFormData?.contributors}
              changeValueByDropDown={changeValueByDropDown}
              updateDropdownValues={updateDropdownValues}
              contributerData={contributerData}
              contactBoxEditCase={contactBoxEditCase}
              contactToSelect={contactToSelect}
              setContactToSelect={setContactToSelect}
              setValue={setValue}
              fieldname="contributor"
              label="Contributor"
            /> */}

            <Col span={24}>
              <TextAreaField
                fieldname="comments"
                label="Comments"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                optionalLabel={true}
              />
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <InputRadio
                fieldname="formOfPayment"
                label="Form of Payment"
                control={control}
                initValue={''}
                classField="custom-radio"
                onChange={(e) => formPayment(e)}
                options={
                  <>
                    {FORMS_OF_PAYMENTS &&
                      FORMS_OF_PAYMENTS?.map((item, index) => (
                        <Fragment key={index}>
                          <Radio.Button value={item?.value}>
                            <Image src={item?.image} preview={false} width={80} />
                            <Paragraph className="smallFont12">{item?.label}</Paragraph>
                          </Radio.Button>
                        </Fragment>
                      ))}
                  </>
                }
                rules={{
                  required: 'Form of Payment is required.',
                }}
                validate={errors.formOfPayment && 'error'}
                validMessage={errors.formOfPayment && errors.formOfPayment.message}
              />
            </Col>

            {formOfPayment && (
              <Col span={24}>
                {formOfPayment != 'in_kind' && (
                  <Row gutter={16}>
                    {formOfPayment == 'check' && (
                      <Col span={12}>
                        <InputField
                          fieldname="check_number"
                          label="Check Number"
                          control={control}
                          initValue=""
                          optionalLabel="(Optional)"
                          iProps={{
                            size: 'large',
                          }}
                        />
                      </Col>
                    )}
                    <Col span={formOfPayment == 'check' ? 12 : 24}>
                      <SelectField
                        isRequired={true}
                        fieldname="account"
                        label="Account"
                        control={control}
                        class="mb-0"
                        iProps={{ placeholder: 'Please select' }}
                        rules={{ required: 'Account is required.' }}
                        initValue=""
                        selectOption={receiptFormData?.accounts}
                        validate={errors.account && 'error'}
                        validMessage={errors.account && errors.account.message}
                      />
                    </Col>
                  </Row>
                )}

                {formOfPayment == 'in_kind' && (
                  <Col span={24}>
                    <InputField
                      isRequired={true}
                      fieldname="in_kind_description"
                      label="In-Kind Description"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                      }}
                      rules={{
                        required: 'In-Kind Description is required.',
                      }}
                      validate={errors.in_kind_description && 'error'}
                      validMessage={errors.in_kind_description && errors.in_kind_description.message}
                    />
                  </Col>
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};
