import * as action_types from './constants';

const initialState = {
  expenditureFormData: {},
  expenditureFormData: {},
  expenditureDropData: {},
  expenditureListData: [],
  unimportedExpenditureListData: [],
  bankAccountsList: [],
  filter: {
    searchExpenditure: undefined,
    date: undefined,
    amount: undefined,
  },
  filterImport: {
    showMandatory: undefined,
    showDublicative: undefined,
  },
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.EXPENDITURE_FORM_DATA:
      return { ...state, expenditureFormData: data };

    case action_types.EXPENDITURE_DROP_DATA:
      return { ...state, expenditureDropData: data };

    case action_types.EXPENDITURE_LIST_DATA:
      return { ...state, expenditureListData: data };

    case action_types.UNIMPORTED_EXPENDITURE_LIST_DATA:
      return { ...state, unimportedExpenditureListData: data };

    case action_types.BANK_ACCOUNTS_LIST:
      return { ...state, bankAccountsList: data };

    case action_types.SET_FILTERS_OPTIONS:
      return { ...state, filter: { ...state.filter, ...action.payload.filter } };

    case action_types.SET_FILTERS_OPTIONS_IMPORT:
      return { ...state, filterImport: { ...state.filterImport, ...action.payload.filterImport } };

    case action_types.CLEAR_EXPENDITURE_FILTER:
      return {
        ...state,
        filter: {
          searchExpenditure: undefined,
          date: undefined,
          amount: undefined,
        },
      };

    default:
      return state;
  }
};
