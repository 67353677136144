import * as action_types from './constants';

const initialState = {
  obligationToListing: [],
  obligationByListing: [],
  obligationDetailData: {},
  filterDebts: {
    searchDebts: undefined,
    status: undefined,
    remainingBalance: undefined
  },
  filterDebtsTo: {
    searchDebts: undefined,
    status: undefined,
    remainingBalance: undefined
  }
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.OBLIGATION_TO_LISTING:
      return { ...state, obligationToListing: data };

    case action_types.OBLIGATION_BY_LISTING:
      return { ...state, obligationByListing: data };
      
    case action_types.OBLIGATION_BY_ID:
      return { ...state, obligationDetailData: data };

    case action_types.SET_FILTERS_OPTIONS_DEBTS:
      return { ...state, filterDebts: { ...state.filterDebts, ...action.payload.filterDebts } }

    case action_types.SET_FILTERS_OPTIONS_DEBTS_TO:
      return { ...state, filterDebtsTo: { ...state.filterDebtsTo, ...action.payload.filterDebtsTo } }

    case action_types.CLEAR_DEBTS_FILTER:
      return {
        ...state,
        filterDebts: {
          searchDebts: undefined,
          status: undefined,
          remainingBalance: undefined,
        },
      };

      case action_types.CLEAR_DEBTS_FILTER_TO:
      return {
        ...state,
        filterDebtsTo: {
          searchDebts: undefined,
          status: undefined,
          remainingBalance: undefined,
        },
      };

    default:
      return state;
  }
};
