import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import moment from 'moment';
import {
  InputRadio,
  InputField,
  TextAreaField,
  DateField,
  SelectField,
  InputCheckbox,
} from '../../../../../atoms/FormElement';
import { countryList, specificField } from '../../../../../../configs/constants';
const { Paragraph, Text } = Typography;

export default (props) => {
  const { control, errors, setValue, formData, exportContactId, setLoading, phoneNumber, setPhoneNumber } = props;
  const dispatch = useDispatch();
  const receiptFormData = useSelector((state) => state.receipt.receiptDropData);
  const [jobTitle, setJobTitle] = useState('Job_title');
  const [employeeName, setEmployeeName] = useState('employersName');

  useEffect(() => {
    setValue('mailingcountryList', { label: 'North Carolina', value: 'NC' });
  }, []);

  useEffect(() => {
    if (formData && Object.keys(formData)?.length > 0) {
      setLoading(true);
      const selectedState = countryList?.find((e) => e?.value == formData?.state);
      const specificDrop = specificField?.find((e) => e?.value == formData?.specific_field);
      const suffixDrop = suffixData?.find((e) => e?.value == formData?.suffix);
      setValue('firstName', formData?.first_name);
      setValue('lastName', formData?.last_name);
      setValue('middle_name', formData?.middle_name);
      setValue('suffix', suffixDrop);
      setValue('candidate_spouse', formData?.is_candidate_spouse);
      setValue('mailingStreet', formData?.mailing_address);
      if (selectedState) {
        setValue('mailingcountryList', selectedState);
      }
      setValue('mailingCity', formData?.city);
      setValue('mailingZip', formData?.post_code);
      //setValue('phoneNumber', formData?.phone);
      setPhoneNumber(formData?.phone);
      setValue('email', formData?.email);

      if (formData?.profession && formData?.profession?.length > 0) {
        setJobTitle('Profession');
      } else if (formData?.job_title === "Retired") {
        setJobTitle('Retired');
      } else {
        setJobTitle('Job_title');
      }
      if (formData?.specific_field && formData?.specific_field?.length > 0) {
        setEmployeeName('specificField');
      } else {
        setEmployeeName('employersName');
      }

      setTimeout(() => {
        setValue('jobTitle', formData?.job_title === 'Retired' ? 'No Job Title or Profession' : formData?.job_title);
        setValue('profession', formData?.profession);
        setValue('employerName', formData?.employer_name);
        setValue('specificField', specificDrop);
        setValue('jobRadio', formData?.profession && formData?.profession?.length > 0 ? 'Profession' : formData?.job_title === 'Retired' ? 'Retired' : 'Job_title');
        setValue(
          'employeeRadio',
          formData?.specific_field && formData?.specific_field?.length > 0 ? 'specificField' : 'employersName',
        );
        setLoading(false);
      }, 1200);
    } else if (exportContactId && Object.keys(exportContactId)?.length > 0) {
      setLoading(true);
      const selectedState = countryList?.find((e) => e?.value == exportContactId?.state);
      const specificDrop = specificField?.find((e) => e?.value == exportContactId?.specific_field);
      const suffixDrop = suffixData?.find((e) => e?.value == exportContactId?.suffix);
      setValue('firstName', exportContactId?.first_name);
      setValue('lastName', exportContactId?.last_name);
      setValue('middle_name', exportContactId?.middle_name);
      setValue('suffix', suffixDrop);
      setValue('candidate_spouse', exportContactId?.is_candidate_spouse);
      setValue('mailingStreet', exportContactId?.mailing_address);
      if (selectedState) {
        setValue('mailingcountryList', selectedState);
      }
      setValue('mailingCity', exportContactId?.city);
      setValue('mailingZip', exportContactId?.post_code);
      //setValue('phoneNumber', formData?.phone);
      setPhoneNumber(exportContactId?.phone);
      setValue('email', exportContactId?.email);
      
      if (exportContactId?.profession && exportContactId?.profession?.length > 0) {
        setJobTitle('Profession');
      }  else if (exportContactId?.job_title === "Retired") {
        setJobTitle('Retired');
      } else {
        setJobTitle('Job_title');
      }
      if (exportContactId?.specific_field && exportContactId?.specific_field?.length > 0) {
        setEmployeeName('specificField');
      } else {
        setEmployeeName('employersName');
      }

      setTimeout(() => {
        setValue('jobTitle', exportContactId?.job_title);
        setValue('jobTitle', exportContactId?.job_title === 'Retired' ? 'No Job Title or Profession' : exportContactId?.job_title);
        setValue('profession', exportContactId?.profession);
        setValue('employerName', exportContactId?.employer_name);
        setValue('specificField', specificDrop);
        setValue('jobRadio', exportContactId?.profession && exportContactId?.profession?.length > 0 ? 'Profession' : exportContactId?.job_title === 'Retired' ? 'Retired' : 'Job_title');
        
        setValue(
          'jobRadio',
          exportContactId?.profession && exportContactId?.profession?.length > 0 ? 'Profession' : 'Job_title',
        );
        setValue(
          'employeeRadio',
          exportContactId?.specific_field && exportContactId?.specific_field?.length > 0
            ? 'specificField'
            : 'employersName',
        );
        setLoading(false);
      }, 1200);
    }
  }, [formData, exportContactId]);

  const onChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const suffixData = [
    { label: 'JR', value: 'JR' },
    { label: 'SR', value: 'SR' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' },
    { label: 'V', value: 'V' },
    { label: 'VI', value: 'VI' },
    { label: 'VII', value: 'VII' },
    { label: 'VIII', value: 'VIII' },
  ];

  const selectedCountry = (e) => {
    if (e?.value == 'WADC') {
      setValue('mailingCity', 'Washington DC');
    } else if (formData && Object.keys(formData)?.length > 0) {
      setValue('mailingCity', formData?.city);
    } else if (exportContactId && Object.keys(exportContactId)?.length > 0) {
      setValue('mailingCity', exportContactId?.city);
    } else {
      setValue('mailingCity', '');
    }
  };

  useEffect(() => {
    if(jobTitle === 'Retired') {
      setValue('employerName', 'Not Employed');
      setValue('jobTitle', 'No Job Title or Profession');
    } else {
      setValue('employerName', '');
      setValue('jobTitle', '');
    }
  }, [jobTitle])

  return (
    <Row gutter={[48, 20]}>
      <Col span={12}>
        <Row gutter={[8, 10]}>
          <Col span={24} style={{ marginBottom: '-8px' }}>
            <Text style={{ fontSize: '12px', color: '#596780' }}>Name</Text>
          </Col>
          <Col span={6}>
            <InputField
              isRequired={true}
              fieldname="firstName"
              label=""
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'First',
              }}
              rules={{
                required: 'Name is required.',
              }}
              validate={errors.firstName && 'error'}
              validMessage={errors.firstName && errors.firstName.message}
            />
          </Col>

          <Col span={6}>
            <InputField
              fieldname="middle_name"
              label=""
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'Middle',
              }}
            />
          </Col>

          <Col span={6}>
            <InputField
              fieldname="lastName"
              label=""
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'Last',
              }}
            />
          </Col>

          <Col span={6}>
            <SelectField
              fieldname="suffix"
              control={control}
              class="mb-0"
              iProps={{ placeholder: 'Suffix', isClearable: true }}
              initValue=""
              selectOption={suffixData}
            />
          </Col>

          <Col span={24}>
            <Row>
              <Col span={24}>
                <InputRadio
                  fieldname="jobRadio"
                  control={control}
                  initValue={'Job_title'}
                  classField="small-radio"
                  onChange={(e) => setJobTitle(e.target.value)}
                  options={
                    <>
                      <Radio value="Job_title">Job Title</Radio>
                      <Radio value="Profession">Profession</Radio>
                      <Radio value="Retired">Retired</Radio>
                    </>
                  }
                />
              </Col>
              <Col span={24}>
                {(jobTitle == 'Job_title' || jobTitle == 'Retired') && (
                  <InputField
                    fieldname="jobTitle"
                    control={control}
                    initValue=""
                    iProps={{
                      size: 'large',
                      placeholder: jobTitle == 'Retired' ? 'No Job Title or Profession' : 'Job Title',
                      disabled: jobTitle === 'Retired'
                    }}
                  />
                )}

                {jobTitle == 'Profession' && (
                  <InputField
                    fieldname="profession"
                    control={control}
                    initValue=""
                    iProps={{
                      size: 'large',
                      placeholder: 'Profession',
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={24}>
                <InputRadio
                  fieldname="employeeRadio"
                  control={control}
                  initValue={'employersName'}
                  onChange={(e) => setEmployeeName(e.target.value)}
                  classField="small-radio"
                  options={
                    <>
                      <Radio value="employersName" disabled={jobTitle === 'Retired'}>Employer's Name</Radio>
                      <Radio value="specificField" disabled={jobTitle === 'Retired'}>Specific Field</Radio>
                    </>
                  }
                />
              </Col>
              <Col span={24}>
                {employeeName == 'employersName' && (
                  <InputField
                    fieldname="employerName"
                    control={control}
                    initValue=""
                    iProps={{
                      size: 'large',
                      placeholder: "Employer's Name",
                      disabled: jobTitle === 'Retired'
                    }}
                  />
                )}

                {employeeName == 'specificField' && (
                  <SelectField
                    fieldname="specificField"
                    control={control}
                    class="mb-0"
                    iProps={{ placeholder: 'Specific Field' }}
                    initValue=""
                    selectOption={specificField}
                  />
                )}
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <InputCheckbox
              fieldname="candidate_spouse"
              label="Candidate Spouse"
              class={`mb-0 one-checbox`}
              control={control}
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Row gutter={[30, 20]}>
          <Col span={24} className="mailingField">
            <Row gutter={[12, 8]}>
              <Col span={24}>
                <InputField
                  fieldname="mailingStreet"
                  label="Mailing Address"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'Street Address',
                  }}
                />
              </Col>

              <Col span={9}>
                <SelectField
                  fieldname="mailingcountryList"
                  control={control}
                  class="mb-0"
                  iProps={{ placeholder: 'Please select' }}
                  initValue={{}}
                  selectOption={countryList}
                  onChange={(e) => selectedCountry(e)}
                />
              </Col>

              <Col span={9}>
                <InputField
                  fieldname="mailingCity"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'City',
                  }}
                />
              </Col>

              <Col span={6}>
                <InputField
                  fieldname="mailingZip"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    placeholder: 'Zip',
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-label">
                <label className="" title="Phone Number">
                  Phone Number
                </label>
                <div className="optional-label" style={{ top: '4px' }}>
                  (Optional)
                </div>
              </div>
            </div>
            <InputMask mask="(999) 999-9999" value={phoneNumber} onChange={onChange}>
              {(inputProps) => <input {...inputProps} type="tel" className="ant-input" />}
            </InputMask>
          </Col>

          <Col span={12}>
            <InputField
              fieldname="email"
              label="Email"
              optionalLabel="(Optional)"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: 'Check if the email is valid',
                },
              }}
              validate={errors.email && 'error'}
              validMessage={errors.email && errors.email.message}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
