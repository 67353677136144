import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Image, Button, Popover, message } from 'antd';
import Login from '../../pages/Login';
import accepted from '../../../assets/svg/accepted.svg';
import { CircleArrowRed } from '../../atoms/CustomIcons';

const { Title, Paragraph } = Typography;

export default (props) => {
  const history = useHistory();

  const redirect = () => {
    history.push('/');
  };
  return (
    <Login load={false}>
      <Row gutter={[30, 24]}>
        <Col span={24}>
          <Title level={2} className="text-center mb-0 font-gilroy">
            Instructions Sent
          </Title>
          <Paragraph className="text-center mt-1">Please check your inbox.</Paragraph>
        </Col>

        <Col span={24} className="text-center">
          <Image width={136} src={accepted} preview={false} />
        </Col>
        <Col span={24} className="text-center mb-0">
          <Link to="/" className="text-uppercase">
            Back to login
          </Link>
        </Col>
      </Row>
    </Login>
  );
};
