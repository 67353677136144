import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio, Button, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { InputRadio, InputField, TextAreaField, DateField, SelectField } from '../../../../../atoms/FormElement';
import { getReceiptDropdownData } from '../../ducks/actions';
import { FORMS_OF_PAYMENTS, specificField } from '../../../../../../configs/constants';
import { getContactFormData, getContactFormDataPayee } from '../../../Contacts/ducks/actions';
import { useFieldArray } from 'react-hook-form';
import plusIcon from '../../../../../../assets/svg/plus.svg';
import trashIcon from '../../../../../../assets/svg/trash-icon.svg';
import { InfoCircleFilled } from '@ant-design/icons';
import ContactBox from '../../../../../molecules/Dashboard/ContactBox';
import Endorsers from './Endorsers';

const { Paragraph, Title } = Typography;

const initQ = {
  endorser_id: '',
  contact_id: '',
  is_amount: '',
  amount: '',
};

export default (props) => {
  const {
    control,
    errors,
    formData,
    setValue,
    setLoading,
    watch,
    deletedIndexes,
    setDeletedIndexes,
    formOfPayment,
    setFormOfPayment,
  } = props;
  const dispatch = useDispatch();
  const [contactBoxEditCase, setContactBoxEditCase] = useState('');
  const [contactToSelect, setContactToSelect] = useState(null);
  const [contactBoxEditCaseLending, setContactBoxEditCaseLending] = useState('');
  const [contactToSelectLending, setContactToSelectLending] = useState(null);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'endorser',
  });
  const receiptFormData = useSelector((state) => state.receipt.receiptDropData);
  const lenderData = useSelector((state) => state.contact.contactFormData);
  const lendinginsData = useSelector((state) => state.contact.contactFormDataPayee);

  useEffect(() => {
    dispatch(getReceiptDropdownData('general_contribution', 'null'));
  }, []);

  useEffect(() => {
    if (
      formData?.receiptData &&
      Object.keys(receiptFormData)?.length > 0 &&
      Object.keys(formData?.receiptData)?.length > 0
    ) {
      const selectedAccountList = receiptFormData?.accounts?.find((e) => e?.value == formData?.receiptData?.account_id);
      const lendingInsID = receiptFormData?.contributors?.find(
        (e) => e?.value == formData?.receiptData?.lending_institution_id,
      );
      const lenderID = receiptFormData?.contributors?.find((e) => e?.value == formData?.receiptData?.contributor_id);
      setValue('amount', formData.receiptData?.amount);
      setValue('date', formData?.receiptData.date ? moment(formData.receiptData.date, 'YYYY-MM-DD HH:mm') : '');
      setValue(
        'end_date',
        formData.receiptData.end_date ? moment(formData.receiptData.end_date, 'YYYY-MM-DD HH:mm') : '',
      );
      setValue('rate', formData.receiptData?.rate);
      setValue('security_pledged', formData.receiptData?.security_pledged);
      setValue('lending_institution', lendingInsID);
      setValue('contributor_id', lenderID);
      setValue('formOfPayment', formData.receiptData?.payment_type);
      setFormOfPayment(formData.receiptData?.payment_type);
      setValue('loan_number', formData.receiptData?.loan_number);
      setValue('comments', formData.receiptData?.comments);
      setContactToSelect(null);
      setContactToSelectLending(null);
      setTimeout(() => {
        setValue('check_number', formData.receiptData?.check_number);
        setValue('in_kind_description', formData.receiptData?.in_kind_description);
        setValue('account', selectedAccountList);
        setContactBoxEditCase(lenderID);
        setContactBoxEditCaseLending(lendingInsID);
      }, 1000);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [receiptFormData, formData]);

  useEffect(() => {
    if (formData && formData?.endorsers) {
      //console.log('formData?.endorsers', formData?.endorsers);
      setValue('endorser', formData?.endorsers);
    }
  }, [formData]);

  const changeValueByDropDown = (e) => {
    dispatch(getContactFormData(e));
  };

  const changeValueByDropDownLending = (e) => {
    dispatch(getContactFormDataPayee(e));
  };

  const updateDropdownValues = () => {
    dispatch(getReceiptDropdownData('general_contribution', 'null'));
  };

  const formPayment = (e) => {
    setFormOfPayment(e.target.value);

    if (receiptFormData && receiptFormData?.accounts?.length == 1) {
      setTimeout(() => {
        setValue('account', receiptFormData?.accounts[0]);
      }, 500);
    }
  };

  return (
    <>
      <Row gutter={[48, 30]}>
        <Col span={24}>
          <Paragraph className="c-blue">Use this from to report a loan received by the committee.</Paragraph>
        </Col>
        <Col span={12}>
          <Row gutter={[12, 30]}>
            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="amount"
                label="Amount"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  prefix: '$',
                  type: 'number',
                }}
                rules={{
                  required: 'Amount is required.',
                }}
                validate={errors.amount && 'error'}
                validMessage={errors.amount && errors.amount.message}
              />
            </Col>

            <Col span={12}>
              <DateField
                fieldname="date"
                label="Start Date"
                control={control}
                class="mb-0"
                iProps={{ size: 'large' }}
                initValue=""
                isRequired={true}
                rules={{
                  required: 'Date is required.',
                }}
                validate={errors.date && 'error'}
                validMessage={errors.date && errors.date.message}
              />
            </Col>

            <Col span={12}>
              <DateField
                fieldname="end_date"
                label="End Date"
                control={control}
                class="mb-0"
                iProps={{ size: 'large' }}
                initValue=""
                isRequired={true}
                tooltip={true}
                tooltipTitle="Enter the end date only if it is set."
              />
            </Col>

            <Col span={8}>
              <InputField
                isRequired={true}
                fieldname="rate"
                label="Rate"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  suffix: '%',
                  type: 'number',
                }}
                tooltip={true}
                tooltipTitle="The interest rate of the loan (if applicable)."
              />
            </Col>

            <Col span={16}>
              <InputField
                isRequired={true}
                fieldname="security_pledged"
                label="Security Pledged"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                tooltip={true}
                tooltipTitle="The security pledged (if applicable)."
              />
            </Col>

            <ContactBox
              control={control}
              errors={errors}
              selectData={receiptFormData?.contributors}
              changeValueByDropDown={changeValueByDropDown}
              updateDropdownValues={updateDropdownValues}
              contributerData={lenderData}
              contactBoxEditCase={contactBoxEditCase}
              contactToSelect={contactToSelect}
              setContactToSelect={setContactToSelect}
              setValue={setValue}
              fieldname="contributor_id"
              label="Lender"
            />

            {/* <Col span={24}>
              <SelectField
                isRequired={true}
                fieldname="contributor_id"
                label="Lender"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  placeholder: 'Please select',
                }}
                selectOption={
                  receiptFormData?.contributors && receiptFormData?.contributors?.length > 0
                    ? receiptFormData?.contributors
                    : []
                }
                rules={{
                  required: 'Lender is required.',
                }}
                validate={errors.contributor_id && 'error'}
                validMessage={errors.contributor_id && errors.contributor_id.message}
              />
            </Col> */}

            <ContactBox
              control={control}
              errors={errors}
              selectData={receiptFormData?.contributors}
              changeValueByDropDown={changeValueByDropDownLending}
              updateDropdownValues={updateDropdownValues}
              contributerData={lendinginsData}
              contactBoxEditCase={contactBoxEditCaseLending}
              contactToSelect={contactToSelectLending}
              setContactToSelect={setContactToSelectLending}
              setValue={setValue}
              fieldname="lending_institution"
              label="Full Name of Lending Institution"
              noValidation={true}
            />

            {/* <Col span={16}>
              <SelectField
                isRequired={true}
                fieldname="lending_institution"
                label="Full Name of Lending Institution"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  placeholder: 'Please select',
                }}
                selectOption={
                  receiptFormData?.contributors && receiptFormData?.contributors?.length > 0
                    ? receiptFormData?.contributors
                    : []
                }
                tooltip={true}
                tooltipTitle="The full name of the lending institution from which the loan was received (if applicable)."
              />
            </Col> */}

            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="loan_number"
                label="Loan Number"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  type: 'number',
                }}
                tooltip={true}
                tooltipTitle="The loan number the lending institution assigns the loan (if applicable)."
              />
            </Col>

            <Col span={24}>
              <TextAreaField
                fieldname="comments"
                label="Comments"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                optionalLabel={true}
              />
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <InputRadio
                fieldname="formOfPayment"
                label="Form of Payment"
                control={control}
                initValue={''}
                classField="custom-radio"
                onChange={(e) => formPayment(e)}
                options={
                  <>
                    {FORMS_OF_PAYMENTS &&
                      FORMS_OF_PAYMENTS?.map((item, index) => (
                        <Fragment key={index}>
                          <Radio.Button value={item?.value}>
                            <Image src={item?.image} preview={false} width={80} />
                            <Paragraph className="smallFont12">{item?.label}</Paragraph>
                          </Radio.Button>
                        </Fragment>
                      ))}
                  </>
                }
                rules={{
                  required: 'Form of Payment is required.',
                }}
                validate={errors.formOfPayment && 'error'}
                validMessage={errors.formOfPayment && errors.formOfPayment.message}
              />
            </Col>

            {formOfPayment && (
              <Col span={24}>
                {formOfPayment != 'in_kind' && (
                  <Row gutter={16}>
                    {formOfPayment == 'check' && (
                      <Col span={12}>
                        <InputField
                          fieldname="check_number"
                          label="Check Number"
                          control={control}
                          initValue=""
                          optionalLabel="(Optional)"
                          iProps={{
                            size: 'large',
                          }}
                        />
                      </Col>
                    )}
                    <Col span={formOfPayment == 'check' ? 12 : 24}>
                      <SelectField
                        isRequired={true}
                        fieldname="account"
                        label="Account"
                        control={control}
                        class="mb-0"
                        iProps={{ placeholder: 'Please select' }}
                        rules={{ required: 'Account is required.' }}
                        initValue=""
                        selectOption={receiptFormData?.accounts}
                        validate={errors.account && 'error'}
                        validMessage={errors.account && errors.account.message}
                      />
                    </Col>
                  </Row>
                )}

                {formOfPayment == 'in_kind' && (
                  <Col span={24}>
                    <InputField
                      isRequired={true}
                      fieldname="in_kind_description"
                      label="In-Kind Description"
                      control={control}
                      initValue=""
                      iProps={{
                        size: 'large',
                      }}
                      rules={{
                        required: 'In-Kind Description is required.',
                      }}
                      validate={errors.in_kind_description && 'error'}
                      validMessage={errors.in_kind_description && errors.in_kind_description.message}
                    />
                  </Col>
                )}
              </Col>
            )}

            <Endorsers
              formData={formData}
              fields={fields}
              watch={watch}
              control={control}
              errors={errors}
              setValue={setValue}
              append={append}
              remove={remove}
              selectData={receiptFormData?.contributors}
              deletedIndexes={deletedIndexes}
              setDeletedIndexes={setDeletedIndexes}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};
