import React, { useState, useEffect } from 'react';
import { Layout, Row, Typography, Spin, Col, Card, Space, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import DashboardSidebar from '../../molecules/Dashboard/Sidebar';
import MainHeader from '../../molecules/Dashboard/Header';
import { useSelector } from 'react-redux';

const { Content, Footer, Sider } = Layout;
const { Title, Text, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const [isFederalPAC, setIsFederalPAC] = useState(true);
  const dashboardData = useSelector((state) => state.dashboard.dashboardData);
  const currentCycleData = useSelector((state) => state.dashboard.currentCycleData);
  const federalPAC = localStorage.getItem('organization');

  useEffect(() => {
    if ((dashboardData && dashboardData?.isFederalCommittee) || federalPAC === 'federal') {
      setIsFederalPAC(false);
    }
  }, [federalPAC, dashboardData]);

  useEffect(() => {
    if (currentCycleData?.isFederalCommittee) {
      localStorage.setItem('organization', 'federal');
      setIsFederalPAC(false);
    }
  }, [currentCycleData?.isFederalCommittee]);

  return (
    <Spin indicator={antIcon} size="large" spinning={props?.load}>
      <Layout>
        <DashboardSidebar noLink={props?.noLink} isFederalPAC={isFederalPAC} />
        <Layout className="base-layout dashboard-layout">
          <MainHeader
            heading={props?.heading}
            backLink={props?.backLink}
            contactButton={true}
            userAccount={props?.noLink ? false : true}
            tags={props?.tags}
          />
          <Content className="custom-content">
            <Card bordered={false} bodyStyle={{ padding: 0 }}>
              {props.children}
            </Card>
          </Content>
        </Layout>
      </Layout>
    </Spin>
  );
};
