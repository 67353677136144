import axios from '../../../../../services/axiosInterceptor';
import * as action_types from './constants';
import { baseUrl } from '../../../../../configs/constants';

export const getReportListingData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getReportList`);
    dispatch({
      type: action_types.REPORT_LIST_DATA,
      data: data,
    });
  };
};

export const getReportFormData = (type, isAmendment = false,year) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getReportFormData/${type}/${isAmendment}/${year}`);
    dispatch({
      type: action_types.REPORT_FORM_DATA,
      data: data,
    });
  };
};

export const getReportDialogData = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getReportsDialogData/`);
    dispatch({
      type: action_types.REPORT_DIALOG_DATA,
      data: data,
    });
  };
};

export const getReportDetailData = (id) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/reports/${id}`);
    dispatch({
      type: action_types.REPORT_DETAIL_DATA,
      data: data,
    });
  };
};

export const getAdminSupportData = (id) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/adminSupporByReportId/${id}`);
    dispatch({
      type: action_types.ADMIN_SUPPORT_DATA,
      data: data,
    });
  };
};

export const getObligationsData = (id, type) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/obligationByReportId/${id}/${type}`);
    dispatch({
      type: action_types.OBLIGATION_BY,
      data: data,
    });
  };
};


export const setFiltersOptionsReport = (type, value) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS,
      payload: { filter: { [type]: value } },
    })
  }
}

export const clearReportFilters = () => ({
  type: action_types.CLEAR_REPORT_FILTER,
})