import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Image, Button, Popover, message } from 'antd';
import Welcome from '../../pages/Welcome';
import accepted from '../../../assets/svg/accepted.svg';
import { CircleArrowRed } from '../../atoms/CustomIcons';
import { verifyEmail } from '../../../services/services';

const { Title, Paragraph } = Typography;

export default (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailVerificationToken = params.get('ticket');
    const authId = params.get('auth');
    verifyEmail(authId, emailVerificationToken)
      .then((response) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setLoading(false);
        setSuccess(false);
        message.error(error?.response?.data?.error?.message);
      });
  }, []);
  const getMessage = () => {
    switch (success) {
      case null:
        return 'Please Wait.';
      case true:
        return 'Your Email Is Confirmed';
      case false:
        return 'Unable To Verify Email.';
    }
  };
  const redirect = () => {
    history.push('/');
  };
  return (
    <Welcome load={loading}>
      <Row gutter={[30, 24]}>
        <Col span={24}>
          <Title level={2} className="text-center mb-0 font-gilroy">
            {getMessage()}
          </Title>
          {success && <Paragraph className="text-center mt-1">Thank you for your time.</Paragraph>}
        </Col>
        {success != null && (
          <>
            <Col span={24} className="text-center">
              {success && <Image width={136} src={accepted} preview={false} />}
            </Col>
            <Col span={24} className="mb-0 mt-1 text-center">
              <Button type="primary" onClick={() => redirect()}>
                {success ? 'Go to My Account' : 'Back'}
              </Button>
            </Col>
          </>
        )}
      </Row>
    </Welcome>
  );
};
