import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Spin, Descriptions, message } from 'antd';
import { useForm } from 'react-hook-form';
import { InputField } from '../../../../../../atoms/FormElement';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { getReportFormData } from '../../../ducks/actions';
import { DATE_FORMAT, ReportTypeCode, urlToReportTypeMap } from '../../../../../../../configs/constants';
import moment from 'moment';
import { addObject } from '../../../../../../../services/crudApis';
import { ADD_REPORT } from '../../../../../../../configs/endpoints';

const { Title, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { currentStep, setCurrentStep, setstartDate, setendDate, type, setReportId, getYear } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, handleSubmit, errors, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const reportFormData = useSelector((state) => state.report.reportFormData);

  useEffect(() => {
    dispatch(getReportFormData(urlToReportTypeMap[type], true, getYear));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (reportFormData && Object.keys(reportFormData)?.length > 0) {
      setValue('fundraisers', reportFormData?.noOfFundraisers);
    }

    if (reportFormData) {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [reportFormData]);

  const onFinish = (values) => {
    setLoading(true);
    //console.log({ startDate: reportFormData?.startPeriod, enddate: reportFormData?.endPeriod });
    setstartDate(moment(reportFormData?.startPeriod).format('YYYY-MM-DD'));
    setendDate(moment(reportFormData?.endPeriod).format('YYYY-MM-DD'));

    let requestBody = {
      type: urlToReportTypeMap[type],
      status: 'draft',
      start_date: moment(reportFormData?.startPeriod).format('YYYY-MM-DD HH:mm:ss') + 'Z',
      end_date: moment(reportFormData?.endPeriod).format('YYYY-MM-DD HH:mm:ss') + 'Z',
      no_of_fundraiser: values.fundraisers,
      isAmendment: true,
    };

    //console.log('requestBody');
    //console.log(JSON.stringify(requestBody));

    addObject(ADD_REPORT, requestBody)
      .then((response) => {
        //console.log('response', response);
        if (response.data) {
          setReportId(response.data);
        }
        setLoading(false);
        setCurrentStep(currentStep + 1);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
        //console.log(e);
      });
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row justify="center">
        <Col flex="0 0 500px">
          <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <div className="detail_label_ins">
                  <Descriptions layout="vertical" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
                    <Descriptions.Item label="Committee Name">{reportFormData?.committeeName}</Descriptions.Item>
                    <Descriptions.Item label="Board of Elections ID">{reportFormData?.boeId}</Descriptions.Item>
                    <Descriptions.Item label="Report Period Start Date">
                      {moment(reportFormData?.startPeriod).isValid()
                        ? moment(reportFormData?.startPeriod).format(DATE_FORMAT)
                        : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Report Period End Date">
                      {' '}
                      {moment(reportFormData?.endPeriod).isValid()
                        ? moment(reportFormData?.endPeriod).format(DATE_FORMAT)
                        : '-'}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </Col>

              <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname="fundraisers"
                  label="Number of Fundraisers"
                  control={control}
                  initValue=""
                  iProps={{
                    size: 'large',
                    type: 'number',
                  }}
                  rules={{
                    required: 'Number of Fundraisers is required.',
                  }}
                  validate={errors.fundraisers && 'error'}
                  validMessage={errors.fundraisers && errors.fundraisers.message}
                />
              </Col>

              {/* <Col span={24}>
                <Row gutter={[20, 20]} align="bottom" style={{ marginBottom: '20px' }}>
                  <Col span={12}>
                    <InputField
                      fieldname="bankAccountCode"
                      label="Bank Account Code"
                      control={control}
                      class="mb-0"
                      iProps={{
                        size: 'large',
                        type: 'number',
                      }}
                      rules={{
                        required: 'Bank Account Code is required.',
                      }}
                      validate={errors.bankAccountCode && 'error'}
                      validMessage={errors.bankAccountCode && errors.bankAccountCode.message}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      fieldname="periodBeginBalance"
                      label="Period Begin Balance"
                      control={control}
                      class="mb-0"
                      iProps={{
                        size: 'large',
                        prefix: '$',
                        type: 'number',
                      }}
                      rules={{
                        required: 'Period Begin Balance is required.',
                      }}
                      validate={errors.periodBeginBalance && 'error'}
                      validMessage={errors.periodBeginBalance && errors.periodBeginBalance.message}
                    />
                  </Col>
                </Row>
              </Col> */}

              <Col span={24} className="text-center">
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Continue
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};
