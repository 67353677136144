import React, { Fragment } from 'react';
import { Space, Button, Row, Col, Typography, Image, Card } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import crossIcon from '../../../assets/svg/cross-icon.svg';
import organizational from '../../../assets/svg/report/org.svg';
import midYear from '../../../assets/svg/report/my.svg';
import quarter1 from '../../../assets/svg/report/q1.svg';
import quarter2 from '../../../assets/svg/report/q2.svg';
import quarter3 from '../../../assets/svg/report/q3.svg';
import quarter4 from '../../../assets/svg/report/q4.svg';
import yearEnd from '../../../assets/svg/report/ye.svg';
import hour48 from '../../../assets/svg/report/h-48.svg';

import organizationalA from '../../../assets/svg/report/org-a.svg';
import midYearA from '../../../assets/svg/report/my-a.svg';
import quarter1A from '../../../assets/svg/report/q1-a.svg';
import quarter2A from '../../../assets/svg/report/q2-a.svg';
import quarter3A from '../../../assets/svg/report/q3-a.svg';
import quarter4A from '../../../assets/svg/report/q4-a.svg';
import yearEndA from '../../../assets/svg/report/ye-a.svg';
import hour48A from '../../../assets/svg/report/h-48-a.svg';

import organizationalInactive from '../../../assets/svg/report/org-inactive.svg';
import midYearInactive from '../../../assets/svg/report/my-inactive.svg';
import quarter1Inactive from '../../../assets/svg/report/q1-inactive.svg';
import quarter2Inactive from '../../../assets/svg/report/q2-inactive.svg';
import quarter3Inactive from '../../../assets/svg/report/q3-inactive.svg';
import quarter4Inactive from '../../../assets/svg/report/q4-inactive.svg';
import yearEndInactive from '../../../assets/svg/report/ye-inactive.svg';
import hour48Inactive from '../../../assets/svg/report/h-48-inactive.svg';
import { ReportCase } from '../../../configs/constants';

const { Title, Paragraph, Text } = Typography;

export default (props) => {
  const { onClose, reportPopData, title, fileExternal } = props;
  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={4} className="mb-0">
            {title}
          </Title>
        </Col>
        <Col>
          <Button size="large" type="link" className="p-0" onClick={onClose}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>
      </Row>

      {reportPopData &&
        reportPopData?.length > 0 &&
        Object?.entries(reportPopData[0])?.length > 0 &&
        Object?.entries(reportPopData[0]?.committeeSchedule) &&
        Object?.entries(reportPopData[0]['committeeSchedule']).map(([itemkey, itemvalue], index) => {
          return (
            <Fragment key={index}>
              <Row justify="space-between" align="middle" className="mb-1" gutter={[8, 8]}>
                <Col>
                  <Title level={5} className="mb-0">
                    {itemkey}
                  </Title>
                </Col>
                {itemvalue.map((items) => {
                  return Object.entries(items).map(([k, v], myindex) => (
                    <Fragment key={index}>
                      <Col span={24}>
                        <Card
                          className={
                            v?.case === ReportCase.DISABLE
                              ? 'white-card popup-card disabled-card'
                              : 'white-card popup-card'
                          }
                        >
                          <Row gutter={15} align="middle">
                            <Col flex="0 0 56px" style={{ display: 'flex' }}>
                              <Link
                                to={`/reports/${v?.heading.toLowerCase().replace(/ /g, '-')}`}
                                className={v?.case === ReportCase.DISABLE ? 'disabled-link' : ''}
                              >
                                {v?.case === ReportCase.AMENDMENT ? (
                                  <Image
                                    src={
                                      v?.heading == 'Organizational'
                                        ? organizationalA
                                        : '' || v?.heading == 'Mid-Year Semiannual'
                                        ? midYearA
                                        : '' || v?.heading == 'Year-End Semiannual'
                                        ? yearEndA
                                        : '' || v?.heading == 'First Quarter Plus'
                                        ? quarter1A
                                        : '' || v?.heading == 'Second Quarter'
                                        ? quarter2A
                                        : '' || v?.heading == 'Third Quarter Plus'
                                        ? quarter3A
                                        : '' || v?.heading == 'Fourth Quarter'
                                        ? quarter4A
                                        : '' || v?.heading == '48-Hour Notice'
                                        ? hour48
                                        : ''
                                    }
                                    preview={false}
                                    width={56}
                                  />
                                ) : v?.case === ReportCase.ACTIVE || v?.case === ReportCase.Due ? (
                                  <Image
                                    src={
                                      v?.heading == 'Organizational'
                                        ? organizational
                                        : '' || v?.heading == 'Mid-Year Semiannual'
                                        ? midYear
                                        : '' || v?.heading == 'Year-End Semiannual'
                                        ? yearEnd
                                        : '' || v?.heading == 'First Quarter Plus'
                                        ? quarter1
                                        : '' || v?.heading == 'Second Quarter'
                                        ? quarter2
                                        : '' || v?.heading == 'Third Quarter Plus'
                                        ? quarter3
                                        : '' || v?.heading == 'Fourth Quarter'
                                        ? quarter4
                                        : '' || v?.heading == '48-Hour Notice'
                                        ? hour48
                                        : ''
                                    }
                                    preview={false}
                                    width={56}
                                  />
                                ) : (
                                  <Image
                                    src={
                                      v?.heading == 'Organizational'
                                        ? organizationalInactive
                                        : '' || v?.heading == 'Mid-Year Semiannual'
                                        ? midYearInactive
                                        : '' || v?.heading == 'Year-End Semiannual'
                                        ? yearEndInactive
                                        : '' || v?.heading == 'First Quarter Plus'
                                        ? quarter1Inactive
                                        : '' || v?.heading == 'Second Quarter'
                                        ? quarter2Inactive
                                        : '' || v?.heading == 'Third Quarter Plus'
                                        ? quarter3Inactive
                                        : '' || v?.heading == 'Fourth Quarter'
                                        ? quarter4Inactive
                                        : '' || v?.heading == '48-Hour Notice'
                                        ? hour48Inactive
                                        : ''
                                    }
                                    preview={false}
                                    width={56}
                                  />
                                )}
                              </Link>
                            </Col>
                            <Col span={v?.heading == '48-Hour Notice' ? 19 : 15}>
                              <Link
                                to={
                                  v?.case === ReportCase.AMENDMENT
                                    ? `/reports-amendment/${v?.heading
                                        .toLowerCase()
                                        .replace(/ /g, '-')}?year=${itemkey}&case=${v?.case}`
                                    : `/reports/${v?.heading.toLowerCase().replace(/ /g, '-')}?year=${itemkey}&case=${
                                        v?.case
                                      }`
                                }
                                className={v?.case === ReportCase.DISABLE ? 'disabled-link' : ''}
                              >
                                <Title level={4} style={{ marginBottom: '6px' }}>
                                  {v?.heading}{' '}
                                  {v?.case === ReportCase.AMENDMENT &&
                                    v?.heading !== '48-Hour Notice' &&
                                    ' (Amendment)'}
                                </Title>
                                <Paragraph className="mb-0">
                                  {v?.content}{' '}
                                  <span style={{ color: 'red' }}>
                                    {' '}
                                    {v?.case === ReportCase.Due ? v?.dueDateData : ''}
                                  </span>
                                </Paragraph>
                              </Link>
                            </Col>
                            <Col className="ml-auto">
                              <Space size={18}>
                                {(v?.case === ReportCase.ACTIVE && v?.heading !== '48-Hour Notice') ||
                                (v?.case === ReportCase.Due && v?.heading !== '48-Hour Notice') ? (
                                  <Button
                                    type="text"
                                    onClick={() => {
                                      fileExternal(v?.heading, itemkey);
                                    }}
                                    className="filed-external"
                                  >
                                    filed externally
                                  </Button>
                                ) : (
                                  ''
                                )}
                                <Text>
                                  {v?.case === ReportCase.AMENDMENT && (
                                    <span style={{ color: '#424C5F' }}>
                                      {' '}
                                      {v?.heading == '48-Hour Notice' ? '$6.99' : '$24.99'}
                                    </span>
                                  )}

                                  {!(v?.case === ReportCase.AMENDMENT) && (
                                    <span style={{ color: '#424C5F' }}>
                                      {' '}
                                      {v?.heading == '48-Hour Notice' ? '$9.99' : '$59.99'}
                                    </span>
                                  )}
                                </Text>
                              </Space>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Fragment>
                  ));
                })}
              </Row>
            </Fragment>
          );
        })}
    </>
  );
};
