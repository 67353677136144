import React, { useState, useEffect } from 'react';
import { Button, Progress, message, Typography, Row, Col, Image, Spin, Space, Card } from 'antd';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import AddCommittee from '../../templates/AddCommittee';
import { UploadField } from '../../atoms/FormElement';
import uploadIcon from '../../../assets/svg/upload.svg';
import importVideo from '../../../assets/img/import-video.png';
import importingImage from '../../../assets/svg/importing.svg';
import completed from '../../../assets/svg/completed.svg';
import rejected from '../../../assets/svg/rejected.svg';
import infoIcon from '../../../assets/svg/info-icon.svg';
import { Popup } from '../../atoms/Popup';
import ReleaseNotes from '../../molecules/Dashboard/ReleaseNotes';
import ContactSupport from '../../molecules/Dashboard/ContactSupport';
import DataPopup from './DataPopup';
import Success from './Components/Success';
import Reject from './Components/Reject';
import { baseUrl } from '../../../configs/constants';
import { DATA_IMPORT, DATA_IMPORT_SELECTED_ID } from '../../../configs/endpoints';
import { addObject, getLogsByCommitteeId } from '../../../services/crudApis';
import { useSelector, useDispatch } from 'react-redux';
import { clearData } from '../Dashboard/Settings/ducks/actions';

const { Title, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [importingDone, setImportingDone] = useState(false);
  const [importStatus, setImportStatus] = useState({
    show: false,
    error: false,
    status: {
      name: '',
      boe_id: '',
      accounts: '',
      officers: '',
      contacts: '',
      receipts: '',
      expenditures: '',
      cat: '',
      reports: '',
    },
  });
  const { control, handleSubmit, errors } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [releaseVisible, setReleaseVisible] = useState(false);
  const [contactPopVisible, setContactPopVisible] = useState(false);
  const [fileUploading, setFileUploading] = useState({
    percent: 0,
    isUploading: false,
  });
  const [selectCommiteePopupProps, setSelectCommiteePopupProps] = useState({
    show: false,
    data: [],
    showError: false,
    filepath: '',
  });
  const [getLogsData, setGetLogsData] = useState([]);
  // const getLogsData = useSelector((state) => state.setting.getLogsData);
  const token = JSON.parse(localStorage.getItem('token'));

  // const onFinish = (values) => {
  //   //console.log('values', values);
  //   setLoading(true);
  //   //console.log('JSON.stringify(info)  onFinishonFinishonFinishonFinish');
  // };

  useEffect(() => {
    dispatch(clearData());
  }, []);

  let intervalId;

  const getLogsApi = (status) => {
    getLogsByCommitteeId()
      .then((e) => {
        setGetLogsData(e?.data);
        if (e?.data?.length !== 10 && status === 'false') {
          intervalId = setInterval(() => {
            getLogsApi(false);
          }, 1000);
        } else {
          clearInterval(intervalId);
          if (e?.data?.length > 9) {
            setTimeout(() => {
              setImportingDone(true);
              setFileUploading({
                percent: 100,
                isUploading: false,
              });
              message.success('Data Imported Successfully.');
            }, 4000);
          }
        }
      })
      .catch((e) => message.error('Something went wrong'));
  };

  const draggerProps = {
    name: 'file',
    multiple: false,
    accept: '.mdb',
    action: baseUrl + '/' + DATA_IMPORT,
    headers: { Authorization: `Bearer ${token.access_token}` },

    onChange(info) {
      const { status } = info.file;

      if (status === 'uploading') {
        setFileUploading({
          percent: Math.round((info.file?.percent + Number.EPSILON) * 100) / 100,
          isUploading: true,
        });

        if (info.file?.percent > 99) {
          getLogsApi('false');
        }
      }

      if (status === 'success') {
        message.success(`${info.file.name} success`);
      }

      if (status === 'done') {
        getLogsApi('true');
        // //console.log('done');
        // message.success(`${info.file.name} file uploaded successfully.`);

        if (
          info.file.response &&
          info.file.response.success &&
          info.file.response.data &&
          info.file.response.data.availableCommittees &&
          info.file.response.data.availableCommittees.length > 0
        ) {
          // getLogsApi('true');
          setSelectCommiteePopupProps({
            show: true,
            data: info.file.response.data.availableCommittees,
            filepath: info.file.response.data.filepath,
            showError: false,
          });
        } else if (
          info.file.response &&
          info.file.response.success &&
          info.file.response.data &&
          info.file.response.data.status
        ) {
          getLogsApi('true');
          setIsImporting(false);
          //setImportingDone(true);
          setImportStatus({ show: true, error: false, status: info.file.response.data.status });
          // setFileUploading({
          //   percent: 100,
          //   isUploading: false,
          // });
        } else if (info.file.response && info.file.response.data && !info.file.response.success) {
          getLogsApi('true');
          //setIsImporting(false);
          setImportStatus({ show: true, error: true });
          // setFileUploading({
          //   percent: 100,
          //   isUploading: false,
          // });
        }
      } else if (status === 'error') {
        setImportStatus({ show: true, error: true });
        setIsImporting(false);
        setTimeout(() => {
          setImportingDone(true);
        }, 1000);
      }
    },

    onDrop(e) {
      //console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const importSelected = (values) => {
    setLoading(true);
    setSelectCommiteePopupProps({ ...selectCommiteePopupProps, show: false });
    getLogsApi('false');
    addObject(DATA_IMPORT_SELECTED_ID, { path: selectCommiteePopupProps.filepath, selecteId: values.jobRadio })
      .then((response) => {
        getLogsApi(false);
        if (response.data && response.data.success) {
          setImportStatus({ show: true, error: false, status: response.data.data.status });
          //setImportingDone(true);
        } else if (response.data && !response.data.success) {
          //setImportingDone(true);
          setImportStatus({ ...importStatus, show: true, error: true });
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error?.message);
      });
  };

  const popup = {
    closable: false,
    visibility: selectCommiteePopupProps.show,
    class: 'black-modal',
    content: (
      <DataPopup
        title="Select Committee"
        onClose={() => setSelectCommiteePopupProps({ ...selectCommiteePopupProps, show: false })}
        onDelete={() => onDelete()}
        reportPopData={selectCommiteePopupProps.data}
        importSelected={importSelected}
        importDidntWork={selectCommiteePopupProps.showError}
      />
    ),
    width: 550,
    onCancel: () => setVisible(false),
  };

  const popup2 = {
    closable: false,
    visibility: visible2,
    class: 'black-modal',
    content: (
      <DataPopup
        title="Import Didn't Work"
        onClose={() => setVisible2(false)}
        onDelete={() => onDelete()}
        importDidntWork={true}
        onContact={() => {
          setVisible2(false), setContactPopVisible(true);
        }}
      />
    ),
    width: 450,
    onCancel: () => setVisible2(false),
  };

  const releasePopup = {
    closable: false,
    visibility: releaseVisible,
    class: 'black-modal',
    content: (
      <ReleaseNotes
        title="Release Notes"
        onClose={() => setReleaseVisible(false)}
        onContact={() => {
          setReleaseVisible(false), setContactPopVisible(true);
        }}
      />
    ),
    width: 540,
    onCancel: () => setReleaseVisible(false),
  };

  const contactPopup = {
    closable: false,
    visibility: contactPopVisible,
    class: 'black-modal',
    content: <ContactSupport title="Contact Support" onClose={() => setContactPopVisible(false)} />,
    width: 540,
    onCancel: () => setContactPopVisible(false),
  };

  const uploadingPoints = [
    { text: 'Initializing process' },
    { text: 'Parsing database data' },
    { text: 'Setting up your committee' },
    { text: 'Adding contact list' },
    { text: 'Adding bank accounts' },
    { text: 'Adding debts' },
    { text: 'Adding receipts' },
    { text: 'Adding expenditures' },
    { text: 'Adding reports' },
    { text: 'Hold tight... Just a few final touches' },
  ];

  const MatchIndicator = ({ isMatch }) => (isMatch ? <CheckOutlined /> : <div className="circle_notmatch" />);

  const MatchDisplay = ({ array1, array2, matchKey }) => {
    // Create a mapping of names from array1 to their matching status
    const matchingStatus = array1.reduce((acc, item) => {
      const itemName = item[matchKey];
      acc[itemName] = array2.some((otherItem) => otherItem[matchKey] === itemName);
      return acc;
    }, {});

    return (
      <ul className="uploading_values">
        {array1.map((item, index) => (
          <li key={index}>
            <div className="left_value">
              {index + 1}. {item[matchKey]}
            </div>
            <div className="right_value">
              <div className="right_count">
                {array2.find((otherItem) => otherItem[matchKey] === item[matchKey])?.current
                  ? array2.find((otherItem) => otherItem[matchKey] === item[matchKey])?.current + '/'
                  : ''}
                {array2.find((otherItem) => otherItem[matchKey] === item[matchKey])?.total}
              </div>
              <MatchIndicator isMatch={matchingStatus[item[matchKey]]} />
            </div>
          </li>
        ))}
      </ul>
    );
  };
  ////console.log('getLogsData', getLogsData);
  return (
    <>
      <AddCommittee
        heading={fileUploading?.isUploading ? 'Your Data Is Being Imported...' : 'Import Data From SBoE Software'}
      >
        <div className={fileUploading?.isUploading ? 'file_is_uploading' : 'file_uploaded'}>
          <Spin indicator={antIcon} size="large" spinning={loading}>
            {importingDone ? (
              importStatus.show && !importStatus.error ? (
                <Success status={importStatus.status} />
              ) : (
                <Reject
                  onClose={() => {
                    setImportingDone(false);
                    setFileUploading({
                      percent: 100,
                      isUploading: false,
                    });
                  }}
                />
              )
            ) : isImporting ? (
              <Row gutter={[35, 35]} className="text-center">
                <Col span={24}>
                  <Image src={importingImage} preview={false} width={138} />
                  <Paragraph className="smallFont12">Please wait until we finish data import.</Paragraph>
                </Col>
                <Col span={24} className="text-center">
                  <Button
                    type="text"
                    htmlType="button"
                    className="font-gilroy"
                    size="large"
                    onClick={() => setIsImporting(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row gutter={[35, 35]}>
                <Col span={24}>
                  <Title level={4}>1. Locate the Database File</Title>
                  <Paragraph>
                    The file you need is <b>cf_data.mdb</b> and can be found in the folder where the software is
                    installed. Usually, the path is:
                  </Paragraph>
                  <Paragraph className="text-highlight mb-0">
                    C:\Program Files (x86)\SBoE\Campaign Finance\CF Remote\dbs\
                  </Paragraph>
                </Col>
                {/* <Col span={24}>
                <Image src={importVideo} preview={false} width={'100%'} />
              </Col> */}
                {/* <Col span={24}>
                <Card className="info-card">
                  <Space size={20}>
                    <Image src={infoIcon} width={30} preview={false} />
                    <div className="info_text">
                      <Paragraph className="smallFont14 w-100">
                        Please note, at this moment, there are a few ElectaFile features outstanding. These include the
                        ability to file amendments and the ability to add, import, and report loans, non-monetary gifts,
                        and independent expenditures. If your filing requires any of these items, you will not be able
                        to file via ElectaFile for Q2 2022. For a full list, please
                        <Button
                          type="text"
                          htmlType="button"
                          className="font-gilroy c-doger-blue smallFont14 text-normal p-0"
                          style={{ marginLeft: '5px' }}
                          onClick={() => setReleaseVisible(true)}
                        >
                          click here
                        </Button>
                        .
                      </Paragraph>
                    </div>
                  </Space>
                </Card>
              </Col> */}

                <Col span={24}>
                  <Title level={4} className="mb-1">
                    2. Drag and Drop the Database File Here
                  </Title>
                  <UploadField
                    isRequired={true}
                    fieldname="uploadSBOE"
                    label=""
                    control={control}
                    dummyRequest=""
                    initValue=""
                    draggerProps={draggerProps}
                    image={uploadIcon}
                    content="Upload SBoE DataBase File"
                    iProps={{
                      size: 'large',
                    }}
                    rules={{
                      required: 'Upload SBoE dataBase file is required.',
                    }}
                    validate={errors.uploadSBOE && 'error'}
                    validMessage={errors.uploadSBOE && errors.uploadSBOE.message}
                  />
                </Col>
                <Col span={24} className="text-center">
                  <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                    Import Data
                  </Button>
                </Col>
                <Col span={24} className="text-center">
                  <Button
                    type="text"
                    htmlType="button"
                    className="font-gilroy c-grey smallFont14"
                    size="large"
                    onClick={() => {
                      history.push('/add-committee');
                    }}
                  >
                    Continue without import
                  </Button>
                </Col>
              </Row>
            )}
          </Spin>
          {fileUploading?.isUploading && (
            <>
              <h4>Please wait until we finish data import. </h4>
              <div className="progress_status">
                <div className="top_progress">
                  <div className="left_progress">In Progress</div>
                  <div className="right_progress">Steps completed {getLogsData?.length}/10</div>
                </div>
                <Progress percent={fileUploading?.percent} status="active" showInfo={false} />
              </div>
              <MatchDisplay array1={uploadingPoints} array2={getLogsData} matchKey="text" />
            </>
          )}
        </div>
      </AddCommittee>
      <Popup {...popup} />
      <Popup {...popup2} />
      <Popup {...releasePopup} />
      <Popup {...contactPopup} />
    </>
  );
};
