import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Card, Button, Image, message, Form } from 'antd';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Dashboard from '../../../../pages/Dashboard';
import previousTransactions from '../../../../../assets/svg/previous-transactions.svg';
import { InputField } from '../../../../atoms/FormElement';
import PreviousForm from './PreviousForm';
import { useLocation } from 'react-router';
import { getAccountInfoFormData } from '../../../../modules/Dashboard/Settings/ducks/actions';
import { addObject } from '../../../../../services/crudApis';
import { SAVE_INITIAL_BALANCE } from '../../../../../configs/endpoints';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { control, errors, handleSubmit, setValue } = useForm({
    defaultValues: {
      prev_transaction: [{ name: 'useFieldArray' }],
    },
  });
  const { fields } = useFieldArray({
    control,
    name: 'prev_transaction',
  });
  const formData = useSelector((state) => state.setting.accountFormData);
  const activeTab = location?.state?.bankTransaction === 'settings';

  const onFinish = (val) => {
    setLoading(true);
    let payload = {};
    if (val && val.prev_transaction && val.prev_transaction.length > 0) {
      payload.accountBalances = val.prev_transaction.map((resp) => ({
        id: resp?.id,
        balance: resp.balance,
      }));
    }
    addObject(SAVE_INITIAL_BALANCE, payload)
      .then((response) => {
        //console.log('response', response);
        setLoading(false);
        message.success('Information Updated.');
        if (activeTab) {
          history.push({
            pathname: `/settings`,
            state: {
              settingTab: '4',
            },
          });
        } else {
          history.push('/specify-report');
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  useEffect(() => {
    dispatch(getAccountInfoFormData());
  }, []);

  useEffect(() => {
    if (formData && formData?.accounts && Object.keys(formData?.accounts).length > 0) {
      setValue('prev_transaction', formData.accounts);
    }
  }, [formData]);

  return (
    <Dashboard load={loading} noLink={true}>
      <Row align="middle" justify="center">
        <Col flex="0 0 740px" style={{ overflow: 'hidden', padding: '0 20px' }}>
          <Card className="white-card text-center">
            <Row justify="center" gutter={[20, 25]}>
              <Col span={24}>
                <Title level={2}>Specify Election Cycle Begin Balance</Title>
              </Col>
              <Col span={24}>
                <Image src={previousTransactions} preview={false} />
              </Col>
              <Col span={24}>
                <Text>
                  This is the <b>bank account balance as of {formData?.startYear}</b> - start date of the election
                  cycle.
                  <br />
                  <br />
                  If the committee participated in previous election cycles, this will also equal to the balance at the
                  end of the previous election cycle.
                </Text>
              </Col>
              <Col span={24}>
                <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
                  <Row gutter={[20, 30]} align="bottom" className="add_account">
                    <Col span={24}>
                      {fields?.map((item, index) => (
                        <Fragment key={item.id}>
                          <Row gutter={[20, 20]} align="bottom" style={{ marginBottom: '20px' }}>
                            <InputField
                              fieldname={`prev_transaction[${index}].id`}
                              label=""
                              control={control}
                              class="d-none mb-0"
                              initValue={item?.id}
                              iProps={{ size: 'large' }}
                            />
                            <Col span={12}>
                              <InputField
                                fieldname={`prev_transaction[${index}].code`}
                                label="Account Code"
                                control={control}
                                class="mb-0 label_inside"
                                initValue={item?.code}
                                iProps={{ size: 'large' }}
                                readOnly={true}
                              />
                            </Col>
                            <Col span={12}>
                              <InputField
                                isRequired={true}
                                fieldname={`prev_transaction[${index}].balance`}
                                label="Election Cycle Begin Balance"
                                tooltip={true}
                                tooltipTitle="Total amount of funds available on this bank account at the start of this election cycle."
                                control={control}
                                initValue={item?.begin_balance}
                                iProps={{
                                  size: 'large',
                                  prefix: '$',
                                  type: 'number',
                                }}
                                rules={{
                                  required: 'Election Cycle Begin Balance is required.',
                                }}
                                validate={
                                  Object.entries(errors).length > 0 &&
                                  errors?.prev_transaction?.length > 0 &&
                                  errors?.prev_transaction[index]?.balance &&
                                  'error'
                                }
                                validMessage={
                                  Object.entries(errors).length > 0 &&
                                  errors?.prev_transaction?.length > 0 &&
                                  errors?.prev_transaction[index]?.balance &&
                                  errors?.prev_transaction[index]?.balance?.message
                                }
                              />
                            </Col>
                          </Row>
                        </Fragment>
                      ))}
                    </Col>

                    <Col span={24} className="text-center">
                      <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                        Continue
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>

              <Col span={24}>
                <Button type="text" htmlType="button" className="font-gilroy c-grey" onClick={() => history.goBack()}>
                  Back
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Dashboard>
  );
};
