import React, { useState, useEffect } from 'react';
import { Spin, Button, Row, Col, Typography, Image, Form, Space, Card, Table, message } from 'antd';
import { useForm } from 'react-hook-form';
import { DateField2, SelectField } from '../../../../atoms/FormElement';
import { useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import crossIcon from '../../../../../assets/svg/cross-icon.svg';
import infoIcon from '../../../../../assets/svg/info-icon.svg';
import { addObject, replaceBankAccount } from '../../../../../services/crudApis';
import moment from 'moment';

const { Title, Paragraph, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { onClose, title, bankAccountDropdown, accountId, afterConfirm } = props;
  const { control, errors, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const dispatch = useDispatch();

  const listColumns = [
    {
      title: 'Year',
      dataIndex: 'year',
    },
    {
      title: 'Report',
      dataIndex: 'label',
    },
    {
      title: 'Filed',
      dataIndex: 'date',
    },
  ];

  const dateSelected = (selectedDate) => {
    setLoading(true);
    const payload = {
      dateEffective: selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : false,
      reportIds: false,
    };
    addObject('getAmendedReports', payload)
      .then((response) => {
        setReportData(response?.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    const payload = {
      deleteAccountId: accountId,
      replaceAccountId: values?.account?.value,
      dateEffective: moment(values?.date_effective).format('YYYY-MM-DD'),
    };
    replaceBankAccount(payload)
      .then(() => {
        afterConfirm();
        setLoading(false);
      })
      .catch(() => {
        message.error('Error');
        setLoading(false);
      });
    // submitFileExternal(date);
  };
  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[20, 20]} justify="space-between" align="middle">
          <Col>
            <Title level={4} className="mb-0">
              {title}
            </Title>
          </Col>
          <Col>
            <Button size="large" type="link" className="p-0" onClick={onClose}>
              <Image src={crossIcon} preview={false} size={48} />
            </Button>
          </Col>
          <Col span={24}>
            <Card className="info-card">
              <Space size={20}>
                <Image src={infoIcon} width={30} preview={false} />
                <div className="info_text">
                  <Paragraph className="smallFont14 w-100 mb-0">
                    These changes will affect the data in the reports you have filed. Report Amendments will be created
                    and should be filed accordingly.
                  </Paragraph>
                </div>
              </Space>
            </Card>
          </Col>
          <Col span={24}>
            <Paragraph>
              These changes will affect the data in the reports you have filed. Report Amendments will be created and
              should be filed accordingly.
            </Paragraph>
          </Col>

          <Col span={24}>
            <SelectField
              isRequired={true}
              fieldname="account"
              label="Account"
              control={control}
              class="mb-0"
              iProps={{ placeholder: 'Please select' }}
              rules={{ required: 'Account is required.' }}
              initValue=""
              selectOption={bankAccountDropdown}
              validate={errors.account && 'error'}
              validMessage={errors.account && errors.account.message}
            />
          </Col>

          <Col span={24}>
            <DateField2
              fieldname="date_effective"
              label="Date Effective"
              control={control}
              class="mb-0"
              iProps={{ size: 'large' }}
              initValue=""
              onChange={(e) => dateSelected(e)}
              isRequired={true}
              rules={{
                required: 'Date Effective',
              }}
              validate={errors.date_effective && 'error'}
              validMessage={errors.date_effective && errors.date_effective.message}
            />
          </Col>

          <Col span={24}>
            <Title level={4} className="text-center font-gilroy RegularFont mt-2 mb-1">
              Reports to be Amended
            </Title>
            <div className="remove_margin">
              <Table columns={listColumns} dataSource={reportData} pagination={false} className="reports_popup" />
            </div>
          </Col>

          <Col span={24}>
            <Row align="middle" justify="center" className="mt-1" gutter={[20, 20]}>
              <Col>
                <Button
                  type="primary"
                  onClick={onClose}
                  htmlType="button"
                  className="font-gilroy white-btn"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Confirm
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
