export const COMMITTEE_FORM_DATA = 'COMMITTEE_FORM_DATA';
export const CANDIDATE_FORM_DATA = 'CANDIDATE_FORM_DATA';
export const OFFICER_FORM_DATA = 'OFFICER_FORM_DATA';
export const ACCOUNT_FORM_DATA = 'ACCOUNT_FORM_DATA';
export const COMMITTEE_DROP_DATA = 'COMMITTEE_DROP_DATA';
export const COMMITTEE_LIST_DATA = 'COMMITTEE_LIST_DATA';
export const BANK_ACCOUNTS_LIST = 'BANK_ACCOUNTS_LIST';
export const FINANCIAL_INSTITUTES = 'FINANCIAL_INSTITUTES';
export const LOGS_BY_COMMITTEE_ID = 'LOGS_BY_COMMITTEE_ID';
export const CLEAR_DATA = 'CLEAR_DATA'
