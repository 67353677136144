export const countyList = [
  {
    label: 'Alamance',
    value: '1',
  },
  {
    label: 'Alexander',
    value: '2',
    mailingAddress: 'PO BOX 326;TAYLORSVILLE, NC 28681',
  },
  {
    label: 'Alleghany',
    value: '3',
    mailingAddress: 'PO BOX 65;SPARTA, NC 28675',
  },
  {
    label: 'Anson',
    value: '4',
    mailingAddress: 'PO BOX 768;WADESBORO, NC 28170',
  },
  {
    label: 'Ashe',
    value: '5',
    mailingAddress: '150 GOVERNMENT CIRCLE, STE 2100;JEFFERSON, NC 28640',
  },
  {
    label: 'Avery',
    value: '6',
    mailingAddress: 'PO BOX 145;NEWLAND, NC 28657',
  },
  {
    label: 'Beaufort',
    value: '7',
    mailingAddress: 'PO BOX 1016;WASHINGTON, NC 27889',
  },
  {
    label: 'Bertie',
    value: '8',
    mailingAddress: 'PO BOX 312;WINDSOR, NC 27983',
  },
  {
    label: 'Bladen',
    value: '9',
    mailingAddress: 'PO BOX 512;ELIZABETHTOWN, NC 28337',
  },
  {
    label: 'Brunswick',
    value: '10',
    mailingAddress: 'PO BOX 2;BOLIVIA, NC 28422',
  },
  {
    label: 'Buncombe',
    value: '11',
    mailingAddress: 'PO BOX 7468;ASHEVILLE, NC 28802',
  },
  {
    label: 'Burke',
    value: '12',
    mailingAddress: 'PO BOX 798;MORGANTON, NC 28680',
  },
  {
    label: 'Cabarrus',
    value: '13',
    mailingAddress: 'PO BOX 1315;CONCORD, NC 28026',
  },
  {
    label: 'Caldwell',
    value: '14',
    mailingAddress: 'PO BOX 564;LENOIR, NC 28645',
  },
  {
    label: 'Camden',
    value: '15',
    mailingAddress: 'PO BOX 206;CAMDEN, NC 27921',
  },
  {
    label: 'Carteret',
    value: '16',
    mailingAddress: '1702 LIVE OAK ST, SUITE 200;BEAUFORT, NC 28516',
  },
  {
    label: 'Caswell',
    value: '17',
    mailingAddress: 'PO BOX 698;YANCEYVILLE, NC 27379',
  },
  {
    label: 'Catawba',
    value: '18',
    mailingAddress: 'PO BOX 132;NEWTON, NC 28658',
  },
  {
    label: 'Chatham',
    value: '19',
    mailingAddress: 'PO BOX 111;PITTSBORO, NC 27312',
  },
  {
    label: 'Cherokee',
    value: '20',
    mailingAddress: '40 PEACHTREE STREET;MURPHY, NC 28906',
  },
  {
    label: 'Chowan',
    value: '21',
    mailingAddress: 'PO BOX 133;EDENTON, NC 27932',
  },
  {
    label: 'Clay',
    value: '22',
    mailingAddress: '75 RIVERSIDE CIRCLE SUITE 3;HAYESVILLE, NC 28904',
  },
  {
    label: 'Cleveland',
    value: '23',
    mailingAddress: 'PO BOX 1299;SHELBY, NC 28151',
  },
  {
    label: 'Columbus',
    value: '24',
    mailingAddress: 'PO BOX 37;WHITEVILLE, NC 28472',
  },
  {
    label: 'Craven',
    value: '25',
    mailingAddress: '406 CRAVEN STREET;NEW BERN, NC 28560',
  },
  {
    label: 'Cumberland',
    value: '26',
    mailingAddress: '227 FOUNTAINHEAD LANE SUITE 101;FAYETTEVILLE, NC 28301',
  },
  {
    label: 'Currituck',
    value: '27',
    mailingAddress: 'PO BOX 177;CURRITUCK, NC 27929',
  },
  {
    label: 'Dare',
    value: '28',
    mailingAddress: 'PO BOX 1000;MANTEO, NC 27954',
  },
  {
    label: 'Davidson',
    value: '29',
    mailingAddress: 'PO BOX 1084;LEXINGTON, NC 27293',
  },
  {
    label: 'Davie',
    value: '30',
    mailingAddress: '161 POPLAR ST. SUITE 102;MOCKSVILLE, NC 27028',
  },
  {
    label: 'Duplin',
    value: '31',
    mailingAddress: 'PO BOX 975;KENANSVILLE, NC 28349',
  },
  {
    label: 'Durham',
    value: '32',
    mailingAddress: 'PO BOX 868;DURHAM, NC 27702',
  },
  {
    label: 'Edgecombe',
    value: '33',
    mailingAddress: 'PO BOX 10;TARBORO, NC 27886',
  },
  {
    label: 'Forsyth',
    value: '34',
    mailingAddress: '201 N. CHESTNUT STREET;WINSTON SALEM, NC 27101',
  },
  {
    label: 'Franklin',
    value: '35',
    mailingAddress: 'PO BOX 180;LOUISBURG, NC 27549',
  },
  {
    label: 'Gaston',
    value: '36',
    mailingAddress: 'PO BOX 1396;GASTONIA, NC 28053',
  },
  {
    label: 'Gates',
    value: '37',
    mailingAddress: 'PO BOX 621;GATESVILLE, NC 27938',
  },
  {
    label: 'Graham',
    value: '38',
    mailingAddress: 'PO BOX 1239;ROBBINSVILLE, NC 28771',
  },
  {
    label: 'Granville',
    value: '39',
    mailingAddress: 'PO BOX 83;OXFORD, NC 27565',
  },
  {
    label: 'Greene',
    value: '40',
    mailingAddress: 'PO BOX 583;SNOW HILL, NC 28580',
  },
  {
    label: 'Guilford',
    value: '41',
    mailingAddress: 'PO BOX 3427;GREENSBORO, NC 27402',
  },
  {
    label: 'Halifax',
    value: '42',
    mailingAddress: 'PO BOX 101;HALIFAX, NC 27839',
  },
  {
    label: 'Harnett',
    value: '43',
    mailingAddress: 'PO BOX 356;LILLINGTON, NC 27546',
  },
  {
    label: 'Haywood',
    value: '44',
    mailingAddress: '63 ELMWOOD WAY;SUITE A;WAYNESVILLE, NC 28786',
  },
  {
    label: 'Henderson',
    value: '45',
    mailingAddress: 'PO BOX 2090;HENDERSONVILLE, NC 28793',
  },
  {
    label: 'Hertford',
    value: '46',
    mailingAddress: 'PO BOX 355;AHOSKIE, NC 27910',
  },
  {
    label: 'Hoke',
    value: '47',
    mailingAddress: 'PO BOX 1565;RAEFORD, NC 28376',
  },
  {
    label: 'Hyde',
    value: '48',
    mailingAddress: 'PO BOX 152;SWAN QUARTER, NC 27885',
  },
  {
    label: 'Iredell',
    value: '49',
    mailingAddress: '203 STOCKTON ST.;STATESVILLE, NC 28677',
  },
  {
    label: 'Jackson',
    value: '50',
    mailingAddress: '876 SKYLAND DRIVE;SUITE 1;SYLVA, NC 28779',
  },
  {
    label: 'Johnston',
    value: '51',
    mailingAddress: 'PO BOX 1172;SMITHFIELD, NC 27577',
  },
  {
    label: 'Jones',
    value: '52',
    mailingAddress: '367-B HWY 58-S, UNIT B;TRENTON, NC 28585',
  },
  {
    label: 'Lee',
    value: '53',
    mailingAddress: '1503 ELM ST. SUITE 1;SANFORD, NC 27330',
  },
  {
    label: 'Lenoir',
    value: '54',
    mailingAddress: 'PO BOX 3503;KINSTON, NC 28502',
  },
  {
    label: 'Lincoln',
    value: '55',
    mailingAddress: 'PO BOX 977;LINCOLNTON, NC 28093',
  },
  {
    label: 'Macon',
    value: '56',
    mailingAddress: '5 WEST MAIN ST;FLOOR 1;FRANKLIN, NC 28734',
  },
  {
    label: 'Madison',
    value: '57',
    mailingAddress: 'PO BOX 142;MARSHALL, NC 28753',
  },
  {
    label: 'Martin',
    value: '58',
    mailingAddress: 'PO BOX 801;WILLIAMSTON, NC 27892',
  },
  {
    label: 'Mcdowell',
    value: '59',
    mailingAddress: 'PO BOX 1509;MARION, NC 28752',
  },
  {
    label: 'Mecklenburg',
    value: '60',
    mailingAddress: 'PO BOX 31788;CHARLOTTE, NC 28231',
  },
  {
    label: 'Mitchell',
    value: '61',
    mailingAddress: '11 N MITCHELL AVE;ROOM 108;BAKERSVILLE, NC 28705',
  },
  {
    label: 'Montgomery',
    value: '62',
    mailingAddress: 'PO BOX 607;TROY, NC 27371',
  },
  {
    label: 'Moore',
    value: '63',
    mailingAddress: 'POST OFFICE BOX 787;CARTHAGE, NC 28327',
  },
  {
    label: 'Nash',
    value: '64',
    mailingAddress: 'PO BOX 305;NASHVILLE, NC 27856',
  },
  {
    label: 'New Hanover',
    value: '65',
    mailingAddress: '1241A MILITARY CUTOFF ROAD;WILMINGTON, NC 28405',
  },
  {
    label: 'Northampton',
    value: '66',
    mailingAddress: 'PO BOX 603;JACKSON, NC 27845',
  },
  {
    label: 'Onslow',
    value: '67',
    mailingAddress: '246 GEORGETOWN RD.;JACKSONVILLE, NC 28540',
  },
  {
    label: 'Orange',
    value: '68',
    mailingAddress: 'PO BOX 220;HILLSBOROUGH, NC 27278',
  },
  {
    label: 'Pamlico',
    value: '69',
    mailingAddress: 'PO BOX 464;BAYBORO, NC 28515',
  },
  {
    label: 'Pasquotank',
    value: '70',
    mailingAddress: 'PO BOX 1797;ELIZABETH CITY, NC 27906',
  },
  {
    label: 'Pender',
    value: '71',
    mailingAddress: 'PO BOX 1232;BURGAW, NC 28425',
  },
  {
    label: 'Perquimans',
    value: '72',
    mailingAddress: 'PO BOX 336;HERTFORD, NC 27944',
  },
  {
    label: 'Person',
    value: '73',
    mailingAddress: '331 SOUTH MORGAN STREET;ROXBORO, NC 27573',
  },
  {
    label: 'Pitt',
    value: '74',
    mailingAddress: 'PO BOX 56;GREENVILLE, NC 27835',
  },
  {
    label: 'Polk',
    value: '75',
    mailingAddress: 'PO BOX 253;COLUMBUS, NC 28722',
  },
  {
    label: 'Randolph',
    value: '76',
    mailingAddress: '1457 N. FAYETTEVILLE ST.;ASHEBORO, NC 27203',
  },
  {
    label: 'Richmond',
    value: '77',
    mailingAddress: 'PO BOX 1843;ROCKINGHAM, NC 28380',
  },
  {
    label: 'Robeson',
    value: '78',
    mailingAddress: 'PO BOX 2159;LUMBERTON, NC 28359',
  },
  {
    label: 'Rockingham',
    value: '79',
    mailingAddress: 'PO BOX 22;WENTWORTH, NC 27375',
  },
  {
    label: 'Rowan',
    value: '80',
    mailingAddress: '1935 JAKE ALEXANDER BLVD W;SUITE D10;SALISBURY, NC 28147',
  },
  {
    label: 'Rutherford',
    value: '81',
    mailingAddress: 'PO BOX 927;RUTHERFORDTON, NC 28139',
  },
  {
    label: 'Sampson',
    value: '82',
    mailingAddress: '335 COUNTY COMPLEX ROAD;SUITE 100;CLINTON, NC 28328',
  },
  {
    label: 'Scotland',
    value: '83',
    mailingAddress: '231 EAST CRONLY ST, SUITE 305;LAURINBURG, NC 28352',
  },
  {
    label: 'Stanly',
    value: '84',
    mailingAddress: 'PO BOX 1309;ALBEMARLE, NC 28002',
  },
  {
    label: 'Stokes',
    value: '85',
    mailingAddress: 'PO BOX 34;DANBURY, NC 27016',
  },
  {
    label: 'Surry',
    value: '86',
    mailingAddress: 'PO BOX 372;DOBSON, NC 27017',
  },
  {
    label: 'Swain',
    value: '87',
    mailingAddress: 'PO BOX 133;BRYSON CITY, NC 28713',
  },
  {
    label: 'Transylvania',
    value: '88',
    mailingAddress: 'PO BOX 868;BREVARD, NC 28712',
  },
  {
    label: 'Tyrrell',
    value: '89',
    mailingAddress: 'PO BOX 449;COLUMBIA, NC 27925',
  },
  {
    label: 'Union',
    value: '90',
    mailingAddress: 'PO BOX 1106;MONROE, NC 28111',
  },
  {
    label: 'Vance',
    value: '91',
    mailingAddress: '300 S. GARNETT ST., STE C;HENDERSON, NC 27536',
  },
  {
    label: 'Wake',
    value: '92',
    mailingAddress: 'PO BOX 695;RALEIGH, NC 27602',
  },
  {
    label: 'Warren',
    value: '93',
    mailingAddress: 'PO BOX 803;WARRENTON, NC 27589',
  },
  {
    label: 'Washington',
    value: '94',
    mailingAddress: 'PO BOX 1007;PLYMOUTH, NC 27962',
  },
  {
    label: 'Watauga',
    value: '95',
    mailingAddress: 'PO BOX 528;BOONE, NC 28607',
  },
  {
    label: 'Wayne',
    value: '96',
    mailingAddress: '309 EAST CHESTNUT STREET;GOLDSBORO, NC 27530',
  },
  {
    label: 'Wilkes',
    value: '97',
    mailingAddress: '110 NORTH STREET, RM 315;WILKESBORO, NC 28697',
  },
  {
    label: 'Wilson',
    value: '98',
    mailingAddress: 'PO BOX 2121;WILSON, NC 27894',
  },
  {
    label: 'Yadkin',
    value: '99',
    mailingAddress: 'PO BOX 877;YADKINVILLE, NC 27055',
  },
  {
    label: 'Yancey',
    value: '100',
    mailingAddress: 'PO BOX 763;BURNSVILLE, NC 28714',
  },
];
