import axios from '../../../../../services/axiosInterceptor';
import * as action_types from './constants';
import { baseUrl } from '../../../../../configs/constants';

export const getExpenditureListingData = (fromDate = 'null', toDate = 'null') => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getExpenditureList/${fromDate}/${toDate}`);
    dispatch({
      type: action_types.EXPENDITURE_LIST_DATA,
      data: data,
    });
  };
};
export const getExemptListing = () => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getExemptListing`);
    dispatch({
      type: action_types.EXEMPT_LIST_DATA,
      data: data,
    });
  };
};

export const getExemptFormData = (id) => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getExemptFormData/${id}`);
    dispatch({
      type: action_types.EXEMPT_PLAN_SINGLE,
      data: data,
    });
  };
};

export const getExpenditureDropdownData = (type, id = '') => {
  return async (dispatch) => {
    const { data: data } = await axios.get(`${baseUrl}/getExpenditureFormData/${type}/${id}`);
    dispatch({
      type: action_types.EXPENDITURE_DROP_DATA,
      data: data,
    });
  };
};
