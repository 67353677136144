import React, { Fragment } from 'react';
import { Space, Button, Row, Col, Typography, Image, Form, Radio } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import crossIcon from '../../../../assets/svg/cross-icon.svg';
import { InputRadio } from '../../../atoms/FormElement';

const { Title, Paragraph, Text } = Typography;

export default (props) => {
  const { onClose, reportPopData, title, importSelected, importDidntWork, onContact } = props;
  const { control, handleSubmit, errors } = useForm();
  return (
    <Form layout="vertical" name="normal_login" onFinish={handleSubmit(importSelected)}>
      <Row justify="space-between" align="middle" gutter={[25, 25]}>
        <Col>
          <Title level={4} className="mb-0">
            {title}
          </Title>
        </Col>
        <Col>
          <Button size="large" type="link" className="p-0" onClick={onClose}>
            <Image src={crossIcon} preview={false} size={48} />
          </Button>
        </Col>
        {importDidntWork ? (
          <>
            <Col span={24}>
              <Paragraph>
                We couldn't recornize the file. Make sure if you're importing the correct file. Please check:
              </Paragraph>
              <ul>
                <li>FIle location</li>
                <li>File name</li>
              </ul>
              <Paragraph>If you are not sure what's wrong, please don't hesitate to contact us.</Paragraph>
            </Col>
            <Col span={24}>
              <Row justify="center" gutter={[20, 20]}>
                <Col>
                  <Button
                    type="primary"
                    onClick={onContact}
                    htmlType="button"
                    className="font-gilroy white-btn"
                    size="large"
                  >
                    Contact Us
                  </Button>
                </Col>
                <Col>
                  <Button type="secondary" htmlType="submit" className="font-gilroy" size="large" onClick={onClose}>
                    Got It
                  </Button>
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <>
            <Col span={24}>
              <Paragraph>The file contains multiple committees, please select which one you want to import.</Paragraph>
            </Col>
            <Col span={24}>
              <InputRadio
                fieldname="jobRadio"
                control={control}
                initValue={'JobTitle'}
                classField="small-radio"
                onChange={(e) => {}}
                options={
                  <>
                    <Row gutter={[20, 20]}>
                      {reportPopData?.map((item, index) => (
                        <Fragment key={index}>
                          <Col span={24}>
                            <Radio value={item.value}>
                              <Paragraph className="mb-0">{item.name}</Paragraph>
                              <Text>{item.boeId}</Text>
                            </Radio>
                          </Col>
                        </Fragment>
                      ))}
                    </Row>
                  </>
                }
              />
            </Col>
            <Col span={24} className="text-center">
              <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                Import Selected
              </Button>
            </Col>
            <Col span={24}>
              <Paragraph>
                If you don't see the committee in this list, this means we do not support such committee type,
                organization level, or election type at the moment. Please let us know if you'd like us to support your
                case. We want to learn more about your needs!
              </Paragraph>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};
