import * as action_types from './constants';

const initialState = {
  adminDataList: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.ADMIN_DATA:
      return { ...state, adminDataList: data };

    default:
      return state;
  }
};
