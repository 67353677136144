import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Radio, Button, Descriptions } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { InputRadio, InputField, TextAreaField, DateField, SelectField } from '../../../../../atoms/FormElement';
import { getReceiptDropdownData } from '../../ducks/actions';
import { getContactFormData, getContactFormDataPayee } from '../../../Contacts/ducks/actions';
import ContactBox from '../../../../../molecules/Dashboard/ContactBox';

const { Paragraph, Title } = Typography;

export default (props) => {
  const { control, errors, setValue, formData, setLoading } = props;
  const dispatch = useDispatch();
  const [contactBoxEditCase, setContactBoxEditCase] = useState('');
  const [contactToSelect, setContactToSelect] = useState(null);
  const [contactBoxEditCaseReimbursee, setContactBoxEditCaseReimbursee] = useState('');
  const [contactToSelectReimbursee, setContactToSelectReimbursee] = useState(null);

  const receiptFormData = useSelector((state) => state.receipt.receiptDropData);
  let contributerData = useSelector((state) => state.contact.contactFormData);
  let contributerDataReimbursee = useSelector((state) => state.contact.contactFormDataPayee);

  useEffect(() => {
    dispatch(getReceiptDropdownData('general_contribution', 'null'));
  }, []);

  useEffect(() => {
    if (
      formData?.receiptData &&
      Object.keys(receiptFormData)?.length > 0 &&
      Object.keys(formData?.receiptData)?.length > 0
    ) {
      const selectedPayeeList = receiptFormData?.contributors?.find(
        (e) => e?.value == (contactToSelect ? contactToSelect : formData?.receiptData?.payee_id),
      );
      const selectedReimburseList = receiptFormData?.contributors?.find(
        (e) =>
          e?.value == (contactToSelectReimbursee ? contactToSelectReimbursee : formData?.receiptData?.reimbursee_id),
      );
      setValue('amount', formData.receiptData?.amount);
      setValue('date', formData?.receiptData?.date ? moment(formData?.receiptData?.date, 'YYYY-MM-DD HH:mm') : '');

      setValue('credit_used', formData.receiptData?.credit_used == true ? 'Yes' : 'No');
      setValue('in_kind_description', formData.receiptData?.in_kind_description);
      setContactToSelect(null);
      setContactToSelectReimbursee(null);
      setTimeout(() => {
        setValue('payee', selectedPayeeList);
        setValue('reimbursee', selectedReimburseList);
        setContactBoxEditCase(selectedPayeeList);
        setContactBoxEditCaseReimbursee(selectedReimburseList);
      }, 1000);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [receiptFormData, formData]);

  const changeValueByDropDown = (e) => {
    dispatch(getContactFormData(e));
  };

  const changeValueByDropDownReimbursee = (e) => {
    dispatch(getContactFormDataPayee(e));
  };

  const updateDropdownValues = () => {
    dispatch(getReceiptDropdownData('general_contribution', 'null'));
  };

  return (
    <>
      <Row gutter={[48, 30]}>
        <Col span={12}>
          <Row gutter={[12, 30]}>
            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="amount"
                label="Amount"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  prefix: '$',
                  type: 'number',
                }}
                rules={{
                  required: 'Amount is required',
                }}
                validate={errors.amount && 'error'}
                validMessage={errors.amount && errors.amount.message}
              />
            </Col>

            <Col span={24}>
              <DateField
                fieldname="date"
                label="Date"
                control={control}
                class="mb-0"
                iProps={{ size: 'large' }}
                initValue=""
                isRequired={true}
                rules={{
                  required: 'Date is required.',
                }}
                validate={errors.date && 'error'}
                validMessage={errors.date && errors.date.message}
              />
            </Col>

            <ContactBox
              control={control}
              errors={errors}
              selectData={receiptFormData?.contributors}
              changeValueByDropDown={changeValueByDropDown}
              updateDropdownValues={updateDropdownValues}
              contributerData={contributerData}
              contactBoxEditCase={contactBoxEditCase}
              contactToSelect={contactToSelect}
              setContactToSelect={setContactToSelect}
              setValue={setValue}
              fieldname="payee"
              label="Payee"
            />

            <ContactBox
              control={control}
              errors={errors}
              selectData={receiptFormData?.contributors}
              changeValueByDropDown={changeValueByDropDownReimbursee}
              updateDropdownValues={updateDropdownValues}
              contributerData={contributerDataReimbursee}
              contactBoxEditCase={contactBoxEditCaseReimbursee}
              contactToSelect={contactToSelectReimbursee}
              setContactToSelect={setContactToSelectReimbursee}
              setValue={setValue}
              fieldname="reimbursee"
              label="Reimbursee"
            />
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TextAreaField
                isRequired={true}
                fieldname="in_kind_description"
                label="In-Kind Contribution Description"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                }}
                rules={{
                  required: 'In-Kind Contribution Description is required.',
                }}
                tooltip={true}
                tooltipTitle="What was purchased on behalf of the committee."
                validate={errors.in_kind_description && 'error'}
                validMessage={errors.in_kind_description && errors.in_kind_description.message}
              />
            </Col>

            <Col span={24}>
              <InputRadio
                fieldname="credit_used"
                label="Was a Credit Card Used For This Transaction?"
                control={control}
                classField="tab-type-radio"
                onChange={(e) => {}}
                options={
                  <>
                    <Row gutter={[20, 20]}>
                      <Col span={12}>
                        <Radio value="No">No</Radio>
                      </Col>
                      <Col span={12}>
                        <Radio value="Yes">Yes</Radio>
                      </Col>
                    </Row>
                  </>
                }
                rules={{
                  required: 'Was a Credit Card Used For This Transaction? is required.',
                }}
                validate={errors.credit_used && 'error'}
                validMessage={errors.credit_used && errors.credit_used.message}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
